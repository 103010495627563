import { faAddressCard } from "@fortawesome/pro-regular-svg-icons/faAddressCard";
import { faCalculator } from "@fortawesome/pro-regular-svg-icons/faCalculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { GlobalRow, GlobalVerticalDivider } from "../../../globalStyles";
import {
    companySituations,
    companyStatuses,
    foreignStateOfResidences,
    inpsHeadQuarters,
    legalForms
} from "../../../utils/constants";

//TODO: Refactor when UX is ready

export const settings = (classifier, taxIdentifier) => {
    return {
        registry: {
            COMPANY: {
                fields: [
                    ...(taxIdentifier?.length > 15 ? ["name", "surname", "birthDate", "gender"] : []),
                    "businessLegalForm",
                    "establishmentDate",
                    "expiryDate"
                    /*"shareCapital",
                "paidUpShareCapital",
                "sharesCount",
                "worthOfStock",
                "companySituation",
                "companyStatus",
                "financialStatementApprovalDate",
                "financialStatementEndDate",
                "inpsHeadQuarter",
                "foreignStateOfResidence"*/
                ],
                dateFields: [
                    "establishmentDate",
                    "expiryDate",
                    ...(taxIdentifier?.length > 15 ? ["birthDate"] : [])
                    /*"financialStatementApprovalDate",
                "financialStatementEndDate"*/
                ],
                sections: [
                    {
                        showAlert: "personalData",
                        showEditButton: true,
                        mainTitle: "c-customer-edit-section.registry.legalFormData",
                        mainTitleSecondary: true,
                        noDividerAfter: true,
                        rows: [
                            ["businessLegalForm"]
                            /*["shareCapital", "paidUpShareCapital"],
                        ["sharesCount", "worthOfStock"]*/
                            //["companySituation"],
                            //["companyStatus"]
                        ]
                    },
                    ...(taxIdentifier?.length > 15
                        ? [
                              {
                                  showAlert: "personalData",
                                  showEditButton: false,
                                  mainTitle: "c-customer-edit-section.registry.otherData",
                                  title: "c-customer-edit-section.registry.subjectData",
                                  rows: [["name", "surname"], ["birthDate"], ["gender"]]
                              }
                          ]
                        : []),
                    {
                        title: "c-customer-edit-section.registry.fiscalPositionData",
                        rows: [["establishmentDate", "expiryDate"]]
                    } /*,
                {
                    title: "c-customer-edit-section.registry.balanceData",
                    rows: [["financialStatementApprovalDate", "financialStatementEndDate"]]
                },
                {
                    title: "c-customer-edit-section.registry.codesData",
                    rows: [["inpsHeadQuarter"], ["foreignStateOfResidence"]]
                }*/
                ]
            },
            BUILDING: {
                fields: [
                    "businessLegalForm",
                    "establishmentDate",
                    "expiryDate"
                    /*"shareCapital",
                "paidUpShareCapital",
                "sharesCount",
                "worthOfStock",
                "companySituation",
                "companyStatus",
                "inpsHeadQuarter",
                "foreignStateOfResidence"*/
                ],
                dateFields: ["establishmentDate", "expiryDate"],
                sections: [
                    {
                        showAlert: "personalData",
                        showEditButton: true,
                        mainTitle: "c-customer-edit-section.registry.legalFormData",
                        mainTitleSecondary: true,
                        noDividerAfter: true,
                        rows: [
                            ["businessLegalForm"]
                            /*["shareCapital", "paidUpShareCapital"],
                        ["sharesCount", "worthOfStock"],
                        ["companySituation"],
                        ["companyStatus"]*/
                        ]
                    },
                    {
                        title: "c-customer-edit-section.registry.fiscalPositionData",
                        rows: [["establishmentDate", "expiryDate"]]
                    } /*,
                {
                    title: "c-customer-edit-section.registry.codesData",
                    rows: [["inpsHeadQuarter"], ["foreignStateOfResidence"]]
                }*/
                ]
            },
            PERSON: {
                fields: ["name", "surname", "birthDate", "gender"],
                dateFields: ["birthDate"],
                sections: [
                    {
                        showAlert: "personalData",
                        showEditButton: true,
                        mainTitle: "c-customer-edit-section.registry.otherData",
                        title: "c-customer-edit-section.registry.subjectData",
                        rows: [["name", "surname"], ["birthDate"], ["gender"]]
                    }
                ]
            },
            PUBLIC_COMPANY: {
                fields: ["companySituation", "companyStatus", "inpsHeadQuarter", "foreignStateOfResidence"],
                sections: [
                    {
                        showAlert: "personalData",
                        showEditButton: true,
                        mainTitle: "c-customer-edit-section.registry.otherData",
                        rows: [["companySituation"], ["companyStatus"]]
                    },
                    {
                        title: "c-customer-edit-section.registry.codesData",
                        rows: [["inpsHeadQuarter"], ["foreignStateOfResidence"]]
                    }
                ]
            }
        }
    };
};

export const headers = {
    registry: {
        icon: <FontAwesomeIcon icon={faAddressCard} />,
        title: "c-customer-edit-section.registry.mainData",
        subtitle: "c-customer-edit-section.registry.mainDataDesc",
        rows: [
            [{ field: "classifier", translateValues: "classifier.", label: "field.registry.type" }],
            [
                {
                    field: "countryCode",
                    subSection: "personalData",
                    customRender: customerDetails => {
                        return customerDetails?.personalData?.countryCode ? (
                            <>
                                <FormattedMessage id={"country." + customerDetails?.personalData?.countryCode} /> (
                                {customerDetails?.personalData?.countryCode})
                            </>
                        ) : (
                            <></>
                        );
                    },
                    label: "field.registry.country"
                }
            ],
            [
                {
                    field: "businessName",
                    subSection: "personalData",
                    label: "field.registry.businessName",
                    labelCondition: customerDetails => {
                        return ["PUBLIC_COMPANY", "BUILDING"].indexOf(customerDetails?.classifier) > -1
                            ? "field.registry.denomination"
                            : "field.registry.businessName";
                    },
                    renderCondition: customerDetails => {
                        return (
                            ["COMPANY", "PUBLIC_COMPANY", "STUDIO", "BUILDING"].indexOf(customerDetails?.classifier) >
                            -1
                        );
                    }
                }
            ],
            [
                {
                    field: "vatIdentifier",
                    subSection: "personalData",
                    label: "field.registry.vatIdentifier",
                    afterElement: <GlobalVerticalDivider />,
                    renderCondition: customerDetails => {
                        return ["COMPANY", "STUDIO"].indexOf(customerDetails?.classifier) > -1;
                    }
                },
                { field: "taxIdentifier", subSection: "personalData", label: "field.registry.taxIdentifier" }
            ]
        ]
    },
    taxation: {
        icon: <FontAwesomeIcon icon={faCalculator} />,
        title: "c-customer-edit-section.taxation.mainData",
        subtitle: "c-customer-edit-section.taxation.mainDataDesc"
    }
};

export const fieldsSettings = {
    name: {
        label: "field.registry.name",
        componentStyle: { width: 250 },
        style: { width: 300 },
        withMargin: true,
        component: "textfield",
        required: true
    },
    surname: {
        label: "field.registry.surname",
        componentStyle: { width: 250 },
        style: { width: 300 },
        withMargin: true,
        component: "textfield",
        required: true
    },
    birthDate: {
        label: "field.registry.birthDate",
        fullWidth: true,
        withMargin: true,
        component: "datepicker",
        emptyLabel: "notSpecified.birthDate"
    },
    gender: {
        label: "field.registry.gender",
        fullWidth: true,
        withMargin: true,
        component: "radio",
        options: [
            { id: "MALE", value: "MALE" },
            { id: "FEMALE", value: "FEMALE" }
        ],
        translateValues: "gender."
    },
    companySituation: {
        label: "field.registry.companySituation",
        withMargin: true,
        fullWidth: true,
        translateValues: "companySituation.",
        component: "select",
        required: true,
        options: companySituations
    },
    companyStatus: {
        label: "field.registry.companyStatus",
        withMargin: true,
        fullWidth: true,
        component: "select",
        translateValues: "companyStatus.",
        required: true,
        options: companyStatuses
    },
    inpsHeadQuarter: {
        label: "field.registry.inpsHeadQuarter",
        withMargin: true,
        fullWidth: true,
        component: "select",
        required: true,
        options: inpsHeadQuarters
    },
    foreignStateOfResidence: {
        label: "field.registry.foreignStateOfResidence",
        withMargin: true,
        fullWidth: true,
        component: "select",
        options: foreignStateOfResidences
    },
    businessLegalForm: {
        label: "field.registry.businessLegalForm",
        fullWidth: true,
        withMargin: true,
        component: "select",
        translateValues: "businessLegalForm.",
        options: legalForms,
        required: true,
        onChange: (value, bodyRequest, setBodyRequest) => {
            return setBodyRequest({
                ...bodyRequest,
                personalData: {
                    ...bodyRequest.personalData,
                    businessLegalForm: value
                }
            });
        }
    },
    shareCapital: {
        label: "field.registry.shareCapital",
        withMargin: true,
        style: { width: 270 },
        component: "textfield",
        componentStyle: { width: 220 },
        componentType: "number",
        isCurrency: true,
        required: true,
        customRender: ({ children }) => (
            <GlobalRow>
                <GlobalVerticalDivider width="5px" />
                {children}
            </GlobalRow>
        ),
        renderCondition: bodyRequest => {
            return ["SP", "SR"].indexOf(bodyRequest?.personalData?.businessLegalForm) > -1;
        }
    },
    paidUpShareCapital: {
        label: "field.registry.paidUpShareCapital",
        withMargin: true,
        style: { width: 270 },
        component: "textfield",
        componentStyle: { width: 220 },
        componentType: "number",
        isCurrency: true,
        required: true,
        renderCondition: bodyRequest => {
            return ["SP", "SR"].indexOf(bodyRequest?.personalData?.businessLegalForm) > -1;
        }
    },
    sharesCount: {
        label: "field.registry.sharesCount",
        withMargin: true,
        style: { width: 270 },
        component: "textfield",
        componentStyle: { width: 220 },
        componentType: "number",
        customRender: ({ children }) => (
            <GlobalRow>
                <GlobalVerticalDivider width="5px" />
                {children}
            </GlobalRow>
        ),
        renderCondition: bodyRequest => {
            return ["SP"].indexOf(bodyRequest?.personalData?.businessLegalForm) > -1;
        }
    },
    worthOfStock: {
        label: "field.registry.worthOfStock",
        withMargin: true,
        style: { width: 270 },
        component: "textfield",
        componentStyle: { width: 220 },
        componentType: "number",
        isCurrency: true,
        renderCondition: bodyRequest => {
            return ["SP"].indexOf(bodyRequest?.personalData?.businessLegalForm) > -1;
        }
    },
    establishmentDate: {
        label: "field.registry.establishmentDate",
        withMargin: true,
        fullWidth: true,
        required: true,
        component: "datepicker"
    },
    expiryDate: {
        label: "field.registry.expiryDate",
        withMargin: true,
        fullWidth: true,
        component: "datepicker"
    },
    financialStatementApprovalDate: {
        label: "field.registry.financialStatementApprovalDate",
        withMargin: true,
        fullWidth: true,
        required: true,
        component: "datepicker"
    },
    financialStatementEndDate: {
        label: "field.registry.financialStatementEndDate",
        withMargin: true,
        fullWidth: true,
        component: "datepicker"
    },
    interimFinancialStatement: {
        label: "field.taxation.interimFinancialStatement",
        fullWidth: true,
        withMargin: true,
        required: true,
        component: "radio",
        radioGroupAlign: "vertical",
        options: [
            { id: "MONTHLY", value: "MONTHLY" },
            { id: "QUARTERLY", value: "QUARTERLY" },
            { id: "HALF_YEARLY", value: "HALF_YEARLY" }
        ],
        translateValues: "interimFinancialStatement."
    },
    excludeVATPaymentsAccumulationOrCompensation: {
        label: "field.taxation.excludeVATPaymentsAccumulationOrCompensation",
        fullWidth: true,
        withMargin: true,
        required: true,
        component: "radio",
        radioGroupAlign: "vertical",
        options: [
            { id: true, value: "true" },
            { id: false, value: "false" }
        ],
        translateValues: "excludeVATPaymentsAccumulationOrCompensation."
    },
    taxationType: {
        label: "field.taxation.taxationType",
        fullWidth: true,
        withMargin: true,
        required: true,
        component: "radio",
        radioGroupAlign: "vertical",
        options: [
            { id: "IRES", value: "IRES" },
            { id: "IRPEF", value: "IRPEF" },
            { id: "IRPEF_OPTION_IRAP", value: "IRPEF_OPTION_IRAP" }
        ],
        translateValues: "taxationType."
    },
    chartAccounts: {
        label: "field.taxation.chartAccounts",
        fullWidth: true,
        withMargin: true,
        required: true,
        component: "select",
        readonly: true,
        options: [{ id: "standard", value: "standard" }],
        translateValues: "chartAccounts.",
        emptyLabel: "field.taxation.chartAccounts.notSpecified"
    }
};
