import styled from "styled-components";

export const UploaderBox = styled.div`
    width: 100%;
    background: rgba(255, 255, 255, 0);
    border: 1px dashed
        ${props =>
            props.error === "" ? props.theme.colors.primary.richElectricBlue : props.theme.colors.status.maximumRed};
    border-radius: 2px;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: ${props => (props.disabled ? 0.3 : 1)};
`;

export const FileName = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const FileSize = styled.div`
    color: ${props => props.theme.colors.text.quickSilver};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const Spacer = styled.div`
    height: ${props => props.spacing || "1rem"};
`;

export const Error = styled.div`
    width: 100%;
    font-size: 13px;
    background: rgba(255, 255, 255, 0);
    color: rgb(216, 40, 41);
    box-sizing: border-box;
`;
