import React from "react";
import { FooterBar, Pull } from "./styled";

export const StickyFooter = ({ pullHeight, height, width, children }) => {
    return (
        <>
            <Pull pullHeight={pullHeight} />
            <FooterBar height={height} width={width}>
                {children}
            </FooterBar>
        </>
    );
};
