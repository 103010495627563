import React from "react";
import PropTypes from "prop-types";

import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import { Descriptive } from "../vapor-components/typography";
import {
    CircleStatus,
    Contact,
    ContactContainer,
    DoubleIconContainer,
    Name,
    NameAndIconContainer,
    SecondIcon,
    Status,
    StatusContainer,
    TaxIdContainer
} from "./styled";
import { statusTagMapping } from "./statusMapping";
import ClassifierIcon from "../classifier-icon";
import Card from "../vapor-components/card";
import { Stack, Box, Avatar } from "@vapor/react-material";

const classifiersWithNoVatNumber = ["PERSON", "BUILDING"];

const CustomerCard = ({ address, classifier, email, footer, name, pec, phone, status, vatNumber, taxId }) => {
    return (
        <Card footer={footer} showProgressBar={false}>
            <NameAndIconContainer>
                <Avatar bgColor={"#B9E6F6"} color={"#005075"}>
                    <ClassifierIcon classifier={classifier} />
                </Avatar>
                <Name>{name}</Name>
            </NameAndIconContainer>
            <StatusContainer>
                {status && (
                    <>
                        <Status>
                            <FormattedMessage id={statusTagMapping[status].label} />
                        </Status>
                        <CircleStatus status={status} />
                    </>
                )}
            </StatusContainer>
            <Descriptive color="cadet">
                {classifier && <FormattedMessage id={`general.classifiers.${classifier}`} />}
            </Descriptive>

            <Descriptive>
                {address && (
                    <Stack mt={2} alignItems="flex-start" spacing={1} direction="row">
                        <Box>{address}</Box>
                        <Box>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </Box>
                    </Stack>
                )}
            </Descriptive>

            <TaxIdContainer>
                {/* dont' show VAT on BUILDING or PERSON */}
                <Stack mt={1.5} alignItems="flex-start" spacing={1} direction="row">
                    {!classifiersWithNoVatNumber.some(item => item === classifier) && (
                        <>
                            <Descriptive>
                                <FormattedMessage id="general.vatnumber" />:
                            </Descriptive>
                            <Descriptive weight="500">{vatNumber}</Descriptive>
                        </>
                    )}
                    <Descriptive>
                        <FormattedMessage id="general.tax-id" />:
                    </Descriptive>
                    <Descriptive weight="500">{taxId}</Descriptive>
                </Stack>
            </TaxIdContainer>
            {email ||
                phone ||
                (pec && (
                    <ContactContainer>
                        <Descriptive weight="500">
                            <Contact>
                                <FontAwesomeIcon icon={faPhone} />
                                {phone}
                            </Contact>
                        </Descriptive>
                        <Descriptive weight="500">
                            <Contact>
                                <FontAwesomeIcon icon={faEnvelope} />
                                {email}
                            </Contact>
                        </Descriptive>
                        <Descriptive weight="500">
                            <Contact>
                                <DoubleIconContainer>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <SecondIcon icon={faLock} />
                                </DoubleIconContainer>
                                {pec}
                            </Contact>
                        </Descriptive>
                    </ContactContainer>
                ))}
        </Card>
    );
};

CustomerCard.propTypes = {
    address: PropTypes.string,
    classifier: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf([
        "ACTIVE",
        "DEACTIVATED",
        "VALIDATED",
        "UNVERIFIABLE",
        "VERIFIED_PENDING_VALIDATE",
        "UNVERIFIABLE_PENDING_VALIDATE",
        "REJECTED",
        "REJECTED_PENDING_VALIDATE"
    ]),
    pec: PropTypes.string,
    phone: PropTypes.string,
    vatNumber: PropTypes.string,
    taxId: PropTypes.string
};

export default CustomerCard;
