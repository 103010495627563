import styled, { createGlobalStyle } from "styled-components";

export const globalPadding = "24px";
export const globalTitleHeight = "80px";
export const globalHeaderHeight = "64px";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-size: 16px;
  }
`;

export const GlobalRow = styled.div`
    display: flex;
    ${props => props.margin && `margin: ${props.margin};`}
`;

export const GlobalCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GlobalVerticalDivider = styled.div`
    width: 1px;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    margin: 0 14px;
    ${props => props.width && `width: ${props.width};`}
`;

export const GlobalHorizontalDivider = styled.div`
    height: 1px;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    margin: 14px 0;
`;
