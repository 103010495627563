import React from "react";
import {
    FieldsBox,
    FieldsBoxTitle,
    FieldBoxInfo,
    Spacer,
    FieldsContainer,
    FieldsBoxHeading,
    IconBox,
    DeleteIcon,
    EditIcon,
    Error
} from "../../styled";
import FormField from "../../../../vapor-components/field";
import DropFiles from "../../../../vapor-components/dropfiles";
import CheckValidityWithPassword from "../check-validity";
import { FormattedMessage, useIntl } from "react-intl";
import { handleMaxLength } from "../../../../../utils/validate";
import { Stack, TextField, Box, Button } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";

const SecurityBox = ({
    box,
    bodyRequest,
    setBodyRequest,
    i,
    handleSave,
    updateBox,
    updateFiles,
    handleDeleteBox,
    handleEditBox,
    removeBodyRequest,
    errors
}) => {
    const intl = useIntl();
    const { id, siteCode, description, entratelCode, files, readOnly, isSaved } = box;

    return (
        <FieldsContainer>
            <FieldsBox error={errors?.id === id ? Object.keys(errors).length > 0 : null}>
                <FieldsBoxHeading>
                    <FieldsBoxTitle>
                        <FormattedMessage
                            id="c-customer-edit-intermediaries.fiedbox-incremental"
                            values={{ n: i + 1 }}
                        />
                    </FieldsBoxTitle>
                    {readOnly && isSaved && (
                        <IconBox>
                            <EditIcon onClick={handleEditBox(i)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </EditIcon>
                            <DeleteIcon onClick={handleDeleteBox(i)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </DeleteIcon>
                        </IconBox>
                    )}
                </FieldsBoxHeading>
                <Box
                    autoComplete="off"
                    component={readOnly ? "div" : "form"}
                    onSubmit={readOnly ? null : handleSave(i)}
                >
                    <FormField
                        label={intl.formatMessage({ id: "field.intermediary.description" })}
                        description={
                            readOnly ? description : intl.formatMessage({ id: "tips.intermediary.description" })
                        }
                    >
                        {!readOnly && (
                            <TextField
                                name="description"
                                autoComplete="off"
                                value={box.description}
                                onChange={updateBox(i)}
                                inputProps={{ onChange: e => handleMaxLength(e, 120) }}
                            />
                        )}

                        {!readOnly && errors.id === id && errors?.description && (
                            <Error>
                                <FormattedMessage id={errors.description} />
                            </Error>
                        )}
                    </FormField>
                    <Stack
                        direction={{ xs: "column", md: "column", lg: "row" }}
                        alignItems="flex-start"
                        spacing={4}
                        mt={2}
                        mb={1}
                    >
                        <Box sx={{ flex: "1 1 20%", width: "100%" }}>
                            <FormField
                                label={intl.formatMessage({ id: "field.code" })}
                                description={readOnly ? siteCode : null}
                            >
                                {!readOnly && (
                                    <TextField
                                        name="siteCode"
                                        autoComplete="off"
                                        placeholder="000"
                                        value={box.siteCode}
                                        onChange={updateBox(i)}
                                        inputProps={{ onChange: e => handleMaxLength(e, 3) }}
                                    />
                                )}
                                {!readOnly && errors.id === id && errors?.siteCode && (
                                    <Error>
                                        <FormattedMessage id={errors.siteCode} />
                                    </Error>
                                )}
                            </FormField>
                        </Box>
                        <Box sx={{ flex: "1 1 50%", width: "100%" }}>
                            <FormField
                                label={intl.formatMessage({ id: "field.entratelCode" })}
                                description={readOnly ? entratelCode : null}
                            >
                                {!readOnly && (
                                    <TextField
                                        name="entratelCode"
                                        autoComplete="off"
                                        placeholder="T0000000"
                                        value={box.entratelCode}
                                        onChange={updateBox(i)}
                                        inputProps={{ onChange: e => handleMaxLength(e, 8), autoComplete: "off" }}
                                    />
                                )}
                                {!readOnly && errors.id === id && errors?.entratelCode && (
                                    <Error>
                                        <FormattedMessage id={errors.entratelCode} />
                                    </Error>
                                )}
                            </FormField>
                        </Box>
                    </Stack>
                    <Box sx={{ mt: "1rem" }}>
                        <FormField
                            label={
                                readOnly
                                    ? intl.formatMessage({ id: "field.multiple-security-env" })
                                    : intl.formatMessage({ id: "tips.intermediary.env-files-title" })
                            }
                            description={
                                readOnly
                                    ? null
                                    : intl.formatMessage({
                                          id: "tips.intermediary.env-files-description"
                                      })
                            }
                        />
                        <DropFiles
                            files={files}
                            setFiles={updateFiles(i)}
                            maxKb={100}
                            maxFiles={3}
                            acceptedFiles={["ks", "p12"]}
                            errors={{
                                fileSize: "field.multiple-upload.error-file-size",
                                fileLength: "field.multiple-upload.error-file-length",
                                fileExtension: "field.multiple-upload.error-file-extension"
                            }}
                            readOnly={readOnly}
                        />
                        {!readOnly && errors.id === id && (errors?.files || errors?.filesAlreadyExists) && (
                            <Error>
                                <FormattedMessage id={errors.files || errors?.filesAlreadyExists} />
                            </Error>
                        )}
                        <Spacer />
                        {!readOnly && (
                            <FieldBoxInfo>
                                <FormField
                                    label={intl.formatMessage({ id: "field.multiple-upload.info-title" })}
                                    description={intl.formatMessage({
                                        id: "field.multiple-upload.info-description"
                                    })}
                                />
                            </FieldBoxInfo>
                        )}
                        <CheckValidityWithPassword
                            index={i}
                            setBodyRequest={setBodyRequest}
                            bodyRequest={bodyRequest}
                            handleChange={updateBox}
                        />
                        {!readOnly && errors.id === id && errors?.password && (
                            <Error>
                                <FormattedMessage id={errors.password} />
                            </Error>
                        )}
                    </Box>
                    {!readOnly && (
                        <Box mt={"2.5rem"}>
                            <Stack direction="row" spacing={2} justifyContent="right">
                                {i > 0 && (
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        size="medium"
                                        onClick={removeBodyRequest(i)}
                                    >
                                        <FormattedMessage id="general.cancel" />
                                    </Button>
                                )}

                                <Button color="primary" variant="contained" size="medium" type="submit">
                                    <FormattedMessage id="general.save" />
                                </Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
            </FieldsBox>
        </FieldsContainer>
    );
};

export default SecurityBox;
