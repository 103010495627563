import customerManagementApiClient from "../apiClient/";

export const GET_CUSTOMER_ACCOUNTING_START = "GET_CUSTOMER_ACCOUNTING_START";
export const GET_CUSTOMER_ACCOUNTING_SUCCESS = "GET_CUSTOMER_ACCOUNTING_SUCCESS";
export const GET_CUSTOMER_ACCOUNTING_ERROR = "GET_CUSTOMER_ACCOUNTING_ERROR";
export const GET_CUSTOMER_ACCOUNTING_RESET = "GET_CUSTOMER_ACCOUNTING_RESET";

export const CREATE_CUSTOMER_ACCOUNTING_START = "CREATE_CUSTOMER_ACCOUNTING_START";
export const CREATE_CUSTOMER_ACCOUNTING_SUCCESS = "CREATE_CUSTOMER_ACCOUNTING_SUCCESS";
export const CREATE_CUSTOMER_ACCOUNTING_ERROR = "CREATE_CUSTOMER_ACCOUNTING_ERROR";

export const UPDATE_CUSTOMER_ACCOUNTING_START = "UPDATE_CUSTOMER_ACCOUNTING_START";
export const UPDATE_CUSTOMER_ACCOUNTING_SUCCESS = "UPDATE_CUSTOMER_ACCOUNTING_SUCCESS";
export const UPDATE_CUSTOMER_ACCOUNTING_ERROR = "UPDATE_CUSTOMER_ACCOUNTING_ERROR";

export function getCustomerAccounting(ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_ACCOUNTING_RESET
        });

        dispatch({
            type: GET_CUSTOMER_ACCOUNTING_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().getCustomerAccounting(ownerId, workspaceId);

            dispatch({
                type: GET_CUSTOMER_ACCOUNTING_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_ACCOUNTING_ERROR,
                payload: { e }
            });
        }
    };
}

export function createCustomerAccounting(ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: CREATE_CUSTOMER_ACCOUNTING_START,
                payload: { ownerId, requestParams }
            });

            try {
                new customerManagementApiClient().createCustomerAccounting(ownerId, workspaceId, requestParams);

                setTimeout(() => {
                    dispatch({
                        type: CREATE_CUSTOMER_ACCOUNTING_SUCCESS,
                        payload: requestParams
                    });
                    resolve(requestParams);
                }, 2000);
            } catch (e) {
                dispatch({
                    type: CREATE_CUSTOMER_ACCOUNTING_ERROR,
                    payload: { e }
                });
                reject(e);
            }
        });
    };
}

export function updateCustomerAccounting(accountingId, ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPDATE_CUSTOMER_ACCOUNTING_START,
                payload: { accountingId, ownerId, requestParams }
            });

            try {
                new customerManagementApiClient().updateCustomerAccounting(
                    accountingId,
                    ownerId,
                    workspaceId,
                    requestParams
                );

                setTimeout(() => {
                    dispatch({
                        type: UPDATE_CUSTOMER_ACCOUNTING_SUCCESS,
                        payload: requestParams
                    });
                    resolve(requestParams);
                }, 2000);
            } catch (e) {
                dispatch({
                    type: UPDATE_CUSTOMER_ACCOUNTING_ERROR,
                    payload: { e }
                });
                reject(e);
            }
        });
    };
}
