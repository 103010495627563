import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/pro-regular-svg-icons/faBriefcase";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faCity } from "@fortawesome/pro-regular-svg-icons/faCity";
import { faUserTie } from "@fortawesome/pro-regular-svg-icons/faUserTie";
import { faCarBuilding } from "@fortawesome/pro-solid-svg-icons/faCarBuilding";

const ClassifierIcon = ({ classifier }) => {
    const icons = {
        STUDIO: faBriefcase,
        COMPANY: faBuilding,
        PERSON: faUserTie,
        BUILDING: faCarBuilding,
        PUBLIC_COMPANY: faCity
    };
    return <FontAwesomeIcon icon={icons[classifier]} />;
};

ClassifierIcon.propTypes = {
    classifier: PropTypes.oneOf(["STUDIO", "COMPANY", "PERSON", "BUILDING", "PUBLIC_COMPANY"])
};

ClassifierIcon.defaultProps = {
    classifier: "COMPANY"
};

export default ClassifierIcon;
