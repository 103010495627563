import styled from "styled-components";

export const Sections = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SectionTitle = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    ${props =>
        props.vertical &&
        `
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 48px;
    `}
    ${props =>
        props.margin &&
        `
        margin: ${props.margin};
    `}
`;

export const TitleText = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 16px;
    margin: ${props => props.margin || "unset"};
`;

export const TitleIcon = styled.div`
    display: flex;
`;

export const MainTitleText = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 24px;
    font-family: Cairo, sans-serif;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    & > svg {
        margin-right: 8px;
    }
`;

export const MainTitleDesc = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 26px;
    margin: 8px 0;
`;

export const SpinnerContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    & > div {
        height: 50vh;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
`;
