import React from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "../../../vapor-components/card";
import { Descriptive } from "../../../vapor-components/typography";
import { Button } from "@ts-digital/vrc";
import Tag from "../../../vapor-components/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import {
    CustomerEditIntermediaryFooterContainer,
    EditButton,
    CustomerEditIntermediaryTitleContainer,
    CustomerEditIntermediaryTitleStatus,
    CustomerEditIntermediaryTitle,
    CustomerEditIntermediaryStatusTag,
    CustomerEditIntermediariesIntermediaryContent,
    CustomerEditIntermediaryTitleText
} from "../styled";

export const CardNewIntermediary = ({ idCustomer }) => {
    const navigate = useNavigate();
    const intl = useIntl();
    return (
        <Card
            backgroundColor={"whiteSmoke"}
            verticalFooter={true}
            footer={
                <CustomerEditIntermediaryFooterContainer>
                    <EditButton>
                        <Button kind="tertiary" onClick={() => navigate(`/${idCustomer}/customer-edit/intermediary`)}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </EditButton>
                </CustomerEditIntermediaryFooterContainer>
            }
            title={
                <CustomerEditIntermediaryTitleContainer>
                    <CustomerEditIntermediaryTitleStatus>
                        <CustomerEditIntermediaryStatusTag>
                            <Tag
                                bgColor="#e4eaf0"
                                color="#5a6872"
                                label={intl.formatMessage({
                                    id: "c-customer-edit-area-title.intermediaries"
                                })}
                                upperText
                            />
                        </CustomerEditIntermediaryStatusTag>
                    </CustomerEditIntermediaryTitleStatus>
                    <CustomerEditIntermediaryTitle>
                        <FontAwesomeIcon icon={faPlus} />
                        <CustomerEditIntermediaryTitleText>
                            <FormattedMessage id="c-customer-edit-intermediaries.create" />
                        </CustomerEditIntermediaryTitleText>
                    </CustomerEditIntermediaryTitle>
                </CustomerEditIntermediaryTitleContainer>
            }
        >
            <CustomerEditIntermediariesIntermediaryContent>
                <Descriptive color="cadet" lineHeight="16px" style={{ marginTop: "10px", marginLeft: "24px" }}>
                    <FormattedMessage id="c-customer-edit-intermediaries.createDescription" />
                </Descriptive>
                <Descriptive color="cadet" lineHeight="40px" style={{ marginLeft: "24px" }}>
                    <FormattedMessage
                        id="c-customer-edit-createIn"
                        values={{ icon: <FontAwesomeIcon icon={faClock} />, minutes: <b>8 min</b> }}
                    />
                </Descriptive>
            </CustomerEditIntermediariesIntermediaryContent>
        </Card>
    );
};
