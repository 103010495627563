import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    background-color: ${props => props.theme.colors.background.white};
`;

export const THead = styled.thead`
    border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    text-align: left;
`;

export const FlattenedTHead = styled.thead`
    text-align: left;
`;

export const Td = styled.td`
    color: ${props => props.theme.colors.text.yankeesBlue};
    border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    padding: 0.5rem;
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    height: 3rem;
    box-sizing: border-box;
    text-align: ${props => (props.centeredText ? "center" : "left")};
`;

export const Th = styled.th`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: 500;
    padding: 1rem 0.5rem;
    text-transform: capitalize;
`;

export const ActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-align: center;
    cursor: pointer;
`;

export const DisabledActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.columbiaBlue};
    text-align: center;
`;

export const LoadingActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-align: center;
`;

export const ArrowsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const LoadingIcon = styled.div`
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "484px"};
    color: ${props => props.color || props.theme.colors.primary.richElectricBlue};
    font-size: ${props => props.size || "1.5rem"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${props => (props.rightGutter ? ".5rem" : "0")};
`;
