import { Box, Button, Stack } from "@vapor/react-material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { FormattedMessage } from "react-intl";
import DelegatedForm from "../delegated-form";
import { DeleteIcon, FieldsBox, FieldsBoxHeading, FieldsBoxTitle, IconBox } from "../../styled";

const ToggleDelegateds = ({ bodyRequest, setBodyRequest, displayBox, setDisplayBox, error, handleChange }) => {
    const toggleDisplay = () => {
        setDisplayBox(!displayBox);
    };
    return (
        <>
            {!displayBox ? (
                <Button
                    onClick={toggleDisplay}
                    sx={{
                        justifyContent: "flex-start",
                        width: "fit-content",
                        marginY: "1.5rem",
                        "&:hover": { background: "none", color: "inherit" },
                        "&:focus": { background: "none" }
                    }}
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <Box>
                            <FormattedMessage id="c-customer-edit-intermediaries.add-delegated" />
                        </Box>
                    </Stack>
                </Button>
            ) : (
                <FieldsBox>
                    <FieldsBoxHeading>
                        <FieldsBoxTitle>
                            <FormattedMessage id="c-customer-edit-intermediaries.add-delegated" />
                        </FieldsBoxTitle>
                        <IconBox>
                            <DeleteIcon onClick={toggleDisplay}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </DeleteIcon>
                        </IconBox>
                    </FieldsBoxHeading>
                    <DelegatedForm
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        error={error}
                    />
                </FieldsBox>
            )}
        </>
    );
};

export default ToggleDelegateds;
