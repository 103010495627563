import React from "react";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";

import Card from "../vapor-components/card";
import { FooterButtonContainer, GridContainer, Info, InfoIcon, StatusIcon, StatusLabel, Title } from "./styled";
import { useIntl } from "react-intl";

const EntrustmentDeedCard = ({ adaInfo, onDownload }) => {
    const intl = useIntl();
    return (
        <Card
            title={intl.formatMessage({
                id: "c-entrustment-deed-card.title"
            })}
            footer={
                <FooterButtonContainer>
                    <Button kind="tertiary" onClick={() => onDownload()}>
                        <FontAwesomeIcon icon={faDownload} />{" "}
                        {intl.formatMessage({
                            id: "c-entrustment-deed-card.download-copy-button-label"
                        })}
                    </Button>
                </FooterButtonContainer>
            }
        >
            <Title>
                {intl.formatMessage({
                    id: "general.status"
                })}
                :
            </Title>
            <GridContainer>
                <StatusIcon icon={faCheckCircle} />
                <StatusLabel>
                    {intl.formatMessage({
                        id: `c-entrustment-deed-card.status.${adaInfo.state}`
                    })}
                </StatusLabel>
                <InfoIcon icon={faInfoCircle} />
                <Info>
                    {intl.formatMessage({
                        id: "c-entrustment-deed-card.info"
                    })}
                </Info>
            </GridContainer>
        </Card>
    );
};

export default EntrustmentDeedCard;
