import {
    GET_CUSTOMER_START,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    GET_CUSTOMER_RESET,
    GET_SPOTLIGHT_CUSTOMERS_START,
    GET_SPOTLIGHT_CUSTOMERS_SUCCESS,
    GET_SPOTLIGHT_CUSTOMERS_RESET,
    GET_SPOTLIGHT_CUSTOMERS_ERROR
} from "../actions/customers";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    spotlight: {
        items: [],
        pagination: {}
    }
};

export function customers(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CUSTOMER_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                items: payload.items,
                pagination: payload.pagination
            };
        case GET_CUSTOMER_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case GET_CUSTOMER_RESET:
            return {
                ...defaultState
            };
        case GET_SPOTLIGHT_CUSTOMERS_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                spotlight: {
                    items: [],
                    pagination: {}
                }
            };
        case GET_SPOTLIGHT_CUSTOMERS_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                spotlight: payload
            };
        case GET_SPOTLIGHT_CUSTOMERS_RESET:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                spotlight: {
                    items: [],
                    pagination: {}
                }
            };
        case GET_SPOTLIGHT_CUSTOMERS_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false
                },
                spotlight: {
                    items: [],
                    pagination: {}
                }
            };

        default:
            return state;
    }
}
