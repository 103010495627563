import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CardsLayout from "../../cards-layout";
import { CardNewIntermediary } from "./card-create";
import { CardIntermediary } from "./card-intermediaries";

const CustomerEditIntermediaries = ({ render }) => {
    //TODO: Replace when we can filter intermediaries
    const intermediaries = useSelector(state => state.intermediaries?.details);
    const customerDetails = useSelector(state => state.customer?.details);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(intermediaries && intermediaries?.isLoading);
    }, [intermediaries, setIsLoading, isLoading]);

    if (!render) {
        return null;
    }

    return (
        <CardsLayout
            title={
                <FormattedMessage
                    id="c-customer-edit-intermediaries.header-title"
                    values={{ icon: <FontAwesomeIcon icon={faUser} /> }}
                />
            }
            description={<FormattedMessage id="c-customer-edit-intermediaries.header-description" />}
            isLoading={isLoading}
        >
            <CardNewIntermediary idCustomer={customerDetails.data.id} />
            {intermediaries.items &&
                intermediaries.items.map(intermediary => {
                    return <CardIntermediary key={intermediary.taxId} {...intermediary} />;
                })}
        </CardsLayout>
    );
};

export default CustomerEditIntermediaries;
