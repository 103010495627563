import styled from "styled-components";

export const CustomerEditAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
`;

export const CustomerEditAreaTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const CustomerEditAreaTitleText = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-right: 16px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
`;

export const CustomerEditAreaTitleIcon = styled.div`
    margin-right: 16px;
    font-size: 24px;
    line-height: 32px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
`;

export const CustomerEditAreaDivider = styled.div`
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
`;

export const CustomerEditAreaContent = styled.div`
    display: flex;
`;

export const SkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SkeletonRow = styled.div`
    display: flex;
`;

export const SkeletonElement = styled.div`
    margin: 8px;
`;
