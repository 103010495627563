import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isVatNumber } from "@ts-digital/agyo-validations";
import { Box, Button, Stack } from "@vapor/react-material";
import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    createIntermediaries,
    getSelectedIntermediary,
    resetSelectedIntermediary
} from "../../../../../actions/intermediaries";
import { validateFiscalCode } from "../../../../../utils/validate";
import { StickyFooter } from "../../../../vapor-components/sticky-footer";
import { LEGAL, PERSON, step1DefaultBody } from "../../constants";
import IntermediaryInputs from "../../fields/intermediary-inputs";
import SelectIntermediaries from "../../fields/select-intermediaries";
import ToggleCreateIntermediary from "../../fields/toggle-createintermediary";
import { FieldsChooseInfo, FieldsContainer, Spacer } from "../../styled";

export const CustomerEditIntermediariesStep1 = ({ setStepNumber, intermediaries, ownerId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [intermediaryType, setIntermediaryType] = useState(PERSON);
    const [error, setError] = useState({});
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [selected, setSelected] = useState(false);
    const [displayBox, setDisplayBox] = useState(false);

    const [bodyRequest, setBodyRequest] = useState({ ...step1DefaultBody });

    const handleChange = useCallback(
        e => {
            let { value, name } = e.target;
            let foundErrors = 0;

            if (name === "description" && value.length === 0) {
                setError({ name, message: "c-customer-edit-intermediary.error.description" });
                foundErrors++;
            }

            if (name === "vatNumber") {
                value = value.replace(/\D/g, "");
                isVatNumber("taxRegion", err => {
                    if (err && (value.length === 11 || value.length === 0)) {
                        setError({ name: "vatNumber", message: err });
                        foundErrors++;
                    }
                })(value, { taxRegion: "IT" });
            } else if (name === "taxId") {
                value = value.replace(/\s/g, "").toUpperCase();
                if (value.length === 0) {
                    setError({ name, message: "c-customer-edit-intermediary.error.taxcode.emptyOrNull" });
                    foundErrors++;
                } else if (value.length === 16 && !validateFiscalCode(value)) {
                    setError({ name: "taxId", message: "c-customer-edit-intermediary.error.taxcode" });
                    foundErrors++;
                }
            }

            if (name === error?.name && foundErrors === 0) {
                setError({});
            }

            setBodyRequest({ ...bodyRequest, [name]: value });

            const intermediaryAlreadyExists = intermediaries.findIndex(
                intermediary => intermediary.taxId === value || intermediary.vatNumber === value
            );

            if (intermediaryAlreadyExists !== -1) {
                setError({ name, message: "c-customer-edit-intermediary.error.alreadyExists" });
                return;
            }
        },
        [bodyRequest, error, intermediaries]
    );

    const handleSubmit = async event => {
        event.preventDefault();

        if (selected) {
            dispatch(
                getSelectedIntermediary({
                    description: bodyRequest.description,
                    vatNumber: bodyRequest.vatNumber,
                    taxId: bodyRequest.taxId
                })
            );
            setStepNumber(1);
        } else {
            const newIntermediary = await dispatch(createIntermediaries(ownerId, bodyRequest));

            if (newIntermediary.code === 202) {
                setStepNumber(1);
            }
        }
    };

    useEffect(() => {
        dispatch(resetSelectedIntermediary());
    }, [dispatch]);

    useEffect(() => {
        const { description, vatNumber, taxId } = bodyRequest;
        if (selected) {
            setEnableSubmit(true);
        } else if (intermediaryType === PERSON) {
            setEnableSubmit(
                description.length > 1 && vatNumber.length === 11 && taxId.length === 16 && validateFiscalCode(taxId)
            );
        } else if (intermediaryType === LEGAL) {
            setEnableSubmit(description.length > 1 && vatNumber.length === 11 && taxId.length === 11);
        }
    }, [selected, bodyRequest, setBodyRequest, intermediaryType, setEnableSubmit, enableSubmit]);

    return (
        <Box component="form" mt={3} onSubmit={handleSubmit}>
            <FieldsContainer>
                {intermediaries.length === 0 && (
                    <>
                        <Spacer spacing={"1rem"} />
                        <IntermediaryInputs
                            type={intermediaryType}
                            bodyRequest={bodyRequest}
                            handleChange={handleChange}
                            error={error}
                            setBodyRequest={setBodyRequest}
                            setError={setError}
                            setIntermediaryType={setIntermediaryType}
                        />
                    </>
                )}

                {intermediaries.length > 0 && (
                    <SelectIntermediaries
                        intermediaries={intermediaries}
                        value={selected ? bodyRequest.taxId : ""}
                        setBodyRequest={setBodyRequest}
                        bodyRequest={bodyRequest}
                        setSelected={setSelected}
                        selected={selected}
                        setError={setError}
                        setDisplayBox={setDisplayBox}
                    />
                )}

                {intermediaries.length > 0 && (
                    <Stack spacing={4} mt={5}>
                        <FieldsChooseInfo>
                            <FormattedMessage id="c-customer-edit-intermediary.choose-toselect-or-totype" />
                        </FieldsChooseInfo>
                        <ToggleCreateIntermediary
                            bodyRequest={bodyRequest}
                            handleChange={handleChange}
                            error={error}
                            type={intermediaryType}
                            selected={selected}
                            setSelected={setSelected}
                            displayBox={displayBox}
                            setDisplayBox={setDisplayBox}
                            setBodyRequest={setBodyRequest}
                            setError={setError}
                            setIntermediaryType={setIntermediaryType}
                        />
                    </Stack>
                )}
            </FieldsContainer>

            <StickyFooter pullHeight={"4rem"}>
                <Stack spacing={3} direction="row">
                    <Button
                        variant="text"
                        size="large"
                        sx={{ width: "fit-content" }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <Stack direction="row">
                            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "1rem" }} />
                            <FormattedMessage id="general.back" />
                        </Stack>
                    </Button>

                    <Button
                        disabled={!enableSubmit || Object.keys(error).length > 0}
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ width: "fit-content", padding: "0 2rem" }}
                    >
                        <FormattedMessage id="general.forward" />
                    </Button>
                </Stack>
            </StickyFooter>
        </Box>
    );
};
