import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import { getSpotlightCustomers } from "../../actions/customers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faTable } from "@fortawesome/pro-regular-svg-icons/faTable";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faThLarge } from "@fortawesome/pro-solid-svg-icons/faThLarge";
import { FormattedMessage, useIntl } from "react-intl";

import { H2, H5 } from "../../components/vapor-components/typography";
import { Skeleton } from "@mui/material";

import {
    Container,
    CustomersContainer,
    CustomersLayout,
    FilterContainer,
    FilterContainerLeft,
    FilterContainerRight,
    Header,
    ToggleButton,
    ToggleViewTypeContainer
} from "./styled";
import {
    HeaderBar,
    Button,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Typography,
    Avatar,
    Stack,
    Box,
    TableRow,
    TableCell,
    ListItemText,
    ListItemButton,
    List,
    Popover,
    IconButton
} from "@vapor/react-material";
import { CustomerCardFooter } from "./customer-card-footer";
import { CardsLayout, WsCard, InfiniteTable, WsEmptyState, SearchBar } from "@onefront/react-workspace-components";
import { CustomersSkeleton } from "./customer-card-skeleton";
import ClassifierIcon from "../../components/classifier-icon";
import { NameAndClassifierContainer } from "../../components/customers-table/styled";
import InfiniteScroll from "react-infinite-scroll-component";
import { useBrowserStorage } from "../../hook/useBrowserStorage";
import { resetCreateNewClient } from "../../actions/newclient";

const Customers = ({ authData, customers, customersStatus, customersPagination, getSpotlightCustomers }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storageView = useBrowserStorage("customersView");

    const [customersList, setCustomersList] = useState([]);
    const [viewType, setViewType] = useState(storageView.get() || "card");
    const [loading, setLoading] = useState(true);
    const [noCustomers, setNoCustomers] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [searchCustomer, setSearchCustomer] = useState("");
    const [orderCustomers, setOrderCustomers] = useState("asc");

    const [spotlightParams, setSpotlightParams] = useState({
        first: 8,
        after: 0,
        sortDirection: orderCustomers,
        level: "ALL"
    });

    useEffect(() => {
        if (authData && authData?.item) {
            setLoading(true);
            getSpotlightCustomers({
                ...spotlightParams,
                ownerId: authData?.item?.id
            }).then(c => {
                setLoading(false);
                if (c?.spotlight?.items?.length > 0) {
                    setNoCustomers(false);
                    setCustomersList(customerList => {
                        const ids = customerList.flatMap(c => c.id);
                        c.spotlight.items = c.spotlight.items.filter(c => !ids.includes(c.id));

                        return [...customerList, ...c.spotlight.items];
                    });
                } else {
                    setNoCustomers(true);
                }
            });
        }
    }, [authData, getSpotlightCustomers, orderCustomers, spotlightParams]);

    const handleSearch = query => {
        setCustomersList([]);
        setLoading(true);
        setSpotlightParams({
            ...spotlightParams,
            first: 8,
            after: 0,
            fullText: query
        });
    };

    const [customerId, setCustomerId] = React.useState(null);

    const handlePopoverOpen = (event, id) => {
        setCustomerId(id);
        setAnchorEl(event.currentTarget);
    };

    const togglePopOver = () => {
        setAnchorEl(null);
        setCustomerId(null);
    };

    return (
        <CustomersLayout withBorder={true}>
            <HeaderBar
                sx={{ width: "100%" }}
                description={<></>}
                divider
                leftItems={[]}
                title={<FormattedMessage id="v-customers.headerbar" />}
            />
            <Container>
                <Header>
                    <H2 color="darkImperialBlue">
                        <FormattedMessage id="v-customers.title" />
                    </H2>
                    <H5 color="yankeesBlue">
                        <FormattedMessage id="v-customers.subtitle" />
                    </H5>
                </Header>
                <FilterContainer>
                    <FilterContainerLeft>
                        <Box
                            sx={{
                                minWidth: "24rem"
                            }}
                        >
                            <SearchBar
                                placeholder={intl.formatMessage({
                                    id: "v-customers.input.search-a-client.placeholder"
                                })}
                                label={intl.formatMessage({
                                    id: "v-customers.input.search-a-client.label"
                                })}
                                onChange={val => setSearchCustomer(val)}
                                onSearch={query => {
                                    setSearchCustomer(query);
                                    handleSearch(searchCustomer);
                                }}
                                onClear={() => {
                                    setSearchCustomer("");
                                    handleSearch("");
                                }}
                                value={searchCustomer}
                            />
                        </Box>

                        <FormControl
                            sx={{
                                minWidth: "180px",
                                width: "fit-content"
                            }}
                        >
                            <InputLabel id="order-customers-list">
                                {intl.formatMessage({
                                    id: "v-customers.select.sort.label"
                                })}
                            </InputLabel>
                            <Select
                                id="order-customers"
                                labelId="order-customers-list"
                                onChange={e => {
                                    setCustomersList([]);
                                    setLoading(true);
                                    setOrderCustomers(e.target.value);
                                    setSpotlightParams({
                                        ...spotlightParams,
                                        sortDirection: e.target.value,
                                        first: 8,
                                        after: 0
                                    });
                                }}
                                value={orderCustomers}
                            >
                                <MenuItem value={"asc"}>Dalla A alla Z</MenuItem>
                                <MenuItem value={"desc"}>Dalla Z alla A</MenuItem>
                            </Select>
                        </FormControl>
                    </FilterContainerLeft>
                    <FilterContainerRight>
                        <Button
                            variant="contained"
                            onClick={() =>
                                dispatch(resetCreateNewClient()).then(() => {
                                    navigate("/new-client");
                                })
                            }
                            sx={{ "& svg": { marginRight: "0.4rem" } }}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <FormattedMessage id="v-customers.create-customer-company" />
                        </Button>
                    </FilterContainerRight>
                </FilterContainer>
                <ToggleViewTypeContainer>
                    <ToggleButton
                        disabled={!customers || customers.length === 0}
                        isActive={viewType === "card"}
                        onClick={() => {
                            setViewType("card");
                            storageView.set("card");
                        }}
                    >
                        <FontAwesomeIcon icon={faThLarge} />
                    </ToggleButton>
                    <ToggleButton
                        disabled={!customers || customers.length === 0}
                        isActive={viewType === "list"}
                        onClick={() => {
                            setViewType("list");
                            storageView.set("list");
                        }}
                    >
                        <FontAwesomeIcon icon={faList} />
                    </ToggleButton>
                </ToggleViewTypeContainer>
                <CustomersContainer>
                    <>
                        {viewType === "card" && !customersStatus?.error && !noCustomers && (
                            <InfiniteScroll
                                dataLength={customersList?.length}
                                next={() => {
                                    setSpotlightParams({
                                        ...spotlightParams,
                                        first: 8,
                                        after: spotlightParams.after + 8
                                    });
                                }}
                                hasMore={customersPagination?.hasNextPage}
                                scrollThreshold={0.7}
                                style={{
                                    overflow: "initial",
                                    width: "100%"
                                }}
                            >
                                <CardsLayout
                                    gap={"24px"}
                                    isLoading={loading}
                                    loadingElements={8}
                                    skeletonElement={<CustomersSkeleton />}
                                    minHeight={"240px"}
                                >
                                    {customersList?.map(customer => {
                                        return (
                                            <WsCard
                                                status="default"
                                                key={`customer-card-id-${customer?.id}`}
                                                avatar={
                                                    <Avatar bgColor={"#B9E6F6"} color={"#005075"}>
                                                        <ClassifierIcon classifier={customer.type} />
                                                    </Avatar>
                                                }
                                                footer={<CustomerCardFooter customerId={customer.id} />}
                                                title={
                                                    <Typography
                                                        variant="subtitle"
                                                        sx={{
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                            WebkitLineClamp: 2,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {customer?.description}
                                                    </Typography>
                                                }
                                            >
                                                <Stack>
                                                    <Typography
                                                        variant="bodySmall"
                                                        color="primary.textSubduedColor"
                                                        fontSize={"12px"}
                                                    >
                                                        {customer?.type && (
                                                            <FormattedMessage
                                                                id={`general.classifiers.${customer?.type}`}
                                                            />
                                                        )}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={2}>
                                                    {customer?.identifiers
                                                        ?.sort((a, b) =>
                                                            a.type === "VAT_NUMBER"
                                                                ? -1
                                                                : b.type === "VAT_NUMBER"
                                                                ? 1
                                                                : 0
                                                        )
                                                        .map(({ type, value }) => (
                                                            <Box key={`identifier-${type}-${value}`}>
                                                                <Typography
                                                                    variant="bodySmall"
                                                                    color="primary.textSubduedColor"
                                                                    fontSize={"12px"}
                                                                >
                                                                    {intl.formatMessage(
                                                                        { id: `spotlight.cards.${type}` },
                                                                        {
                                                                            b: () => (
                                                                                <Typography variant="bodySmall500">
                                                                                    {value}
                                                                                </Typography>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                </Stack>
                                            </WsCard>
                                        );
                                    })}
                                </CardsLayout>
                            </InfiniteScroll>
                        )}
                        {viewType === "list" && !customersStatus?.error && !noCustomers && (
                            <>
                                <InfiniteTable
                                    headings={[
                                        intl.formatMessage({ id: "general.business-name" }),
                                        intl.formatMessage({ id: "general.tax-id" }),
                                        intl.formatMessage({ id: "general.vatnumber" }),
                                        intl.formatMessage({ id: "c-customers-table.thead.kind-activity" }),
                                        ""
                                    ]}
                                    loader
                                    loaderRows={{ rows: 1, height: "64px" }}
                                    endMessage={""}
                                    endCursor={customersList?.length}
                                    hasMore={customersPagination?.hasNextPage}
                                    next={() => {
                                        setSpotlightParams({
                                            ...spotlightParams,
                                            first: spotlightParams.first + 8,
                                            after: spotlightParams.after + 8
                                        });
                                    }}
                                >
                                    {customersList?.length === 0 && loading && (
                                        <>
                                            {Array.from(Array(8).keys()).map(i => (
                                                <TableRow>
                                                    <TableCell
                                                        sx={{ minWidth: "13rem", padding: "0 8px", height: "64px" }}
                                                        padding={"none"}
                                                    >
                                                        <Skeleton height="100%" width={"100%"} />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: "18rem", padding: "0 8px", height: "64px" }}
                                                        padding={"none"}
                                                    >
                                                        <Skeleton height="100%" width={"100%"} />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: "18rem", padding: "0 8px", height: "64px" }}
                                                        padding={"none"}
                                                    >
                                                        <Skeleton height="100%" width={"100%"} />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: "18rem", padding: "0 8px", height: "64px" }}
                                                        padding={"none"}
                                                    >
                                                        <Skeleton height="100%" width={"100%"} />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: "18rem", padding: "0 8px", height: "64px" }}
                                                        padding={"none"}
                                                    >
                                                        <Skeleton height="100%" width={"100%"} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                    {customersList?.map(customer => {
                                        return (
                                            <TableRow key={customer.id}>
                                                <TableCell sx={{ maxWidth: "18rem" }}>
                                                    <NameAndClassifierContainer>
                                                        <Avatar bgColor={"#B9E6F6"} color={"#005075"}>
                                                            <ClassifierIcon classifier={customer.type} />
                                                        </Avatar>
                                                        {customer.description}
                                                    </NameAndClassifierContainer>
                                                </TableCell>
                                                <TableCell>
                                                    {customer.identifiers?.find(i => i.type === "TAX_ID")?.value}
                                                </TableCell>
                                                <TableCell>
                                                    {customer.identifiers?.find(i => i.type === "VAT_NUMBER")?.value}
                                                </TableCell>
                                                <TableCell>
                                                    {customer.type && (
                                                        <FormattedMessage
                                                            id={`general.classifiers.${customer?.type}`}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            aria-describedby={customer.id}
                                                            onClick={e => handlePopoverOpen(e, customer.id)}
                                                        >
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </IconButton>
                                                        <Popover
                                                            className={`popover-${customer.id}`}
                                                            open={customerId === customer.id}
                                                            anchorEl={anchorEl}
                                                            onClose={togglePopOver}
                                                            anchorOrigin={{
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            }}
                                                        >
                                                            <List disablePadding sx={{ width: 240 }}>
                                                                <ListItemButton
                                                                    onClick={() => {
                                                                        navigate(`/${customer.id}/customer-edit/main`);
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            <FormattedMessage id="c-customer-card.footerlabel-edit-customerdata" />
                                                                        }
                                                                    />
                                                                </ListItemButton>
                                                            </List>
                                                        </Popover>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </InfiniteTable>
                            </>
                        )}
                        {!loading && (customersStatus?.error || customersList?.length === 0) && (
                            <WsEmptyState
                                icon={faTable}
                                title={<FormattedMessage id="v-customers.empty-customers-message" />}
                            />
                        )}
                    </>
                </CustomersContainer>
            </Container>
        </CustomersLayout>
    );
};

function mapStateToProps(state) {
    return {
        authData: state.initDataFromParent.auth,
        customers: state.customers.spotlight.items,
        customersPagination: state.customers.spotlight.pagination,
        customersStatus: state.customers.status
    };
}

const actions = {
    getSpotlightCustomers
};

const composedHoc = compose(connect(mapStateToProps, actions));

export default composedHoc(Customers);
