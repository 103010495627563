import CustomerManagementApiClient from "../../apiClient";

export const GET_METERING_CONSUMPTION_START = "GET_METERING_CONSUMPTION_START";
export const GET_METERING_CONSUMPTION_SUCCESS = "GET_METERING_CONSUMPTION_SUCCESS";
export const GET_METERING_CONSUMPTION_ERROR = "GET_METERING_CONSUMPTION_ERROR";
export const GET_METERING_CONSUMPTION_RESET = "GET_METERING_CONSUMPTION_RESET";

export const customerManagementApiClient = new CustomerManagementApiClient();

export function getConsumption(ownerId, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_METERING_CONSUMPTION_START,
            payload: { ownerId, itemId }
        });

        try {
            const res = await customerManagementApiClient.getMeteringConsumption(ownerId, itemId);
            dispatch({
                type: GET_METERING_CONSUMPTION_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_METERING_CONSUMPTION_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetGetConsumption() {
    return dispatch => {
        dispatch({
            type: GET_METERING_CONSUMPTION_RESET
        });
    };
}
