import apiClient from "../apiClient";

export const UPDATE_CUSTOMER_PERSONAL_DATA_START = "UPDATE_CUSTOMER_PERSONAL_DATA_START";
export const UPDATE_CUSTOMER_PERSONAL_DATA_SUCCESS = "UPDATE_CUSTOMER_PERSONAL_DATA_SUCCESS";
export const UPDATE_CUSTOMER_PERSONAL_DATA_ERROR = "UPDATE_CUSTOMER_PERSONAL_DATA_ERROR";

export function updateCustomerMainData(workspaceId, params, subSection) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_CUSTOMER_PERSONAL_DATA_START,
            payload: { ...params }
        });

        try {
            const res = await new apiClient().updateCustomerMainData(workspaceId, params, subSection);

            dispatch({
                type: UPDATE_CUSTOMER_PERSONAL_DATA_SUCCESS,
                payload: {
                    id: res.id,
                    data: params?.[subSection],
                    subSection: subSection
                }
            });
            return res;
        } catch (e) {
            dispatch({
                type: UPDATE_CUSTOMER_PERSONAL_DATA_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
            throw e;
        }
    };
}
