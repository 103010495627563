import styled from "styled-components";

export const Spacer = styled.div`
    height: ${props => props.spacing || "1rem"};
`;

export const FormContainer = styled.div`
    display: flex;
    padding: 2rem 0 0 0;
    flex-direction: column;
`;

export const StepContainer = styled.div`
    width: 35%;
    min-width: fit-content;
    margin-bottom: 2rem;
    padding: 0 0.7rem;
`;

export const StepTitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Cairo, sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    padding: 0 3rem;
`;

export const StepSubtitle = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 16px;
    margin: 8px 0;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 26px;
    padding: 0 3rem;
`;

export const FieldsContainer = styled.div`
    max-width: ${props => props.maxWidth || "62%"};
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 1480px) {
        max-width: 80%;
    }
    @media (max-width: 968px) {
        max-width: 95%;
    }
`;

export const FieldsBox = styled.div`
    display: flex;
    flex-direction: column;
    border: ${props => (props.error ? "1px solid rgb(255, 0, 0)" : "1px solid rgb(201, 217, 232)")};
    border-radius: 8px;
    padding: 32px;
    margin-top: 1rem;
`;

export const FieldsBoxHeading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
`;

export const FieldsBoxTitle = styled.div`
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 19.6px;
    color: #2e2f30;
`;

export const FieldBoxInfo = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 0.5rem 1.5rem;
`;

export const FieldsChooseInfo = styled.div`
    color: rgb(90, 104, 114);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 26px;
    user-select: none;
`;

export const Error = styled.div`
    width: 100%;
    margin-top: 0.3rem;
    font-size: 13px;
    background: rgba(255, 255, 255, 0);
    color: rgb(216, 40, 41);
    box-sizing: border-box;
`;

export const IconBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.3rem;
`;

export const DeleteIcon = styled.div`
    cursor: pointer;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 1.2rem;
    margin-left: 1rem;
`;

export const EditIcon = styled.div`
    cursor: pointer;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 1.2rem;
    margin-left: 1rem;
`;

export const ResetSelect = styled.div`
    display: ${props => (props.disabled ? "none" : "block")};
    position: absolute;
    right: 2.2em;
    bottom: 0.283em;
    font-size: 16px;
    color: rgb(21, 41, 53);
    border-right: 1px solid hsl(199, 4%, 75%);
    height: 32px;
    padding: 0.2em 0.83em;
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.2s ease 0s;
    &:hover {
        color: ${props => props.theme.colors.primary.richElectricBlue};
    }
`;
