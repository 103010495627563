import customerManagementApiClient from "../apiClient";

export const CREATE_NEW_BUSINESS_START = "CREATE_NEW_BUSINESS_START";
export const CREATE_NEW_BUSINESS_SUCCESS = "CREATE_NEW_BUSINESS_SUCCESS";
export const CREATE_NEW_BUSINESS_ERROR = "CREATE_NEW_BUSINESS_ERROR";
export const CREATE_NEW_BUSINESS_RESET = "CREATE_NEW_BUSINESS_RESET";

export const DELETE_BUSINESS_START = "DELETEBUSINESS_START";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_ERROR = "DELETE_BUSINESS_ERROR";
export const DELETE_BUSINESS_RESET = "DELETE_BUSINESS_RESET";

export const UPDATE_BUSINESS_START = "UPDATE_BUSINESS_START";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_ERROR = "UPDATE_BUSINESS_ERROR";

export const GET_CUSTOMER_BUSINESS_START = "GET_CUSTOMER_BUSINESS_START";
export const GET_CUSTOMER_BUSINESS_SUCCESS = "GET_CUSTOMER_BUSINESS_SUCCESS";
export const GET_CUSTOMER_BUSINESS_ERROR = "GET_CUSTOMER_BUSINESS_ERROR";
export const GET_CUSTOMER_BUSINESS_RESET = "GET_CUSTOMER_BUSINESS_RESET";

export const GET_ATECO_START = "GET_ATECO_START";
export const GET_ATECO_SUCCESS = "GET_ATECO_SUCCESS";
export const GET_ATECO_ERROR = "GET_ATECO_ERROR";

export const GET_ASSET_GROUP_START = "GET_ASSET_GROUP_START";
export const GET_ASSET_GROUP_SUCCESS = "GET_ASSET_GROUP_SUCCESS";
export const GET_ASSET_GROUP_ERROR = "GET_ASSET_GROUP_ERROR";

export const CREATE_BUSINESS_SECTIONAL_START = "CREATE_BUSINESS_SECTIONAL_START";
export const CREATE_BUSINESS_SECTIONAL_SUCCESS = "CREATE_BUSINESS_SECTIONAL_SUCCESS";
export const CREATE_BUSINESS_SECTIONAL_ERROR = "CREATE_BUSINESS_SECTIONAL_ERROR";

export const UPDATE_BUSINESS_SECTIONAL_START = "UPDATE_BUSINESS_SECTIONAL_START";
export const UPDATE_BUSINESS_SECTIONAL_SUCCESS = "UPDATE_BUSINESS_SECTIONAL_SUCCESS";
export const UPDATE_BUSINESS_SECTIONAL_ERROR = "UPDATE_BUSINESS_SECTIONAL_ERROR";

export const DELETE_BUSINESS_SECTIONAL_START = "DELETE_BUSINESS_SECTIONAL_START";
export const DELETE_BUSINESS_SECTIONAL_SUCCESS = "DELETE_BUSINESS_SECTIONAL_SUCCESS";
export const DELETE_BUSINESS_SECTIONAL_ERROR = "DELETE_BUSINESS_SECTIONAL_ERROR";

export const RESET_BUSINESS_SECTIONAL = "RESET_BUSINESS_SECTIONAL";

export function createNewBusiness(ownerid, params) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_NEW_BUSINESS_START,
            payload: { ...params }
        });

        try {
            const res = await new customerManagementApiClient().createNewBusiness({ ownerId: ownerid, ...params });

            dispatch({
                type: CREATE_NEW_BUSINESS_SUCCESS,
                payload: {
                    id: res.id
                }
            });
        } catch (e) {
            dispatch({
                type: CREATE_NEW_BUSINESS_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function updateBusiness(workspaceId, businessId, params) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_BUSINESS_START,
            payload: { ...params }
        });
        try {
            const res = await new customerManagementApiClient().updateBusiness({
                workspaceId: workspaceId,
                businessId: businessId,
                ...params
            });

            dispatch({
                type: UPDATE_BUSINESS_SUCCESS,
                payload: res?.id
            });

            return getState()?.customer?.details?.additionalProps?.business?.find(item => item.id === businessId);
        } catch (e) {
            dispatch({
                type: UPDATE_BUSINESS_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function getCustomerBusiness(ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_BUSINESS_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().getCustomerBusiness(ownerId, workspaceId);

            dispatch({
                type: GET_CUSTOMER_BUSINESS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_BUSINESS_ERROR,
                payload: { e }
            });
        }
    };
}

export function deleteBusiness(ownerid, workspaceId, businessId) {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_BUSINESS_START,
            payload: { businessId: businessId }
        });

        try {
            const res = await new customerManagementApiClient().deleteBusiness(ownerid, workspaceId, businessId);

            dispatch({
                type: DELETE_BUSINESS_SUCCESS,
                payload: {
                    id: res.id
                }
            });
        } catch (e) {
            dispatch({
                type: DELETE_BUSINESS_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function createBusinessSectional(workspaceId, businessId, params) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_BUSINESS_SECTIONAL_START,
            payload: { workspaceId, businessId, ...params }
        });

        try {
            const res = await new customerManagementApiClient().createBusinessSectional(
                workspaceId,
                businessId,
                params
            );

            dispatch({
                type: CREATE_BUSINESS_SECTIONAL_SUCCESS,
                payload: {
                    id: res.id
                }
            });

            return getState();
        } catch (e) {
            dispatch({
                type: CREATE_BUSINESS_SECTIONAL_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function updateBusinessSectional(workspaceId, businessId, params) {
    return async dispatch => {
        dispatch({
            type: UPDATE_BUSINESS_SECTIONAL_START,
            payload: { workspaceId, businessId, ...params }
        });

        try {
            const res = await new customerManagementApiClient().updateBusinessSectional(
                workspaceId,
                businessId,
                params
            );

            dispatch({
                type: UPDATE_BUSINESS_SECTIONAL_SUCCESS,
                payload: {
                    id: res.id
                }
            });
        } catch (e) {
            dispatch({
                type: UPDATE_BUSINESS_SECTIONAL_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function deleteBusinessSectional(workspaceId, businessId, sectionalId) {
    return async dispatch => {
        dispatch({
            type: DELETE_BUSINESS_SECTIONAL_START,
            payload: { businessId: businessId }
        });

        try {
            await new customerManagementApiClient().deleteBusinessSectional(workspaceId, businessId, {
                id: sectionalId
            });

            dispatch({
                type: DELETE_BUSINESS_SECTIONAL_SUCCESS,
                payload: `deleted ${sectionalId}`
            });
        } catch (e) {
            dispatch({
                type: DELETE_BUSINESS_SECTIONAL_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function resetBusinessSectional() {
    return dispatch => {
        dispatch({
            type: RESET_BUSINESS_SECTIONAL
        });
    };
}

export function resetCreateNewClient() {
    return dispatch => {
        dispatch({
            type: CREATE_NEW_BUSINESS_RESET
        });
    };
}

export function getAteco(ownerId) {
    return async dispatch => {
        dispatch({
            type: GET_ATECO_START
        });

        try {
            const res = await new customerManagementApiClient().getAteco(ownerId);

            dispatch({
                type: GET_ATECO_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_ATECO_ERROR,
                payload: { e }
            });
        }
    };
}

export function getAssetGroups(ownerId) {
    return async dispatch => {
        dispatch({
            type: GET_ASSET_GROUP_START
        });

        try {
            const res = await new customerManagementApiClient().getGroups(ownerId);

            dispatch({
                type: GET_ASSET_GROUP_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_ASSET_GROUP_ERROR,
                payload: { e }
            });
        }
    };
}
