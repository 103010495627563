import { ParentApp } from "@mondora/microfrontends";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "../components/vapor-components/spinner";

import styled from "styled-components";
import { useDispatch } from "react-redux";
import { initDataFromParent } from "../actions/auth";

import initializeApp from "../core/initializeApp";

import { axiosFactory } from "../core/common/axiosFactory";

const SpinnerContainer = styled.div`
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ParentAppContext = createContext();

export const ParentAppProvider = ({ children, isWaitingForParentApp = true }) => {
    const [parentApp, setParentApp] = useState();

    const [isWaitingForParent, setIsWaitingForParent] = useState(isWaitingForParentApp);

    const dispatch = useDispatch();

    const [data, setData] = useState({});

    useEffect(() => {
        const parentApp = new ParentApp({
            onLaunch: params => {
                setData(params);
                dispatch(initDataFromParent(params));
                axiosFactory.setAccessToken(params.user.token);
                setIsWaitingForParent(false);
            }
        });

        setParentApp(parentApp);
    }, [dispatch, setData, setParentApp]);

    useEffect(() => {
        if (parentApp) {
            initializeApp(() => parentApp.call("getNewAccessToken"));
        }
    }, [parentApp]);

    const refreshToken = useCallback(async () => {
        const token = await parentApp.call("getNewAccessToken");
        setData({ ...data, user: { ...data.user, token } });

        return token;
    }, [data, parentApp]);

    const contextValues = useMemo(() => ({ data, methods: { refreshToken } }), [data, refreshToken]);

    return isWaitingForParent ? (
        <SpinnerContainer>
            <Spinner loading={true}>
                <></>
            </Spinner>
        </SpinnerContainer>
    ) : (
        <ParentAppContext.Provider value={{ parentApp: contextValues }}>{children}</ParentAppContext.Provider>
    );
};
