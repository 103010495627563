import { InputAdornment, Stack, TextField } from "@vapor/react-material";
import { FormattedMessage } from "react-intl";
import { getISODateAndMonth, getISOFromDateAndMonth } from "../../../../../../utils/convert";
import { InfoTooltip } from "../../../../../tooltip";

const subcomponentCashAccounting = {
    k: "cashAccounting",
    component: "radio",
    radioGroupAlign: "vertical",
    fullWidth: true,
    style: { width: "100%" },
    options: [
        { value: <FormattedMessage id={"general.no"} />, id: false },
        { value: <FormattedMessage id={"general.yes"} />, id: true }
    ]
};

export const specialVatRegimeOptions = intl => [
    { value: "Nessun regime speciale", id: "NO_SPECIAL_REGIME" },
    { value: "Agenzia viaggi", id: "TRAVEL_AGENCY" },
    { value: "Agricoltura speciale", id: "SPECIAL_SCHEME_OF_AGRICOLTURE" },
    { value: "Agricoltura esonero", id: "AGRICOLTURE_EXEMPTION" },
    {
        value: "Autotrasporto conto terzi",
        id: "TRUCK_THIRD_PARTIES",
        subcomponent: {
            ...subcomponentCashAccounting,
            label: (
                <Stack direction="row" spacing={1}>
                    <div>{intl.formatMessage({ id: "field.business.cashAccounting" })}</div>
                    <div>
                        <InfoTooltip
                            title={intl.formatMessage({ id: "tips.business.cashAccountingTitle" })}
                            message={intl.formatMessage({ id: "tips.business.cashAccountingDescription" })}
                        />
                    </div>
                </Stack>
            )
        }
    },
    { value: "Editoria", id: "PUBLISHING" },
    { value: "Margine analitico", id: "ANALYTICAL_MARGIN" },
    { value: "Margine globale", id: "GLOBAL_MARGIN" },
    {
        value: "Margine forfettario",
        id: "FLAT_RATE_MARGIN",
        subcomponent: {
            k: "flatTaxAccountingRate",
            label: intl.formatMessage({ id: "field.business.flatTaxAccountingRate" }),
            component: "textfield",
            componentType: "number",
            additionalProps: {
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    style: { textAlign: "left", width: "5.5rem" },
                    inputProps: {
                        style: { textAlign: "left", width: "2.5rem" },
                        onChange: e => {
                            const value = e.target.value;
                            if (value && !/^\d+$/.test(value)) {
                                e.target.value = value.slice(0, -1);
                            }
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                            if (e.target.value > 100) {
                                e.target.value = 100;
                            }
                            if (e.target.value < 0) {
                                e.target.value = 0;
                            }
                        }
                    }
                }
            }
        }
    },
    {
        value: "Ventilazione",
        id: "VENTILATION",
        subcomponent: {
            ...subcomponentCashAccounting,
            label: (
                <Stack direction="row" spacing={1}>
                    <div>{intl.formatMessage({ id: "field.business.cashAccounting" })}</div>
                    <div>
                        <InfoTooltip
                            title={intl.formatMessage({ id: "tips.business.cashAccountingTitle" })}
                            message={intl.formatMessage({ id: "tips.business.cashAccountingDescription" })}
                        />
                    </div>
                </Stack>
            )
        }
    },
    {
        value: "Ventilazione macellai",
        id: "BUTCHERS_VENTILATION",
        subcomponent: {
            ...subcomponentCashAccounting,
            label: (
                <Stack direction="row" spacing={1}>
                    <div>{intl.formatMessage({ id: "field.business.cashAccounting" })}</div>
                    <div>
                        <InfoTooltip
                            title={intl.formatMessage({ id: "tips.business.cashAccountingTitle" })}
                            message={intl.formatMessage({ id: "tips.business.cashAccountingDescription" })}
                        />
                    </div>
                </Stack>
            )
        }
    },
    { value: "Agriturismo", id: "AGRITOURISM" },
    { value: "Associazione Sindacale", id: "TRADE_UNION_ASSOCIATION" },
    { value: "Attività connesse agricoltura", id: "RELATED_AGRICOLTURE_ACTIVITIES" },
    { value: "Enoturismo", id: "WINE_TOURISM" },
    { value: "Oleoturismo", id: "OLEOTOURISM" }
];

export const specialVatDeductionRate = {
    NO_SPECIAL_REGIME: 0,
    TRAVEL_AGENCY: 0,
    SPECIAL_SCHEME_OF_AGRICOLTURE: 0,
    AGRICOLTURE_EXEMPTION: 0,
    TRUCK_THIRD_PARTIES: 0,
    PUBLISHING: 0,
    ANALYTICAL_MARGIN: 0,
    GLOBAL_MARGIN: 0,
    FLAT_RATE_MARGIN: 0,
    VENTILATION: 0,
    BUTCHERS_VENTILATION: 0,
    AGRITOURISM: 50,
    TRADE_UNION_ASSOCIATION: 33,
    RELATED_AGRICOLTURE_ACTIVITIES: 50,
    WINE_TOURISM: 50,
    OLEOTOURISM: 50
};

export const vatPeriodicityOptions = intl => [
    {
        id: "NORMAL_MONTHLY",
        value: intl.formatMessage({
            id: "c-customer-edit-business.vatPeriodicity.NORMAL_MONTHLY"
        })
    },
    {
        id: "MONTHLY_ADVANCE",
        value: intl.formatMessage({
            id: "c-customer-edit-business.vatPeriodicity.MONTHLY_ADVANCE"
        })
    },
    {
        id: "NORMAL_QUARTERLY",
        value: intl.formatMessage({
            id: "c-customer-edit-business.vatPeriodicity.NORMAL_QUARTERLY"
        })
    },
    {
        id: "QUARTERLY_ART_74_C_4",
        value: intl.formatMessage({
            id: "c-customer-edit-business.vatPeriodicity.QUARTERLY_ART_74_C_4"
        })
    }
];

export const vatExemptionManagementForExporterOptions = (intl, vatConfiguration, setVatConfiguration, handleChange) => [
    {
        id: "NO",
        label: intl.formatMessage({
            id: "general.no"
        })
    },
    {
        id: "FIXED_PLAFOND",
        label: intl.formatMessage({
            id: "c-customer-edit-business.plafondManagement.FIXED_PLAFOND"
        })
    },
    {
        id: "MOBILE_PLAFOND",
        label: intl.formatMessage({
            id: "c-customer-edit-business.plafondManagement.MOBILE_PLAFOND"
        }),
        direction: "row",
        sublist: [
            {
                id: "vatExempitonForExporterStartingDate",
                component: "datepicker",
                fullWidth: true,
                label: intl.formatMessage({ id: "field.business.mobileplafondPeriodStart" }),
                value: vatConfiguration?.vatExempitonForExporterStartingDate
                    ? getISOFromDateAndMonth(vatConfiguration?.vatExempitonForExporterStartingDate)
                    : null,
                isEditing: true,
                onChange: val => {
                    setVatConfiguration(
                        {
                            ...vatConfiguration,
                            vatExempitonForExporterStartingDate: getISODateAndMonth(val)
                        },
                        newVatConfiguration => handleChange(newVatConfiguration, "vatConfiguration")
                    );
                },
                additionalProps: {
                    sx: { width: "40%" },
                    views: ["month", "year"],
                    renderInput: params => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "gg/mm/aaaa"
                            }}
                        />
                    )
                }
            }
        ]
    }
];
