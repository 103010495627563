import {
    GET_CONNECTIONS_START,
    GET_CONNECTIONS_SUCCESS,
    GET_CONNECTIONS_ERROR,
    GET_CONNECTIONS_RESET
} from "../actions/connections";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function connections(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CONNECTIONS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CONNECTIONS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                connections: payload
            };
        case GET_CONNECTIONS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case GET_CONNECTIONS_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
