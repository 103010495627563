import React from "react";
import PropTypes from "prop-types";

import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

import { ChildrenContainer, LoadingIconContainer } from "./styled";

/**
 * Renders an arc Button which can enter a "loading" state.
 * While in the loading state, the button is disabled and displays
 * a spinner to the left of the button's text
 */
const LoadingButton = ({
    children,
    className,
    disabled,
    loading,
    onClick,
    size,
    variant,
    colorScheme,
    dataGainsightTrack,
    ...rest
}) => (
    <Button
        className={className}
        disabled={disabled || loading}
        onClick={onClick}
        size={size}
        kind={variant}
        colorScheme={colorScheme}
        data-gainsight-track={dataGainsightTrack || ""}
        {...rest}
    >
        <ChildrenContainer>
            {loading && (
                <LoadingIconContainer>
                    <FontAwesomeIcon icon={faCircleNotch} className={"fa-spin"} />
                </LoadingIconContainer>
            )}
            {children}
        </ChildrenContainer>
    </Button>
);

LoadingButton.defaultProps = {
    colorScheme: "default",
    disabled: false,
    loading: false,
    onClick: () => {},
    size: "medium",
    variant: "primary"
};

LoadingButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.oneOf(["blue", "white"]),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    variant: PropTypes.oneOf(["primary", "secondary", "tertiary"])
};

export default LoadingButton;
