import styled, { keyframes } from "styled-components";
const animationdBackground = keyframes`
64% {
    background: #c9d9e8;
}
100% {
    background: #E4EAF0;
}
`;
export const LoadingElement = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    animation-name: ${animationdBackground};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
`;
