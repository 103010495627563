import React from "react";
import { Button, Stack, Box } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { FormattedMessage } from "react-intl";
import { DeleteIcon, FieldsBox, FieldsBoxHeading, FieldsBoxTitle, IconBox } from "../../styled";
import IntermediaryInputs from "../intermediary-inputs";
import { step1DefaultBody } from "../../constants";

const ToggleCreateIntermediary = ({
    bodyRequest,
    selected,
    setSelected,
    displayBox,
    setDisplayBox,
    handleChange,
    error,
    type,
    setBodyRequest,
    setError,
    setIntermediaryType
}) => {
    const toggleDisplay = () => {
        setDisplayBox(!displayBox);
        setBodyRequest({ ...step1DefaultBody });
        setSelected(false);
    };

    return (
        <>
            {!displayBox ? (
                <Button
                    onClick={toggleDisplay}
                    sx={{
                        justifyContent: "flex-start",
                        width: "fit-content",
                        marginY: "1.5rem",
                        "&:hover": { background: "none", color: "inherit" },
                        "&:focus": { background: "none" }
                    }}
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <Box>
                            <FormattedMessage id="c-customer-edit-intermediaries.create-intermediary" />
                        </Box>
                    </Stack>
                </Button>
            ) : (
                <FieldsBox>
                    <FieldsBoxHeading>
                        <FieldsBoxTitle>
                            <FormattedMessage id="c-customer-edit-intermediaries.create-intermediary" />
                        </FieldsBoxTitle>
                        <IconBox>
                            <DeleteIcon onClick={toggleDisplay}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </DeleteIcon>
                        </IconBox>
                    </FieldsBoxHeading>
                    <IntermediaryInputs
                        type={type}
                        bodyRequest={bodyRequest}
                        handleChange={handleChange}
                        error={error}
                        setBodyRequest={setBodyRequest}
                        setError={setError}
                        setIntermediaryType={setIntermediaryType}
                    />
                </FieldsBox>
            )}
        </>
    );
};

export default ToggleCreateIntermediary;
