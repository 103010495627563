import styled from "styled-components";

export const ToggleBox = styled.div`
    display: flex;
    flex-direction: column;
    border: ${props => (props.error ? "1px solid rgb(255, 0, 0)" : "1px solid rgb(201, 217, 232)")};
    border-radius: 8px;
    padding: 32px;
    margin-top: 1rem;
`;
