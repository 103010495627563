import { Box, Button, Stack } from "@vapor/react-material";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { FileName, UploaderBox, Spacer, FileSize, Error } from "./styled";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const DropFiles = ({ files, setFiles, maxFiles, maxKb, acceptedFiles, errors, readOnly }) => {
    const fileInput = useRef();
    const dropFilesArea = useRef();
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const convertFileToBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
        });
    };

    const onFileDrop = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled || readOnly) {
                return;
            }

            const filesTransfer = e.dataTransfer?.files || e.target.files;
            const fileNumber = files.length + filesTransfer.length;

            const fileSize = [...filesTransfer].map(item => {
                return parseInt(item.size / 1024);
            });

            const fileExtension = [...filesTransfer].map(item => {
                return item.name
                    .split(".")
                    .pop()
                    .toLowerCase();
            });

            if (maxKb && fileSize.find(item => item > maxKb)) {
                setError(errors?.fileSize);
                return;
            }

            if (maxFiles && fileNumber > maxFiles) {
                setError(errors?.fileLength);
                return;
            }

            if (acceptedFiles && !acceptedFiles.find(item => fileExtension.includes(item.toLowerCase()))) {
                setError(errors?.fileExtension);
                return;
            }

            const filesWithBase64 = [...filesTransfer].map(async item => {
                let base64 = await convertFileToBase64(item);
                base64 = base64.split(",")[1];
                return {
                    name: item.name,
                    size: item.size,
                    base64
                };
            });

            Promise.all(filesWithBase64).then(base64Files => {
                setFiles([...files, ...base64Files]);
            });
        },
        [disabled, files, setFiles, maxFiles, maxKb, acceptedFiles, errors, readOnly]
    );

    const onFileRemove = useCallback(
        file => {
            const updatedList = [...files];
            updatedList.splice(files.indexOf(file), 1);
            setFiles(updatedList);
        },
        [files, setFiles]
    );

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = e => {
        if (!disabled) {
            fileInput.current.click();
        }
    };

    useEffect(() => {
        const areaDropFile = dropFilesArea.current;
        if (!readOnly) {
            areaDropFile.addEventListener("dragover", handleDragOver);
            areaDropFile.addEventListener("drop", onFileDrop);

            return () => {
                areaDropFile.removeEventListener("dragover", handleDragOver);
                areaDropFile.removeEventListener("drop", onFileDrop);
            };
        }
    }, [dropFilesArea, onFileDrop, readOnly]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setError("");
        }, 5500);
        return () => {
            clearTimeout(timer);
        };
    }, [error]);

    useEffect(() => {
        setDisabled(files.length >= maxFiles);
    }, [files, maxFiles, errors]);

    return (
        <>
            {!readOnly && (
                <>
                    <UploaderBox ref={dropFilesArea} error={error} disabled={disabled}>
                        <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                            <Box
                                sx={{
                                    display: "inline-flex",
                                    mr: "1rem",
                                    fontSize: "16px",
                                    letterSpacing: ".2px",
                                    color: "rgb(0, 144, 209)",
                                    cursor: disabled ? "not-allowed" : "pointer"
                                }}
                            >
                                <FormattedMessage
                                    id="field.multiple-upload"
                                    values={{
                                        maxfiles: maxFiles || ""
                                    }}
                                />
                            </Box>
                        </label>

                        <Button
                            variant="outlined"
                            onClick={handleClick}
                            sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
                        >
                            <FormattedMessage id="field.multiple-upload-button" />
                        </Button>
                        <input
                            id="file-upload"
                            ref={fileInput}
                            onChange={onFileDrop}
                            onDrop={onFileDrop}
                            type="file"
                            disabled={disabled}
                            multiple
                            hidden
                        />
                    </UploaderBox>
                    <Spacer spacing={".5rem"} />
                    {error !== "" && (
                        <Error>
                            <FormattedMessage id={error} />
                            <Spacer />
                        </Error>
                    )}
                </>
            )}

            {files.map((item, index) => (
                <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        borderBottom: "1px solid rgb(201, 217, 232)",
                        padding: "1rem 0"
                    }}
                >
                    <Box>
                        <FileName>{item.name}</FileName>
                        <Spacer spacing=".5rem" />
                        <FileSize>{parseInt(item.size / 1024)}kB</FileSize>
                    </Box>
                    {!readOnly && (
                        <span onClick={() => onFileRemove(item)}>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ color: "rgb(216, 40, 41)", cursor: "pointer" }}
                            />
                        </span>
                    )}
                </Stack>
            ))}
        </>
    );
};

DropFiles.propTypes = {
    files: PropTypes.array.isRequired,
    setFiles: PropTypes.func.isRequired,
    maxFiles: PropTypes.number,
    maxKb: PropTypes.number,
    acceptedFiles: PropTypes.array,
    errors: PropTypes.object,
    readOnly: PropTypes.bool
};

export default DropFiles;
