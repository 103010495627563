import {
    SEARCH_FROM_CERVED_START,
    SEARCH_FROM_CERVED_SUCCESS,
    SEARCH_FROM_CERVED_ERROR
  } from "../actions/newclient.js";
  
  const defaultState = {
    status: {
      started: false,
      error: false,
      ended: false,
      errorInfo: {
        code: "",
        message: "",
      },
    },
    result: null
  };
  
  export function cerved(state = defaultState, { type, payload, error }) {
    switch (type) {
      case SEARCH_FROM_CERVED_START:
        return {
          status: {
            started: true,
            error: false,
            ended: false,
          },
        };
      case SEARCH_FROM_CERVED_SUCCESS:
        return {
          status: {
            started: false,
            error: false,
            ended: true,
          },
          result : payload.subjects
        };
      case SEARCH_FROM_CERVED_ERROR:
        return {
          status: {
            started: false,
            error: true,
            ended: false,
            errorInfo: { error },
          },
        };
      
      default:
        return state;
    }
  }