import React from "react";
import { WsCard } from "@onefront/react-workspace-components";
import { Skeleton } from "@mui/material";

export const CustomersSkeleton = () => {
    return (
        <WsCard
            avatar={<Skeleton variant="circular" width={40} height={40} />}
            title={<Skeleton width="100%" height="37px" />}
            footer={<Skeleton width="100%" height="100%" sx={{ padding: "15px" }} />}
        >
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
        </WsCard>
    );
};
