import styled from "styled-components";

export const Container = styled.div`
    border-bottom: 0.125rem solid ${props => props.theme.colors.primary.richElectricBlue};
    background-color: ${props => props.theme.colors.background.white};
    padding: 0.5rem;
    display: grid;
    height: 62px;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;

    & > :first-child {
        justify-self: start;
    }

    & > :last-child {
        justify-self: end;
    }
`;

export const BackButtonTitle = styled.div`
    display: inline-block;
    margin-left: 1rem;
`;

export const TitleContainer = styled.div`
    text-align: center;
`;
