import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectCountry, StepTitle, StepSubtitle } from "../../styled";
import { translateMessage } from "../../../../../i18n";
import { H1, H5 } from "../../../../vapor-components/typography";
import FormSelect from "../../../../general/form-select";
import { fieldRequired } from "@ts-digital/agyo-validations";
import { useDispatch } from "react-redux";
import { resetSpotlightCustomers } from "../../../../../actions/customers";

const NewClientCountry = ({ taxRegion }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    dispatch(resetSpotlightCustomers());

    return (
        <div>
            <StepTitle>
                <H1 color="yankeesBlue">
                    <FormattedMessage id="c-new-registry.title1" />
                </H1>
            </StepTitle>
            <StepSubtitle>
                <H5 weight="normal" color="cadet">
                    <FormattedMessage id="c-new-registry.subtitle1" />
                </H5>
            </StepSubtitle>
            <SelectCountry>
                <FormSelect
                    name={"countryCode"}
                    label={intl.formatMessage({
                        id: "c-new-client.form.country"
                    })}
                    defaultValue={taxRegion}
                    options={[
                        {
                            description: intl.formatMessage({
                                id: "c-new-client.form.taxregion.it"
                            }),
                            value: "IT"
                        },
                        {
                            description: intl.formatMessage({
                                id: "c-new-client.form.taxregion.spagna"
                            }),
                            value: "ES"
                        },
                        {
                            description: intl.formatMessage({
                                id: "c-new-client.form.taxregion.swiss"
                            }),
                            value: "CH"
                        },
                        {
                            description: intl.formatMessage({
                                id: "c-new-client.form.taxregion.sanmarino"
                            }),
                            value: "SM"
                        }
                    ]}
                    required={true}
                    validate={fieldRequired(translateMessage("general.form.field-required"))}
                    placeholder={translateMessage("general.select")}
                />
            </SelectCountry>
        </div>
    );
};

NewClientCountry.propTypes = {
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool
};

export default NewClientCountry;
