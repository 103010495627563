import { Stack, TextField, Button } from "@vapor/react-material";
import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { parsePKCS12 } from "../../../../../utils/certificate";
import { handleMaxLength } from "../../../../../utils/validate";
import FormField from "../../../../vapor-components/field";
import { Error } from "../../styled";

const CheckValidityWithPassword = ({ index, bodyRequest, setBodyRequest, handleChange }) => {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { files, password } = bodyRequest[index];

    const expireDate = useCallback(
        i => () => {
            if (files.length > 2 && password.length > 0) {
                const p12File = files.find(file => file.name.toLowerCase().endsWith(".p12"));
                if (p12File) {
                    try {
                        const p12 = parsePKCS12(p12File.base64, password);
                        const { validity } = p12?.cert;
                        const notAfter = validity.notAfter.toISOString();
                        let arr = [...bodyRequest];
                        arr[i].expirationDate = notAfter;
                        setBodyRequest(arr);
                        setError(null);
                        setSuccess(true);
                    } catch (e) {
                        console.error(e);
                        setError(intl.formatMessage({ id: "c-customer-edit-intermediary.error.password" }));
                    }
                } else {
                    setError(intl.formatMessage({ id: "c-customer-edit-intermediary.error.invalid-extension" }));
                }
            } else {
                setError(intl.formatMessage({ id: "c-customer-edit-intermediary.error.filemissing" }));
            }
        },
        [bodyRequest, files, password, intl, setBodyRequest]
    );

    const convertDate = useCallback(date => {
        const arr = date.split("T");
        const dateArr = arr[0].split("-");
        const timeArr = arr[1].split(":");
        return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]} ${timeArr[0]}:${timeArr[1]}`;
    }, []);

    useEffect(() => {
        const findP12onFile = files.find(file => file.name.toLowerCase().endsWith(".p12"));
        if ((success && !findP12onFile) || (success && files.length < 3)) {
            let arr = [...bodyRequest];
            arr[index].expirationDate = "";
            setBodyRequest(arr);
            setError(null);
            setSuccess(false);
        }
    }, [success, files, index, bodyRequest, setBodyRequest]);

    return (
        <Stack mt={3} spacing={2}>
            {bodyRequest[index].expirationDate !== "" ? (
                <FormField
                    label={intl.formatMessage({ id: "general.expire-date" })}
                    description={convertDate(bodyRequest[index].expirationDate)}
                />
            ) : (
                <>
                    <FormField
                        label={intl.formatMessage({ id: "general.expire-date" })}
                        description={intl.formatMessage({
                            id: "c-customer-edit-intermediaries.expirydate-info"
                        })}
                    />
                    <Stack direction={"row"} alignItems={"flex-end"} spacing={2}>
                        <TextField
                            label={intl.formatMessage({ id: "general.password" })}
                            error={error}
                            name="password"
                            type={"password"}
                            placeholder={intl.formatMessage({ id: "general.insert-password" })}
                            value={bodyRequest[index].password}
                            onChange={handleChange(index)}
                            inputProps={{ onChange: e => handleMaxLength(e, 32), autoComplete: "off" }}
                            sx={{ flex: "0 50%" }}
                        />
                        <Button variant="outlined" onClick={expireDate(index)}>
                            <FormattedMessage id="general.confirm" />
                        </Button>
                    </Stack>
                    {error && (
                        <Error>
                            <FormattedMessage id={error} />
                        </Error>
                    )}
                </>
            )}
        </Stack>
    );
};

export default CheckValidityWithPassword;
