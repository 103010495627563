import styled, { keyframes } from "styled-components";

const expandWidth = (toWidth) => keyframes`
    0% { width: 0; }
    100% { width: ${toWidth}%; }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  color: ${(props) => props.theme.colors.text.quickSilver};
  font-size: 0.75rem;
  font-family: Cairo;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.5rem;
`;

export const Percentage = styled.div`
  color: ${(props) => props.theme.colors.primary.richElectricBlue};
`;

export const Progress = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${(props) => props.theme.colors.text.gainsboro};
  position: relative;

  &::before {
    position: absolute;
    width: ${(props) => props.toWidth}%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(
      to right,
      ${(props) => props.theme.colors.primary.richElectricBlue},
      ${(props) => props.theme.colors.primary.cyanProgress}
    );
    animation: ${(props) => expandWidth(props.toWidth)} 2s;
  }
`;
