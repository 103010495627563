import customerManagementApiClient from "../apiClient/";

export const CREATE_CUSTOMER_ANTIMONEYLAUNDERING_START = "CREATE_CUSTOMER_ANTIMONEYLAUNDERING_START";
export const CREATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS = "CREATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS";
export const CREATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR = "CREATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR";

export const UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_START = "UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_START";
export const UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS = "UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS";
export const UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR = "UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR";

export function createCustomerAntiMoneyLaundering(requestBody) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_CUSTOMER_ANTIMONEYLAUNDERING_START,
            payload: requestBody
        });

        try {
            const res = await new customerManagementApiClient().createCustomerAntiMoneyLaundering(requestBody);

            dispatch({
                type: CREATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: CREATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR,
                payload: { e }
            });
        }
    };
}

export function updateCustomerAntiMoneyLaundering(requestBody) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_START,
            payload: requestBody
        });

        try {
            const res = await new customerManagementApiClient().updateCustomerAntiMoneyLaundering(requestBody);

            dispatch({
                type: UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: UPDATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR,
                payload: { e }
            });
        }
    };
}
