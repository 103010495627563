import React, { useState } from "react";

import { FormattedMessage } from "react-intl";

import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { Input } from "@ts-digital/vrc";

import { UpdateSearchContainer, SearchIcon, SearchIconContainer } from "./styled";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";

export const SearchInputBar = ({ value, label, onSearch, placeholder, width, onLoading }) => {
    const [textValue, setTextValue] = useState(value);

    return (
        <>
            <UpdateSearchContainer>
                <div>
                    <Input
                        label={label ? label : <FormattedMessage id="general.users-search" />}
                        style={{ width: width ? width : "300px" }}
                        value={textValue}
                        onChange={value => {
                            setTextValue(value);
                        }}
                        onKeyPress={e => {
                            if (e.key === "Enter") onSearch(e.target.value ? e.target.value : null);
                        }}
                        placeholder={placeholder}
                    />
                </div>
                <SearchIconContainer onClick={() => onSearch(textValue)}>
                    <SearchIcon icon={!onLoading ? faSearch : faCircleNotch} spin={onLoading} />
                </SearchIconContainer>
            </UpdateSearchContainer>
        </>
    );
};

export default SearchInputBar;
