import styled from "styled-components";

export const CustomerSectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerSectionsTitle = styled.div`
    margin-bottom: 16px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 20px;
    font-family: Cairo, sans-serif;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

export const CustomerSectionsItems = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerSectionItem = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 43px;
    max-width: 270px;
    cursor: pointer;
    border-left: 5px solid transparent;
    ${props =>
        props.selected &&
        `background: ${props.theme.colors.secondary.azure}; border-left: 5px solid ${props.theme.colors.primary.richElectricBlue}; color: ${props.theme.colors.primary.richElectricBlue};`}
`;
