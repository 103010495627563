import styled from "styled-components";

export const CustomerEditBankContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const CustomerEditBankList = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const CustomerEditBankItem = styled.div`
    display: flex;
    margin: 8px;
`;

export const CustomerEditBankItemContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    min-height: 100px;
`;

export const CustomerEditBankTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerEditBankTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
`;

export const CustomerEditBankTitleText = styled.div`
    margin-left: 10px;
`;

export const CustomerEditBankTitleStatus = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const CustomerEditBankStatusTag = styled.div`
    flex: 1 0 auto;
    margin-bottom: 1rem;
`;

export const CustomerEditBankStatus = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-left: 32px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const DotStatus = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-right: 8px;
    background-color: ${props =>
        props.missing ? props.theme.colors.status.crayola : props.theme.colors.status.laSalleGreen};
`;

export const CustomerEditBankItemContentLine = styled.div`
    max-width: 230px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 30px;
`;

export const CustomerEditBankFooterContainer = styled.div`
    width: 100%;
`;

export const ModalContainer = styled.div`
    width: 370px;
    margin: 10px auto 10px auto;
`;

export const CustomerBankModalFooter = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const MenuButton = styled.div`
    text-align: center;
    margin-bottom: 5px;
`;

export const EditButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
    border-top-style: solid;
    border-top-width: 0.1px;
    border-top-color: ${props => props.theme.colors.background.azureishWhite};
`;

export const WhiteButton = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.background.white};
    font-size: 16px;
    cursor: pointer;
`;

export const CustomerEditBankCardTitle = styled.div`
    min-height: 90px;
    width: 250px;
`;

export const CustomerEditBankCardContent = styled.div`
    margin-top: 26px;
    min-height: 40px;
    width: 250px;
`;

export const CustomerEditBankCardLogo = styled.div`
    & img {
        max-width: 127px;
        max-height: 80px;
    }
`;

export const CustomerEditBankCardInfo = styled.div``;

export const CustomerEditBankCardData = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    color: rgb(19, 41, 53);
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
`;

export const CustomerEditFeedbackContainer = styled.div`
    position: absolute;
    right: 5vw;
`;
