import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faUniversity } from "@fortawesome/pro-regular-svg-icons/faUniversity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "@ts-digital/vrc";
import { Menu, MenuItem } from "@vapor/react-material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router";
import { deleteCustomerBanks, getCustomerBanks, getTsPayBanks } from "../../../actions/banks";
import Card from "../../vapor-components/card";
import Tag from "../../vapor-components/tag";
import { Descriptive, H4 } from "../../vapor-components/typography";
import PayPalLogo from "../../../assets/img/paypal.png";
import { MainTitleDesc, MainTitleText } from "../customer-edit-registry/styled";
import {
    CustomerEditBankContainer,
    CustomerEditBankItemContent,
    CustomerEditBankTitleContainer,
    CustomerEditBankTitle,
    CustomerEditBankTitleText,
    CustomerEditBankTitleStatus,
    CustomerEditBankStatusTag,
    CustomerEditBankFooterContainer,
    CustomerBankModalFooter,
    ModalContainer,
    EditButton,
    WhiteButton,
    CustomerEditBankCardTitle,
    CustomerEditBankCardContent,
    CustomerEditBankCardLogo,
    CustomerEditBankCardInfo,
    CustomerEditBankCardData,
    CustomerEditFeedbackContainer
} from "./styled";

import CustomerBankRegistryCreate from "./create/registry";
import { useEffect } from "react";
import { getBankLogo } from "./tspay/bank-logos";
import CardsLayout from "../../cards-layout";
import VaporAlert from "../../vapor-components/alert";

const convertBankData = data => {
    let newData = {
        ...data
    };

    if (newData.abi?.toString().length > 0) {
        newData.abi = newData.abi?.toString().padStart(5, "0");
    }
    if (newData.cab?.toString().length > 0) {
        newData.cab = newData.cab?.toString().padStart(5, "0");
    }
    if (newData.accountNumber?.toString().length > 0) {
        newData.accountNumber = newData.accountNumber?.toString().padStart(12, "0");
    }
    return newData;
};

const CustomerEditBanks = ({ render, theme }) => {
    const intl = useIntl();

    const disableTSPay = true; // Remove to enable tspay card

    const dispatch = useDispatch();

    const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
    const [isCreating, setIsCreating] = useState(null);
    const [isEditing, setIsEditing] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bankId, setBankId] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const customerDetails = useSelector(state => state.customer.details);
    const tspayBanks = useSelector(state => state.tspay.banks?.data);

    useEffect(() => {
        if (render) {
            dispatch(getTsPayBanks(ownerId, customerDetails.data.id));
        }
    }, [dispatch, customerDetails.data.id, ownerId, render]);

    const open = Boolean(anchorEl);

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    if (!render) {
        return null;
    }

    async function onDeleteBank(bankId) {
        if (!bankId) {
            return null;
        }
        setFeedback(null);
        await dispatch(deleteCustomerBanks(ownerId, workspaceId, bankId))
            .then(() => {
                setFeedback("delete");
            })
            .catch(() => {
                setFeedback("error");
            });
        setTimeout(() => dispatch(getCustomerBanks(ownerId, workspaceId)), 500);
        setBankId(null);
        setAnchorEl(null);
    }

    const handleClick = event => {
        setBankId(event.currentTarget.id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <CustomerEditBankContainer>
            <CustomerBankRegistryCreate
                open={isCreating === "registry"}
                onClose={() => {
                    setIsCreating(null);
                    setIsEditing(null);
                    setBankId(null);
                }}
                setFeedback={setFeedback}
                bankId={bankId}
                bankData={
                    isEditing
                        ? convertBankData(customerDetails?.additionalProps?.banks?.find(x => x.id === bankId))
                        : null
                }
            />
            {visibleConfirmModal && (
                <Modal
                    onClose={() => setVisibleConfirmModal(false)}
                    closable={true}
                    type={"danger"}
                    footer={
                        <CustomerBankModalFooter>
                            <Button
                                style={{ marginRight: "10px" }}
                                kind="secondary"
                                onClick={() => setVisibleConfirmModal(false)}
                            >
                                <FormattedMessage id="general.cancel" />
                            </Button>
                            <Button
                                colorScheme="alert"
                                kind="primary"
                                onClick={() => {
                                    onDeleteBank(bankId);
                                    setVisibleConfirmModal(false);
                                }}
                            >
                                <FormattedMessage id="general.delete" />
                            </Button>
                        </CustomerBankModalFooter>
                    }
                    visible={visibleConfirmModal}
                    title={intl.formatMessage({ id: "c-customer-edit-bank.delete" })}
                >
                    <ModalContainer>
                        <H4 lineHeight="2rem">
                            <FormattedMessage id="c-customer-edit-bank.deleteDescription" />
                        </H4>
                    </ModalContainer>
                </Modal>
            )}

            {feedback && (
                <CustomerEditFeedbackContainer>
                    <VaporAlert
                        type={feedback === "error" ? "error" : "success"}
                        message={<FormattedMessage id={`feedbacks.banks.${feedback}`} />}
                        title={<FormattedMessage id={`feedbacks.banks.${feedback}.title`} />}
                        onClose={() => setFeedback(null)}
                        open={true}
                        anchor={{ top: "124px" }}
                    />
                </CustomerEditFeedbackContainer>
            )}

            <CardsLayout
                title={
                    <MainTitleText>
                        <FontAwesomeIcon icon={faUniversity} /> <FormattedMessage id="c-customer-edit-banks.title" />
                    </MainTitleText>
                }
                description={
                    <MainTitleDesc>
                        <FormattedMessage id="c-customer-edit-banks.titleDescription" />
                    </MainTitleDesc>
                }
                isLoading={customerDetails?.additionalProps?.isGettingBanks}
                skeletonFirstGrey
            >
                <Card
                    backgroundColor={"whiteSmoke"}
                    verticalFooter={true}
                    footer={
                        <CustomerEditBankFooterContainer>
                            <EditButton>
                                <Button
                                    kind="tertiary"
                                    onClick={() => {
                                        setBankId(null);
                                        setIsCreating("registry");
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </Button>
                            </EditButton>
                        </CustomerEditBankFooterContainer>
                    }
                    title={
                        <CustomerEditBankTitleContainer>
                            <CustomerEditBankTitleStatus>
                                <CustomerEditBankStatusTag>
                                    <Tag
                                        bgColor="#e4eaf0"
                                        color="#5a6872"
                                        label={<FormattedMessage id="c-customer-edit-bank.tag.registryAccount" />}
                                        upperText
                                    />
                                </CustomerEditBankStatusTag>
                            </CustomerEditBankTitleStatus>
                            <CustomerEditBankTitle>
                                <FontAwesomeIcon icon={faPlus} />
                                <CustomerEditBankTitleText>
                                    <FormattedMessage id="c-customer-edit-bank.create" />
                                </CustomerEditBankTitleText>
                            </CustomerEditBankTitle>
                        </CustomerEditBankTitleContainer>
                    }
                >
                    <CustomerEditBankItemContent>
                        <Descriptive color="cadet" lineHeight="16px" style={{ marginTop: "10px", marginLeft: "24px" }}>
                            <FormattedMessage id="c-customer-edit-bank.createDescription" />
                        </Descriptive>
                        <Descriptive color="cadet" lineHeight="40px" style={{ marginLeft: "24px" }}>
                            <FormattedMessage
                                id="c-customer-edit-createIn"
                                values={{
                                    icon: <FontAwesomeIcon icon={faClock} />,
                                    minutes: (
                                        <b>
                                            2 <FormattedMessage id="c-customer-edit-bank.createMin" />
                                        </b>
                                    )
                                }}
                            />
                        </Descriptive>
                    </CustomerEditBankItemContent>
                </Card>
                {!disableTSPay && (
                    <Card
                        dark
                        verticalFooter={true}
                        footer={
                            <CustomerEditBankFooterContainer dark={true}>
                                <EditButton dark={true}>
                                    <WhiteButton>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </WhiteButton>
                                </EditButton>
                            </CustomerEditBankFooterContainer>
                        }
                        title={
                            <CustomerEditBankTitleContainer>
                                <CustomerEditBankTitleStatus>
                                    <CustomerEditBankStatusTag>
                                        <Tag
                                            label={<FormattedMessage id="c-customer-edit-bank.tag.tspayAccount" />}
                                            color="info"
                                            variant="filled"
                                            size="small"
                                            style={{ borderRadius: 4, marginBottom: 8 }}
                                        />
                                    </CustomerEditBankStatusTag>
                                </CustomerEditBankTitleStatus>
                                <CustomerEditBankTitle>
                                    <FontAwesomeIcon icon={faPlus} />
                                    <CustomerEditBankTitleText dark={true}>
                                        <FormattedMessage id="c-customer-edit-bank.createTsPay" />
                                    </CustomerEditBankTitleText>
                                </CustomerEditBankTitle>
                            </CustomerEditBankTitleContainer>
                        }
                    >
                        <CustomerEditBankItemContent>
                            <Descriptive color="white" lineHeight="20px">
                                <FormattedMessage id="c-customer-edit-bank.createTsPayDescription" />
                            </Descriptive>
                            <Descriptive color="white" lineHeight="40px">
                                <FormattedMessage id="c-customer-edit-bank.createTsPayIn" />
                                <FontAwesomeIcon icon={faClock} />{" "}
                                <b>
                                    5 <FormattedMessage id="c-customer-edit-bank.createMin" />
                                </b>
                            </Descriptive>
                        </CustomerEditBankItemContent>
                    </Card>
                )}
                {tspayBanks?.length > 0 &&
                    customerDetails?.additionalProps?.banks?.map(bank => {
                        if (bank.iban?.length < 10 && bank.type !== "PAYPAL_ACCOUNT") {
                            return null;
                        }
                        const bankFind = tspayBanks.find(x => x.aspspCode === bank?.iban?.substring(5, 10));
                        return (
                            <>
                                <Card
                                    verticalFooter={true}
                                    footer={
                                        <CustomerEditBankFooterContainer>
                                            <EditButton>
                                                <Button id={bank.id} kind="tertiary" onClick={handleClick}>
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Button>
                                            </EditButton>
                                            <Menu
                                                id={`menu-bank-card_${bank.id}`}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    "aria-labelledby": "basic-button"
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={e => {
                                                        setIsCreating("registry");
                                                        setIsEditing(true);
                                                        handleClose(e);
                                                    }}
                                                >
                                                    <FormattedMessage id="general.edit" />
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={e => {
                                                        setVisibleConfirmModal(true);
                                                        handleClose(e);
                                                    }}
                                                >
                                                    <FormattedMessage id="general.delete" />
                                                </MenuItem>
                                            </Menu>
                                        </CustomerEditBankFooterContainer>
                                    }
                                    title={
                                        <CustomerEditBankCardTitle>
                                            <CustomerEditBankCardLogo>
                                                <img
                                                    width={80}
                                                    height={80}
                                                    src={
                                                        bank.type === "PAYPAL_ACCOUNT"
                                                            ? PayPalLogo
                                                            : getBankLogo(bankFind?.logo)
                                                    }
                                                    alt=""
                                                />
                                            </CustomerEditBankCardLogo>
                                            <CustomerEditBankCardInfo></CustomerEditBankCardInfo>
                                        </CustomerEditBankCardTitle>
                                    }
                                >
                                    <CustomerEditBankCardContent>
                                        <CustomerEditBankCardData>
                                            {bank.type === "PAYPAL_ACCOUNT"
                                                ? bank.bankName
                                                : bankFind?.businessName || bank.bankName}
                                            <br />
                                            {bank.type === "PAYPAL_ACCOUNT" ? bank.account : bank.iban}
                                        </CustomerEditBankCardData>
                                    </CustomerEditBankCardContent>
                                </Card>
                            </>
                        );
                    })}
            </CardsLayout>
        </CustomerEditBankContainer>
    );
};

export default CustomerEditBanks;
