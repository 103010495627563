import customerManagementApiClient from "../apiClient/";

export const GET_CUSTOMER_INTRASTAT_START = "GET_CUSTOMER_INTRASTAT_START";
export const GET_CUSTOMER_INTRASTAT_SUCCESS = "GET_CUSTOMER_INTRASTAT_SUCCESS";
export const GET_CUSTOMER_INTRASTAT_ERROR = "GET_CUSTOMER_INTRASTAT_ERROR";
export const GET_CUSTOMER_INTRASTAT_RESET = "GET_CUSTOMER_INTRASTAT_RESET";

export const CREATE_CUSTOMER_INTRASTAT_START = "CREATE_CUSTOMER_INTRASTAT_START";
export const CREATE_CUSTOMER_INTRASTAT_SUCCESS = "CREATE_CUSTOMER_INTRASTAT_SUCCESS";
export const CREATE_CUSTOMER_INTRASTAT_ERROR = "CREATE_CUSTOMER_INTRASTAT_ERROR";

export const UPDATE_CUSTOMER_INTRASTAT_START = "UPDATE_CUSTOMER_INTRASTAT_START";
export const UPDATE_CUSTOMER_INTRASTAT_SUCCESS = "UPDATE_CUSTOMER_INTRASTAT_SUCCESS";
export const UPDATE_CUSTOMER_INTRASTAT_ERROR = "UPDATE_CUSTOMER_INTRASTAT_ERROR";

export const DELETE_CUSTOMER_INTRASTAT_START = "DELETE_CUSTOMER_INTRASTAT_START";
export const DELETE_CUSTOMER_INTRASTAT_SUCCESS = "DELETE_CUSTOMER_INTRASTAT_SUCCESS";
export const DELETE_CUSTOMER_INTRASTAT_ERROR = "DELETE_CUSTOMER_INTRASTAT_ERROR";

export function getCustomerIntrastat(ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_INTRASTAT_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().getCustomerIntrastat(ownerId, workspaceId);

            dispatch({
                type: GET_CUSTOMER_INTRASTAT_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_INTRASTAT_ERROR,
                payload: { e }
            });
        }
    };
}

export function createCustomerIntrastat(ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_CUSTOMER_INTRASTAT_START,
            payload: { ownerId, requestParams }
        });

        try {
            const res = await new customerManagementApiClient().createCustomerIntrastat(
                ownerId,
                workspaceId,
                requestParams
            );

            dispatch({
                type: CREATE_CUSTOMER_INTRASTAT_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: CREATE_CUSTOMER_INTRASTAT_ERROR,
                payload: { e }
            });
        }
    };
}

export function updateCustomerIntrastat(intrastatId, ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_CUSTOMER_INTRASTAT_START,
            payload: { intrastatId, ownerId, requestParams }
        });

        try {
            const res = await new customerManagementApiClient().updateCustomerIntrastat(
                intrastatId,
                ownerId,
                workspaceId,
                requestParams
            );

            dispatch({
                type: UPDATE_CUSTOMER_INTRASTAT_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: UPDATE_CUSTOMER_INTRASTAT_ERROR,
                payload: { e }
            });
        }
    };
}

export function deleteCustomerIntrastat(intrastatId, ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_CUSTOMER_INTRASTAT_START,
            payload: { intrastatId, ownerId }
        });

        try {
            await new customerManagementApiClient().deleteCustomerIntrastat(intrastatId, ownerId, workspaceId);

            dispatch({
                type: DELETE_CUSTOMER_INTRASTAT_SUCCESS,
                payload: intrastatId
            });
        } catch (e) {
            dispatch({
                type: DELETE_CUSTOMER_INTRASTAT_ERROR,
                payload: { e }
            });
        }
    };
}
