import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Stack,
    Typography
} from "@vapor/react-material";
import React, { useState, useEffect, useCallback } from "react";
import { FieldsBox } from "@onefront/react-workspace-components";
import { FormattedMessage, useIntl } from "react-intl";
import { sectionalRows } from "./rows";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import Tag from "../../../../../vapor-components/tag";
import { SectionalTitleContainer } from "./styled";
import { isEmptyOrSpaces } from "../../../../../../utils/string";
import { useDispatch } from "react-redux";
import {
    createBusinessSectional,
    deleteBusinessSectional,
    updateBusinessSectional
} from "../../../../../../actions/business";
import { AlertMessage } from "../../../../../alert-message";
import { ceasedBusiness } from "../../constants";

export const CustomerEditBusinessStep3 = ({
    bodyRequest,
    handleChange,
    setErrors,
    workspaceId,
    businessId,
    displayErrors,
    setDisplayErrors,
    errors
}) => {
    const [isEditing, setIsEditing] = useState(
        !businessId && (bodyRequest?.sectionals?.length === 0 || bodyRequest?.sectionals?.find(s => s?.id === -1))
            ? -1
            : false
    );
    const [mainReplace, setMainReplace] = useState(false);
    const [items, setItems] = useStateWithCallbackLazy(bodyRequest?.sectionals);
    const [itemToSave, setItemToSave] = useState({});
    const [idx, setIdx] = useState(1);
    const [itemsSettings, setItemsSettings] = useState({});
    const [mainItem, setMainItem] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        setErrors({
            sectional: true
        });

        if (items.length > 0) {
            !businessId && setIdx(items?.length);
            const error = items.some(item => {
                return (
                    isEmptyOrSpaces(item?.description || "") ||
                    isEmptyOrSpaces(item?.numberSeries || "") ||
                    isEmptyOrSpaces(item?.province || "")
                );
            });

            setErrors({
                sectional: error && isEditing
            });

            setDisplayErrors(error && isEditing);
        }
    }, [bodyRequest, setErrors, businessId, items, isEditing, setDisplayErrors]);

    useEffect(() => {
        let settings = {};

        items?.map((it, index) => {
            if (it.main) {
                settings[it.id] = {
                    title: (
                        <SectionalTitleContainer>
                            {intl.formatMessage({ id: "field.business.sectionalCode" })} {index + 1}{" "}
                            <Tag
                                label={intl.formatMessage({
                                    id: "c-customer-edit-business.type.PREVALENT"
                                })}
                                type="blueSapphire"
                                ghost
                            />
                        </SectionalTitleContainer>
                    ),
                    notCancellable: true
                };
            } else {
                settings[it.id] = {
                    title: (
                        <SectionalTitleContainer>
                            {intl.formatMessage({ id: "field.business.sectionalCode" })} {index + 1}{" "}
                        </SectionalTitleContainer>
                    )
                };
            }
            return null;
        });

        setItemsSettings(settings);
    }, [items, setItems, setItemsSettings, intl]);

    const ReplaceMainDialog = () => {
        return (
            <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={mainReplace}>
                <DialogTitle>
                    <FormattedMessage id="c-customer-edit-business.dialog-main-sectional-title" />
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="c-customer-edit-business.dialog-main-sectional-content"
                            values={{
                                sectionalDescription: items?.find(s => s.main)?.description
                            }}
                        />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setMainReplace(false)}>
                        <FormattedMessage id="general.cancel" />
                    </Button>
                    <Button variant="contained" onClick={() => confirmReplace()}>
                        <FormattedMessage id="c-customer-edit-business.dialog-main-sectional-confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const confirmReplace = () => {
        setMainReplace(false);
        saveItem(true);
        setItemToSave({});
        setIsEditing(null);
    };

    const checkMainReplace = () => {
        let exists = false;
        items?.map(it => {
            if (it.id !== itemToSave.id && it.main) {
                exists = true;
            }
            return null;
        });
        return exists;
    };

    const onAdd = () => {
        setMainItem(items?.find(s => s.main));
        setItems([...items, { id: -1 }], newStateItems => handleChange(newStateItems, "sectionals"));
        setItemToSave({ main: false });
        setIsEditing(-1);
    };

    const removeItem = index => {
        setItems(
            items
                .filter(function(obj) {
                    return obj.id !== index;
                })
                .sort((a, b) => b.main - a.main) || [],
            newStateItems => handleChange(newStateItems, "sectionals")
        );
        setIsEditing(null);
        if (businessId && index !== -1) {
            dispatch(deleteBusinessSectional(workspaceId, businessId, index)).then(() => {
                setAlertMessage({
                    status: "success",
                    title: intl.formatMessage({ id: "c-customer-edit-business.sectional.delete.success" })
                });
            });
        }
    };

    const saveItem = useCallback(
        (replaceMain = false) => {
            let newItems = [];

            if (isEditing < 0) {
                items?.map(it => {
                    if (it.id !== isEditing) {
                        if (replaceMain && it.id !== isEditing && it.main) {
                            newItems.push({ ...it, main: false });
                        } else {
                            newItems.push(it);
                        }
                    }
                    return null;
                });

                if (businessId) {
                    if (mainItem && mainItem.id !== itemToSave.id && itemToSave.main) {
                        dispatch(updateBusinessSectional(workspaceId, businessId, { ...mainItem, main: false })).then(
                            () => {
                                dispatch(createBusinessSectional(workspaceId, businessId, itemToSave)).then(
                                    async item => {
                                        await item;
                                        let newItem = {
                                            ...itemToSave,
                                            id: item?.customer.details?.additionalProps?.sectionalId
                                        };
                                        newItems.push(newItem);
                                        setAlertMessage({
                                            status: "success",
                                            title: intl.formatMessage({
                                                id: "c-customer-edit-business.sectional.create.success"
                                            })
                                        });
                                    }
                                );
                            }
                        );
                    } else {
                        dispatch(createBusinessSectional(workspaceId, businessId, itemToSave)).then(async item => {
                            await item;
                            let newItem = {
                                ...itemToSave,
                                id: item?.customer.details?.additionalProps?.sectionalId
                            };
                            newItems.push(newItem);
                            setAlertMessage({
                                status: "success",
                                title: intl.formatMessage({
                                    id: "c-customer-edit-business.sectional.create.success"
                                })
                            });
                        });
                    }
                } else {
                    newItems.push({ ...itemToSave, id: idx, main: itemToSave?.main || false });
                    setIdx(idx + 1);
                }
            } else {
                items?.map(item => {
                    if (item.id !== isEditing) {
                        if (replaceMain && item.id !== isEditing && item.main) {
                            newItems.push({ ...item, main: false });
                            if (businessId) {
                                dispatch(
                                    updateBusinessSectional(workspaceId, businessId, { ...item, main: false })
                                ).then(() => {
                                    dispatch(updateBusinessSectional(workspaceId, businessId, { ...itemToSave })).then(
                                        () => {
                                            setAlertMessage({
                                                status: "success",
                                                title: intl.formatMessage({
                                                    id: "c-customer-edit-business.sectional.update.success"
                                                })
                                            });
                                        }
                                    );
                                });
                            }
                        } else {
                            newItems.push(item);
                        }
                    } else {
                        newItems.push(itemToSave);
                        if (businessId && !replaceMain) {
                            dispatch(updateBusinessSectional(workspaceId, businessId, { ...itemToSave })).then(() => {
                                setAlertMessage({
                                    status: "success",
                                    title: intl.formatMessage({
                                        id: "c-customer-edit-business.sectional.update.success"
                                    })
                                });
                            });
                        }
                    }
                    return null;
                });
            }
            if (businessId) {
                setItems(newItems.sort((a, b) => b.main - a.main) || [], newStateItems => {
                    handleChange(newStateItems, "sectionals");
                });
            } else {
                setItems(newItems.sort((a, b) => b.main - a.main) || [], newStateItems => {
                    handleChange(newStateItems, "sectionals");
                });
            }
        },
        [isEditing, items, itemToSave, idx, dispatch, workspaceId, businessId, intl, mainItem, handleChange, setItems]
    );

    return (
        <Stack>
            {businessId && alertMessage?.status && (
                <AlertMessage
                    status={alertMessage?.status}
                    title={alertMessage?.title}
                    description={alertMessage?.description}
                    setAlertMessage={setAlertMessage}
                />
            )}
            <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                <FormattedMessage id="c-customer-edit-business.step3-title" />
            </Typography>
            {!businessId && (
                <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.step3-description" />
                </Typography>
            )}
            {mainReplace && <ReplaceMainDialog />}
            <Stack>
                <FormControl
                    sx={{
                        minWidth: 120,
                        my: 3,
                        gap: "3rem",
                        width: "766px"
                    }}
                >
                    <FieldsBox
                        editedItem={isEditing ? itemToSave : null}
                        isEditing={isEditing}
                        name={intl.formatMessage({ id: "field.business.sectionalCode" }).toLowerCase()}
                        rows={sectionalRows(intl)}
                        items={
                            ceasedBusiness(bodyRequest?.endDate)
                                ? items.map(it => {
                                      return { ...it, readonly: true };
                                  })
                                : items
                        }
                        itemsSettings={itemsSettings}
                        isMain={"main"} //accept string param to the boolean in item object
                        enableSave={
                            itemToSave.description?.length > 0 &&
                            itemToSave.numberSeries?.length > 0 &&
                            itemToSave.province?.length > 0
                        }
                        onAdd={() => onAdd()}
                        onDelete={itemId => removeItem(itemId)}
                        onEdit={(itemId, item) => {
                            setIsEditing(itemId);
                            setItemToSave(item);
                        }}
                        onValueChange={(name, value, additionalProps) => {
                            if (name === "main") {
                                return setItemToSave({ ...itemToSave, [name]: additionalProps.checked });
                            }
                            setItemToSave({ ...itemToSave, [name]: value });
                        }}
                        onCancel={() => {
                            removeItem(-1);
                            setItemToSave({});
                            setIsEditing(null);
                        }}
                        onSave={() => {
                            if (itemToSave.main && checkMainReplace()) {
                                return setMainReplace(true);
                            }
                            saveItem();
                            setItemToSave({});
                            setIsEditing(null);
                        }}
                        disableAdd={ceasedBusiness(bodyRequest?.endDate)}
                    />
                </FormControl>
            </Stack>
        </Stack>
    );
};
