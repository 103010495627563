export const INIT_DATA_FROM_PARENT_START = "INIT_DATA_FROM_PARENT_START";
export const INIT_DATA_FROM_PARENT_SUCCESS = "INIT_DATA_FROM_PARENT_SUCCESS";
export const INIT_DATA_FROM_PARENT_ERROR = "INIT_DATA_FROM_PARENT_ERROR";

export function initDataFromParent(params) {
    return async dispatch => {
        dispatch({
            type: INIT_DATA_FROM_PARENT_START,
            payload: params
        });

        if (params) {
            dispatch({
                type: INIT_DATA_FROM_PARENT_SUCCESS,
                payload: params
            });
        } else {
            dispatch({
                type: INIT_DATA_FROM_PARENT_ERROR,
                payload: {}
            });
        }
    };
}
