export const getBankLogo = logoName => {
    let logo;
    try {
        let logoImg = require(`./${logoName}`);
        logo = logoImg;
    } catch (e) {
        let logoImg = require(`./default_logo.png`);
        logo = logoImg;
    }
    return logo;
};
