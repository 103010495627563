import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/pro-regular-svg-icons/faArrowCircleDown";
import { faArrowCircleUp } from "@fortawesome/pro-regular-svg-icons/faArrowCircleUp";

import { SubTitleRegular } from "../vapor-components/typography";
import {
    AppName,
    ArrowIconContainer,
    Container,
    Date,
    Footer,
    Header,
    KpiContainer,
    Percentage,
    Value,
    ValueDescription
} from "./styled";

const MeteringWidget = ({ appName, date, kpiDescription, kpiValue, percentage, title }) => {
    return (
        <Container>
            <Header>
                <div>
                    <SubTitleRegular color="darkImperialBlue">{title}</SubTitleRegular>
                    <AppName>{appName}</AppName>
                </div>
                <KpiContainer>
                    <Value>{kpiValue}</Value>
                    <ValueDescription>{kpiDescription}</ValueDescription>
                </KpiContainer>
                <ArrowIconContainer percentage={percentage}>
                    <FontAwesomeIcon icon={percentage > 0 ? faArrowCircleUp : faArrowCircleDown} />
                </ArrowIconContainer>
            </Header>
            <Footer>
                <Date>{date}</Date>
                <Percentage percentage={percentage}>{percentage}%</Percentage>
            </Footer>
        </Container>
    );
};

MeteringWidget.propTypes = {
    appName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    kpiValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    kpiDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default MeteringWidget;
