import React from "react";
import { Box, Chip } from "@vapor/react-material";

const Tag = ({ ghost, label, color, bgColor, upperText, type }) => {
    const tagBg = ghost ? "transparent" : bgColor;
    const upper = upperText ? "uppercase !important" : "none";

    return (
        <Box>
            <Chip
                label={label}
                variant={ghost ? "ghost" : "standard"}
                type={type}
                sx={{
                    "&.MuiChip-root.MuiChip-root": {
                        background: `${tagBg} !important`,
                        borderColor: `${bgColor} !important`,
                        "& .MuiChip-label": {
                            color: `${color} !important`,
                            textTransform: upper
                        },
                        ":hover": {
                            background: tagBg,
                            "& .MuiChip-label": {
                                color: `${color} !important`
                            }
                        }
                    }
                }}
            />
        </Box>
    );
};

export default Tag;
