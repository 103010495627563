import React from "react";
import { Stack, TextField } from "@vapor/react-material";
import { handleMaxLength } from "../../../../../utils/validate";
import { useIntl } from "react-intl";

const DelegatedForm = ({ bodyRequest, setBodyRequest, error, handleChange }) => {
    const intl = useIntl();

    return (
        <Stack spacing={4}>
            <Stack mt={2} spacing={3} direction="row">
                <TextField
                    //error={error?.name === "description"}
                    //helperText={error?.name === "description" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    autoComplete="off"
                    type="text"
                    name="description"
                    value={bodyRequest.description}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "field.intermediary.description" })}
                    inputProps={{ onChange: e => handleMaxLength(e, 60) }}
                    sx={{
                        "& input": {
                            textAlign: `left`
                        }
                    }}
                    variant="outlined"
                />
            </Stack>
            <Stack mt={2} spacing={3} direction="row">
                <TextField
                    //error={error?.name === "firstName"}
                    //helperText={error?.name === "firstName" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    autoComplete="off"
                    type="text"
                    name="firstName"
                    value={bodyRequest.firstName}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "general.name" })}
                    inputProps={{ onChange: e => handleMaxLength(e, 32) }}
                    sx={{
                        "& input": {
                            textAlign: `left`
                        }
                    }}
                    variant="outlined"
                />
                <TextField
                    //error={error?.name === "lastName"}
                    //helperText={error?.name === "lastName" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    autoComplete="off"
                    type="text"
                    name="lastName"
                    value={bodyRequest.lastName}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "general.surname" })}
                    inputProps={{ onChange: e => handleMaxLength(e, 32) }}
                    sx={{
                        "& input": {
                            textAlign: `left`
                        }
                    }}
                    variant="outlined"
                />
            </Stack>
            <Stack mt={2} spacing={3} direction="row">
                <TextField
                    //error={error?.name === "lastName"}
                    //helperText={error?.name === "lastName" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    autoComplete="off"
                    type="text"
                    name="taxId"
                    value={bodyRequest.taxId}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "general.tax-id" })}
                    inputProps={{ onChange: e => handleMaxLength(e, 32) }}
                    sx={{
                        "& input": {
                            textAlign: `left`
                        }
                    }}
                    variant="outlined"
                />
            </Stack>
        </Stack>
    );
};

export default DelegatedForm;
