import customerManagementApiClient from "../apiClient";

export const GET_INTERMEDIARIES_START = "GET_INTERMEDIARIES_START";
export const GET_INTERMEDIARIES_SUCCESS = "GET_INTERMEDIARIES_SUCCESS";
export const GET_INTERMEDIARIES_ERROR = "GET_INTERMEDIARIES_ERROR";
export const GET_INTERMEDIARIES_RESET = "GET_INTERMEDIARIES_RESET";

export const GET_SELECTED_INTERMEDIARY = "GET_SELECTED_INTERMEDIARY";
export const RESET_SELECTED_INTERMEDIARY = "RESET_SELECTED_INTERMEDIARY";

export const CREATE_INTERMEDIARIES_START = "CREATE_INTERMEDIARIES_START";
export const CREATE_INTERMEDIARIES_SUCCESS = "CREATE_INTERMEDIARIES_SUCCESS";
export const CREATE_INTERMEDIARIES_ERROR = "CREATE_INTERMEDIARIES_ERROR";

export const GET_SECURITY_ENVIRONMENT_START = "GET_SECURITY_ENVIRONMENT_START";
export const GET_SECURITY_ENVIRONMENT_SUCCESS = "GET_SECURITY_ENVIRONMENT_SUCCESS";
export const GET_SECURITY_ENVIRONMENT_ERROR = "GET_SECURITY_ENVIRONMENT_ERROR";
export const GET_SECURITY_ENVIRONMENT_RESET = "GET_SECURITY_ENVIRONMENT_RESET";

export const CREATE_SECURITY_ENVIRONMENT_START = "CREATE_SECURITY_ENVIRONMENT_START";
export const CREATE_SECURITY_ENVIRONMENT_SUCCESS = "CREATE_SECURITY_ENVIRONMENT_SUCCESS";
export const CREATE_SECURITY_ENVIRONMENT_ERROR = "CREATE_SECURITY_ENVIRONMENT_ERROR";
export const CREATE_SECURITY_ENVIRONMENT_RESET = "CREATE_SECURITY_ENVIRONMENT_RESET";

export const GET_DELEGATEDS_START = "GET_DELEGATEDS_START";
export const GET_DELEGATEDS_SUCCESS = "GET_DELEGATEDS_SUCCESS";
export const GET_DELEGATEDS_ERROR = "GET_DELEGATEDS_ERROR";
export const GET_DELEGATEDS_RESET = "GET_DELEGATEDS_RESET";

export const CREATE_DELEGATEDS_START = "CREATE_DELEGATEDS_START";
export const CREATE_DELEGATEDS_SUCCESS = "CREATE_DELEGATEDS_SUCCESS";
export const CREATE_DELEGATEDS_ERROR = "CREATE_DELEGATEDS_ERROR";
export const CREATE_DELEGATEDS_RESET = "CREATE_DELEGATEDS_RESET";

export const getIntermediaries = itemId => {
    return async dispatch => {
        dispatch({
            type: GET_INTERMEDIARIES_START,
            payload: { itemId }
        });

        try {
            const res = await new customerManagementApiClient().getIntermediaries(itemId);

            dispatch({
                type: GET_INTERMEDIARIES_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_INTERMEDIARIES_ERROR,
                payload: { e }
            });
        }
    };
};

export const getSelectedIntermediary = intermediary => {
    return dispatch => {
        dispatch({
            type: GET_SELECTED_INTERMEDIARY,
            payload: { intermediary }
        });
    };
    //TODO: dispatch post to correlate the selected intermediary to the workspaceId
};

export const resetSelectedIntermediary = () => {
    return dispatch => {
        dispatch({
            type: RESET_SELECTED_INTERMEDIARY
        });
    };
};

export const createIntermediaries = (itemId, requestParams) => {
    return async dispatch => {
        dispatch({
            type: CREATE_INTERMEDIARIES_START,
            payload: { ...requestParams }
        });

        try {
            const res = await new customerManagementApiClient().postIntermediaries(itemId, requestParams);

            dispatch({
                type: CREATE_INTERMEDIARIES_SUCCESS,
                payload: res
            });

            return res;
        } catch (e) {
            dispatch({
                type: CREATE_INTERMEDIARIES_ERROR,
                payload: { e }
            });
            return e;
        }
    };
};

export const getSecurityEnvironments = (itemId, taxId) => {
    return async dispatch => {
        dispatch({
            type: GET_SECURITY_ENVIRONMENT_START,
            payload: { itemId, taxId }
        });

        try {
            const res = await new customerManagementApiClient().getSecurityEnvList(itemId, taxId);

            dispatch({
                type: GET_SECURITY_ENVIRONMENT_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_SECURITY_ENVIRONMENT_ERROR,
                payload: { e }
            });
        }
    };
};

export const createSecurityEnvironments = (itemId, taxId, requestParams) => {
    return async dispatch => {
        dispatch({
            type: CREATE_SECURITY_ENVIRONMENT_START,
            payload: { ...requestParams }
        });

        try {
            const res = await new customerManagementApiClient().createSecurityEnv(itemId, taxId, requestParams);

            dispatch({
                type: CREATE_SECURITY_ENVIRONMENT_SUCCESS,
                payload: res
            });

            return res;
        } catch (e) {
            const error = JSON.parse(e.message);
            dispatch({
                type: CREATE_SECURITY_ENVIRONMENT_ERROR,
                payload: { error, id: requestParams.id }
            });
            return { error, id: requestParams.id };
        }
    };
};

export const resetSecurityEnv = () => {
    return dispatch => {
        dispatch({
            type: CREATE_SECURITY_ENVIRONMENT_RESET
        });
    };
};

export const getDelegateds = (itemId, intermediaryTaxId) => {
    return async dispatch => {
        dispatch({
            type: GET_DELEGATEDS_START,
            payload: { itemId, intermediaryTaxId }
        });

        try {
            const res = await new customerManagementApiClient().getDelegateds(itemId, intermediaryTaxId);

            dispatch({
                type: GET_DELEGATEDS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_DELEGATEDS_ERROR,
                payload: { e }
            });
        }
    };
};

export const createDelegateds = (itemId, intermediaryTaxId, requestParams) => {
    return async dispatch => {
        dispatch({
            type: CREATE_DELEGATEDS_START,
            payload: { ...requestParams }
        });

        try {
            const res = await new customerManagementApiClient().createSecurityEnv(
                itemId,
                intermediaryTaxId,
                requestParams
            );

            dispatch({
                type: CREATE_DELEGATEDS_SUCCESS,
                payload: res
            });

            return res;
        } catch (e) {
            dispatch({
                type: CREATE_INTERMEDIARIES_ERROR,
                payload: { e }
            });
            return e;
        }
    };
};
