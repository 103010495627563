import {
    GET_INTERMEDIARIES_ERROR,
    GET_INTERMEDIARIES_RESET,
    GET_INTERMEDIARIES_START,
    GET_INTERMEDIARIES_SUCCESS,
    GET_SELECTED_INTERMEDIARY,
    CREATE_INTERMEDIARIES_ERROR,
    CREATE_INTERMEDIARIES_START,
    CREATE_INTERMEDIARIES_SUCCESS,
    GET_SECURITY_ENVIRONMENT_START,
    GET_SECURITY_ENVIRONMENT_SUCCESS,
    GET_SECURITY_ENVIRONMENT_ERROR,
    GET_SECURITY_ENVIRONMENT_RESET,
    CREATE_SECURITY_ENVIRONMENT_START,
    CREATE_SECURITY_ENVIRONMENT_SUCCESS,
    CREATE_SECURITY_ENVIRONMENT_ERROR,
    CREATE_SECURITY_ENVIRONMENT_RESET,
    GET_DELEGATEDS_START,
    GET_DELEGATEDS_SUCCESS,
    GET_DELEGATEDS_RESET,
    GET_DELEGATEDS_ERROR,
    RESET_SELECTED_INTERMEDIARY
} from "../actions/intermediaries";

const defaultState = {
    details: {
        items: [],
        flowItem: {},
        status: {
            started: false,
            error: false,
            ended: false,
            errorInfo: {
                code: "",
                message: ""
            }
        },
        isLoading: false
    },
    securityEnviroments: {
        items: [],
        flowItems: [],
        status: {
            started: false,
            error: false,
            ended: false,
            errorInfo: {
                code: "",
                message: ""
            }
        },
        isLoading: false
    },
    delegateds: {
        items: [],
        flowItems: [],
        status: {
            started: false,
            error: false,
            ended: false,
            errorInfo: {
                code: "",
                message: ""
            }
        },
        isLoading: false
    }
};

export function intermediaries(state = defaultState, { type, payload }) {
    switch (type) {
        //GET INTERMEDIARIES
        case GET_INTERMEDIARIES_START:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: true,
                        error: false,
                        ended: false
                    },
                    isLoading: true
                }
            };
        case GET_INTERMEDIARIES_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: false,
                        error: false,
                        ended: true
                    },
                    isLoading: false,
                    items: payload
                }
            };
        case GET_INTERMEDIARIES_ERROR:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: false,
                        error: true,
                        ended: false,
                        errorInfo: {
                            code: payload?.code,
                            message: payload.message
                        }
                    },
                    isLoading: false
                }
            };
        case GET_INTERMEDIARIES_RESET:
            return {
                ...state,
                ...defaultState.details
            };
        case GET_SELECTED_INTERMEDIARY:
            return {
                ...state,
                details: {
                    ...state.details,
                    flowItem: payload
                }
            };
        case RESET_SELECTED_INTERMEDIARY:
            return {
                ...state,
                details: {
                    ...state.details,
                    flowItem: {}
                }
            };
        //POST INTERMEDIARIES
        case CREATE_INTERMEDIARIES_START:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: true,
                        error: false,
                        ended: false
                    },
                    isLoading: true
                }
            };
        case CREATE_INTERMEDIARIES_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: false,
                        error: false
                    },
                    flowItem: payload,
                    isLoading: false
                }
            };
        case CREATE_INTERMEDIARIES_ERROR:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: {
                        started: false,
                        error: true,
                        ended: false,
                        errorInfo: {
                            code: payload?.code,
                            message: payload.message
                        }
                    },
                    isLoading: false
                }
            };
        //GET SECURITY ENVIRONMENT
        case GET_SECURITY_ENVIRONMENT_START:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    status: {
                        started: true,
                        error: false,
                        ended: false
                    },
                    isLoading: true
                }
            };
        case GET_SECURITY_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    status: {
                        started: false,
                        error: false,
                        ended: true
                    },
                    isLoading: false,
                    items: payload
                }
            };

        case GET_SECURITY_ENVIRONMENT_ERROR:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    status: {
                        started: false,
                        error: true,
                        ended: false,
                        errorInfo: {
                            code: payload?.code,
                            message: payload.message
                        }
                    },
                    isLoading: false
                }
            };
        case GET_SECURITY_ENVIRONMENT_RESET:
            return {
                ...state,
                ...defaultState.securityEnviroments
            };
        //POST SECURITY ENVIRONMENT
        case CREATE_SECURITY_ENVIRONMENT_START:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments
                }
            };
        case CREATE_SECURITY_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    flowItems: [...state.securityEnviroments.flowItems, payload]
                }
            };
        case CREATE_SECURITY_ENVIRONMENT_ERROR:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    flowItems: [...state.securityEnviroments.flowItems, payload]
                }
            };
        case CREATE_SECURITY_ENVIRONMENT_RESET:
            return {
                ...state,
                securityEnviroments: {
                    ...state.securityEnviroments,
                    flowItems: []
                }
            };
        //GET DELEGATEDS
        case GET_DELEGATEDS_START:
            return {
                ...state,
                delegateds: {
                    status: {
                        started: true,
                        error: false,
                        ended: false
                    },
                    isLoading: true
                }
            };
        case GET_DELEGATEDS_SUCCESS:
            return {
                ...state,
                delegateds: {
                    ...state.delegateds,
                    status: {
                        started: false,
                        error: false,
                        ended: true
                    },
                    isLoading: false,
                    items: payload
                }
            };
        case GET_DELEGATEDS_ERROR:
            return {
                ...state,
                delegateds: {
                    ...state.delegateds,
                    status: {
                        started: false,
                        error: true,
                        ended: false,
                        errorInfo: {
                            code: payload?.code,
                            message: payload.message
                        }
                    },
                    isLoading: false
                }
            };
        case GET_DELEGATEDS_RESET:
            return {
                ...state,
                ...defaultState.delegateds
            };
        default:
            return state;
    }
}
