import styled from "styled-components";
import { globalHeaderHeight, globalPadding, globalTitleHeight } from "../../../globalStyles";

export const ContentWrapper = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    min-height: calc(100vh - 7rem);
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem 1.5rem;
    grid-auto-columns: 1fr;
    grid-template-areas:
        "customer-card customer-card customer-card widget widget widget widget widget widget widget widget widget"
        "customer-card customer-card customer-card graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools tools tools graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools-advising tools-advising tools-advising entrustment-deed entrustment-deed entrustment-deed rdc-delegation rdc-delegation rdc-delegation . . .";
`;

export const GridElement = styled.div`
    width: ${props => props.width};
    ${props => props.withPadding && "padding: 32px;"};
    ${props => props.fullHeight && "min-height: calc(100vh - 7rem);"};
`;

export const GridContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const RightGridWrapper = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    min-height: calc(100vh - ${globalHeaderHeight} - ${globalTitleHeight} - ${globalPadding});
    height: 100%;
`;
