import { FormattedMessage } from "react-intl";

export const ceasedBusiness = endDate => {
    return endDate && new Date(endDate) < new Date();
};

export const steps = [
    <FormattedMessage id="c-customer-edit-business.step1" key="c-customer-edit-business.step1" />,
    <FormattedMessage id="c-customer-edit-business.step2" key="c-customer-edit-business.step2" />,
    <FormattedMessage id="c-customer-edit-business.step3" key="c-customer-edit-business.step3" />,
    <FormattedMessage id="c-customer-edit-business.step4" key="c-customer-edit-business.step4" />,
    <FormattedMessage id="c-customer-edit-business.step5" key="c-customer-edit-business.step5" />
];

export const defaultBusinessBody = {
    atecoCode: "",
    atecoCodeDescription: "",
    type: "",
    description: "",
    sectionals: [{ id: -1 }],
    ivaProRataHandled: false,
    ivaProRata: [],
    selfEmployed: false,
    asset: {
        assetGroup: "",
        assetSpecies: ""
    },
    vatConfiguration: {
        vatPeriodicity: "",
        specialVatRegime: "NO_SPECIAL_REGIME",
        vatExemptionManagementForExporter: "",
        cashAccounting: false,
        vatStatementRegistry: "",
        vatInAdvanceType: "",
        validFrom: new Date()
    },
    validFrom: new Date(),
    startDate: ""
};

export const taxRegimeOptions = [
    { value: "Nessun regime speciale", id: "NO_SPECIAL_REGIME" },
    { value: "Agenzia viaggi", id: "TRAVEL_AGENCY" },
    { value: "Agricoltura speciale", id: "SPECIAL_SCHEME_OF_AGRICOLTURE" },
    { value: "Agricoltura esonero", id: "AGRICOLTURE_EXEMPTION" },
    { value: "Autotrasporto conto terzi", id: "TRUCK_THIRD_PARTIES" },
    { value: "Editoria", id: "PUBLISHING" },
    { value: "Margine analitico", id: "ANALYTICAL_MARGIN" },
    { value: "Margine globale", id: "GLOBAL_MARGIN" },
    { value: "Margine forfettario", id: "FLAT_RATE_MARGIN" },
    { value: "Ventilazione", id: "VENTILATION" },
    { value: "Ventilazione macellai", id: "BUTCHERS_VENTILATION" },
    { value: "Agriturismo", id: "AGRITOURISM" },
    { value: "Associazione Sindacale", id: "TRADE_UNION_ASSOCIATION" },
    { value: "Attività connesse agricoltura", id: "RELATED_AGRICOLTURE_ACTIVITIES" },
    { value: "Enoturismo", id: "WINE_TOURISM" },
    { value: "Oleoturismo", id: "OLEOTOURISM" }
];
