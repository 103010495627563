import React from "react";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@vapor/react-material";
import { FormattedMessage } from "react-intl";
import { MainTitleText, SectionTitle, TitleIcon, TitleText } from "../../styled";
import { AlertSection } from "../styled";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons/faMinusCircle";

const CustomerEditSectionTitle = ({ alert, isEditing, section, setAlert, setIsEditing, secondary }) => {
    return (
        <SectionTitle margin={"16px 0"}>
            {secondary ? (
                <TitleText>
                    {section?.showAlert && alert && alert.indexOf(section?.showAlert) === 0 ? (
                        <Alert
                            severity={alert.split("-")[1]}
                            icon={
                                <FontAwesomeIcon
                                    icon={alert.split("-")[1] === "error" ? faMinusCircle : faCheckCircle}
                                />
                            }
                            variant="outlined"
                            onClose={() => setAlert(null)}
                        >
                            <AlertSection>
                                <FormattedMessage id={section?.mainTitle} />
                            </AlertSection>
                            <FormattedMessage id={"alerts." + alert} />
                        </Alert>
                    ) : (
                        <FormattedMessage id={section?.mainTitle} />
                    )}
                </TitleText>
            ) : (
                <MainTitleText>
                    {section?.showAlert && alert && alert.indexOf(section?.showAlert) === 0 ? (
                        <Alert
                            severity={alert.split("-")[1]}
                            icon={
                                <FontAwesomeIcon
                                    icon={alert.split("-")[1] === "error" ? faMinusCircle : faCheckCircle}
                                />
                            }
                            variant="outlined"
                            onClose={() => setAlert(null)}
                        >
                            <AlertSection>
                                <FormattedMessage id={section?.mainTitle} />
                            </AlertSection>
                            <FormattedMessage id={"alerts." + alert} />
                        </Alert>
                    ) : (
                        <FormattedMessage id={section?.mainTitle} />
                    )}
                </MainTitleText>
            )}
            {section?.showEditButton && !isEditing && (
                <TitleIcon>
                    <Button
                        onClick={() => {
                            setAlert(null);
                            setIsEditing(true);
                        }}
                        color="secondary"
                    >
                        <FormattedMessage id="general.edit" />
                        <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 12 }} />
                    </Button>
                </TitleIcon>
            )}
        </SectionTitle>
    );
};

export default CustomerEditSectionTitle;
