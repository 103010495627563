import styled from "styled-components";

export const Container = styled.div`
    min-height: calc(100vh - 64px);
    background-color: ${props => props.theme.colors.background.azureishWhite};
    padding: 1.5rem 2rem;
`;

export const ContentWrapper = styled.div`
    min-height: calc(100vh - 106px);
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem 1.5rem;
    grid-auto-columns: 1fr;
    grid-template-areas:
        "customer-card customer-card customer-card widget widget widget widget widget widget widget widget widget"
        "customer-card customer-card customer-card graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools tools tools graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools-advising tools-advising tools-advising entrustment-deed entrustment-deed entrustment-deed rdc-delegation rdc-delegation rdc-delegation . . .";
`;

export const GridElement = styled.div`
    width: ${props => props.width};
    padding: 32px;
    ${props => props.white && `background: ${props.theme.colors.background.white};`}
`;

export const GridContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const WhiteContainer = styled.div`
    background: ${props => props.theme.colors.background.white};
`;

export const CustomerDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerDataTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo, sans-serif;
    font-weight: 600;
    line-height: 40px;
    margin: 16px 0;
    & svg {
        font-size: 24px;
        margin-right: 8px;
    }
`;

export const CustomerDataText = styled.div`
    display: flex;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
`;

export const CustomerDataLoadingElements = styled.div`
    display: flex;
    flex-direction: column;
    & div {
        margin: 4px 0;
    }
`;
