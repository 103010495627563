import { axiosFactory } from "../core/common/axiosFactory";

const axios = axiosFactory.axiosInstance;

export default class CustomerManagementApiClient {
    createNewClient = async requestParams => {
        const response = await axios.request({
            method: "POST",
            url: `/workspaces`,
            data: {
                ...requestParams
            }
        });
        return response.data;
    };

    updateCustomerMainData = async (workspaceId, requestParams, subSection) => {
        const copyRequestParams = { [subSection]: requestParams[subSection] };
        const response = await axios.request({
            method: "PATCH",
            url: `/workspaces/${workspaceId}`,
            data: {
                ...copyRequestParams
            }
        });
        return response.data;
    };

    createNewBusiness = async requestParams => {
        const response = await axios.request({
            method: "POST",
            url: `/workspaces/${requestParams.ownerId}/business`,
            data: {
                ...requestParams
            }
        });
        return response.data;
    };

    createCustomerAntiMoneyLaundering = async requestParams => {
        const { workspaceId, ...rest } = requestParams;
        const response = await axios.request({
            method: "POST",
            url: `/workspaces/${workspaceId}/antiMoneyLaundering`,
            data: {
                ...rest
            }
        });
        return response.data;
    };

    cervedSearch = async requestParams => {
        const response = await axios.request({
            method: "POST",
            url: `/cerved/entitySearch/advanced`,
            data: {
                ...requestParams
            }
        });
        return response.data;
    };

    createBusinessSectional = async (workspaceId, businessId, requestParams) => {
        const response = await axios.request({
            method: "POST",
            url: `/workspaces/${workspaceId}/business/${businessId}/sectional`,
            data: {
                ...requestParams
            }
        });
        return response.data;
    };

    deleteBusinessSectional = async (workspaceId, businessId, requestParams) => {
        const response = await axios.request({
            method: "DELETE",
            url: `/workspaces/${workspaceId}/business/${businessId}/sectional`,
            data: {
                id: requestParams?.id
            }
        });
        return response.data;
    };

    updateBusinessSectional = async (workspaceId, businessId, requestParams) => {
        const response = await axios.request({
            method: "PATCH",
            url: `/workspaces/${workspaceId}/business/${businessId}/sectional`,
            data: {
                ...requestParams
            }
        });
        return response.data;
    };

    deleteBusiness = async (ownerId, workspaceId, businessId) => {
        const response = await axios.request({
            method: "DELETE",
            url: `/workspaces/${workspaceId}/business/${businessId}?ownerId=${ownerId}`
        });
        return response.data;
    };

    updateBusiness = async requestParams => {
        const { workspaceId, businessId, ...rest } = requestParams;
        const response = await axios.request({
            method: "PATCH",
            url: `/workspaces/${workspaceId}/business/${businessId}`,
            data: {
                ...rest
            }
        });
        return response.data;
    };

    updateCustomerAntiMoneyLaundering = async requestParams => {
        const { workspaceId, id, ...rest } = requestParams;
        const response = await axios.request({
            method: "PATCH",
            url: `/workspaces/${workspaceId}/antiMoneyLaundering/${id}`,
            data: {
                ...rest
            }
        });
        return response.data;
    };

    getCustomers = async requestQueryParams => {
        const response = await axios.request({
            method: "GET",
            url: `/workspaces`,
            params: {
                ...requestQueryParams
            }
        });
        return response.data;
    };

    getSpotlightCustomers = async requestQueryParams => {
        const { ownerId, ...params } = requestQueryParams;
        const response = await axios.request({
            method: "GET",
            url: `/workspaces/search/spotlight/${ownerId}`,
            params: {
                ...params
            }
        });
        return response.data;
    };

    getCustomerExists = async (ownerId, identifier) => {
        const response = await axios.request({
            method: "GET",
            url: `/workspaces/search/exists/${identifier}/?ownerId=${ownerId}`
        });
        return response.data;
    };

    getCustomerDetail = async (ownerId, workspaceId, validityFrom) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}?ownerId=${ownerId}&validityFrom=${validityFrom}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getCustomerBusiness = async (ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}/business?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getCustomerAccounting = async (ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}/accounting?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getCustomerIntrastat = async (ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}/intrastatConfiguration?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getCustomerBanks = async (ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}/banks?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    createCustomerBanks = async (ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "POST",
                url: `/workspaces/${workspaceId}/banks?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    updateCustomerBanks = async (bankId, ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "PATCH",
                url: `/workspaces/${workspaceId}/banks/${bankId}?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    deleteCustomerBank = async (ownerId, workspaceId, bankId) => {
        const response = await axios.request({
            method: "DELETE",
            url: `/workspaces/${workspaceId}/banks/${bankId}?ownerId=${ownerId}`
        });
        return response.data;
    };

    getTsPayBanks = async (ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/banks?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    createCustomerIntrastat = async (ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "POST",
                url: `/workspaces/${workspaceId}/intrastatConfiguration?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    updateCustomerIntrastat = async (intrastatId, ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "PATCH",
                url: `/workspaces/${workspaceId}/intrastatConfiguration/${intrastatId}?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    deleteCustomerIntrastat = async (intrastatId, ownerId, workspaceId) => {
        try {
            const response = await axios.request({
                method: "DELETE",
                url: `/workspaces/${workspaceId}/intrastatConfiguration/${intrastatId}?ownerId=${ownerId}`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    createCustomerAccounting = async (ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "POST",
                url: `/workspaces/${workspaceId}/accounting?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    updateCustomerAccounting = async (accountingId, ownerId, workspaceId, requestParams) => {
        try {
            const response = await axios.request({
                method: "PATCH",
                url: `/workspaces/${workspaceId}/accounting/${accountingId}?ownerId=${ownerId}`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getAteco = async () => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/getAteco`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getGroups = async () => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/getGroups`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getWorkspaceActivities = async workspaceId => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/workspaces/${workspaceId}/business`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getIntermediaries = async itemId => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/intermediaries/${itemId}/intermediary`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    postIntermediaries = async (itemId, requestParams) => {
        try {
            const response = await axios.request({
                method: "POST",
                url: `/intermediaries/${itemId}/intermediary`,
                data: {
                    ...requestParams
                }
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    getSecurityEnvList = async (itemId, taxId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/intermediaries/${itemId}/intermediary/${taxId}/security-environment?withFiles=true`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    createSecurityEnv = async (itemId, taxId, requestParams) => {
        const { siteCode, entratelCode, description, password, files } = requestParams;

        try {
            const response = await axios.request({
                method: "POST",
                url: `/intermediaries/${itemId}/intermediary/${taxId}/security-environment/${siteCode}`,
                data: {
                    entratelCode,
                    description,
                    securityEnvironmentAttachment: {
                        password,
                        securityEnvironmentFiles: files.map(file => ({
                            name: file.name,
                            content: file.base64
                        }))
                    }
                }
            });
            return response.data;
        } catch (e) {
            throw new Error(
                JSON.stringify({
                    code: e.response.status,
                    message: e.response.data
                })
            );
        }
    };

    getDelegateds = async (itemId, intermediaryTaxId) => {
        try {
            const response = await axios.request({
                method: "GET",
                url: `/intermediaries/${itemId}/intermediary/${intermediaryTaxId}/delegated`
            });
            return response.data;
        } catch (e) {
            throw new Error({
                code: e.response.status,
                message: e.response.data
            });
        }
    };

    createDelegated = async (itemId, intermediaryTaxId, requestParams) => {
        try {
            const response = await axios.request({
                method: "POST",
                url: `/intermediaries/${itemId}/intermediary/${intermediaryTaxId}/delegated`,
                data: requestParams
            });
            return response.data;
        } catch (e) {
            throw new Error(
                JSON.stringify({
                    code: e.response.status,
                    message: e.response.data
                })
            );
        }
    };

    getConnections = async itemId => {
        const response = await axios.request({
            method: "GET",
            url: `/connections/${itemId}`
        });
        return response.data;
    };

    getAdaInfo = async connectionId => {
        const response = await axios.request({
            method: "GET",
            url: `/ada/${connectionId}`
        });
        return response.data;
    };

    getTools = async () => {
        const response = await axios.request({
            method: "GET",
            url: `/tools`
        });
        return response.data;
    };

    getItemTools = async itemId => {
        const response = await axios.request({
            method: "GET",
            url: `/tools/${itemId}`
        });
        return response.data;
    };

    getArchiveInfo = async (ownerId, itemId) => {
        const response = await axios.request({
            method: "GET",
            url: `/archive/${ownerId}/${itemId}`
        });
        return response.data;
    };

    getMeteringConsumption = async (ownerId, itemId) => {
        const response = await axios.request({
            method: "GET",
            url: `/metering/consumption/${ownerId}/${itemId}`
        });
        return response.data;
    };

    getCurrencies = async ownerId => {
        const response = await axios.request({
            method: "GET",
            url: `/currencies`
        });
        return response.data;
    };
}
