import styled from "styled-components";

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    & > div {
        margin: 4px 0;
    }
`;

export const FieldLabel = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: ${props => `calc( ${props.size} * 14px )` || "14px"};
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 20px;
`;

export const FieldDescription = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: ${props => `calc( ${props.size} * 13px )` || "12px"};
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
`;

export const InfoIcon = styled.div`
    margin-left: 10px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
`;

export const FieldChildren = styled.div``;
