import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Tooltip from "../tooltip";
import { FieldContainer, FieldLabel, FieldDescription, FieldChildren, InfoIcon } from "./styled";

const Field = ({ children, label, description, infoTooltip, tooltipDirection, size }) => {
    return (
        <FieldContainer>
            <FieldLabel size={size}>
                {label || <>&nbsp;</>}
                {infoTooltip && (
                    <Tooltip direction={tooltipDirection} content={infoTooltip}>
                        <InfoIcon>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </InfoIcon>
                    </Tooltip>
                )}
            </FieldLabel>
            {description && <FieldDescription size={size}>{description}</FieldDescription>}
            <FieldChildren>{children}</FieldChildren>
        </FieldContainer>
    );
};

export default Field;
