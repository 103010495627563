import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { Stack, Tooltip, Box } from "@vapor/react-material";
import { FormattedMessage } from "react-intl";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";

export const IvaProRataTooltip = ({ handleIvaProRata }) => {
    return (
        <Tooltip
            title={
                <Stack sx={{ p: 1 }} spacing={1}>
                    <Box>
                        <FormattedMessage id="c-alert-business-iva-pro-rata" />
                    </Box>
                    <Stack direction="row">
                        <Box>
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    color: "#FFF",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    padding: "0 16px 0 0",
                                    height: "32px",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    lineHeight: "32px",
                                    gap: "8px"
                                }}
                                onClick={() => {
                                    return handleIvaProRata(false);
                                }}
                            >
                                <Box>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Box>
                                <FormattedMessage
                                    id="c-alert-business-iva-pro-rata-notHandle"
                                    values={{
                                        year: new Date().getFullYear()
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                kind="primary"
                                style={{ height: "32px" }}
                                onClick={() => {
                                    return handleIvaProRata(true);
                                }}
                            >
                                <FormattedMessage id="general.handle" />
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            }
            arrow
            placement="bottom-start"
        >
            <div>
                <Button kind="tertiary" style={{ padding: "10px 0 0 0", height: "inherit" }}>
                    <FontAwesomeIcon icon={faExclamationCircle} color="#fdb927" />
                </Button>
            </div>
        </Tooltip>
    );
};
