import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: ${props => props.theme.colors.background.white};
`;

export const Content = styled.div`
    padding: 1rem;
`;

export const SubTitleRegular = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 0.25rem;
    display: flex;
    align-items: right;
    min-height: 3rem;
    box-sizing: border-box;
    justify-content: flex-end;
    width: 100%;
`;
