import {
  ADA_GENERATE_START,
  ADA_GENERATE_SUCCESS,
  ADA_GENERATE_ERROR,
  ADA_GENERATE_RESET,
} from "../actions/ada";

const defaultState = {
  status: {
    started: false,
    error: false,
    ended: false,
    errorInfo: {
      code: "",
      message: "",
    },
  },
};

export function adaGenerate(state = defaultState, { type, payload, error }) {
  switch (type) {
    case ADA_GENERATE_START:
      return {
        status: {
          started: true,
          error: false,
          ended: false,
        },
      };
    case ADA_GENERATE_SUCCESS:
      return {
        status: {
          started: false,
          error: false,
          ended: true,
        },
      };
    case ADA_GENERATE_ERROR:
      return {
        status: {
          started: false,
          error: true,
          ended: false,
          errorInfo: { message: error },
        },
      };
    case ADA_GENERATE_RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}
