import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@vapor/react-material";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { AtecoSelector, Field } from "@onefront/react-workspace-components";
import { atecoList } from "@onefront/react-workspace-components/AtecoSelector";
import { isEmptyOrSpaces } from "../../../../../../utils/string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateBusiness } from "../../../../../../actions/business";
import { useSelector } from "react-redux";
import { ceasedBusiness } from "../../constants";

export const CustomerEditBusinessStep1 = ({
    bodyRequest,
    usedAtecos,
    errors,
    setErrors,
    displayErrors,
    handleChange,
    businessId,
    isEditingStep,
    setIsEditingStep,
    prevalentBusiness,
    workspaceId
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [openPrevalentDialog, setOpenPrevalentDialog] = useState(false);
    const businessType = useSelector(state => state?.customer?.details?.data?.classifier);

    const handleDialog = () => {
        setOpenPrevalentDialog(!openPrevalentDialog);
    };

    const handlePrevalentBusiness = () => {
        const patchBody = { ...prevalentBusiness };
        delete patchBody["id"];
        dispatch(updateBusiness(workspaceId, prevalentBusiness.id, { ...patchBody, type: "SECONDARY" })).then(() => {
            handleChange("MAIN", "type");
            handleDialog();
        });
    };

    //set secondary type as default if prevalentBusiness exists
    useEffect(() => {
        if (prevalentBusiness && bodyRequest?.type === "") {
            handleChange("SECONDARY", "type");
        }
    }, [prevalentBusiness, bodyRequest?.type, handleChange]);

    //validation
    useEffect(() => {
        const validAteco =
            !isEmptyOrSpaces(bodyRequest.atecoCode) &&
            !usedAtecos.includes(bodyRequest.atecoCode) &&
            bodyRequest?.atecoCodeDescription?.length > 0
                ? true
                : false;
        const fieldsDefaultValidation = Object.keys({
            description: true,
            startDate: true,
            atecoCodeDescription: true,
            type: true
        }).reduce((acc, key) => {
            acc[key] = isEmptyOrSpaces(bodyRequest[key] || "");
            return acc;
        }, {});

        setErrors({
            atecoCode: !validAteco,
            ...fieldsDefaultValidation
        });
    }, [bodyRequest, usedAtecos, setErrors]);

    return (
        <Stack>
            <Dialog
                open={openPrevalentDialog}
                onClose={handleDialog}
                aria-labelledby="alert-dialog-prevalentChoiseLabel"
                aria-describedby="alert-dialog-prevalentChoiseDescription"
            >
                <DialogTitle id="alert-dialog-prevalentChoiseLabel">
                    {intl.formatMessage({ id: "c-customer-edit-business.alert.prevalentExist-title" })}
                    <IconButton color="primary" onClick={handleDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="alert" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-prevalentChoiseDescription">
                        <FormattedMessage
                            id="c-customer-edit-business.alert.prevalentExist-description"
                            values={{
                                businessName: prevalentBusiness?.description
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialog}>
                        {intl.formatMessage({ id: "general.cancel" })}
                    </Button>
                    <Button variant="contained" color="error" onClick={handlePrevalentBusiness}>
                        {intl.formatMessage({ id: "general.replaceClaim" })}
                    </Button>
                </DialogActions>
            </Dialog>

            <Stack direction="row" sx={{ justifyContent: "space-between", maxWidth: "60%" }}>
                <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.step1-title" />
                </Typography>
                {businessId && !isEditingStep && !ceasedBusiness(bodyRequest?.endDate) && (
                    <Button onClick={() => setIsEditingStep(true)}>
                        <Stack direction="row" spacing={1}>
                            <Box>
                                <FontAwesomeIcon icon={faEdit} />
                            </Box>
                            <Box>
                                <FormattedMessage id="general.edit" />
                            </Box>
                        </Stack>
                    </Button>
                )}
            </Stack>
            {!businessId && (
                <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.step1-description" />
                </Typography>
            )}
            <Stack>
                <FormControl
                    sx={{
                        minWidth: 120,
                        my: 3,
                        gap: isEditingStep ? "3rem" : "1rem",
                        maxWidth: "60%"
                    }}
                >
                    {/* Business Description */}
                    <Field
                        component="textfield"
                        label={intl.formatMessage({ id: "field.business.description" })}
                        labelTip={isEditingStep && intl.formatMessage({ id: "tips.business.description" })}
                        isEditing={isEditingStep}
                        fullWidth
                        onChange={val => {
                            handleChange(val, "description");
                        }}
                        value={bodyRequest?.description}
                        additionalProps={{
                            autoComplete: "off",
                            error: displayErrors && errors.description,
                            helperText:
                                displayErrors &&
                                errors.description &&
                                intl.formatMessage({ id: "error.business.description" }),
                            InputProps: {
                                autoComplete: "off"
                            }
                        }}
                    />
                    {/* Choose business if prevalent or secondary */}
                    <Box sx={{ width: "fit-content" }}>
                        <Field
                            component="radio"
                            label={intl.formatMessage({ id: "field.business.businessType" })}
                            isEditing={isEditingStep}
                            radioGroupAlign="vertical"
                            options={[
                                {
                                    id: "MAIN",
                                    value: intl.formatMessage({ id: "c-customer-edit-business.type.PREVALENT" })
                                },
                                {
                                    id: "SECONDARY",
                                    value: intl.formatMessage({ id: "c-customer-edit-business.type.SECONDARY" })
                                }
                            ]}
                            onChange={val => {
                                prevalentBusiness && val === "MAIN" ? handleDialog() : handleChange(val, "type");
                            }}
                            value={bodyRequest?.type}
                        />
                        {displayErrors && errors.type && (
                            <FormHelperText error={displayErrors && errors.type}>
                                {intl.formatMessage({ id: "error.business.type" })}
                            </FormHelperText>
                        )}
                    </Box>
                    {/* Choose the business if refer to selfEmployed !important only if is the business is refers to a company */}
                    {/* TODO: the field is not visible actually, will be avaiable in future */}
                    {false && businessType === "COMPANY" && (
                        <FormControl
                            sx={{
                                minWidth: 220,
                                maxWidth: "60%"
                            }}
                        >
                            <Field
                                component="radio"
                                label={intl.formatMessage({ id: "field.business.selfEmployed" })}
                                isEditing={isEditingStep}
                                radioGroupAlign="vertical"
                                options={[
                                    {
                                        id: "true",
                                        value: intl.formatMessage({ id: "general.yes" })
                                    },
                                    {
                                        id: "false",
                                        value: intl.formatMessage({ id: "general.no" })
                                    }
                                ]}
                                onChange={val => {
                                    handleChange(val, "selfEmployed");
                                }}
                                value={bodyRequest?.selfEmployed.toString()}
                            />
                        </FormControl>
                    )}
                    <AtecoSelector
                        value={bodyRequest?.atecoCode}
                        atecoCodes={atecoList.filter(item => item.code.length === 8)}
                        error={displayErrors && errors.atecoCode}
                        usedAtecos={usedAtecos}
                        helperText
                        isEditing={isEditingStep}
                        onChange={ateco => {
                            handleChange(ateco, "atecoCode");
                            handleChange(
                                atecoList.find(item => item.code === ateco)?.description,
                                "atecoCodeDescription"
                            );
                        }}
                    />

                    {/* ATECO Description read only */}
                    <Box>
                        <Field
                            component="textfield"
                            readOnly={true}
                            label={intl.formatMessage({ id: "field.business.activity" })}
                            value={
                                bodyRequest?.atecoCode?.length > 0 &&
                                atecoList.find(item => item.code === bodyRequest?.atecoCode)
                                    ? atecoList.find(item => item.code === bodyRequest?.atecoCode)?.description
                                    : "-"
                            }
                        />
                        {displayErrors && errors.atecoCodeDescription && (
                            <FormHelperText error={displayErrors && errors.atecoCodeDescription}>
                                {intl.formatMessage({ id: "error.business.atecoCodeDescription" })}
                            </FormHelperText>
                        )}
                    </Box>
                    {/* Date beginning business */}
                    <Box>
                        <Field
                            component="datepicker"
                            label={intl.formatMessage({ id: "field.business.activityStart" })}
                            isEditing={isEditingStep}
                            value={bodyRequest?.startDate ? new Date(bodyRequest?.startDate) : ""}
                            onChange={val => {
                                handleChange(val, "startDate");
                            }}
                            additionalProps={{
                                renderInput: params => (
                                    <TextField
                                        {...params}
                                        error={displayErrors && errors.startDate}
                                        helperText={
                                            displayErrors &&
                                            errors.startDate &&
                                            intl.formatMessage({ id: "error.business.startDate" })
                                        }
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "gg/mm/aaaa"
                                        }}
                                    />
                                )
                            }}
                        />
                    </Box>
                </FormControl>
            </Stack>
        </Stack>
    );
};
