export const useBrowserStorage = key => {
    const get = () => JSON.parse(localStorage.getItem(key));

    const set = val => {
        localStorage.setItem(key, JSON.stringify(val));
        return true;
    };

    const clean = () => {
        localStorage.removeItem(key);
        return true;
    };

    return {
        get,
        set,
        clean
    };
};
