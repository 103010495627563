import React from "react";
import { MenuItem, Select, TextField, RadioGroup, Radio, FormControlLabel } from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { FormattedMessage } from "react-intl";
import VaporFormField from "../../../../../vapor-components/form-field";
import { InputAdornment } from "@vapor/react-material";
import { InputAdornmentContent } from "../../styled";

const CustomerEditRegistryFormEditField = ({ bodyRequest, field, fieldSettings, setBodyRequest, subSection }) => {
    let component = <FormattedMessage id="general.settingsNoComponent" values={{ field: field }} />;

    const customInputAdornment = (
        <InputAdornment component="div" disablePointerEvents position="start">
            <InputAdornmentContent>€</InputAdornmentContent>
        </InputAdornment>
    );

    // check component in settings
    if (fieldSettings.component === "select") {
        component = (
            <Select
                id={`customer-edit-registry-${field}`}
                labelId={`customer-edit-registry-${field}-label`}
                value={
                    fieldSettings.options?.find(x => x.id === bodyRequest[subSection]?.[field])
                        ? bodyRequest[subSection]?.[field]
                        : ""
                }
                onChange={e => {
                    if (fieldSettings.onChange) {
                        return fieldSettings.onChange(e.target.value, bodyRequest, setBodyRequest);
                    }
                    setBodyRequest({
                        ...bodyRequest,
                        [subSection]: {
                            ...bodyRequest[subSection],
                            [field]: e.target.value
                        }
                    });
                }}
            >
                {fieldSettings.options?.map(option => {
                    return (
                        <MenuItem key={`customer-edit-field_${field}_${option.id}`} value={option.id}>
                            {option.value}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
    if (fieldSettings.component === "radio") {
        component = (
            <RadioGroup
                id={`customer-edit-registry-${field}`}
                value={bodyRequest[subSection]?.[field]}
                row={fieldSettings.radioGroupAlign !== "vertical"}
                onChange={e => {
                    if (fieldSettings.onChange) {
                        return fieldSettings.onChange(e.target.value, bodyRequest, setBodyRequest);
                    }
                    setBodyRequest({
                        ...bodyRequest,
                        [subSection]: {
                            ...bodyRequest[subSection],
                            [field]: e.target.value
                        }
                    });
                }}
            >
                {fieldSettings.options?.map(option => {
                    return (
                        <FormControlLabel
                            key={`customer-edit-field_${field}_${option.id}`}
                            value={option.id}
                            control={<Radio />}
                            label={
                                fieldSettings.translateValues ? (
                                    <FormattedMessage id={`${fieldSettings.translateValues}${option.id}`} />
                                ) : (
                                    option.value
                                )
                            }
                        />
                    );
                })}
            </RadioGroup>
        );
    }
    if (fieldSettings.component === "datepicker") {
        component = (
            <DatePicker
                value={bodyRequest[subSection]?.[field] || null}
                onChange={val => {
                    if (fieldSettings.onChange) {
                        return fieldSettings.onChange(val, bodyRequest, setBodyRequest);
                    }
                    setBodyRequest({
                        ...bodyRequest,
                        [subSection]: {
                            ...bodyRequest[subSection],
                            [field]: val
                        }
                    });
                }}
                renderInput={params => <TextField {...params} style={{ width: 180 }} />}
            />
        );
    }
    if (fieldSettings.component === "textfield") {
        component = (
            <TextField
                sx={{
                    input: { textAlign: fieldSettings.componentAlign || "left" }
                }}
                style={fieldSettings.componentStyle || {}}
                type={fieldSettings.componentType || "text"}
                value={bodyRequest[subSection]?.[field]}
                onChange={e => {
                    if (fieldSettings.onChange) {
                        return fieldSettings.onChange(e.target.value, bodyRequest, setBodyRequest);
                    }

                    setBodyRequest({
                        ...bodyRequest,
                        [subSection]: {
                            ...bodyRequest[subSection],
                            [field]: e.target.value
                        }
                    });
                }}
                InputProps={
                    fieldSettings.isCurrency && {
                        startAdornment: customInputAdornment
                    }
                }
            />
        );
    }

    // check if field has a custom render
    const CustomTag = fieldSettings.customRender ? fieldSettings.customRender : React.Fragment;

    return (
        <CustomTag>
            <VaporFormField
                fullWidth={fieldSettings.fullWidth}
                withMargin={fieldSettings.withMargin}
                style={fieldSettings.style}
                label={
                    <>
                        <FormattedMessage id={fieldSettings?.label || `fields.${field}`} />
                        {!fieldSettings.required && (
                            <>
                                {" "}
                                (<FormattedMessage id={"general.optional"} />)
                            </>
                        )}
                    </>
                }
            >
                {component}
            </VaporFormField>
        </CustomTag>
    );
};

export default CustomerEditRegistryFormEditField;
