import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Button } from "@ts-digital/vrc";
import { useIntl, FormattedMessage } from "react-intl";
import { Container, Content, SubTitleRegular, Footer } from "./styled";

const MeteringChart = data => {
    const intl = useIntl();
    const { consumption, appDescription, year } = data.data;
    return (
        <Container>
            <Content>
                <SubTitleRegular color="#D5B6E1">
                    <FormattedMessage
                        id="c-metering-chart.title"
                        values={{
                            appDescription: appDescription,
                            year: year
                        }}
                    />
                </SubTitleRegular>
            </Content>
            <ResponsiveContainer width="100%" height="90%">
                <BarChart data={consumption}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="sdi" stackId="doc" fill="#D5B6E1" />
                    <Bar dataKey="eRicevi" stackId="doc" fill="#A247B9" />
                    <Bar dataKey="crt" stackId="doc" fill="#814099" />
                    <Bar dataKey="nso" stackId="doc" fill="#946FEF" />
                </BarChart>
            </ResponsiveContainer>
            <Footer>
                <Button kind="tertiary" onClick={() => {}}>
                    {intl.formatMessage({
                        id: "c-metering-chart.button"
                    })}
                </Button>
            </Footer>
        </Container>
    );
};

MeteringChart.propTypes = {};

export default MeteringChart;
