import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const SubTitleContainer = styled.div`
    max-width: 530px;
    text-align: center;
`;

export const ShadowBox = styled.div`
    background: ${props => props.theme.colors.background.white};
    box-shadow: 0px 2px 5px 0px rgba(21, 41, 53, 0.32);
    border-radius: 0px;
    padding: 3rem 5rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
`;

export const SuccessCheckIcon = styled(FontAwesomeIcon)`
    font-size: 5rem;
    color: ${props => props.theme.colors.status.laSalleGreen};
`;

export const ClassifierIcon = styled(FontAwesomeIcon)`
    font-size: 3.75rem;
    color: ${props => props.theme.colors.accent.blueSapphire};
`;

export const ButtonContainer = styled.div`
    margin-top: 1rem;
`;
