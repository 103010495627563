import { combineReducers } from "redux";
import {
    GET_TSPAY_BANKS_START,
    GET_TSPAY_BANKS_RESET,
    GET_TSPAY_BANKS_SUCCESS,
    GET_TSPAY_BANKS_ERROR
} from "../actions/banks";

const defaultBankState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    data: []
};

function banks(state = defaultBankState, { type, payload, error }) {
    switch (type) {
        case GET_TSPAY_BANKS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                data: []
            };
        case GET_TSPAY_BANKS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload
            };
        case GET_TSPAY_BANKS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                },
                data: []
            };
        case GET_TSPAY_BANKS_RESET:
            return defaultBankState;
        default:
            return state;
    }
}

export const tspayReducer = combineReducers({
    banks
});

export default tspayReducer;
