import React from "react";
import {
    Box,
    Button,
    Drawer,
    FormControl,
    Grid,
    HeaderBar,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@vapor/react-material";
import { Close } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useEffect } from "react";
import iban from "iban";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createCustomerBank, getCustomerBanks, updateCustomerBank } from "../../../../actions/banks";
import { SpinnerContainer } from "../../../vapor-components/spinner/styled";
import { Spinner } from "../../../vapor-components/spinner";
import { useMatch } from "react-router";
import { Field } from "@onefront/react-workspace-components";

const PaypalForm = ({ bodyRequest, setBodyRequest }) => {
    const intl = useIntl();
    const currencies = useSelector(state => state.currencies);
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
                <Field
                    label={intl.formatMessage({ id: "c-customer-edit-bank.bank" })}
                    component="textfield"
                    isEditing={true}
                    value={bodyRequest.bankName}
                    onChange={val => setBodyRequest({ ...bodyRequest, bankName: val })}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    label={intl.formatMessage({ id: "c-customer-edit-bank.account" })}
                    component="textfield"
                    isEditing={true}
                    value={bodyRequest.account}
                    onChange={val => setBodyRequest({ ...bodyRequest, account: val })}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-currency-label">
                        <FormattedMessage id="c-customer-edit-bank.currency" />
                    </InputLabel>
                    <Select
                        value={bodyRequest.currency}
                        onChange={e => setBodyRequest({ ...bodyRequest, currency: e.target.value })}
                        id="customer-edit-bank-modal-currency"
                        labelId="customer-edit-bank-modal-currency-label"
                    >
                        <MenuItem value="EUR">
                            <FormattedMessage id="currencies.EUR" /> (EUR)
                        </MenuItem>
                        {currencies?.result &&
                            currencies?.result?.map(currency => {
                                if (!currency.code) {
                                    return null;
                                }
                                return (
                                    <MenuItem value={currency.code}>
                                        {currency.currency} ({currency.code})
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

const BankAccountForm = ({ bodyRequest, setBodyRequest }) => {
    const tspay = useSelector(state => state.tspay);
    const currencies = useSelector(state => state.currencies);
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-iban-label">
                        <FormattedMessage id="c-customer-edit-bank.iban" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.iban}
                        onChange={e => {
                            if (iban.isValid(e.target.value)) {
                                setBodyRequest({
                                    ...bodyRequest,
                                    iban: e.target.value,
                                    accountNumber: e.target.value.substring(15, e.target.value.length),
                                    abi: e.target.value.substring(5, 10),
                                    cab: e.target.value.substring(10, 15),
                                    cin: e.target.value.substring(4, 5),
                                    bankName:
                                        tspay.banks?.data?.find(x => x.aspspCode === e.target.value.substring(5, 10))
                                            ?.businessName || "Banca non identificata"
                                });
                            } else {
                                setBodyRequest({
                                    ...bodyRequest,
                                    iban: e.target.value,
                                    accountNumber: "",
                                    abi: "",
                                    cab: "",
                                    cin: "",
                                    bankName: ""
                                });
                            }
                        }}
                        id="customer-edit-bank-modal-iban"
                        labelId="customer-edit-bank-modal-iban-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-bankAccount-label">
                        <FormattedMessage id="c-customer-edit-bank.bankAccount" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.accountNumber}
                        id="customer-edit-bank-modal-bankAccount"
                        labelId="customer-edit-bank-modal-bankAccount-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-bic-label">
                        <FormattedMessage id="c-customer-edit-bank.bic" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.bic}
                        onChange={e => setBodyRequest({ ...bodyRequest, bic: e.target.value })}
                        id="customer-edit-bank-modal-bic"
                        labelId="customer-edit-bank-modal-bic-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-abi-label">
                        <FormattedMessage id="c-customer-edit-bank.abi" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.abi}
                        onChange={e => setBodyRequest({ ...bodyRequest, abi: e.target.value })}
                        id="customer-edit-bank-modal-abi"
                        labelId="customer-edit-bank-modal-abi-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-cab-label">
                        <FormattedMessage id="c-customer-edit-bank.cab" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.cab}
                        onChange={e => setBodyRequest({ ...bodyRequest, cab: e.target.value })}
                        id="customer-edit-bank-modal-cab"
                        labelId="customer-edit-bank-modal-cab-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-cin-label">
                        <FormattedMessage id="c-customer-edit-bank.cin" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.cin}
                        onChange={e => setBodyRequest({ ...bodyRequest, cin: e.target.value })}
                        id="customer-edit-bank-modal-cin"
                        labelId="customer-edit-bank-modal-cin-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-bank-label">
                        <FormattedMessage id="c-customer-edit-bank.bank" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.bankName}
                        onChange={e => setBodyRequest({ ...bodyRequest, bankName: e.target.value })}
                        id="customer-edit-bank-modal-bank"
                        labelId="customer-edit-bank-modal-bank-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-currency-label">
                        <FormattedMessage id="c-customer-edit-bank.currency" />
                    </InputLabel>
                    <Select
                        value={bodyRequest.currency}
                        onChange={e => setBodyRequest({ ...bodyRequest, currency: e.target.value })}
                        id="customer-edit-bank-modal-currency"
                        labelId="customer-edit-bank-modal-currency-label"
                    >
                        <MenuItem value="EUR">
                            <FormattedMessage id="currencies.EUR" /> (EUR)
                        </MenuItem>
                        {currencies?.result &&
                            currencies?.result?.map(currency => {
                                if (!currency.code) {
                                    return null;
                                }
                                return (
                                    <MenuItem value={currency.code}>
                                        {currency.currency} ({currency.code})
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-overdraftAmount-label">
                        <FormattedMessage id="c-customer-edit-bank.overdraftAmount" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.overdraftAmount}
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="number"
                        onChange={e => setBodyRequest({ ...bodyRequest, overdraftAmount: e.target.value })}
                        id="customer-edit-bank-modal-overdraftAmount"
                        labelId="customer-edit-bank-modal-overdraftAmount-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="customer-edit-bank-modal-creditLineAmount-label">
                        <FormattedMessage id="c-customer-edit-bank.creditLineAmount" />
                    </InputLabel>
                    <TextField
                        value={bodyRequest.creditLineAmount}
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="number"
                        onChange={e => setBodyRequest({ ...bodyRequest, creditLineAmount: e.target.value })}
                        id="customer-edit-bank-modal-creditLineAmount"
                        labelId="customer-edit-bank-modal-creditLineAmount-label"
                        autoComplete="off"
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

const getBodyDiff = (a, b) => {
    return Object.keys(a).reduce((diff, key) => {
        if (b[key] === a[key]) return diff;
        return {
            ...diff,
            [key]: a[key]
        };
    }, {});
};

const CustomerBankRegistryCreate = ({ open, onClose, bankId, bankData, setFeedback }) => {
    const [bodyRequest, setBodyRequest] = useState(
        bankData || {
            source: "USER",
            currency: "EUR",
            type: null
        }
    );
    const [enableSave, setEnableSave] = useState(false);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const isSavingBank = useSelector(state => state.customer?.details?.additionalProps?.isSavingBank);

    const intl = useIntl();

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    const dispatch = useDispatch();

    useEffect(() => {
        if (!open) {
            return null;
        }
        let enabled = true;
        if (!bodyRequest.type) {
            enabled = false;
        }
        if (bodyRequest.type === "PAYPAL_ACCOUNT") {
            if (!bodyRequest.bankName || !bodyRequest.account) {
                enabled = false;
            }
            if (
                bodyRequest.account &&
                !bodyRequest.account.match(
                    /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
            ) {
                enabled = false;
            }
        }
        if (bodyRequest.type === "CURRENT_ACCOUNT" || bodyRequest.type === "SAVINGS_ACCOUNT") {
            if (!bodyRequest.bankName || !bodyRequest.iban) {
                enabled = false;
            }
            if (bodyRequest.iban && !iban.isValid(bodyRequest.iban)) {
                enabled = false;
            }
        }
        if (bankId && Object.keys(getBodyDiff(bodyRequest, bankData)).length === 0) {
            enabled = false;
        }
        setEnableSave(enabled);
    }, [bodyRequest, bankId, bankData, open]);

    const onSave = () => {
        if (bankId) {
            return dispatch(updateCustomerBank(bankId, ownerId, workspaceId, getBodyDiff(bodyRequest, bankData))).then(
                res => {
                    dispatch(getCustomerBanks(ownerId, workspaceId));
                    onClose();
                    setFeedback("update");
                }
            );
        }
        dispatch(createCustomerBank(ownerId, workspaceId, bodyRequest)).then(res => {
            dispatch(getCustomerBanks(ownerId, workspaceId));
            onClose();
            setFeedback("create");
        });
        return;
    };

    return (
        <Drawer
            key="customer-bank-registry-create"
            id="customer-bank-registry-create"
            anchor="right"
            open={open}
            onClose={onClose}
            hideBackdrop
            width={650}
        >
            <HeaderBar
                divider
                description={<FormattedMessage id="c-customer-edit-bank.createModal.subtitle" />}
                rightItems={
                    !isSavingBank && [
                        <IconButton onClick={onClose} color="primary">
                            <Close />
                        </IconButton>
                    ]
                }
                title={
                    <FormattedMessage
                        id={bankId ? "c-customer-edit-bank.editModal.title" : "c-customer-edit-bank.createModal.title"}
                    />
                }
            />

            {open && isSavingBank && (
                <Box style={{ marginTop: 200 }}>
                    <SpinnerContainer>
                        <Spinner loading />
                    </SpinnerContainer>
                </Box>
            )}

            {open && !isSavingBank && (
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 32
                    }}
                >
                    <Field
                        disabled={bankId}
                        component="select"
                        isEditing={true}
                        style={{ marginBottom: -8 }}
                        label={intl.formatMessage({ id: "c-customer-edit-bank.type" })}
                        value={bodyRequest.type}
                        onChange={value => setBodyRequest({ source: "USER", type: value, currency: "EUR" })}
                        options={[
                            { id: "CURRENT_ACCOUNT", value: intl.formatMessage({ id: "bankTypes.BANK_ACCOUNT" }) },
                            { id: "PAYPAL_ACCOUNT", value: intl.formatMessage({ id: "bankTypes.PAYPAL_ACCOUNT" }) },
                            { id: "SAVINGS_ACCOUNT", value: intl.formatMessage({ id: "bankTypes.DEPOSIT_ACCOUNT" }) }
                        ]}
                    />
                    {bodyRequest.type === "PAYPAL_ACCOUNT" && (
                        <PaypalForm bodyRequest={bodyRequest} setBodyRequest={setBodyRequest} />
                    )}
                    {(bodyRequest.type === "CURRENT_ACCOUNT" || bodyRequest.type === "SAVINGS_ACCOUNT") && (
                        <BankAccountForm bodyRequest={bodyRequest} setBodyRequest={setBodyRequest} />
                    )}
                </Box>
            )}

            {open && !isSavingBank && (
                <Box
                    sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "end",
                        justifyContent: "end"
                    }}
                >
                    <Stack direction="row" sx={{ p: 1 }}>
                        <Button onClick={onClose}>
                            <FormattedMessage id="general.cancel" />
                        </Button>
                        <Button onClick={onSave} disabled={!enableSave} variant="contained" sx={{ ml: 1 }}>
                            <FormattedMessage id="general.save" />
                        </Button>
                    </Stack>
                </Box>
            )}
        </Drawer>
    );
};

export default CustomerBankRegistryCreate;
