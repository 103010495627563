import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack
} from "@vapor/react-material";
import { Close } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
    createSecurityEnvironments,
    getSecurityEnvironments,
    resetSecurityEnv
} from "../../../../../actions/intermediaries";
import { getBase64Size } from "../../../../../utils/convert";
import FormField from "../../../../vapor-components/field";
import { StickyFooter } from "../../../../vapor-components/sticky-footer";
import { step2DefaultBody } from "../../constants";
import SecurityBox from "../../fields/security-box";
import { FieldBoxInfo, FieldsContainer } from "../../styled";

export const CustomerEditIntermediariesStep2 = ({ setStepNumber, taxIdentifier, ownerId, securityEnviroments }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const isLoading = useSelector(state => state.intermediaries?.securityEnviroments?.isLoading);

    const [bodyRequest, setBodyRequest] = useState([]);
    const [securityEnvLength, setSecurityEnvLength] = useState(0);
    const [disableAddBox, setDisableAddBox] = useState(true);
    const [errorBox, setErrorBox] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const updateBox = useCallback(
        i => e => {
            let { value } = e.target;
            const { name } = e.target;
            let newArr = [...bodyRequest];

            if (Object.keys(errorBox).length > 1) {
                delete errorBox[name];
                setErrorBox(errorBox);
            }

            value = name === "siteCode" ? value.replace(/\D/g, "") : value;
            if (name === "entratelCode") {
                const substr = value.substring(1, value.length);
                value = (value.charAt(0).replace(/[^a-zA-Z]/, "") + substr.replace(/\D/g, "")).trim();
            }

            newArr[i][name] = value;
            setBodyRequest(newArr);
        },
        [bodyRequest, errorBox]
    );

    const updateFiles = useCallback(
        i => files => {
            let newArr = [...bodyRequest];
            newArr[i].files = files;
            setBodyRequest(newArr);
        },
        [bodyRequest]
    );

    const handleAddBox = useCallback(() => {
        setBodyRequest([...bodyRequest, { ...step2DefaultBody, id: uuidv4() }]);
        setDisableAddBox(true);
        setIsSubmit(false);
    }, [bodyRequest]);

    const handleEditBox = useCallback(
        i => e => {
            let newArr = [...bodyRequest];
            newArr[i] = { ...newArr[i], readOnly: false, isSaved: false, expirationDate: "", password: "" };
            setBodyRequest(newArr);
            setIsSubmit(false);
        },
        [bodyRequest]
    );

    const handleDeleteBox = useCallback(
        i => e => {
            const newArr = [...bodyRequest];
            newArr.splice(i, 1);
            setBodyRequest(newArr);
            setDisableAddBox(false);
            const boxActives = newArr.filter(item => item.fromApi || item.isSaved).length;
            setIsSubmit(boxActives > 0);
        },
        [bodyRequest]
    );

    const handleSave = useCallback(
        i => e => {
            e.preventDefault();

            const { id, entratelCode, description, password, expirationDate, siteCode, files } = bodyRequest[i];

            //setting errors for fields
            const errors = {};
            errors.entratelCode = entratelCode.length !== 8 ? "c-customer-edit-intermediary.error.entratelCode" : null;
            errors.description =
                description.length > 100 || description.length < 1
                    ? "c-customer-edit-intermediary.error.description"
                    : null;
            errors.siteCode =
                siteCode.length !== 3
                    ? "c-customer-edit-intermediary.error.sitecode"
                    : // check if the sitecode is already taken by another security environment
                    bodyRequest.some(box => {
                          return box.siteCode === siteCode && box.id !== id;
                      })
                    ? "c-customer-edit-intermediary.error.sitecode-duplicated"
                    : null;
            errors.password =
                expirationDate === "" || password.length < 3
                    ? "c-customer-edit-intermediary.error.password-not-typed"
                    : null;
            errors.files = files.length < 3 ? "c-customer-edit-intermediary.error.files" : null;

            //filter files that have extension p12 and ks
            const p12Files = files.filter(
                file =>
                    file.name
                        .toLowerCase()
                        .split(".")
                        .pop() === "p12"
            ).length;
            const ksFiles = files.filter(
                file =>
                    file.name
                        .toLowerCase()
                        .split(".")
                        .pop() === "ks"
            ).length;
            //check if there is more than one file with extension ks or not two file of p12
            errors.files = ksFiles > 1 || p12Files !== 2 ? "c-customer-edit-intermediary.error.filesIncongruent" : null;

            //check if there are same files in the other security env
            if (files.length === 3) {
                bodyRequest.map(
                    item =>
                        item.id !== id &&
                        item.files.map(file => files.some(item => item.base64 === file.base64)) &&
                        (errors.filesAlreadyExists = "c-customer-edit-intermediary.error.filesExists")
                );
            }

            if (Object.keys(errors).some(k => k !== id && errors[k])) {
                setErrorBox({ id: id, ...errors });
                return;
            } else {
                setErrorBox({});
                setDisableAddBox(false);
                setIsSubmit(true);
                let newArr = [...bodyRequest];
                newArr[i].readOnly = true;
                newArr[i].isSaved = true;
                setBodyRequest(newArr);
            }
        },
        [bodyRequest]
    );

    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();

            if (isSubmit) {
                dispatch(resetSecurityEnv());
                const promises = [];
                bodyRequest
                    .filter(item => item.isSaved && !item.fromApi)
                    .map(req => promises.push(dispatch(createSecurityEnvironments(ownerId, taxIdentifier, req))));
                await Promise.allSettled(promises);
                setStepNumber(2);
            } else {
                //TODO: Set error if security env response 502 (doesn't exists in UX)
            }
        },
        [bodyRequest, dispatch, isSubmit, ownerId, taxIdentifier, setStepNumber]
    );

    const removeBodyRequest = useCallback(
        i => e => {
            e.preventDefault();
            if (i > 0) {
                let newArr = [...bodyRequest];
                newArr.splice(i, 1);
                setBodyRequest(newArr);
            }

            bodyRequest.find(item => item.fromApi === true) ? setDisableAddBox(false) : setDisableAddBox(true);

            setIsSubmit(true);
        },
        [bodyRequest]
    );

    useEffect(() => {
        dispatch(getSecurityEnvironments(ownerId, taxIdentifier));
    }, [dispatch, ownerId, taxIdentifier]);

    useEffect(() => {
        setSecurityEnvLength(securityEnviroments?.length || 0);
        setBodyRequest(
            !isLoading && securityEnvLength < 1
                ? [{ ...step2DefaultBody, id: uuidv4() }]
                : securityEnviroments?.map(item => {
                      return {
                          ...item,
                          id: item.siteCode,
                          readOnly: true,
                          fromApi: true,
                          files: item?.files?.map(file => ({
                              base64: file.content,
                              name: file.name,
                              size: getBase64Size(file.content)
                          }))
                      };
                  })
        );
        setIsSubmit(securityEnvLength > 0);
        setDisableAddBox(!securityEnviroments?.length > 0);
        setErrorBox({});
    }, [securityEnviroments, isLoading, securityEnvLength]);

    useEffect(() => {
        const timeoutErrors = window.setTimeout(() => {
            setErrorBox({});
        }, 3000);

        return () => window.clearTimeout(timeoutErrors);
    }, [errorBox]);

    return (
        <>
            {!isLoading && securityEnvLength > 0 && (
                <FieldsContainer maxWidth="52%">
                    <FieldBoxInfo>
                        <FormField
                            label={intl.formatMessage(
                                { id: "c-customer-edit-intermediaries.securityenv-exists" },
                                { icon: <FontAwesomeIcon icon={faInfoCircle} /> }
                            )}
                            description={intl.formatMessage({
                                id: "c-customer-edit-intermediaries.securityenv-exists-description"
                            })}
                        />
                    </FieldBoxInfo>
                </FieldsContainer>
            )}
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "20vh"
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                bodyRequest &&
                bodyRequest?.map((box, i) => {
                    return (
                        <SecurityBox
                            i={i}
                            key={box.id}
                            box={box}
                            bodyRequest={bodyRequest}
                            setBodyRequest={setBodyRequest}
                            updateBox={updateBox}
                            updateFiles={updateFiles}
                            removeBodyRequest={removeBodyRequest}
                            handleSave={handleSave}
                            handleEditBox={handleEditBox}
                            handleDeleteBox={handleDeleteBox}
                            enableAddBox={!disableAddBox}
                            errors={errorBox}
                        />
                    );
                })
            )}

            {!isLoading && (
                <FieldsContainer>
                    <Button
                        onClick={handleAddBox}
                        disabled={disableAddBox}
                        sx={{
                            justifyContent: "flex-start",
                            width: "fit-content",
                            "&:hover": { background: "none" },
                            "&:focus": { background: "none" }
                        }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <Box>
                                <FormattedMessage id="c-customer-edit-intermediaries.add-security-env" />
                            </Box>
                        </Stack>
                    </Button>
                </FieldsContainer>
            )}

            <StickyFooter pullHeight={"4rem"}>
                <Stack spacing={3} direction="row">
                    <Button
                        variant="text"
                        size="large"
                        sx={{
                            width: "fit-content",
                            "&:hover": { background: "none", color: "inherit" },
                            "&:focus": { background: "none", color: "inherit" }
                        }}
                        onClick={() => setStepNumber(0)}
                    >
                        <Stack direction="row">
                            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "1rem" }} />
                            <FormattedMessage id="general.back" />
                        </Stack>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        disabled={!isSubmit || isLoading}
                        sx={{ width: "fit-content", padding: "0 2rem" }}
                        onClick={e => {
                            if (bodyRequest.some(item => item.isSaved === true)) {
                                handleDialog();
                            } else {
                                handleSubmit(e);
                            }
                        }}
                    >
                        <FormattedMessage id="general.forward" />
                    </Button>
                </Stack>
            </StickyFooter>

            <Dialog
                open={openDialog}
                onClose={handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <FormattedMessage id={"c-customer-edit-intermediary.security-env-dialog.title"} />
                    <IconButton color="primary" onClick={handleDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={"c-customer-edit-intermediary.security-env-dialog.content"} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialog}>
                        <FormattedMessage id={"general.cancel"} />
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        <FormattedMessage id={"c-customer-edit-intermediary.security-env-dialog.confirm"} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
