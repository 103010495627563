export const validateVatNumber = vatNumber => {
    return /^[0-9]{11}$/.test(vatNumber);
};

export const validateFiscalCode = fiscalCode => {
    return /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(fiscalCode);
};

export const handleMaxLength = (event, maxLength) => {
    if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.substring(0, maxLength);
    }
};
