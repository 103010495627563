import React from "react";
import { LoadingElement } from "../../vapor-components/loading";
import { SkeletonContainer, SkeletonElement, SkeletonRow } from "./styled";

const LoadingSkeleton = () => {
    return (
        <SkeletonContainer>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="600px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="400px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="200px" height="20px" />
                </SkeletonElement>
                <SkeletonElement>
                    <LoadingElement width="200px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="500px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="100px" height="20px" />
                </SkeletonElement>
                <SkeletonElement>
                    <LoadingElement width="300px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="600px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="400px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="200px" height="20px" />
                </SkeletonElement>
                <SkeletonElement>
                    <LoadingElement width="200px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="500px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
            <SkeletonRow>
                <SkeletonElement>
                    <LoadingElement width="100px" height="20px" />
                </SkeletonElement>
                <SkeletonElement>
                    <LoadingElement width="300px" height="20px" />
                </SkeletonElement>
            </SkeletonRow>
        </SkeletonContainer>
    );
};

export default LoadingSkeleton;
