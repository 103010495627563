import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import italia from "italia";
import {
    NewClient,
    NewClientRow,
    NewClientReminder,
    NewClientRowHeadquarter,
    NewClientRowReminder,
    ErrorMessage,
    StepTitle
} from "../../styled";
import { translateMessage, translateMessageWithParams } from "../../../../../i18n";
import { H1 } from "../../../../vapor-components/typography";
import TextInputField from "../../../../general/form-text-input";
import { fieldRequired, isTaxId, isVatNumber, fieldLength } from "@ts-digital/agyo-validations";
import orValidators from "@ts-digital/agyo-validations/lib/utils/orValidators";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import FormSelect from "../../../../general/form-select";
import { useSelector, useDispatch } from "react-redux";
import { getSpotlightCustomers } from "../../../../../actions/customers";

const NewClientData = ({ searchOrCreateStep, errorMessage, setErroMessage, formParams, formParamsCerved }) => {
    const regions = italia.comuni.regioni;
    const provinces = regions.flatMap(r => r.province);

    const provinceList = provinces.map(prov => ({ description: prov.nome, value: prov.code }));

    const cervedStatus = formParamsCerved && searchOrCreateStep;

    const [cfValue, setCfValue] = useState(null);
    const [pivaValue, setPivaValue] = useState(null);
    const [capValue, setCapValue] = useState(null);
    const [displayPersonalData, setDisplayPersonalData] = useState(formParams?.taxIdentifier?.length > 15 || false);
    const [keyValidator, setKeyValidator] = useState(false);

    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const spotlightResults = useSelector(state => state.customers.spotlight.items);
    const dispatch = useDispatch();

    provinceList.sort((a, b) => {
        if (b.value.toLowerCase() < a.value.toLowerCase()) {
            return 1;
        }
        if (b.value.toLowerCase() > a.value.toLowerCase()) {
            return -1;
        }
        return 0;
    });

    useEffect(() => {
        if (cervedStatus === "modify") {
            setDisplayPersonalData(formParamsCerved?.taxIdentifier?.length > 15 || false);
            setCfValue(formParamsCerved?.taxIdentifier);
            setPivaValue(formParamsCerved?.vatIdentifier);
        }
    }, [cervedStatus, formParamsCerved]);

    return (
        <div>
            <StepTitle>
                <H1 color="yankeesBlue">
                    <FormattedMessage id="c-new-registry.title3_1" />
                </H1>
            </StepTitle>
            <NewClient>
                {!formParams.classifier.includes("PERSON") ? (
                    <TextInputField
                        name={"businessName"}
                        label={
                            formParams.classifier !== "BUILDING"
                                ? translateMessage("c-new-client.form.ragsoc")
                                : translateMessage("c-new-client.form.denominazione")
                        }
                        placeholder={
                            formParams.classifier !== "BUILDING"
                                ? translateMessage("c-new-client.form.ragsocInsert")
                                : translateMessage("c-new-client.form.denominazioneInsert")
                        }
                        required={true}
                        validate={fieldRequired(translateMessage("general.form.field-required"))}
                        initialValue={cervedStatus === "modify" ? formParamsCerved?.businessName : ""}
                    />
                ) : (
                    <div style={{ display: "flex", gap: "20px" }}>
                        <TextInputField
                            style={{ width: "300px" }}
                            name={"name"}
                            label={translateMessage("c-new-client.form.name")}
                            placeholder={translateMessage("c-new-client.form.nameInsert")}
                            required={true}
                            validate={fieldRequired(translateMessage("general.form.field-required"))}
                            initialValue={""}
                        />
                        <TextInputField
                            style={{ width: "300px" }}
                            name={"surname"}
                            label={translateMessage("c-new-client.form.surname")}
                            placeholder={translateMessage("c-new-client.form.surnameInsert")}
                            required={true}
                            validate={fieldRequired(translateMessage("general.form.field-required"))}
                            initialValue={""}
                        />
                    </div>
                )}

                <NewClientRow>
                    {["COMPANY", "PUBLIC_COMPANY", "ASSOCIATION"].includes(formParams?.classifier) && (
                        <TextInputField
                            style={{ width: "300px" }}
                            name={"vatIdentifier"}
                            label={translateMessage("c-new-client.form.piva")}
                            placeholder={translateMessage("c-new-client.form.pivaInsert")}
                            required={["COMPANY", "PUBLIC_COMPANY"].includes(formParams?.classifier)}
                            onChange={e => {
                                let value = e.replace(/\D/g, "");
                                setPivaValue(value);

                                if (value.length > 11 && formParams.countryCode === "IT") {
                                    setPivaValue(value.slice(0, 11));
                                }
                                if (e?.length === 11 && !isVatNumber("countryCode", translateMessage)(e, formParams)) {
                                    dispatch(
                                        getSpotlightCustomers({
                                            ownerId: ownerId,
                                            fullText: value,
                                            first: 1
                                        })
                                    ).then(() => {
                                        if (spotlightResults?.length > 0) {
                                            setKeyValidator("true");
                                        }
                                    });
                                }
                            }}
                            validate={(value, formValues) => {
                                if (isVatNumber("countryCode", translateMessage)(value, formValues)) {
                                    return isVatNumber("countryCode", translateMessage)(value, formValues);
                                }

                                if (value?.length === 11 && spotlightResults?.length > 0) {
                                    return translateMessage("c-new-client.form.piva-duplicated");
                                }
                            }}
                            initialValue={pivaValue?.toUpperCase()}
                        />
                    )}
                    <TextInputField
                        style={{
                            width: "300px"
                        }}
                        name={"taxIdentifier"}
                        label={translateMessage("c-new-client.form.taxid")}
                        placeholder={translateMessage("c-new-client.form.taxidInsert")}
                        required={true}
                        key={keyValidator}
                        validate={(value, formValues) => {
                            const { classifier } = formParams;

                            if (
                                (classifier === "PERSON" && value?.length === 16 && spotlightResults?.length > 0) ||
                                ((value?.length === 11 || value?.length === 16) && spotlightResults?.length > 0)
                            ) {
                                return translateMessage("c-new-client.form.taxid-duplicated");
                            }

                            if (classifier === "PERSON") {
                                return isTaxId("countryCode", "classifier-notset", translateMessage)(value, formValues);
                            }

                            if (classifier !== "PERSON") {
                                return orValidators(
                                    isVatNumber("countryCode", translateMessage),
                                    isTaxId("countryCode", "classifier-notset", translateMessage)
                                )(value, formValues);
                            }
                        }}
                        onChange={e => {
                            setCfValue(e);
                            const { classifier } = formParams;

                            if (
                                ["PERSON", "COMPANY"].includes(classifier) &&
                                e.length > 16 &&
                                formParams.countryCode === "IT"
                            ) {
                                setCfValue(e.slice(0, 16));
                            } else if (
                                ["BUILDING", "ASSOCIATION"].includes(classifier) &&
                                formParams.countryCode === "IT"
                            ) {
                                setCfValue(e.replace(/\D/g, "").slice(0, 11));
                            }

                            if (
                                (e?.length === 11 || e.length === 16) &&
                                !orValidators(
                                    isVatNumber("countryCode", translateMessage),
                                    isTaxId("countryCode", "classifier-notset", translateMessage)
                                )(e, formParams)
                            ) {
                                dispatch(
                                    getSpotlightCustomers({
                                        ownerId: ownerId,
                                        fullText: e,
                                        first: 1
                                    })
                                ).then(() => {
                                    if (spotlightResults?.length > 0) {
                                        setKeyValidator("true");
                                    }
                                });
                            }

                            setDisplayPersonalData(classifier === "COMPANY" && e.length > 15);
                        }}
                        initialValue={cfValue?.toUpperCase()}
                    />
                </NewClientRow>

                {displayPersonalData && (
                    <NewClientRow>
                        <TextInputField
                            style={{ width: "300px" }}
                            name={"name"}
                            label={translateMessage("c-new-client.form.name")}
                            placeholder={translateMessage("c-new-client.form.nameInsert")}
                            required={true}
                            validate={fieldRequired(translateMessage("general.form.field-required"))}
                            initialValue={""}
                        />
                        <TextInputField
                            style={{ width: "300px" }}
                            name={"surname"}
                            label={translateMessage("c-new-client.form.surname")}
                            placeholder={translateMessage("c-new-client.form.surnameInsert")}
                            required={true}
                            validate={fieldRequired(translateMessage("general.form.field-required"))}
                            initialValue={""}
                        />
                    </NewClientRow>
                )}

                <NewClientRowHeadquarter>
                    <TextInputField
                        style={{ width: "95%" }}
                        name={"streetName"}
                        label={
                            formParams.classifier !== "PERSON"
                                ? translateMessage("c-new-client.form.sede-legale")
                                : translateMessage("c-new-client.form.residenza")
                        }
                        placeholder={translateMessage("c-new-client.form.sede-legaleInsert")}
                        validate={(value, formValues) =>
                            fieldRequired(translateMessage("general.form.field-required"))(value, formValues)
                        }
                        initialValue={cervedStatus === "modify" ? formParamsCerved.streetName : ""}
                    />
                    <TextInputField
                        name={"streetNumber"}
                        label={translateMessage("c-new-client.form.numero_civico")}
                        placeholder={translateMessage("c-new-client.form.numero_civicoInsert")}
                        validate={(value, formValues) =>
                            fieldRequired(translateMessage("general.form.field-required"))(value, formValues)
                        }
                        initialValue={cervedStatus === "modify" ? formParamsCerved.streetNumber : ""}
                    />
                </NewClientRowHeadquarter>

                <NewClientRow>
                    <TextInputField
                        style={{ width: "300px" }}
                        name={"city"}
                        label={translateMessage("c-new-client.form.city")}
                        placeholder={translateMessage("c-new-client.form.cityInsert")}
                        validate={(value, formValues) =>
                            fieldRequired(translateMessage("general.form.field-required"))(value, formValues)
                        }
                        initialValue={cervedStatus === "modify" ? formParamsCerved.city : ""}
                    />
                    <TextInputField
                        style={{ width: "120px" }}
                        name={"zipCode"}
                        label={translateMessage("c-new-client.form.cap")}
                        placeholder={translateMessage("c-new-client.form.capInsert")}
                        onChange={e => {
                            let value = e.replace(/\D/g, "");
                            setCapValue(value);
                            if (e.length > 5 && formParams.countryCode === "IT") {
                                setCapValue(value.slice(0, 5));
                            }
                        }}
                        validate={(value, formValues) => {
                            return combineValidators(
                                fieldRequired(translateMessage("general.form.field-required")),
                                fieldLength(
                                    translateMessageWithParams("general.form.field-length-range", {
                                        min: 1,
                                        max: 5
                                    }),
                                    1,
                                    5
                                )
                            )(value, formValues);
                        }}
                        initialValue={cervedStatus === "modify" ? formParamsCerved.zipCode : capValue?.toUpperCase()}
                    />

                    <FormSelect
                        name={"province"}
                        style={{ width: "150px", lineHeight: "1.9" }}
                        label={translateMessage("c-new-client.form.province")}
                        options={provinceList}
                        placeholder={translateMessage("general.select")}
                        validate={(value, formValues) =>
                            fieldRequired(translateMessage("general.form.field-required"))(value, formValues)
                        }
                        initialValue={cervedStatus === "modify" ? formParamsCerved.province : ""}
                    />
                </NewClientRow>

                <NewClientRowReminder>
                    <NewClientReminder>
                        <FormattedMessage
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                            id="c-new-registry.reminderLabel"
                        />
                    </NewClientReminder>
                </NewClientRowReminder>
                {errorMessage ? (
                    <ErrorMessage>
                        <FormattedMessage id="general.error" />: {errorMessage}
                    </ErrorMessage>
                ) : null}
            </NewClient>
        </div>
    );
};

NewClientData.propTypes = {
    setStepNumber: PropTypes.func,
    setSearchOrCreateStep: PropTypes.func,
    selectedRadio: PropTypes.string,
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    errorMessage: PropTypes.string,
    setErroMessage: PropTypes.func,
    formParams: PropTypes.shape({
        countryCode: PropTypes.string,
        headquarterType: PropTypes.string,
        classifier: PropTypes.string
    })
};

export default NewClientData;
