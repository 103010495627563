import styled from "styled-components";

export const LockedCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 600px;
    background: rgb(242, 245, 248);
    border-radius: 0px;
`;

export const LockedCardTitle = styled.div`
    color: rgb(21, 41, 53);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
`;

export const LockedCardContent = styled.div`
    color: rgb(90, 104, 114);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin-top: 6px;
`;
