import React from "react";
import { FormattedMessage } from "react-intl";
import { Section, SectionTitle, MainTitleText, MainTitleDesc, FieldContainer } from "../styled";
import RegistryField from "../customer-edit-registry-field";
import { GlobalCol, GlobalRow, GlobalHorizontalDivider } from "../../../../globalStyles";
import { headers } from "../settings";
import { v4 as uuidv4 } from "uuid";
import { Typography } from "@vapor/react-material";

const CustomerEditRegistryHeader = ({ customerDetails, section, subSection }) => {
    return (
        <>
            <Section>
                <SectionTitle vertical>
                    <MainTitleText>
                        {headers?.[section]?.icon}
                        <FormattedMessage id={headers?.[section]?.title || "general.notSpecified"} />
                    </MainTitleText>
                    <MainTitleDesc>
                        <FormattedMessage id={headers?.[section]?.subtitle || "general.notSpecified"} />
                    </MainTitleDesc>
                </SectionTitle>
                <GlobalCol>
                    {headers?.[section]?.rows?.map(row => {
                        return (
                            <GlobalRow key={`customer-edit-header-row_${uuidv4()}`}>
                                {row?.map(item => {
                                    if (item.renderCondition && item.renderCondition(customerDetails) !== true) {
                                        return null;
                                    }
                                    let componentValue = customerDetails?.[item.field];
                                    if (item.subSection) {
                                        componentValue = customerDetails?.[subSection]?.[item.field];
                                    }
                                    if (item.translateValues && customerDetails?.[item.field]) {
                                        componentValue = (
                                            <FormattedMessage
                                                id={`${item.translateValues}${
                                                    item.subSection
                                                        ? customerDetails?.[subSection]?.[item.field]
                                                        : customerDetails?.[item.field]
                                                }`}
                                            />
                                        );
                                    }
                                    if (item.customRender) {
                                        componentValue = item.customRender(customerDetails);
                                    }
                                    return (
                                        <FieldContainer key={`customer-edit-header-field_${uuidv4()}`}>
                                            {item.beforeElement}
                                            <RegistryField
                                                label={
                                                    <Typography
                                                        variant="subtitle"
                                                        component="div"
                                                        sx={{ color: "rgb(0, 80, 117)" }}
                                                    >
                                                        <FormattedMessage
                                                            id={
                                                                item.labelCondition
                                                                    ? item.labelCondition(customerDetails)
                                                                    : item.label || "general.notFound"
                                                            }
                                                        />
                                                    </Typography>
                                                }
                                            >
                                                <Typography
                                                    variant="subtitle"
                                                    component="div"
                                                    sx={{
                                                        color: "rgb(21, 41, 53)",
                                                        fontWeight: "700"
                                                    }}
                                                >
                                                    {componentValue}
                                                </Typography>
                                            </RegistryField>
                                            {item.afterElement}
                                        </FieldContainer>
                                    );
                                })}
                            </GlobalRow>
                        );
                    })}
                </GlobalCol>
                <GlobalHorizontalDivider />
            </Section>
        </>
    );
};

export default CustomerEditRegistryHeader;
