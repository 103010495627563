import {
    GET_ITEM_TOOLS_START,
    GET_ITEM_TOOLS_SUCCESS,
    GET_ITEM_TOOLS_ERROR,
    GET_ITEM_TOOLS_RESET,
    GET_TOOLS_START,
    GET_TOOLS_SUCCESS,
    GET_TOOLS_ERROR,
    GET_TOOLS_RESET
} from "../actions/tools";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function itemTools(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_ITEM_TOOLS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ITEM_TOOLS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                tools: payload
            };
        case GET_ITEM_TOOLS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case GET_ITEM_TOOLS_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export function tools(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_TOOLS_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_TOOLS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true,
                    itemTools: payload
                }
            };
        case GET_TOOLS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case GET_TOOLS_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
