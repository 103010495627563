import styled from "styled-components";

export const CustomerEditContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const CustomerEditGridCards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24em, 1fr));
    grid-auto-rows: ${props => `minmax(${props.minHeight || "240px"}, auto)`};
    grid-gap: ${props => props.gap || "32px"};
    margin-top: 2rem;
`;
