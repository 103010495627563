import styled from "styled-components";

export const EditFormContainer = styled.div``;

export const FormContainer = styled.div`
    ${props => (props.isEditing ? "max-width: 750px;" : "max-width: 100%;")}
`;

export const EditFormFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
    & > button {
        margin: 0 8px;
    }
    & > button:last-of-type {
        margin-right: 0;
    }
    & > button:first-of-type {
        margin-left: 0;
    }
`;

export const InputAdornmentContent = styled.div`
    background-color: white;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 13px;
    width: 20px;
    color: rgb(21, 41, 53);
`;

export const AlertSection = styled.span`
    font-weight: 500;
    margin-right: 4px;
    &::after {
        content: ":";
    }
`;
