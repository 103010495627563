import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faIndustryAlt } from "@fortawesome/pro-regular-svg-icons/faIndustryAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    CustomerEditBusinessTitleStatus,
    CustomerEditBusinessTitle,
    CustomerEditBusinessStatusTag,
    CustomerEditBusinessTitleText,
    CustomerEditBusinessFooterContainer,
    EditButton,
    CustomerEditBusinessTitleContainer
} from "../styled";
import { Button as MondoraButton } from "@ts-digital/vrc";
import { CustomerEditBusinessActivityContent, CustomerEditBusinessActivityContentLine, MenuButton } from "../../styled";
import Card from "../../../../vapor-components/card";
import { Descriptive } from "../../../../vapor-components/typography";
import Tag from "../../../../vapor-components/tag";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    List,
    Button,
    ListItemButton,
    ListItemText,
    Popover,
    Typography
} from "@vapor/react-material";
import { IvaProRataTooltip } from "./handleIvaProRata";
import { Close } from "@mui/icons-material";
import { useBrowserStorage } from "../../../../../hook/useBrowserStorage";

export const CardBusiness = ({
    business,
    setBusinessId,
    setIsEditing,
    setDeleteConfirmModal,
    setEndDateConfirmModal,
    setActiveStep,
    setIsEditingStep
}) => {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogIvaProRata, setOpenDialogIvaProRata] = useState(false);
    const ivaProRataHandling = useBrowserStorage("noIvaProRataHandling");
    const ivaProRataHandlingCheck = ivaProRataHandling
        ?.get()
        ?.find(item => item.businessId === business.id && item.year === new Date().getFullYear());

    const enableNotifyIvaProRata = () => {
        return new Date().getTime() <= new Date(`January 15, ${new Date().getFullYear()} 00:00:00`).getTime();
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? business.id : undefined;

    const ceasedBusiness = () => {
        return business.endDate && new Date(business.endDate) < new Date();
    };

    const handleIvaProRata = handle => {
        if (handle) {
            setBusinessId(business.id);
            setActiveStep(4);
            setIsEditingStep(true);
            setIsEditing(true);
        } else {
            setOpenDialogIvaProRata(true);
        }
    };

    const handleNoIvaProRataHandle = () => {
        if (ivaProRataHandlingCheck) {
            setOpenDialogIvaProRata(false);
            return;
        }

        ivaProRataHandling.set([
            ...(ivaProRataHandling.get() || []),
            {
                businessId: business.id,
                year: new Date().getFullYear()
            }
        ]);
        setOpenDialogIvaProRata(false);
    };

    return (
        <>
            <Dialog
                open={openDialogIvaProRata}
                onClose={() => {
                    setOpenDialogIvaProRata(false);
                }}
                aria-labelledby="alert-dialog-iva-pro-rata-title"
                aria-describedby="alert-dialog--iva-pro-rata-description"
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                        maxWidth: "500px"
                    }
                }}
            >
                <DialogTitle>
                    <FormattedMessage
                        id="dialog.business.ivaprorata.title"
                        values={{
                            year: new Date().getFullYear()
                        }}
                    />
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setOpenDialogIvaProRata(false);
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="bodyLarge">
                            <FormattedMessage
                                id="dialog.business.ivaprorata.description"
                                values={{
                                    year: new Date().getFullYear(),
                                    br: <br />
                                }}
                            />
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpenDialogIvaProRata(false);
                        }}
                    >
                        <FormattedMessage id="general.cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleNoIvaProRataHandle}>
                        <FormattedMessage
                            id="c-alert-business-iva-pro-rata-notHandle"
                            values={{
                                year: new Date().getFullYear()
                            }}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            <Card
                verticalFooter={true}
                footer={
                    <CustomerEditBusinessFooterContainer
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%"
                        }}
                    >
                        <Box>
                            <MenuButton>
                                {!ceasedBusiness() && !business.ivaProRataHandled && enableNotifyIvaProRata()
                                    ? !ivaProRataHandlingCheck && (
                                          <IvaProRataTooltip handleIvaProRata={handleIvaProRata} />
                                      )
                                    : null}
                            </MenuButton>
                        </Box>
                        <Box>
                            <MenuButton>
                                <MondoraButton kind="tertiary" aria-describedby={id} id={id} onClick={handleClick}>
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </MondoraButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    BackdropProps={{
                                        invisible: false
                                    }}
                                >
                                    <List disablePadding sx={{ width: 240 }}>
                                        <ListItemButton
                                            component="a"
                                            onClick={() => {
                                                setDeleteConfirmModal(true);
                                                setBusinessId(business.id);
                                            }}
                                        >
                                            <ListItemText
                                                primary={intl.formatMessage({ id: "list.business.deleteBusiness" })}
                                            />
                                        </ListItemButton>
                                        {!ceasedBusiness() && (
                                            <ListItemButton
                                                component="a"
                                                onClick={() => {
                                                    setEndDateConfirmModal(true);
                                                    setBusinessId(business.id);
                                                }}
                                            >
                                                <ListItemText
                                                    primary={intl.formatMessage({
                                                        id: "list.business.changeIntoCeased"
                                                    })}
                                                />
                                            </ListItemButton>
                                        )}
                                        {/*}
                                <ListItemButton component="a">
                                    <ListItemText
                                        primary={intl.formatMessage({ id: "list.business.favoritesBusiness" })}
                                    />
                                </ListItemButton>
                                {*/}
                                    </List>
                                </Popover>
                            </MenuButton>
                            <EditButton>
                                <MondoraButton
                                    kind="tertiary"
                                    onClick={() => {
                                        setBusinessId(business.id);
                                        setIsEditing(true);
                                        setActiveStep(0);
                                        setIsEditingStep(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </MondoraButton>
                            </EditButton>
                        </Box>
                    </CustomerEditBusinessFooterContainer>
                }
                title={
                    <CustomerEditBusinessTitleContainer>
                        <CustomerEditBusinessTitleStatus>
                            <CustomerEditBusinessStatusTag>
                                <Tag
                                    label={intl.formatMessage({
                                        id: `c-customer-edit-activity-tag.${
                                            ceasedBusiness() ? "ceased" : business.type
                                        }`
                                    })}
                                    ghost={business.type !== "MAIN"}
                                    type={ceasedBusiness() ? "rustyRed" : "blueSapphire"}
                                    upperText
                                />
                            </CustomerEditBusinessStatusTag>
                        </CustomerEditBusinessTitleStatus>
                        <CustomerEditBusinessTitle>
                            <FontAwesomeIcon icon={faIndustryAlt} />
                            <CustomerEditBusinessTitleText>{business.description}</CustomerEditBusinessTitleText>
                        </CustomerEditBusinessTitle>
                    </CustomerEditBusinessTitleContainer>
                }
            >
                <CustomerEditBusinessActivityContent>
                    {business.atecoCode && (
                        <Descriptive>
                            <CustomerEditBusinessActivityContentLine>
                                <FormattedMessage id="field.business.atecoCode" />:{" "}
                                <strong>{business.atecoCode}</strong>
                            </CustomerEditBusinessActivityContentLine>
                        </Descriptive>
                    )}
                    {business.atecoCodeDescription && (
                        <Descriptive>
                            <CustomerEditBusinessActivityContentLine>
                                <FormattedMessage id="c-customer-edit-business.atecoName" />{" "}
                                <strong>{business.atecoCodeDescription}</strong>
                            </CustomerEditBusinessActivityContentLine>
                        </Descriptive>
                    )}
                </CustomerEditBusinessActivityContent>
            </Card>
        </>
    );
};
