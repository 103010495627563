import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import {
    CheckIcon,
    NextStepRow,
    NextStepIcon,
    NextStepRowDetail,
    NextStepsLabel1,
    NextStepsLabel2,
    StepFinalTitle
} from "../../styled";
import { Button } from "@ts-digital/vrc";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { BodyCopy, H1, SubTitleRegular } from "../../../../vapor-components/typography";

const NewClientResult = () => {
    return (
        <div>
            <CheckIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
            </CheckIcon>
            <StepFinalTitle>
                <H1>
                    <FormattedMessage id="c-new-registry.title4" />
                </H1>
            </StepFinalTitle>
            <NextStepsLabel1>
                <SubTitleRegular weight="bold">
                    <FormattedMessage id="c-new-client.new-client-form.nextSteps" />
                </SubTitleRegular>
            </NextStepsLabel1>
            <NextStepsLabel2>
                <BodyCopy>
                    <FormattedMessage id="c-new-client.new-client-form.nextSteps2" />
                </BodyCopy>
            </NextStepsLabel2>
            <div>
                <NextStepRow>
                    <NextStepRowDetail>
                        <NextStepIcon>
                            <FontAwesomeIcon icon={faCog} />
                        </NextStepIcon>
                        <div>
                            <FormattedMessage
                                values={{
                                    b: chunks => <b>{chunks}</b>
                                }}
                                id="c-new-client.new-client-form.serviceButton"
                            />
                        </div>
                    </NextStepRowDetail>
                    <div>
                        <Button style={{ width: "210px" }} kind="secondary">
                            <FormattedMessage id="c-new-client.new-client-form.serviceButton3" />
                        </Button>
                    </div>
                </NextStepRow>
                <NextStepRow>
                    <NextStepRowDetail>
                        <NextStepIcon>
                            <FontAwesomeIcon icon={faUsers} />
                        </NextStepIcon>
                        <div>
                            <FormattedMessage
                                values={{
                                    b: chunks => <b>{chunks}</b>
                                }}
                                id="c-new-client.new-client-form.completeAnag"
                            />
                        </div>
                    </NextStepRowDetail>
                    <div>
                        <Button style={{ width: "210px" }} kind="secondary">
                            <FormattedMessage id="c-new-client.new-client-form.completeAnagButton" />
                        </Button>
                    </div>
                </NextStepRow>
            </div>
        </div>
    );
};

NewClientResult.propTypes = {};

export default NewClientResult;
