import customerManagementApiClient from "../apiClient";

export const GET_CONNECTIONS_START = "GET_CONNECTIONS_START";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_ERROR = "GET_CONNECTIONS_ERROR";
export const GET_CONNECTIONS_RESET = "GET_CONNECTIONS_RESET";

export function getConnections(ownerId) {
    return async dispatch => {
        dispatch({
            type: GET_CONNECTIONS_START,
            payload: { ownerId }
        });

        try {
            const res = await new customerManagementApiClient().getConnections(ownerId);

            dispatch({
                type: GET_CONNECTIONS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CONNECTIONS_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetConnections() {
    return dispatch => {
        dispatch({
            type: GET_CONNECTIONS_RESET
        });
    };
}
