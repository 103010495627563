import React from "react";

import { Input } from "@ts-digital/vrc";
import { Field } from "react-final-form";

const TextInput = ({ input, meta, ...props }) => {
    return (
        <Input
            {...input}
            {...props}
            error={meta.touched === true && meta.error !== undefined}
            errorMessage={meta.error}
            autoComplete="off"
        />
    );
};

const TextInputField = ({ ...props }) => {
    return <Field {...props} component={TextInput} />;
};

export default TextInputField;
