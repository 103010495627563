import styled from "styled-components";

export const LeftSideIconDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const LeftSideIcon = styled.div`
    font-size: 1.5rem;
    margin-right: 10px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
`;

export const LeftSideDescription = styled.div`
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const HorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #c9d9e8;
`;
