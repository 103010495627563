import React, { useMemo } from "react";

import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { getMessagesFromLocale } from "../i18n";
import NewClient from "./new-client";
import Customers from "./customers";
import CustomerCompanyCreated from "./customer-company-created";
import CustomerDashboard from "./customer-dashboard";
import CustomerEditMain from "./customer-edit/main";
import CustomerEditIntermediary from "./customer-edit/intermediary";
import { LocalizationProvider, AdapterDateFns } from "@vapor/react-x-date-pickers";
import enLocale from "date-fns/locale/en-US";
import itLocale from "date-fns/locale/it";

const localeMap = {
    en: enLocale,
    it: itLocale
};

const HandlerRoutes = ({ userLocale = "it" }) => {
    const messages = useMemo(() => getMessagesFromLocale(userLocale), [userLocale]);

    return (
        <IntlProvider key={userLocale} locale={userLocale} messages={messages} timeZoneName="it-IT">
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[userLocale] || itLocale}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Customers />} path="/" />
                        <Route element={<NewClient />} path="/new-client" />
                        <Route element={<CustomerCompanyCreated />} path="/customer-company-created" />
                        <Route element={<CustomerDashboard />} path="/:item/customer-dashboard" />
                        <Route element={<CustomerEditMain />} path="/:item/customer-edit/main" />
                        <Route element={<CustomerEditIntermediary />} path="/:item/customer-edit/intermediary" />
                    </Routes>
                </BrowserRouter>
            </LocalizationProvider>
        </IntlProvider>
    );
};

export default HandlerRoutes;
