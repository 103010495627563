import styled from "styled-components";

export const CustomerHeaderNameContainer = styled.div`
    display: flex;
`;

export const CustomerName = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerNameTitle = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 34px;
    font-family: Cairo, sans-serif;
    font-weight: 600;
    line-height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
`;

export const CustomerNameSubtitle = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 14px;
    font-family: Cairo, sans-serif;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    margin-bottom: 16px;
`;

export const CustomerNameAdditionals = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
`;

export const CustomerNameAdditionalsItem = styled.div`
    margin: 4px 0;
`;

export const CustomerNameStatus = styled.div`
    margin: 8px 0;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
`;

export const CustomerNameStatusIcon = styled.div`
    font-size: 8px;
    margin-right: 8px;
    color: ${props =>
        props.status === "ACTIVE" ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.crayola};
`;

export const CustomerChip = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    border-radius: 8px;
    background: ${props => props.theme.colors.background.azureishWhite};
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    width: fit-content;
`;
