import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Descriptive } from "../../../../vapor-components/typography";
import { Button } from "@ts-digital/vrc";
import Tag from "../../../../vapor-components/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import {
    CustomerEditBusinessFooterContainer,
    EditButton,
    CustomerEditBusinessTitleContainer,
    CustomerEditBusinessTitleStatus,
    CustomerEditBusinessTitle,
    CustomerEditBusinessStatusTag,
    CustomerEditBusinessTitleText,
    CustomerEditBusinessContent
} from "../styled";
import Card from "../../../../vapor-components/card";

export const CardNewBusiness = ({
    setIsEditing,
    setBusinessId,
    activeStep,
    setActiveStep,
    isEditingStep,
    setIsEditingStep
}) => {
    const intl = useIntl();
    return (
        <Card
            backgroundColor={"whiteSmoke"}
            verticalFooter={true}
            footer={
                <CustomerEditBusinessFooterContainer>
                    <EditButton>
                        <Button
                            kind="tertiary"
                            onClick={() => {
                                setActiveStep(0);
                                setIsEditingStep(true);
                                setBusinessId(null);
                                setIsEditing(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </EditButton>
                </CustomerEditBusinessFooterContainer>
            }
            title={
                <CustomerEditBusinessTitleContainer>
                    <CustomerEditBusinessTitleStatus>
                        <CustomerEditBusinessStatusTag>
                            <Tag
                                bgColor="#e4eaf0"
                                color="#5a6872"
                                label={intl.formatMessage({
                                    id: "c-customer-edit-area-title.business"
                                })}
                                upperText
                            />
                        </CustomerEditBusinessStatusTag>
                    </CustomerEditBusinessTitleStatus>
                    <CustomerEditBusinessTitle>
                        <FontAwesomeIcon icon={faPlus} />
                        <CustomerEditBusinessTitleText>
                            <FormattedMessage id="c-customer-edit-business.create" />
                        </CustomerEditBusinessTitleText>
                    </CustomerEditBusinessTitle>
                </CustomerEditBusinessTitleContainer>
            }
        >
            <CustomerEditBusinessContent>
                <Descriptive color="cadet" lineHeight="16px" style={{ marginTop: "10px", marginLeft: "24px" }}>
                    <FormattedMessage id="c-customer-edit-business.createDescription" />
                </Descriptive>
                <Descriptive color="cadet" lineHeight="40px" style={{ marginLeft: "24px" }}>
                    <FormattedMessage
                        id="c-customer-edit-createIn"
                        values={{ icon: <FontAwesomeIcon icon={faClock} />, minutes: <b>20 min</b> }}
                    />
                </Descriptive>
            </CustomerEditBusinessContent>
        </Card>
    );
};
