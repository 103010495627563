import { Button } from "@vapor/react-material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isEmptyOrSpaces } from "../../../../utils/string";
import { settings, fieldsSettings } from "../settings";
import { EditFormFooter, FormContainer } from "./styled";
import CustomerEditSection from "./section";

const CustomerRegistryEditForm = props => {
    const {
        alert,
        customerDetails,
        bodyRequest,
        isEditing,
        onSave,
        section,
        setBodyRequest,
        setAlert,
        setIsEditing,
        subSection,
        differentByClassifier
    } = props;
    const [enableSave, setEnableSave] = useState(false);

    const subSettings = settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)?.[section];
    const settingsFind = differentByClassifier ? subSettings?.[customerDetails.classifier] : subSettings;

    // REQUIRED FIELDS CHECK
    useEffect(() => {
        if (customerDetails?.classifier && settingsFind) {
            let flagFalse = false;
            settingsFind.fields?.map(field => {
                if (fieldsSettings?.[field]?.required) {
                    let objSetting = fieldsSettings?.[field];
                    if (!bodyRequest[subSection]?.[field] || isEmptyOrSpaces(bodyRequest[subSection]?.[field])) {
                        if (
                            !objSetting.renderCondition ||
                            (objSetting.renderCondition && objSetting.renderCondition(bodyRequest))
                        ) {
                            flagFalse = true;
                            return null;
                        }
                    }
                }
                return null;
            });
            if (flagFalse) {
                return setEnableSave(false);
            }
        }
        setEnableSave(true);
    }, [bodyRequest, customerDetails?.classifier, settingsFind, subSection]);

    if (!subSettings) {
        return <FormattedMessage id="general.subSettingsNotFound" values={{ section: section }} />;
    }

    if (!settingsFind) {
        return <FormattedMessage id="general.settingsNotFound" values={{ classifier: customerDetails?.classifier }} />;
    }

    return (
        <FormContainer isEditing={isEditing}>
            {settingsFind?.sections?.map((item, index) => {
                return (
                    <CustomerEditSection
                        key={`customer-edit-section_${index}`}
                        alert={alert}
                        bodyRequest={bodyRequest}
                        customerDetails={customerDetails}
                        isEditing={isEditing}
                        section={item}
                        setAlert={setAlert}
                        setBodyRequest={setBodyRequest}
                        setIsEditing={setIsEditing}
                        settingsFind={settingsFind}
                        subSection={subSection}
                    />
                );
            })}

            {isEditing && (
                <EditFormFooter>
                    <Button onClick={() => setIsEditing(false)} style={{ width: 92 }} size="small" variant="outlined">
                        <FormattedMessage id="general.cancel" />
                    </Button>
                    <Button
                        onClick={() => onSave()}
                        disabled={!enableSave}
                        style={{ width: 92 }}
                        size="small"
                        color="primary"
                        variant="contained"
                    >
                        <FormattedMessage id="general.save" />
                    </Button>
                </EditFormFooter>
            )}
        </FormContainer>
    );
};

export default CustomerRegistryEditForm;
