import { combineReducers } from "redux";
import {
    GET_CUSTOMER_BUSINESS_ERROR,
    GET_CUSTOMER_BUSINESS_RESET,
    GET_CUSTOMER_BUSINESS_START,
    GET_CUSTOMER_BUSINESS_SUCCESS,
    CREATE_BUSINESS_SECTIONAL_START,
    CREATE_BUSINESS_SECTIONAL_SUCCESS,
    CREATE_BUSINESS_SECTIONAL_ERROR
} from "../actions/business";
import {
    CREATE_CUSTOMER_BANK_ERROR,
    CREATE_CUSTOMER_BANK_START,
    CREATE_CUSTOMER_BANK_SUCCESS,
    GET_CUSTOMER_BANKS_ERROR,
    GET_CUSTOMER_BANKS_RESET,
    GET_CUSTOMER_BANKS_START,
    GET_CUSTOMER_BANKS_SUCCESS,
    UPDATE_CUSTOMER_BANK_ERROR,
    UPDATE_CUSTOMER_BANK_START,
    UPDATE_CUSTOMER_BANK_SUCCESS
} from "../actions/banks";
import {
    GET_CUSTOMER_DETAIL_START,
    GET_CUSTOMER_DETAIL_SUCCESS,
    GET_CUSTOMER_DETAIL_ERROR,
    GET_CUSTOMER_DETAIL_RESET
} from "../actions/customers";
import {
    UPDATE_CUSTOMER_PERSONAL_DATA_ERROR,
    UPDATE_CUSTOMER_PERSONAL_DATA_START,
    UPDATE_CUSTOMER_PERSONAL_DATA_SUCCESS
} from "../actions/customerUpdate";
import {
    CREATE_CUSTOMER_ACCOUNTING_ERROR,
    CREATE_CUSTOMER_ACCOUNTING_START,
    CREATE_CUSTOMER_ACCOUNTING_SUCCESS,
    GET_CUSTOMER_ACCOUNTING_ERROR,
    GET_CUSTOMER_ACCOUNTING_RESET,
    GET_CUSTOMER_ACCOUNTING_START,
    GET_CUSTOMER_ACCOUNTING_SUCCESS,
    UPDATE_CUSTOMER_ACCOUNTING_ERROR,
    UPDATE_CUSTOMER_ACCOUNTING_START,
    UPDATE_CUSTOMER_ACCOUNTING_SUCCESS
} from "../actions/accounting";
import {
    CREATE_CUSTOMER_INTRASTAT_ERROR,
    CREATE_CUSTOMER_INTRASTAT_START,
    CREATE_CUSTOMER_INTRASTAT_SUCCESS,
    GET_CUSTOMER_INTRASTAT_ERROR,
    GET_CUSTOMER_INTRASTAT_START,
    GET_CUSTOMER_INTRASTAT_SUCCESS,
    UPDATE_CUSTOMER_INTRASTAT_ERROR,
    UPDATE_CUSTOMER_INTRASTAT_START,
    UPDATE_CUSTOMER_INTRASTAT_SUCCESS
} from "../actions/intrastat";
import {
    CREATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR,
    CREATE_CUSTOMER_ANTIMONEYLAUNDERING_START,
    CREATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS
} from "../actions/antimoneylaundering";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    data: {},
    additionalProps: {
        banks: [],
        isGettingBanks: false,
        isSavingBank: false,
        business: [],
        isGettingBusiness: false,
        //TODO: Save here the customer correlated intermediaries when API is avaiable
        intermediaries: [],
        isGettingIntermediaries: false,
        headquarters: [],
        isGettingHeadquarters: false,
        accounting: {},
        isGettingAccounting: false,
        isSavingAccounting: false,
        intrastat: {},
        isGettingIntrastat: false,
        isSavingIntrastat: false
    },
    isSavingData: false
};

function details(state = defaultState, { type, payload, error }) {
    switch (type) {
        //customer
        case GET_CUSTOMER_DETAIL_START:
            return {
                ...state,
                status: {
                    started: true,
                    error: false,
                    ended: false
                },
                isSavingData: false
            };
        case GET_CUSTOMER_DETAIL_SUCCESS:
            return {
                ...state,
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                data: payload,
                isSavingData: false
            };
        case GET_CUSTOMER_DETAIL_ERROR:
            return {
                ...state,
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                },
                isSavingData: false
            };
        case GET_CUSTOMER_DETAIL_RESET:
            return defaultState;
        //activities
        case GET_CUSTOMER_BUSINESS_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBusiness: true,
                    business: []
                }
            };
        case GET_CUSTOMER_BUSINESS_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBusiness: false,
                    business: payload
                }
            };
        case GET_CUSTOMER_BUSINESS_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBusiness: false,
                    business: []
                }
            };
        case GET_CUSTOMER_BUSINESS_RESET:
            return { ...state, additionalProps: { ...state.additionalProps, isGettingBusiness: false, business: [] } };
        case GET_CUSTOMER_ACCOUNTING_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingAccounting: true,
                    accounting: {}
                }
            };
        case GET_CUSTOMER_ACCOUNTING_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingAccounting: false,
                    accounting: payload
                }
            };
        case GET_CUSTOMER_ACCOUNTING_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingAccounting: false,
                    accounting: {}
                }
            };
        case GET_CUSTOMER_ACCOUNTING_RESET:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingAccounting: false,
                    accounting: {}
                }
            };
        case GET_CUSTOMER_INTRASTAT_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingIntrastat: true,
                    intrastat: {}
                }
            };
        case GET_CUSTOMER_INTRASTAT_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingIntrastat: false,
                    intrastat: payload
                }
            };
        case GET_CUSTOMER_INTRASTAT_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingIntrastat: false,
                    intrastat: {}
                }
            };
        case CREATE_BUSINESS_SECTIONAL_START:
            return { ...state, additionalProps: { ...state.additionalProps, sectionalError: false } };
        case CREATE_BUSINESS_SECTIONAL_SUCCESS:
            return { ...state, additionalProps: { ...state.additionalProps, sectionalId: payload?.id } };
        case CREATE_BUSINESS_SECTIONAL_ERROR:
            return {
                ...state,
                additionalProps: { ...state.additionalProps, sectionalId: payload, sectionalError: true }
            };
        case GET_CUSTOMER_BANKS_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBanks: true,
                    banks: []
                }
            };
        case GET_CUSTOMER_BANKS_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBanks: false,
                    banks: payload
                }
            };
        case GET_CUSTOMER_BANKS_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isGettingBanks: false,
                    banks: []
                }
            };
        case CREATE_CUSTOMER_BANK_START:
        case UPDATE_CUSTOMER_BANK_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingBank: true
                }
            };
        case CREATE_CUSTOMER_BANK_SUCCESS:
        case UPDATE_CUSTOMER_BANK_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingBank: false
                }
            };
        case CREATE_CUSTOMER_BANK_ERROR:
        case UPDATE_CUSTOMER_BANK_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingBank: false
                }
            };
        case CREATE_CUSTOMER_ACCOUNTING_START:
        case UPDATE_CUSTOMER_ACCOUNTING_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingAccounting: true
                }
            };
        case CREATE_CUSTOMER_ACCOUNTING_SUCCESS:
        case UPDATE_CUSTOMER_ACCOUNTING_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingAccounting: false
                }
            };
        case CREATE_CUSTOMER_ACCOUNTING_ERROR:
        case UPDATE_CUSTOMER_ACCOUNTING_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingAccounting: false
                }
            };
        case CREATE_CUSTOMER_INTRASTAT_START:
        case UPDATE_CUSTOMER_INTRASTAT_START:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingIntrastat: true
                }
            };
        case CREATE_CUSTOMER_INTRASTAT_SUCCESS:
        case UPDATE_CUSTOMER_INTRASTAT_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingIntrastat: false
                }
            };
        case CREATE_CUSTOMER_INTRASTAT_ERROR:
        case UPDATE_CUSTOMER_INTRASTAT_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingIntrastat: false
                }
            };
        case GET_CUSTOMER_BANKS_RESET:
            return { ...state, additionalProps: { ...state.additionalProps, isGettingBanks: false, banks: [] } };
        case UPDATE_CUSTOMER_PERSONAL_DATA_START:
            return { ...state, isSavingData: true };
        case UPDATE_CUSTOMER_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                data: { ...state.data, [payload.subSection]: { ...state.data?.[payload.subSection], ...payload.data } },
                isSavingData: false
            };
        case UPDATE_CUSTOMER_PERSONAL_DATA_ERROR:
            return { ...state, isSavingData: false };
        case CREATE_CUSTOMER_ANTIMONEYLAUNDERING_START:
            return { ...state, additionalProps: { ...state.additionalProps, isSavingAntiMoneyLaundering: true } };
        case CREATE_CUSTOMER_ANTIMONEYLAUNDERING_SUCCESS:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingAntiMoneyLaundering: false,
                    antiMoneyLaundering: payload
                }
            };
        case CREATE_CUSTOMER_ANTIMONEYLAUNDERING_ERROR:
            return {
                ...state,
                additionalProps: {
                    ...state.additionalProps,
                    isSavingAntiMoneyLaundering: false,
                    antiMoneyLaundering: null
                }
            };
        default:
            return state;
    }
}

export const customerReducer = combineReducers({
    details
});

export default customerReducer;
