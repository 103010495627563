import styled from "styled-components";

export const SubField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: fit-content;
    border-left: 5px solid #c9d9e8;
    padding-left: 25px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: 75%;
`;
