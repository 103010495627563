import {
    Box,
    FormControl,
    Stack,
    Typography,
    Button,
    FormHelperText,
    ButtonGroup,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    Dialog
} from "@vapor/react-material";
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router";
import { AlertMessage } from "../../alert-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faExchange } from "@fortawesome/pro-regular-svg-icons/faExchange";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { FormattedMessage, useIntl } from "react-intl";
import { InfoBox } from "../../infobox";
import { Field, WsTag } from "@onefront/react-workspace-components";
import { ToggleBox } from "./styled";
import {
    MONTHLY,
    QUARTERLY,
    TAX_PURPOSE,
    TAX_AND_STATISTICS_PURPOSE,
    intrastatGoodsKeys,
    intrastatServicesKeys
} from "./constants";
import { useSelector, useDispatch } from "react-redux";
import {
    createCustomerIntrastat,
    updateCustomerIntrastat,
    getCustomerIntrastat,
    deleteCustomerIntrastat
} from "../../../actions/intrastat";
import { LoadingIcon } from "../../vapor-components/table";
import { isEmptyOrSpaces } from "../../../utils/string";
import LoadingSkeleton from "../customer-edit-area/loading-skeleton";
import { Close } from "@mui/icons-material";

export const CustomerEditIntrastat = ({ render }) => {
    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    const intrastat = useSelector(state => state?.customer?.details?.additionalProps?.intrastat);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const isLoading = useSelector(
        state =>
            state?.customer?.details?.additionalProps?.isSavingIntrastat ||
            state?.customer?.details?.additionalProps?.isGettingIntrastat
    );

    const [intrastatConfigurated, setIntrastatConfigurated] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [toggleGoods, setToggleGoods] = useState(intrastatGoodsKeys.every(key => intrastat[key] !== null));
    const [toggleServices, setToggleServices] = useState(intrastatServicesKeys.every(key => intrastat[key] !== null));

    const [intrastatToggle, setIntrastatToggle] = useState(false);
    const [bodyRequest, setBodyRequest] = useState(
        intrastatConfigurated ? { ...intrastat, handleIntrastat: intrastatToggle.toString() } : null
    );
    const [errors, setErrors] = useState(null);
    const [displayErrors, setDisplayErrors] = useState(false);

    const [openNotHandleIntrastat, setOpenNotHandleIntrastat] = useState(false);

    const dispatch = useDispatch();

    const intl = useIntl();

    useEffect(() => {
        if (Object.keys(intrastat)?.length > 0) {
            setIntrastatConfigurated(true);
            setBodyRequest({ ...intrastat, handleIntrastat: "true" });
            setIntrastatToggle(
                intrastatGoodsKeys.every(key => intrastat[key] !== null) ||
                    intrastatServicesKeys.every(key => intrastat[key] !== null)
            );
            setIsEditing(false);
        } else {
            setIsEditing(true);
            setBodyRequest(null);
            setIntrastatToggle(false);
            setIntrastatConfigurated(false);
        }
    }, [intrastat]);

    useEffect(() => {
        if (bodyRequest && intrastatToggle && isEditing) {
            const fieldsDefaultValidation = Object.keys({
                ...(toggleGoods && {
                    goodsSoldDatatypeReport: true,
                    goodsSoldReportFrequency: true,
                    goodsPurchasedReportFrequency: true
                }),
                ...(toggleServices && { servicesSoldReportFrequency: true, servicesPurchasedReportFrequency: true })
            }).reduce((acc, key) => {
                if (isEmptyOrSpaces(bodyRequest?.[key] || "")) {
                    acc[key] = true;
                }

                return acc;
            }, {});

            setErrors({
                ...fieldsDefaultValidation
            });
        }
    }, [bodyRequest, setErrors, isEditing, intrastatToggle, toggleGoods, toggleServices]);

    if (!render) {
        return null;
    }

    const handleDialog = () => {
        setOpenNotHandleIntrastat(!openNotHandleIntrastat);
    };

    const handleChange = (val, key) => {
        setDisplayErrors(false);
        setBodyRequest({
            ...bodyRequest,
            [key]: val,
            ...(key === "goodsSoldReportFrequency" && val === QUARTERLY && { goodsSoldDatatypeReport: TAX_PURPOSE })
        });
    };

    const handleSave = () => {
        if (!toggleGoods && !toggleServices) {
            setOpenNotHandleIntrastat(true);
            return;
        }

        if (Object?.keys(errors)?.every(key => !errors[key])) {
            dispatch(createCustomerIntrastat(ownerId, workspaceId, bodyRequest)).then(() => {
                setIsEditing(false);
                setIntrastatConfigurated(true);
                setAlertMessage({
                    status: "success",
                    title: intl.formatMessage({ id: "c-customer-edit-intrastat.alertMessage.success.title" }),
                    description: intl.formatMessage({
                        id: "c-customer-edit-intrastat.alertMessage.success.description"
                    })
                });
                dispatch(getCustomerIntrastat(ownerId, workspaceId));
            });
        } else {
            setDisplayErrors(true);
        }
    };

    const handleModalDelete = () => {
        const { id } = bodyRequest;
        setOpenNotHandleIntrastat(false);
        dispatch(deleteCustomerIntrastat(id, ownerId, workspaceId)).then(() => {
            setAlertMessage({
                status: "success",
                title: intl.formatMessage({ id: "c-customer-edit-intrastat.alertMessage.success.title" }),
                description: intl.formatMessage({
                    id: "c-customer-edit-intrastat.alertMessage.success.description"
                })
            });
            setIsEditing(false);
            setIntrastatConfigurated(false);
            dispatch(getCustomerIntrastat(ownerId, workspaceId));
        });
    };

    const handlePatch = () => {
        const { id, handleIntrastat, ...rest } = bodyRequest;

        if (!intrastatToggle) {
            dispatch(deleteCustomerIntrastat(id, ownerId, workspaceId)).then(() => {
                setAlertMessage({
                    status: "success",
                    title: intl.formatMessage({ id: "c-customer-edit-intrastat.alertMessage.success.title" }),
                    description: intl.formatMessage({
                        id: "c-customer-edit-intrastat.alertMessage.success.description"
                    })
                });
                setIsEditing(false);
                setIntrastatConfigurated(false);
                dispatch(getCustomerIntrastat(ownerId, workspaceId));
            });
            return;
        }

        if (intrastatToggle && !toggleGoods && !toggleServices) {
            setOpenNotHandleIntrastat(true);
            return;
        }

        if (Object?.keys(errors)?.every(key => !errors[key])) {
            if (handleIntrastat) {
                dispatch(updateCustomerIntrastat(id, ownerId, workspaceId, rest)).then(() => {
                    setAlertMessage({
                        status: "success",
                        title: intl.formatMessage({ id: "c-customer-edit-intrastat.alertMessage.success.title" }),
                        description: intl.formatMessage({
                            id: "c-customer-edit-intrastat.alertMessage.success.description"
                        })
                    });
                    setIsEditing(false);
                    setIntrastatConfigurated(true);
                    dispatch(getCustomerIntrastat(ownerId, workspaceId));
                });
            } else {
                dispatch(deleteCustomerIntrastat(id, ownerId, workspaceId)).then(() => {
                    setAlertMessage({
                        status: "success",
                        title: intl.formatMessage({ id: "c-customer-edit-intrastat.alertMessage.success.title" }),
                        description: intl.formatMessage({
                            id: "c-customer-edit-intrastat.alertMessage.success.description"
                        })
                    });
                    setIsEditing(false);
                    setIntrastatConfigurated(false);
                    dispatch(getCustomerIntrastat(ownerId, workspaceId));
                });
            }
        } else {
            setDisplayErrors(true);
        }
    };

    return (
        <>
            <Dialog
                open={openNotHandleIntrastat}
                scroll="body"
                onClose={handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <FormattedMessage id="dialog.intrastat.text.title" />
                    <IconButton color="primary" onClick={handleDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider variant="alert" />
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="dialog.intrastat.text.content" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialog}>
                        <FormattedMessage id="general.cancel" />
                    </Button>
                    <Button variant="contained" sx={{ mr: 1 }} color="error" onClick={handleModalDelete}>
                        <FormattedMessage id="dialog.intrastat.button.confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            {alertMessage?.status && (
                <AlertMessage
                    status={alertMessage?.status}
                    title={alertMessage?.title}
                    description={alertMessage?.description}
                    setAlertMessage={setAlertMessage}
                />
            )}
            <Box
                sx={{
                    width: "75%",
                    "@media (min-width: 1680px)": {
                        width: "60%"
                    },
                    gap: "2rem"
                }}
            >
                <Stack mb={5}>
                    <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                        <Box sx={{ display: "inline-block", marginRight: ".5rem" }}>
                            <FontAwesomeIcon icon={faExchange} />
                        </Box>
                        <FormattedMessage id="c-customer-edit-intrastat-title" />
                    </Typography>
                    <Typography variant="bodyLarge" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-intrastat-description" />
                    </Typography>
                </Stack>
                {isLoading && <LoadingSkeleton />}
                {!isLoading && (
                    <>
                        <Stack mb={5}>
                            <InfoBox
                                title={intl.formatMessage({ id: "c-customer-edit-intrastat.InfoBox.title" })}
                                description={
                                    <Stack>
                                        <Box>
                                            <FormattedMessage
                                                id={"c-customer-edit-intrastat.InfoBox.intro"}
                                                values={{
                                                    b: chunks => <strong>{chunks}</strong>
                                                }}
                                            />
                                        </Box>
                                        <Stack mt={0.5} mb={2} spacing={0.3}>
                                            <Box>
                                                <FormattedMessage
                                                    id={"c-customer-edit-intrastat.InfoBox.nonEU.goods"}
                                                    values={{
                                                        b: chunks => <strong>{chunks}</strong>
                                                    }}
                                                />
                                            </Box>
                                            <Box>
                                                <FormattedMessage
                                                    id={"c-customer-edit-intrastat.InfoBox.intracommunityoperators"}
                                                    values={{
                                                        b: chunks => <strong>{chunks}</strong>
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Box>
                                            <FormattedMessage
                                                id={"c-customer-edit-intrastat.InfoBox.outro"}
                                                values={{
                                                    b: chunks => <strong>{chunks}</strong>
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                }
                            />
                        </Stack>
                        <Stack mb={3} direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
                            <Field
                                component="radio"
                                fullWidth
                                label={intl.formatMessage({ id: "field.intrastat.toggle-label" })}
                                isEditing={isEditing}
                                radioGroupAlign="vertical"
                                options={[
                                    {
                                        id: "true",
                                        value: intl.formatMessage({ id: "general.yes" })
                                    },
                                    {
                                        id: "false",
                                        value: intl.formatMessage({ id: "general.no" })
                                    }
                                ]}
                                onChange={val => {
                                    setIntrastatToggle(val === "true");
                                    setToggleServices(val === "true");
                                    setToggleGoods(val === "true");
                                    setBodyRequest(
                                        val === "true"
                                            ? {
                                                  ...bodyRequest,
                                                  handleIntrastat: val === "true",
                                                  goodsSoldDatatypeReport: TAX_PURPOSE
                                              }
                                            : { ...bodyRequest, handleIntrastat: val === "true" }
                                    );
                                }}
                                value={intrastatToggle.toString()}
                            />
                            {intrastatConfigurated && !isEditing && (
                                <Button
                                    onClick={() => {
                                        setIsEditing(true);
                                        setIntrastatConfigurated(true);
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Box>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Box>
                                        <Box>
                                            <FormattedMessage id="general.edit" />
                                        </Box>
                                    </Stack>
                                </Button>
                            )}
                        </Stack>
                        {intrastatToggle && (
                            <Stack spacing={2.5}>
                                {/* Goods */}
                                <ToggleBox
                                    error={displayErrors && Object?.keys(errors)?.some(key => key.includes("goods"))}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={isEditing ? "space-between" : "flex-start"}
                                        spacing={2}
                                    >
                                        <Typography
                                            variant="titleSmall"
                                            color="primary.textSubduedColor"
                                            component="div"
                                        >
                                            <FormattedMessage id={"field.intrastat.buyAndSell.goods.title"} />
                                        </Typography>
                                        {isEditing && (
                                            <ButtonGroup aria-label="goodsToggle" variant="contained">
                                                <Button
                                                    size="small"
                                                    sx={{ py: 2, px: 3, height: "2.5rem !important" }}
                                                    variant={toggleGoods ? "outlined" : "contained"}
                                                    disabled={!isEditing}
                                                    onClick={() => {
                                                        setDisplayErrors(false);
                                                        setToggleGoods(true);
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            goodsPurchasedReportFrequency: null,
                                                            goodsSoldDatatypeReport: TAX_PURPOSE,
                                                            goodsSoldReportFrequency: null
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="general.handle" />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    sx={{ py: 2, px: 3, height: "2.5rem !important" }}
                                                    variant={toggleGoods ? "contained" : "outlined"}
                                                    disabled={!isEditing}
                                                    onClick={() => {
                                                        setDisplayErrors(false);
                                                        setToggleGoods(false);
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            goodsPurchasedReportFrequency: null,
                                                            goodsSoldDatatypeReport: null,
                                                            goodsSoldReportFrequency: null
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="general.notHandle" />
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                        {!isEditing && !toggleGoods && (
                                            <WsTag
                                                size="small"
                                                textColor={"White"}
                                                color={"MaximumPurple"}
                                                label={intl.formatMessage({
                                                    id: "general.notHandled"
                                                })}
                                            />
                                        )}
                                    </Stack>
                                    {toggleGoods && (
                                        <Stack spacing={4} mt={4}>
                                            <FormControl
                                                sx={{
                                                    gap: "1rem",
                                                    maxWidth: "60%"
                                                }}
                                            >
                                                <Box>
                                                    <Field
                                                        component="radio"
                                                        fullWidth
                                                        label={intl.formatMessage({
                                                            id:
                                                                "field.intrastat.buyAndSell.goods.goodsPurchasedReportFrequency"
                                                        })}
                                                        isEditing={isEditing}
                                                        radioGroupAlign="vertical"
                                                        options={[
                                                            {
                                                                id: MONTHLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.MONTHLY"
                                                                })
                                                            },
                                                            {
                                                                id: QUARTERLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.QUARTERLY"
                                                                })
                                                            }
                                                        ]}
                                                        onChange={val =>
                                                            handleChange(val, "goodsPurchasedReportFrequency")
                                                        }
                                                        value={bodyRequest?.goodsPurchasedReportFrequency}
                                                    />
                                                    {displayErrors && errors.goodsPurchasedReportFrequency && (
                                                        <FormHelperText
                                                            error={
                                                                displayErrors && errors?.goodsPurchasedReportFrequency
                                                            }
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "error.intrastat.buyAndSell.goods.goodsPurchasedReportFrequency"
                                                            })}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Field
                                                        component="radio"
                                                        fullWidth
                                                        label={intl.formatMessage({
                                                            id:
                                                                "field.intrastat.buyAndSell.goods.goodsSoldReportFrequency"
                                                        })}
                                                        isEditing={isEditing}
                                                        radioGroupAlign="vertical"
                                                        options={[
                                                            {
                                                                id: MONTHLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.MONTHLY"
                                                                })
                                                            },
                                                            {
                                                                id: QUARTERLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.QUARTERLY"
                                                                })
                                                            }
                                                        ]}
                                                        onChange={val => {
                                                            handleChange(val, "goodsSoldReportFrequency");
                                                        }}
                                                        value={bodyRequest?.goodsSoldReportFrequency}
                                                    />
                                                    {displayErrors && errors.goodsSoldReportFrequency && (
                                                        <FormHelperText
                                                            error={displayErrors && errors?.goodsSoldReportFrequency}
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "error.intrastat.buyAndSell.goods.goodsSoldReportFrequency"
                                                            })}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Field
                                                        component="radio"
                                                        fullWidth
                                                        label={intl.formatMessage({
                                                            id:
                                                                "field.intrastat.buyAndSell.goods.goodsSoldDatatypeReport"
                                                        })}
                                                        isEditing={
                                                            isEditing
                                                                ? bodyRequest.hasOwnProperty("goodsSoldReportFrequency")
                                                                    ? bodyRequest?.goodsSoldReportFrequency === null ||
                                                                      bodyRequest?.goodsSoldReportFrequency === MONTHLY
                                                                    : true
                                                                : false
                                                        }
                                                        radioGroupAlign="vertical"
                                                        options={[
                                                            {
                                                                id: TAX_PURPOSE,
                                                                value: intl.formatMessage({
                                                                    id: "field.intrastat.buyAndSell.goods.TAX_PURPOSE"
                                                                })
                                                            },
                                                            {
                                                                id: TAX_AND_STATISTICS_PURPOSE,
                                                                value: intl.formatMessage({
                                                                    id:
                                                                        "field.intrastat.buyAndSell.goods.TAX_AND_STATISTICS_PURPOSE"
                                                                })
                                                            }
                                                        ]}
                                                        onChange={val => handleChange(val, "goodsSoldDatatypeReport")}
                                                        value={bodyRequest?.goodsSoldDatatypeReport}
                                                    />
                                                    {displayErrors && errors.goodsSoldDatatypeReport && (
                                                        <FormHelperText
                                                            error={displayErrors && errors?.goodsSoldDatatypeReport}
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "error.intrastat.buyAndSell.goods.goodsSoldDatatypeReport"
                                                            })}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                            </FormControl>
                                        </Stack>
                                    )}
                                </ToggleBox>
                                {/* Services */}
                                <ToggleBox
                                    error={displayErrors && Object?.keys(errors)?.some(key => key.includes("services"))}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={isEditing ? "space-between" : "flex-start"}
                                        spacing={2}
                                    >
                                        <Typography
                                            variant="titleSmall"
                                            color="primary.textSubduedColor"
                                            component="div"
                                        >
                                            <FormattedMessage id={"field.intrastat.buyAndSell.services.title"} />
                                        </Typography>
                                        {isEditing && (
                                            <ButtonGroup aria-label="servicesToggle" variant="contained">
                                                <Button
                                                    size="small"
                                                    sx={{ py: 2, px: 3, height: "2.5rem !important" }}
                                                    variant={toggleServices ? "outlined" : "contained"}
                                                    onClick={() => {
                                                        setDisplayErrors(false);
                                                        setToggleServices(true);
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            servicesPurchasedReportFrequency: null,
                                                            servicesSoldReportFrequency: null
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="general.handle" />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant={toggleServices ? "contained" : "outlined"}
                                                    sx={{ py: 2, px: 3, height: "2.5rem !important" }}
                                                    onClick={() => {
                                                        setDisplayErrors(false);
                                                        setToggleServices(false);
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            servicesPurchasedReportFrequency: null,
                                                            servicesSoldReportFrequency: null
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="general.notHandle" />
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                        {!isEditing && !toggleServices && (
                                            <WsTag
                                                size="small"
                                                textColor={"White"}
                                                color={"MaximumPurple"}
                                                label={intl.formatMessage({
                                                    id: "general.notHandled"
                                                })}
                                            />
                                        )}
                                    </Stack>
                                    {toggleServices && (
                                        <Stack spacing={4} mt={4}>
                                            <FormControl
                                                sx={{
                                                    gap: "1rem",
                                                    maxWidth: "60%"
                                                }}
                                            >
                                                <Box>
                                                    <Field
                                                        component="radio"
                                                        fullWidth
                                                        label={intl.formatMessage({
                                                            id:
                                                                "field.intrastat.buyAndSell.services.servicesPurchasedReportFrequency"
                                                        })}
                                                        isEditing={isEditing}
                                                        radioGroupAlign="vertical"
                                                        options={[
                                                            {
                                                                id: MONTHLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.MONTHLY"
                                                                })
                                                            },
                                                            {
                                                                id: QUARTERLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.QUARTERLY"
                                                                })
                                                            }
                                                        ]}
                                                        onChange={val =>
                                                            handleChange(val, "servicesPurchasedReportFrequency")
                                                        }
                                                        value={bodyRequest?.servicesPurchasedReportFrequency}
                                                    />
                                                    {displayErrors && errors.servicesPurchasedReportFrequency && (
                                                        <FormHelperText
                                                            error={
                                                                displayErrors &&
                                                                errors?.servicesPurchasedReportFrequency
                                                            }
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "error.intrastat.buyAndSell.services.servicesPurchasedReportFrequency"
                                                            })}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Field
                                                        component="radio"
                                                        fullWidth
                                                        label={intl.formatMessage({
                                                            id:
                                                                "field.intrastat.buyAndSell.services.servicesSoldReportFrequency"
                                                        })}
                                                        isEditing={isEditing}
                                                        radioGroupAlign="vertical"
                                                        options={[
                                                            {
                                                                id: MONTHLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.MONTHLY"
                                                                })
                                                            },
                                                            {
                                                                id: QUARTERLY,
                                                                value: intl.formatMessage({
                                                                    id: "interimFinancialStatement.QUARTERLY"
                                                                })
                                                            }
                                                        ]}
                                                        onChange={val =>
                                                            handleChange(val, "servicesSoldReportFrequency")
                                                        }
                                                        value={bodyRequest?.servicesSoldReportFrequency}
                                                    />
                                                    {displayErrors && errors.servicesSoldReportFrequency && (
                                                        <FormHelperText
                                                            error={displayErrors && errors?.servicesSoldReportFrequency}
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "error.intrastat.buyAndSell.services.servicesSoldReportFrequency"
                                                            })}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                            </FormControl>
                                        </Stack>
                                    )}
                                </ToggleBox>
                            </Stack>
                        )}
                    </>
                )}
                {!isLoading && ((isEditing && intrastatConfigurated) || (isEditing && intrastatToggle)) && (
                    <Stack spacing={3} direction="row" mt={6}>
                        <Button variant="outlined" onClick={() => setIsEditing(false)}>
                            <FormattedMessage id="general.cancel" />
                        </Button>
                        <Button
                            variant="contained"
                            disabled={isLoading}
                            onClick={intrastatConfigurated ? handlePatch : handleSave}
                        >
                            {isLoading && (
                                <LoadingIcon
                                    color="white"
                                    size="1rem"
                                    height="fit-content"
                                    width="fit-content"
                                    rightGutter
                                >
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
                                </LoadingIcon>
                            )}
                            <FormattedMessage id="general.save" />
                        </Button>
                    </Stack>
                )}
                <Box sx={{ height: "4rem" }} />
            </Box>
        </>
    );
};
