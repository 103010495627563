import React from "react";
import { LoadingElement } from "../../vapor-components/loading";
import { LoadingCardsContainer, LoadingRow, TypeCards } from "./styled";

let cards = [];

for (let i = 1; i <= 2; i++) {
    cards.push(
        <TypeCards key={`loading-${i}`}>
            <LoadingRow>
                <LoadingElement width="60%" height="35px"></LoadingElement>
                <LoadingElement width="5%" height="35px"></LoadingElement>
            </LoadingRow>
            <LoadingRow>
                <LoadingElement width="65%" height="20px"></LoadingElement>
                <LoadingElement width="20%" height="20px"></LoadingElement>
            </LoadingRow>
            <LoadingRow>
                <LoadingElement width="60%" height="20px"></LoadingElement>
            </LoadingRow>
        </TypeCards>
    );
}

const LoadingCards = () => {
    return <LoadingCardsContainer>{cards}</LoadingCardsContainer>;
};

export default LoadingCards;
