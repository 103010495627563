import React from "react";
import { FormattedMessage} from "react-intl";
import { Field } from "react-final-form";
import SelectorBox from "../../vapor-components/selector-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon, LabelContainer } from "../../vapor-components/selector-box/styled";
import { H3, SubTitleRegular } from "../../vapor-components/typography";
import { CardFooter, CardHeader } from "./styled";

export const TypeSelectorBoxesInput = ({ input, meta, radioCardsList, selectedRadio, setSelectedRadio }) => {

    return radioCardsList.map(oneRadioCard => (
        <SelectorBox
            key={oneRadioCard.radioValue}
            cardHeader={<CardHeader>
                <Icon><FontAwesomeIcon icon={oneRadioCard.icon} /></Icon>
                <LabelContainer>
                    <H3 lineHeight="0.1rem" color="darkImperialBlue">
                        <FormattedMessage id = {oneRadioCard.label}></FormattedMessage>
                    </H3>
                </LabelContainer>
            </CardHeader>}
            cardFooter={<CardFooter><SubTitleRegular color="cadet"><FormattedMessage id ={oneRadioCard.description}></FormattedMessage></SubTitleRegular></CardFooter>}
            
            onChange={() => {
                input.onChange(oneRadioCard.radioValue);
                setSelectedRadio(oneRadioCard.radioValue);
            }}
            selected={oneRadioCard.radioValue === selectedRadio}
            value={oneRadioCard.radioValue}
        ></SelectorBox>
    ));
};

const TypeSelectorBoxesField = ({ ...props }) => {
    return <Field type="radio" {...props} component={TypeSelectorBoxesInput}></Field>;
};

export default TypeSelectorBoxesField;
