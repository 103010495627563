import { InputAdornment } from "@vapor/react-material";

const proRataOptions = intl => [
    {
        id: "NON_DEDUCTIBLE_VAT_ON_COSTS",
        value: intl.formatMessage({ id: "c-customer-edit-business.proRataManagementType.NON_DEDUCTIBLE_VAT_ON_COSTS" })
    },
    {
        id: "NON_DEDUCTIBLE_VAT_DINSTINCT_FROM_COSTS",
        value: intl.formatMessage({
            id: "c-customer-edit-business.proRataManagementType.NON_DEDUCTIBLE_VAT_DINSTINCT_FROM_COSTS"
        })
    }
];

export const proRataRows = (intl, itemToSave, isEditing) => {
    return [
        [
            {
                component: "radio",
                radioGroupAlign: "vertical",
                label: intl.formatMessage({ id: "c-customer-edit-business.proRata.yearQuestion" }),
                ...(isEditing && !itemToSave?.proRataManagementType && { value: "" }),
                name: "proRataManagementType",
                withMargin: true,
                fullWidth: true,
                style: { minWidth: "30rem" },
                options: proRataOptions(intl)
            }
        ],
        (!isEditing || itemToSave?.proRataManagementType) && [
            {
                component: "textfield",
                ...(!isEditing && {
                    label: intl.formatMessage({ id: "c-customer-edit-business.proRataNonDeducibilityRate" })
                }),
                ...(isEditing && {
                    labelWithTooltip: {
                        label: intl.formatMessage({ id: "c-customer-edit-business.proRataNonDeducibilityRate" }),

                        tooltip: {
                            message: itemToSave?.proRataManagementType
                                ? itemToSave?.proRataManagementType === "NON_DEDUCTIBLE_VAT_ON_COSTS"
                                    ? intl.formatMessage({
                                          id: "c-customer-edit-business.NON_DEDUCTIBLE_VAT_ON_COSTS.Tooltip"
                                      })
                                    : intl.formatMessage({
                                          id: "c-customer-edit-business.NON_DEDUCTIBLE_VAT_DINSTINCT_FROM_COSTS.Tooltip"
                                      })
                                : null,
                            placement: "bottom"
                        }
                    }
                }),
                name: "proRataNonDeducibilityRate",
                fullWidth: true,
                withMargin: true,
                style: { minWidth: "42rem" },
                disabled: itemToSave?.proRataManagementType === "NON_DEDUCTIBLE_VAT_ON_COSTS",
                additionalProps: {
                    sx: { width: "12rem" },
                    InputProps: {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        style: { textAlign: "left", width: "7.5rem" },
                        inputProps: {
                            style: { textAlign: "left", width: "4.5rem" },
                            onChange: e => {
                                const value = e.target.value;
                                if (value && !/^\d+$/.test(value)) {
                                    e.target.value = value.slice(0, -1);
                                }
                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                if (e.target.value > 100) {
                                    e.target.value = 100;
                                }
                                if (e.target.value < 0) {
                                    e.target.value = 0;
                                }
                            }
                        }
                    }
                }
            }
        ]
    ];
};
