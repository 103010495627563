export const provinceValues = [
    { id: "AG", name: "Agrigento" },
    { id: "AL", name: "Alessandria" },
    { id: "AN", name: "Ancona" },
    { id: "AO", name: "Aosta" },
    { id: "AR", name: "Arezzo" },
    { id: "AP", name: "Ascoli Piceno" },
    { id: "AT", name: "Asti" },
    { id: "AV", name: "Avellino" },
    { id: "BA", name: "Bari" },
    { id: "BT", name: "Barletta-Andria-Trani" },
    { id: "BL", name: "Belluno" },
    { id: "BN", name: "Benevento" },
    { id: "BG", name: "Bergamo" },
    { id: "BI", name: "Biella" },
    { id: "BO", name: "Bologna" },
    { id: "BZ", name: "Bolzano" },
    { id: "BS", name: "Brescia" },
    { id: "BR", name: "Brindisi" },
    { id: "CA", name: "Cagliari" },
    { id: "CL", name: "Caltanissetta" },
    { id: "CB", name: "Campobasso" },
    { id: "CI", name: "Carbonia-Iglesias" },
    { id: "CE", name: "Caserta" },
    { id: "CT", name: "Catania" },
    { id: "CZ", name: "Catanzaro" },
    { id: "CH", name: "Chieti" },
    { id: "CO", name: "Como" },
    { id: "CS", name: "Cosenza" },
    { id: "CR", name: "Cremona" },
    { id: "KR", name: "Crotone" },
    { id: "CN", name: "Cuneo" },
    { id: "EN", name: "Enna" },
    { id: "FM", name: "Fermo" },
    { id: "FE", name: "Ferrara" },
    { id: "FI", name: "Firenze" },
    { id: "FG", name: "Foggia" },
    { id: "FC", name: "Forlì-Cesena" },
    { id: "FR", name: "Frosinone" },
    { id: "GE", name: "Genova" },
    { id: "GO", name: "Gorizia" },
    { id: "GR", name: "Grosseto" },
    { id: "IM", name: "Imperia" },
    { id: "IS", name: "Isernia" },
    { id: "SP", name: "La Spezia" },
    { id: "AQ", name: "L'Aquila" },
    { id: "LT", name: "Latina" },
    { id: "LE", name: "Lecce" },
    { id: "LC", name: "Lecco" },
    { id: "LI", name: "Livorno" },
    { id: "LO", name: "Lodi" },
    { id: "LU", name: "Lucca" },
    { id: "MC", name: "Macerata" },
    { id: "MN", name: "Mantova" },
    { id: "MS", name: "Massa-Carrara" },
    { id: "MT", name: "Matera" },
    { id: "ME", name: "Messina" },
    { id: "MI", name: "Milano" },
    { id: "MO", name: "Modena" },
    { id: "MB", name: "Monza e della Brianza" },
    { id: "NA", name: "Napoli" },
    { id: "NO", name: "Novara" },
    { id: "NU", name: "Nuoro" },
    { id: "OT", name: "Olbia-Tempio" },
    { id: "OR", name: "Oristano" },
    { id: "PD", name: "Padova" },
    { id: "PA", name: "Palermo" },
    { id: "PR", name: "Parma" },
    { id: "PV", name: "Pavia" },
    { id: "PG", name: "Perugia" },
    { id: "PU", name: "Pesaro e Urbino" },
    { id: "PE", name: "Pescara" },
    { id: "PC", name: "Piacenza" },
    { id: "PI", name: "Pisa" },
    { id: "PT", name: "Pistoia" },
    { id: "PN", name: "Pordenone" },
    { id: "PZ", name: "Potenza" },
    { id: "PO", name: "Prato" },
    { id: "RG", name: "Ragusa" },
    { id: "RA", name: "Ravenna" },
    { id: "RC", name: "Reggio Calabria" },
    { id: "RE", name: "Reggio Emilia" },
    { id: "RI", name: "Rieti" },
    { id: "RN", name: "Rimini" },
    { id: "RM", name: "Roma" },
    { id: "RO", name: "Rovigo" },
    { id: "SA", name: "Salerno" },
    { id: "VS", name: "Medio Campidano" },
    { id: "SS", name: "Sassari" },
    { id: "SV", name: "Savona" },
    { id: "SI", name: "Siena" },
    { id: "SR", name: "Siracusa" },
    { id: "SO", name: "Sondrio" },
    { id: "TA", name: "Taranto" },
    { id: "TE", name: "Teramo" },
    { id: "TR", name: "Terni" },
    { id: "TO", name: "Torino" },
    { id: "OG", name: "Ogliastra" },
    { id: "TP", name: "Trapani" },
    { id: "TN", name: "Trento" },
    { id: "TV", name: "Treviso" },
    { id: "TS", name: "Trieste" },
    { id: "UD", name: "Udine" },
    { id: "VA", name: "Varese" },
    { id: "VE", name: "Venezia" },
    { id: "VB", name: "Verbano-Cusio-Ossola" },
    { id: "VC", name: "Vercelli" },
    { id: "VR", name: "Verona" },
    { id: "VV", name: "Vibo Valentia" },
    { id: "VI", name: "Vicenza" },
    { id: "VT", name: "Viterbo" }
];

export const legalForms = [
    { id: "SP", value: "SOCIETA' PER AZIONI" },
    { id: "SR", value: "SOCIETA' A RESPONSABILITA' LIMITATA" },
    { id: "AA", value: "SOCIETA' IN ACCOMANDITA PER AZIONI" },
    { id: "AC", value: "ASSOCIAZIONE" },
    { id: "AE", value: "SOCIETA' CONSORTILE IN ACCOMANDITA SEMPLICE" },
    { id: "AF", value: "ALTRE FORME" },
    { id: "AI", value: "ASSOCIAZIONE IMPRESA" },
    { id: "AL", value: "AZIENDA SPECIALE DI ENTE LOCALE" },
    { id: "AM", value: "AZIENDA MUNICIPALE" },
    { id: "AN", value: "SOCIETA' CONSORTILE IN NOME COLLETTIVO" },
    { id: "AP", value: "AZIENDA PROVINCIALE" },
    { id: "AR", value: "AZIENDA REGIONALE" },
    { id: "AS", value: "SOCIETA' IN ACCOMANDITA SEMPLICE" },
    { id: "AT", value: "AZIENDA AUTONOMA STATALE" },
    { id: "AU", value: "SOCIETA' PER AZIONI CON SOCIO UNICO" },
    { id: "AZ", value: "AZIENDA SPECIALE" },
    { id: "CC", value: "CONSORZIO CON ATTIVITA' ESTERNA" },
    { id: "CE", value: "COMUNIONE EREDITARIA" },
    { id: "CF", value: "CONSORZIO FIDI" },
    { id: "CI", value: "SOCIETA' COOPERATIVA A RESPONSABILITA ILLIMITATA" },
    { id: "CL", value: "SOCIETA' COOPERATIVA A RESPONSABILITA LIMITATA" },
    { id: "CM", value: "CONSORZIO MUNICIPALE" },
    { id: "CN", value: "SOCIETA' CONSORTILE" },
    { id: "CO", value: "CONSORZIO" },
    { id: "CR", value: "CONSORZIO INTERCOMUNALE" },
    { id: "CS", value: "CONSORZIO SENZA ATTIVITA' ESTERNA" },
    { id: "CZ", value: "CONSORZIO DI CUI AL DLGS 267/2000" },
    { id: "DI", value: "IMPRESA INDIVIDUALE" },
    { id: "EC", value: "ENTE PUBBLICO COMMERCIALE" },
    { id: "ED", value: "ENTE DIRITTO PUBBLICO" },
    { id: "EE", value: "ENTE ECCLESIASTICO" },
    { id: "EI", value: "ENTE IMPRESA" },
    { id: "EL", value: "ENTE SOCIALE" },
    { id: "EM", value: "ENTE MORALE" },
    { id: "EN", value: "ENTE" },
    { id: "EP", value: "ENTE PUBBLICO ECONOMICO" },
    { id: "ER", value: "ENTE ECCLESIASTICO CIVILMENTE RICONOSCIUTO" },
    { id: "ES", value: "ENTE DI CUI ALLA L.R. 21-12-93" },
    { id: "FI", value: "FONDAZIONE IMPRESA" },
    { id: "FO", value: "FONDAZIONE" },
    { id: "GE", value: "GRUPPO EUROPEO DI INTERESSE ECONOMICO" },
    { id: "IC", value: "ISTITUTO DI CREDITO" },
    { id: "ID", value: "ISTITUTO DI CREDITO DI DIRITTO PUBBLICO" },
    { id: "IF", value: "IMPRESA FAMILIARE" },
    { id: "IR", value: "ISTITUTO RELIGIOSO" },
    { id: "LL", value: "AZIENDA SPECIALE DI CUI AL DLGS 267/2000" },
    { id: "MA", value: "MUTUA ASSICURAZIONE" },
    { id: "OC", value: "SOCIETA' COOPERATIVA CONSORTILE" },
    { id: "OO", value: "COOPERATIVA SOCIALE" },
    { id: "OS", value: "SOCIETA' CONSORTILE COOPERATIVA A RESPONSABILITA' LIMITATA" },
    { id: "PA", value: "ASSOCIAZIONE IN PARTECIPAZIONE" },
    { id: "PC", value: "PICCOLA SOCIETA' COOPERATIVA" },
    { id: "PF", value: "PERSONA FISICA" },
    { id: "PS", value: "PICCOLA SOCIETA' COOPERATIVA A RESPONSABILITA' LIMITATA" },
    { id: "RC", value: "CONTRATTO DI RETE DOTATO DI SOGGETTIVITA' GIURIDICA" },
    { id: "RR", value: "SOCIETA' A RESPONSABILITA' LIMITATA A CAPITALE RIDOTTO" },
    { id: "RS", value: "SOCIETA' A RESPONSABILITA' LIMITATA SEMPLIFICATA" },
    { id: "SA", value: "SOCIETA' ANONIMA" },
    { id: "SC", value: "SOCIETA' COOPERATIVA" },
    { id: "SD", value: "SOCIETA' EUROPEA" },
    { id: "SE", value: "SOCIETA' SEMPLICE" },
    { id: "SF", value: "SOCIETA' DI FATTO" },
    { id: "SG", value: "SOCIETA' COOPERATIVA EUROPEA" },
    { id: "SI", value: "SOCIETA' IRREGOLARE" },
    { id: "SL", value: "SOCIETA' CONSORTILE A RESPONSABILITA' LIMITATA" },
    { id: "SM", value: "SOCIETA' DI MUTUO SOCCORSO" },
    { id: "SN", value: "SOCIETA' IN NOME COLLETTIVO" },
    { id: "SO", value: "SOCIETA' CONSORTILE PER AZIONI" },
    { id: "SS", value: "SOCIETA' COSTITUITA IN BASE A LEGGI DI ALTRO STATO" },
    { id: "ST", value: "SOGGETTO ESTERO" },
    { id: "SU", value: "SOCIETA' A RESPONSABILITA' LIMITATA CON UNICO SOCIO" },
    { id: "SV", value: "SOCIETA' TRA PROFESSIONISTI" },
    { id: "SZ", value: "SOCIETA' NON PREVISTA DALLA LEGISLAZIONE ITALIANA" },
    { id: "XX", value: "NON PRECISATA" }
];

export const companySituations = [
    { id: "TAX_PERIOD_START_OF_LIQUIDATION", value: "Periodo d'imposta inizio liquidazione" },
    { id: "TAX_PERIODS_SUBSEQUENT_BANKRUPTCY", value: "Periodo d'imposta successivi al fallimento" },
    { id: "TAX_PERIOD_END_OF_LIQUIDATION", value: "Periodo d'imposta termine liquidazione" },
    { id: "TAX_PERIOD_EXTINCTION_BY_MERGER", value: "Periodo d'imposta estinzione per fusione" },
    { id: "TAX_PERIOD_TRANSFORMATION_IRES_NON", value: "Periodo d'imposta trasformazione IRES" },
    { id: "NORMAL_TAX_PERIOD", value: "Periodo normale d'imposta" },
    { id: "TAX_PERIOD_EXTINCTION_BY_DIVISION", value: "Periodo d'imposta estinzione per scissione" }
];

export const companyStatuses = [
    { id: "SUBJECT_IN_NORMAL_ACTIVITY", value: "Soggetto in attività" },
    { id: "SUBJECT_IN_LIQUIDATION_FOR_DISCONTINUED_ACTIVITY", value: "Soggetto in liquidazione cessata attività" },
    { id: "SUBJECT_IN_BANKRUPTCY_OR_COMPULSORY_WINDING_UP", value: "Soggetto in fallimento o liquidazione coatta" },
    { id: "EXTINCT_SUBJECT", value: "Soggetto estinto" }
];

export const inpsHeadQuarters = [
    { id: "100", value: "Agrigento" },
    { id: "2791", value: "Alba C.O" },
    { id: "200", value: "Alessandria" },
    { id: "300", value: "Ancona" },
    { id: "901", value: "Andria" },
    { id: "7095", value: "Anzio" },
    { id: "400", value: "Aosta" },
    { id: "500", value: "Arezzo" },
    { id: "890", value: "Ariano Irpino" },
    { id: "600", value: "Ascoli Piceno" },
    { id: "700", value: "Asti" },
    { id: "800", value: "Avellino" },
    { id: "2001", value: "Aversa" },
    { id: "3802", value: "Avezzano" },
    { id: "4892", value: "Barcellona Pozzo di Gotto" },
    { id: "900", value: "Bari" },
    { id: "7202", value: "Battipaglia" },
    { id: "1000", value: "Belluno" },
    { id: "1100", value: "Benevento" },
    { id: "1200", value: "Bergamo" },
    { id: "8901", value: "Biella" },
    { id: "1300", value: "Bologna" },
    { id: "1400", value: "Bolzano" },
    { id: "1500", value: "Brescia" },
    { id: "1600", value: "Brindisi" },
    { id: "8790", value: "Busto" },
    { id: "1700", value: "Cagliari" },
    { id: "2190", value: "Caltagirone" },
    { id: "1800", value: "Caltanissetta" },
    { id: "1900", value: "Campobasso" },
    { id: "2490", value: "Cantù" },
    { id: "4101", value: "Casarano" },
    { id: "2000", value: "Caserta" },
    { id: "3301", value: "Cassino" },
    { id: "5101", value: "Castellammare di Stabia" },
    { id: "2591", value: "Castrovillari" },
    { id: "2100", value: "Catania" },
    { id: "2200", value: "Catanzaro" },
    { id: "8691", value: "Cervignano del Friuli" },
    { id: "3290", value: "Cesena C.O." },
    { id: "1595", value: "Chiari" },
    { id: "8192", value: "Chieri" },
    { id: "2300", value: "Chieti" },
    { id: "8891", value: "Chioggia" },
    { id: "8193", value: "Chivasso" },
    { id: "5801", value: "Citta' di Castello" },
    { id: "5490", value: "Cittadella" },
    { id: "7006", value: "Civitavecchia" },
    { id: "8106", value: "Collegno" },
    { id: "2400", value: "Como" },
    { id: "8490", value: "Conegliano C.O." },
    { id: "2500", value: "Cosenza" },
    { id: "2600", value: "Cremona" },
    { id: "2203", value: "Crotone" },
    { id: "2700", value: "Cuneo" },
    { id: "4995", value: "Desio (C.O.)" },
    { id: "3090", value: "Empoli C.O" },
    { id: "2800", value: "Enna" },
    { id: "5992", value: "Fano" },
    { id: "690", value: "Fermo" },
    { id: "2900", value: "Ferrara" },
    { id: "3000", value: "Firenze" },
    { id: "3100", value: "Foggia" },
    { id: "5890", value: "Foligno" },
    { id: "3200", value: "Forli'" },
    { id: "3300", value: "Frosinone" },
    { id: "3400", value: "Genova" },
    { id: "993", value: "Gioia del Colle" },
    { id: "5199", value: "Giugliano" },
    { id: "3500", value: "Gorizia" },
    { id: "3600", value: "Grosseto" },
    { id: "7074", value: "Grottaferrata" },
    { id: "6891", value: "Guastalla" },
    { id: "7099", value: "Guidonia" },
    { id: "1701", value: "Iglesias" },
    { id: "1301", value: "Imola" },
    { id: "3700", value: "Imperia" },
    { id: "9400", value: "Isernia" },
    { id: "8102", value: "Ivrea" },
    { id: "391", value: "Jesi" },
    { id: "3900", value: "La Spezia" },
    { id: "2201", value: "Lamezia Terme" },
    { id: "3800", value: "L'Aquila" },
    { id: "4000", value: "Latina" },
    { id: "4100", value: "Lecce" },
    { id: "2401", value: "Lecco" },
    { id: "9090", value: "Legnago" },
    { id: "4200", value: "Livorno" },
    { id: "6791", value: "Locri" },
    { id: "4927", value: "Lodi" },
    { id: "4300", value: "Lucca" },
    { id: "6691", value: "Lugo" },
    { id: "4400", value: "Macerata" },
    { id: "7890", value: "Manduria" },
    { id: "1594", value: "Manerbio" },
    { id: "4500", value: "Mantova" },
    { id: "4600", value: "Massa Carrara" },
    { id: "4700", value: "Matera" },
    { id: "4991", value: "Melegnano" },
    { id: "6490", value: "Melfi" },
    { id: "1491", value: "Merano" },
    { id: "4800", value: "Messina" },
    { id: "8894", value: "Mestre1" },
    { id: "4900", value: "Milano" },
    { id: "4905", value: "Milano Corvetto" },
    { id: "4904", value: "Milano Fiori" },
    { id: "4908", value: "Milano Legnano" },
    { id: "4903", value: "Milano Missori" },
    { id: "4902", value: "Milano Nord" },
    { id: "4909", value: "Milano Seregno (C.O.)" },
    { id: "5000", value: "Modena" },
    { id: "6590", value: "Modica" },
    { id: "8101", value: "Moncalieri" },
    { id: "2792", value: "Mondovì" },
    { id: "7094", value: "Monterotondo" },
    { id: "4901", value: "Monza" },
    { id: "5100", value: "Napoli" },
    { id: "5103", value: "Napoli Arzano" },
    { id: "5104", value: "Napoli Soccavo" },
    { id: "5105", value: "Napoli Vomero" },
    { id: "4190", value: "Nardò" },
    { id: "7201", value: "Nocera Inferiore" },
    { id: "5102", value: "Nola" },
    { id: "7601", value: "Noto" },
    { id: "5200", value: "Novara" },
    { id: "5300", value: "Nuoro" },
    { id: "7390", value: "Olbia" },
    { id: "8191", value: "Orbassano" },
    { id: "9500", value: "Oristano" },
    { id: "5400", value: "Padova" },
    { id: "5500", value: "Palermo" },
    { id: "5502", value: "Palermo Sud Ovest" },
    { id: "7096", value: "Palestrina" },
    { id: "6790", value: "Palmi" },
    { id: "2590", value: "Paola" },
    { id: "5600", value: "Parma" },
    { id: "5700", value: "Pavia" },
    { id: "5800", value: "Perugia" },
    { id: "5900", value: "Pesaro" },
    { id: "6000", value: "Pescara" },
    { id: "6100", value: "Piacenza" },
    { id: "8105", value: "Pinerolo" },
    { id: "4201", value: "Piombino" },
    { id: "6200", value: "Pisa" },
    { id: "6300", value: "Pistoia" },
    { id: "7015", value: "Pomezia" },
    { id: "9300", value: "Pordenone" },
    { id: "6400", value: "Potenza" },
    { id: "5106", value: "Pozzuoli" },
    { id: "3001", value: "Prato" },
    { id: "1791", value: "Quartu Sant'Elena" },
    { id: "6500", value: "Ragusa" },
    { id: "6600", value: "Ravenna" },
    { id: "6700", value: "Reggio Calabria" },
    { id: "6800", value: "Reggio Emilia" },
    { id: "6900", value: "Rieti" },
    { id: "3201", value: "Rimini" },
    { id: "7000", value: "Roma" },
    { id: "7012", value: "Roma Aurelio" },
    { id: "7009", value: "Roma Casilino Prenestino" },
    { id: "7005", value: "Roma Centro" },
    { id: "7001", value: "Roma Eur" },
    { id: "7014", value: "Roma Flaminio" },
    { id: "7004", value: "Roma Montesacro" },
    { id: "7002", value: "Roma Monteverde" },
    { id: "7003", value: "Roma Ostia Lido" },
    { id: "7013", value: "Roma Tiburtino" },
    { id: "7010", value: "Roma Tuscolano" },
    { id: "2501", value: "Rossano Calabro" },
    { id: "7100", value: "Rovigo" },
    { id: "7200", value: "Salerno" },
    { id: "8801", value: "San Dona' di Piave" },
    { id: "1393", value: "San Lazzaro di Savena" },
    { id: "3191", value: "San Severo" },
    { id: "4891", value: "Santa Teresa di Riva" },
    { id: "7300", value: "Sassari" },
    { id: "5094", value: "Sassuolo" },
    { id: "7400", value: "Savona" },
    { id: "3093", value: "Scandicci" },
    { id: "101", value: "Sciacca" },
    { id: "3094", value: "Sesto Fiorentino" },
    { id: "4955", value: "Sesto San Giovanni" },
    { id: "3401", value: "Sestri Levante" },
    { id: "3402", value: "Sestri Ponente" },
    { id: "7500", value: "Siena" },
    { id: "7600", value: "Siracusa" },
    { id: "7700", value: "Sondrio" },
    { id: "3801", value: "Sulmona" },
    { id: "7800", value: "Taranto" },
    { id: "7900", value: "Teramo" },
    { id: "1990", value: "Termoli" },
    { id: "8000", value: "Terni" },
    { id: "4091", value: "Terracina" },
    { id: "7091", value: "Tivoli" },
    { id: "8100", value: "Torino" },
    { id: "8103", value: "Torino Nord" },
    { id: "8200", value: "Trapani" },
    { id: "8300", value: "Trento" },
    { id: "8400", value: "Treviso" },
    { id: "8500", value: "Trieste" },
    { id: "8600", value: "Udine" },
    { id: "5290", value: "V.C.O. - Verbano Cusio Ossola" },
    { id: "8700", value: "Varese" },
    { id: "2391", value: "Vasto" },
    { id: "7092", value: "Velletri" },
    { id: "8800", value: "Venezia" },
    { id: "8900", value: "Vercelli" },
    { id: "9000", value: "Verona" },
    { id: "2202", value: "Vibo Valenzia" },
    { id: "9100", value: "Vicenza" },
    { id: "5791", value: "Vigevano" },
    { id: "4979", value: "Vimercate" },
    { id: "9200", value: "Viterbo" }
];

export const foreignStateOfResidences = [
    { id: "AF", value: "Afghanistan" },
    { id: "AL", value: "Albania" },
    { id: "DZ", value: "Algeria" },
    { id: "AD", value: "Andorra" },
    { id: "AO", value: "Angola" },
    { id: "AI", value: "Anguilla" },
    { id: "AQ", value: "Antartide" },
    { id: "AG", value: "Antigua e Barbuda" },
    { id: "AN", value: "Antille Olandesi" },
    { id: "SA", value: "Arabia Saudita" },
    { id: "AR", value: "Argentina" },
    { id: "AM", value: "Armenia" },
    { id: "AW", value: "Aruba" },
    { id: "AU", value: "Australia" },
    { id: "AT", value: "Austria" },
    { id: "AZ", value: "Azerbaigian" },
    { id: "BS", value: "Bahamas" },
    { id: "BH", value: "Bahrain" },
    { id: "BD", value: "Bangladesh" },
    { id: "BB", value: "Barbados" },
    { id: "BE", value: "Belgio" },
    { id: "BZ", value: "Belize" },
    { id: "BJ", value: "Benin" },
    { id: "BM", value: "Bermuda" },
    { id: "BT", value: "Bhutan" },
    { id: "BY", value: "Bielorussia" },
    { id: "MM", value: "Birmania" },
    { id: "BO", value: "Bolivia" },
    { id: "BA", value: "Bosnia-Erzegovina" },
    { id: "BW", value: "Botswana" },
    { id: "BR", value: "Brasile" },
    { id: "BN", value: "Brunei" },
    { id: "BG", value: "Bulgaria" },
    { id: "BF", value: "Burkina Faso" },
    { id: "BI", value: "Burundi" },
    { id: "KH", value: "Cambogia" },
    { id: "CM", value: "Camerun" },
    { id: "CA", value: "Canada" },
    { id: "CV", value: "Capo Verde" },
    { id: "TD", value: "Ciad" },
    { id: "CL", value: "Cile" },
    { id: "CN", value: "Cina" },
    { id: "CY", value: "Cipro" },
    { id: "VA", value: "Città del Vaticano" },
    { id: "CO", value: "Colombia" },
    { id: "KM", value: "Comore" },
    { id: "KP", value: "Corea del Nord" },
    { id: "KR", value: "Corea del Sud" },
    { id: "CI", value: "Costa d'Avorio" },
    { id: "CR", value: "Costa Rica" },
    { id: "CU", value: "Cuba" },
    { id: "DK", value: "Danimarca" },
    { id: "DM", value: "Dominica" },
    { id: "EC", value: "Ecuador" },
    { id: "EG", value: "Egitto" },
    { id: "SV", value: "El Salvador" },
    { id: "AE", value: "Emirati Arabi Uniti" },
    { id: "ER", value: "Eritrea" },
    { id: "EE", value: "Estonia" },
    { id: "ET", value: "Etiopia" },
    { id: "FJ", value: "Figi" },
    { id: "PH", value: "Filippine" },
    { id: "FI", value: "Finlandia" },
    { id: "FR", value: "Francia" },
    { id: "GA", value: "Gabon" },
    { id: "GM", value: "Gambia" },
    { id: "GE", value: "Georgia" },
    { id: "GS", value: "Georgia del Sud e isole Sandwich meridionali" },
    { id: "DE", value: "Germania" },
    { id: "GH", value: "Ghana" },
    { id: "JM", value: "Giamaica" },
    { id: "JP", value: "Giappone" },
    { id: "GI", value: "Gibilterra" },
    { id: "DJ", value: "Gibuti" },
    { id: "JO", value: "Giordania" },
    { id: "EL GR", value: "Grecia" },
    { id: "GD", value: "Grenada" },
    { id: "GL", value: "Groenlandia" },
    { id: "GP", value: "Guadalupa" },
    { id: "GU", value: "Guam" },
    { id: "GT", value: "Guatemala" },
    { id: "GG", value: "Guernsey" },
    { id: "GN", value: "Guinea" },
    { id: "GQ", value: "Guinea Equatoriale" },
    { id: "GW", value: "Guinea-Bissau" },
    { id: "GY", value: "Guyana" },
    { id: "GF", value: "Guyana Francese" },
    { id: "HT", value: "Haiti" },
    { id: "HN", value: "Honduras" },
    { id: "HK", value: "Hong Kong" },
    { id: "IN", value: "India" },
    { id: "ID", value: "Indonesia" },
    { id: "IR", value: "Iran" },
    { id: "IQ", value: "Iraq" },
    { id: "IE", value: "Irlanda" },
    { id: "IS", value: "Islanda" },
    { id: "BV", value: "Isola Bouvet" },
    { id: "CX", value: "Isola Christmas" },
    { id: "IM", value: "Isola di Man" },
    { id: "NF", value: "Isola Norfolk" },
    { id: "AX", value: "Isole Åland" },
    { id: "KY", value: "Isole Cayman" },
    { id: "CC", value: "Isole Cocos" },
    { id: "CK", value: "Isole Cook" },
    { id: "FO", value: "Isole Fær Øer" },
    { id: "FK", value: "Isole Falkland" },
    { id: "HM", value: "Isole Heard e McDonald" },
    { id: "MP", value: "Isole Marianne Settentrionali" },
    { id: "MH", value: "Isole Marshall" },
    { id: "UM", value: "Isole minori esterne degli Stati Uniti" },
    { id: "PN", value: "Isole Pitcairn" },
    { id: "SB", value: "Isole Salomone" },
    { id: "TC", value: "Isole Turks e Caicos" },
    { id: "VI", value: "Isole Vergini Americane" },
    { id: "VG", value: "Isole Vergini Britanniche" },
    { id: "IL", value: "Israele" },
    { id: "IT", value: "Italia" },
    { id: "JE", value: "Jersey" },
    { id: "KZ", value: "Kazakistan" },
    { id: "KE", value: "Kenya" },
    { id: "KG", value: "Kirghizistan" },
    { id: "KI", value: "Kiribati" },
    { id: "KW", value: "Kuwait" },
    { id: "LA", value: "Laos" },
    { id: "LS", value: "Lesotho" },
    { id: "LV", value: "Lettonia" },
    { id: "LB", value: "Libano" },
    { id: "LR", value: "Liberia" },
    { id: "LY", value: "Libia" },
    { id: "LI", value: "Liechtenstein" },
    { id: "LT", value: "Lituania" },
    { id: "LU", value: "Lussemburgo" },
    { id: "MO", value: "Macao" },
    { id: "MK", value: "Macedonia" },
    { id: "MG", value: "Madagascar" },
    { id: "MW", value: "Malawi" },
    { id: "MV", value: "Maldive" },
    { id: "MY", value: "Malesia" },
    { id: "ML", value: "Mali" },
    { id: "MT", value: "Malta" },
    { id: "MA", value: "Marocco" },
    { id: "MQ", value: "Martinica" },
    { id: "MR", value: "Mauritania" },
    { id: "MU", value: "Mauritius" },
    { id: "YT", value: "Mayotte" },
    { id: "MX", value: "Messico" },
    { id: "MD", value: "Moldavia" },
    { id: "MC", value: "Monaco" },
    { id: "MN", value: "Mongolia" },
    { id: "ME", value: "Montenegro" },
    { id: "MS", value: "Montserrat" },
    { id: "MZ", value: "Mozambico" },
    { id: "NA", value: "Namibia" },
    { id: "NR", value: "Nauru" },
    { id: "NP", value: "Nepal" },
    { id: "NI", value: "Nicaragua" },
    { id: "NE", value: "Niger" },
    { id: "NG", value: "Nigeria" },
    { id: "NU", value: "Niue" },
    { id: "NO", value: "Norvegia" },
    { id: "NC", value: "Nuova Caledonia" },
    { id: "NZ", value: "Nuova Zelanda" },
    { id: "NL", value: "Olanda" },
    { id: "OM", value: "Oman" },
    { id: "PK", value: "Pakistan" },
    { id: "PW", value: "Palau" },
    { id: "PA", value: "Panamá" },
    { id: "PG", value: "Papua Nuova Guinea" },
    { id: "PY", value: "Paraguay" },
    { id: "PE", value: "Perù" },
    { id: "PF", value: "Polinesia Francese" },
    { id: "PL", value: "Polonia" },
    { id: "PR", value: "Porto Rico" },
    { id: "PT", value: "Portogallo" },
    { id: "QA", value: "Qatar" },
    { id: "GB", value: "Regno Unito" },
    { id: "CZ", value: "Repubblica Ceca" },
    { id: "CF", value: "Repubblica Centrafricana" },
    { id: "CG", value: "Repubblica del Congo" },
    { id: "CD", value: "Repubblica Democratica del Congo" },
    { id: "TW", value: "Repubblica di Cina" },
    { id: "DO", value: "Repubblica Dominicana" },
    { id: "RE", value: "Réunion" },
    { id: "RO", value: "Romania" },
    { id: "RW", value: "Ruanda" },
    { id: "RU", value: "Russia" },
    { id: "EH", value: "Sahara Occidentale" },
    { id: "KN", value: "Saint Kitts e Nevis" },
    { id: "PM", value: "Saint Pierre e Miquelon" },
    { id: "VC", value: "Saint Vincent e Grenadine" },
    { id: "BL", value: "Saint-Barthélemy" },
    { id: "MF", value: "Saint-Martin" },
    { id: "WS", value: "Samoa" },
    { id: "AS", value: "Samoa Americane" },
    { id: "SM", value: "San Marino" },
    { id: "LC", value: "Santa Lucia" },
    { id: "SH", value: "Sant'Elena" },
    { id: "ST", value: "São Tomé e Príncipe" },
    { id: "SN", value: "Senegal" },
    { id: "RS", value: "Serbia" },
    { id: "SC", value: "Seychelles" },
    { id: "SL", value: "Sierra Leone" },
    { id: "SG", value: "Singapore" },
    { id: "SY", value: "Siria" },
    { id: "SK", value: "Slovacchia" },
    { id: "SI", value: "Slovenia" },
    { id: "SO", value: "Somalia" },
    { id: "ES", value: "Spagna" },
    { id: "LK", value: "Sri Lanka" },
    { id: "FM", value: "Stati Federati di Micronesia" },
    { id: "US", value: "Stati Uniti d'America" },
    { id: "ZA", value: "Sudafrica" },
    { id: "SD", value: "Sudan" },
    { id: "SR", value: "Suriname" },
    { id: "SJ", value: "Svalbard" },
    { id: "SE", value: "Svezia" },
    { id: "CH", value: "Svizzera" },
    { id: "SZ", value: "Swaziland" },
    { id: "TJ", value: "Tagikistan" },
    { id: "TZ", value: "Tanzania" },
    { id: "IO", value: "Territori Britannici dell'Oceano Indiano" },
    { id: "TF", value: "Territori Francesi del Sud" },
    { id: "PS", value: "Territori Palestinesi Occupati" },
    { id: "TH", value: "Thailandia" },
    { id: "TL", value: "Timor Est" },
    { id: "TG", value: "Togo" },
    { id: "TK", value: "Tokelau" },
    { id: "TO", value: "Tonga" },
    { id: "TT", value: "Trinidad e Tobago" },
    { id: "TN", value: "Tunisia" },
    { id: "TR", value: "Turchia" },
    { id: "TM", value: "Turkmenistan" },
    { id: "TV", value: "Tuvalu" },
    { id: "UA", value: "Ucraina" },
    { id: "UG", value: "Uganda" },
    { id: "HU", value: "Ungheria" },
    { id: "UY", value: "Uruguay" },
    { id: "UZ", value: "Uzbekistan" },
    { id: "VU", value: "Vanuatu" },
    { id: "VE", value: "Venezuela" },
    { id: "VN", value: "Vietnam" },
    { id: "WF", value: "Wallis e Futuna" },
    { id: "YE", value: "Yemen" },
    { id: "ZM", value: "Zambia" },
    { id: "ZW", value: "Zimbabwe" }
];

export const days = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 11, value: 11 },
    { id: 12, value: 12 },
    { id: 13, value: 13 },
    { id: 14, value: 14 },
    { id: 15, value: 15 },
    { id: 16, value: 16 },
    { id: 17, value: 17 },
    { id: 18, value: 18 },
    { id: 19, value: 19 },
    { id: 20, value: 20 },
    { id: 21, value: 21 },
    { id: 22, value: 22 },
    { id: 23, value: 23 },
    { id: 24, value: 24 },
    { id: 25, value: 25 },
    { id: 26, value: 26 },
    { id: 27, value: 27 },
    { id: 28, value: 28 },
    { id: 29, value: 29 },
    { id: 30, value: 30 },
    { id: 31, value: 31 }
];

export const months = [
    { id: 1, value: "JAN" },
    { id: 2, value: "FEB" },
    { id: 3, value: "MAR" },
    { id: 4, value: "APR" },
    { id: 5, value: "MAY" },
    { id: 6, value: "JUN" },
    { id: 7, value: "JUL" },
    { id: 8, value: "AUG" },
    { id: 9, value: "SEP" },
    { id: 10, value: "OCT" },
    { id: 11, value: "NOV" },
    { id: 12, value: "DEC" }
];
