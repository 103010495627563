import {
    GET_ARCHIVE_INFO_START,
    GET_ARCHIVE_INFO_SUCCESS,
    GET_ARCHIVE_INFO_ERROR,
    GET_ARCHIVE_INFO_RESET
} from "../actions/archive";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function archiveInfo(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_ARCHIVE_INFO_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_ARCHIVE_INFO_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                info: payload
            };
        case GET_ARCHIVE_INFO_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case GET_ARCHIVE_INFO_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
