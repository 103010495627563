import React, { useEffect, useState } from "react";

import { Sections, SpinnerContainer } from "./styled";
import CustomerRegistryEditForm from "./customer-edit-registry-form";
import { useDispatch } from "react-redux";
import { updateCustomerMainData } from "../../../actions/customerUpdate";
import { Spinner } from "../../vapor-components/spinner";
import { settings } from "./settings";
import CustomerEditRegistryHeader from "./customer-edit-registry-header";

const CustomerEditRegistry = ({
    customerDetails,
    differentByClassifier,
    isSavingData,
    render,
    section,
    subSection
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState(null);
    const [bodyRequest, setBodyRequest] = useState({ [subSection]: {} });
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const fromApiToDate = date => {
        if (!date || new Date(date) < 0 || (typeof date === "string" && date.indexOf("-") === -1)) {
            return null;
        }
        try {
            return new Date(date);
        } catch (e) {
            return null;
        }
    };

    // used to update bodyRequest
    useEffect(() => {
        let newBodyRequest = { [subSection]: {} };

        let dateFields = settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)?.[
            section
        ]?.[customerDetails?.classifier]?.dateFields;
        let fieldsToCheck =
            settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)?.[section]?.[
                customerDetails?.classifier
            ]?.fields || [];
        if (!differentByClassifier) {
            dateFields = settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)?.[section]
                ?.dateFields;
            fieldsToCheck =
                settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)?.[section]
                    ?.fields || [];
        }
        if (fieldsToCheck) {
            fieldsToCheck?.map(field => {
                if (customerDetails[subSection]?.[field] !== undefined) {
                    newBodyRequest[subSection][field] =
                        dateFields?.indexOf(field) > -1
                            ? customerDetails[subSection]?.[field]
                                ? fromApiToDate(customerDetails[subSection]?.[field])
                                : null
                            : customerDetails[subSection]?.[field];
                }
                return null;
            });
        }
        setBodyRequest(newBodyRequest);
    }, [customerDetails, section, subSection, differentByClassifier, isEditing]);

    // on section change reset alert and set editing to false
    useEffect(() => {
        setIsEditing(false);
        setAlert(null);
    }, [section]);

    const onSave = async () => {
        const additionalParams = {};
        let dateFields = settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)[
            customerDetails?.classifier
        ]?.dateFields;
        let fieldsToCheck = settings(customerDetails?.classifier, customerDetails?.personalData?.taxIdentifier)[
            customerDetails?.classifier
        ]?.fields;
        fieldsToCheck?.map(field => {
            if (bodyRequest[subSection]?.[field] && dateFields?.indexOf(field) > -1) {
                let tmpDate = new Date(bodyRequest[subSection]?.[field]);
                tmpDate.setHours(tmpDate.getHours() + 10);
                additionalParams[field] = tmpDate.toISOString();
            }
            return null;
        });

        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsEditing(false);
        await dispatch(
            updateCustomerMainData(
                customerDetails.id,
                {
                    ...bodyRequest,
                    [subSection]: { ...bodyRequest[subSection], ...additionalParams }
                },
                subSection
            )
        )
            .then(res => {
                setAlert(`${subSection}-success`);
            })
            .catch(e => {
                setAlert(`${subSection}-error`);
            });
    };

    if (!render || !customerDetails) {
        return null;
    }

    return (
        <Sections>
            <CustomerEditRegistryHeader customerDetails={customerDetails} section={section} subSection={subSection} />
            {!isEditing && isSavingData && (
                <SpinnerContainer>
                    <Spinner loading />
                </SpinnerContainer>
            )}
            {(isEditing || (!isEditing && !isSavingData)) && (
                <CustomerRegistryEditForm
                    bodyRequest={bodyRequest}
                    errors={errors}
                    setBodyRequest={setBodyRequest}
                    setErrors={setErrors}
                    customerDetails={customerDetails}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    onSave={onSave}
                    alert={alert}
                    setAlert={setAlert}
                    section={section}
                    subSection={subSection}
                    differentByClassifier={differentByClassifier}
                />
            )}
        </Sections>
    );
};

export default CustomerEditRegistry;
