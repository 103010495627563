import axios from "axios";
import cuid from "cuid";
import { v4 as uuid } from "uuid";
import get from "lodash/get";
import isNil from "lodash/isNil";
import qs from "qs";
import { API_URL } from "../../config";

const pkg = require("../../../package.json");

export class AxiosFactory {
    axiosInstance = null;
    getNewAccessToken = () => {};

    createAxiosInstance() {
        if (this.axiosInstance) return this.axiosInstance;

        const axiosInstance = axios.create({
            baseURL: API_URL,
            withCredentials: true,
            headers: {
                "x-app-name": pkg.name,
                "x-app-version": pkg.version,
                "x-request-id": cuid(),
                "x-correlation-id": uuid()
            },
            paramsSerializer: qs.stringify
        });
        this.addAccessTokenRefreshingInterceptor(axiosInstance);
        this.axiosInstance = axiosInstance;
        return axiosInstance;
    }

    setGetNewAccessToken(getNewAccessToken) {
        this.getNewAccessToken = getNewAccessToken;
    }

    setAccessToken(accessToken) {
        this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    addAccessTokenRefreshingInterceptor(axiosInstance) {
        /**
         * funzione di retry in caso il token non sia ancora stato initizializzato nell'instanza di axios
         *
         * ATTENZIONE:
         * la gestione del tsid non è ancora stata implementata
         */
        axiosInstance.interceptors.response.use(undefined, async data => {
            const { config } = data;
            if (!axiosInstance.defaults.headers.common.Authorization) {
                const newAccessToken = await this.getNewAccessToken();
                // Otherwise, get a new access token and retry the request
                this.setAccessToken(newAccessToken);

                axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

                return await axios.request({
                    ...config,
                    headers: {
                        ...config.headers,
                        Authorization: `Bearer ${newAccessToken}`
                    },
                    // Mark the request as a retry request
                    isRetry: true
                });
            } else {
                // Re-throw the error if either:
                //   - it doesn't have a request config object that can be used to
                //     retry the request
                //   - it's not a 401 error
                //   - it's an error to a retried request
                //TODO è necessario utilizzare axios per farlo?
                if (isNil(config) || get(data, "response.status") !== 401 || get(data, "config.isRetry")) {
                    throw data;
                }
                // Otherwise, get a new access token and retry the request
                const newAccessToken = await this.getNewAccessToken();

                this.setAccessToken(newAccessToken);
                // Update the token for other requests
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
                return await axios.request({
                    ...config,
                    headers: {
                        ...config.headers,
                        Authorization: `Bearer ${newAccessToken}`
                    },
                    // Mark the request as a retry request
                    isRetry: true
                });
            }
        });
    }
}

const axiosFactory = new AxiosFactory();
axiosFactory.createAxiosInstance();

export { axiosFactory };
