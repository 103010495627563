import customerManagementApiClient from "../apiClient";

export const GET_CUSTOMER_START = "GET_CUSTOMER_START";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const GET_CUSTOMER_RESET = "GET_CUSTOMER_RESET";
export const GET_SPOTLIGHT_CUSTOMERS_START = "GET_SPOTLIGHT_CUSTOMERS_START";
export const GET_SPOTLIGHT_CUSTOMERS_SUCCESS = "GET_SPOTLIGHT_CUSTOMERS_SUCCESS";
export const GET_SPOTLIGHT_CUSTOMERS_ERROR = "GET_SPOTLIGHT_CUSTOMERS_ERROR";
export const GET_SPOTLIGHT_CUSTOMERS_RESET = "GET_SPOTLIGHT_CUSTOMERS_RESET";
export const GET_CUSTOMER_DETAIL_START = "GET_CUSTOMER_DETAIL_START";
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS";
export const GET_CUSTOMER_DETAIL_ERROR = "GET_CUSTOMER_DETAIL_ERROR";
export const GET_CUSTOMER_DETAIL_RESET = "GET_CUSTOMER_DETAIL_RESET";

export function getSpotlightCustomers(params) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_SPOTLIGHT_CUSTOMERS_START,
            payload: params
        });

        try {
            const res = await new customerManagementApiClient().getSpotlightCustomers({
                ...params
            });

            dispatch({
                type: GET_SPOTLIGHT_CUSTOMERS_SUCCESS,
                payload: {
                    items: res.results,
                    pagination: res.pageInfo
                }
            });

            return getState()?.customers;
        } catch (e) {
            dispatch({
                type: GET_SPOTLIGHT_CUSTOMERS_ERROR,
                payload: { e }
            });
        }
    };
}

export const resetSpotlightCustomers = () => dispatch => {
    dispatch({
        type: GET_SPOTLIGHT_CUSTOMERS_RESET
    });
};

export function getCustomers(ownerId, page, size) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_START,
            payload: { ownerId, page, size }
        });

        try {
            const res = await new customerManagementApiClient().getCustomers({
                ownerId,
                page,
                size
            });

            dispatch({
                type: GET_CUSTOMER_SUCCESS,
                payload: {
                    items: res.items,
                    pagination: res.pagination
                }
            });

            return getState()?.customers;
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_ERROR,
                payload: { e }
            });
        }
    };
}

export function getCustomerDetails(ownerId, workspaceId, validityFrom) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_DETAIL_START,
            payload: { ownerId, workspaceId, validityFrom }
        });

        try {
            const res = await new customerManagementApiClient().getCustomerDetail(ownerId, workspaceId, validityFrom);

            dispatch({
                type: GET_CUSTOMER_DETAIL_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_DETAIL_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetGetCustomers() {
    return dispatch => {
        dispatch({
            type: GET_CUSTOMER_RESET
        });
    };
}

export function resetGetCustomerDetails() {
    return dispatch => {
        dispatch({
            type: GET_CUSTOMER_DETAIL_RESET
        });
    };
}
