import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { useIntl } from "react-intl";
import PageTitle from "../../components/vapor-components/page-title";
import NewClientForm from "../../components/new-client-form";
import { createNewClient, resetCreateNewClient } from "../../actions/newclient";
import { generateAda, sendAda } from "../../actions/ada";
import { Container } from "./styled";
import { StepContainer } from "../../components/new-client-form/new-client-form-wrapper/styled";
import { steps } from "../../components/new-client-form/new-client-form-wrapper/constants";
import { Step, StepLabel, Stepper } from "@vapor/react-material";

const CreateNewClientView = ({
    adaSendState,
    adaGenerateState,
    authData,
    createNewClient,
    createNewClientState,
    generateAda,
    resetCreateNewClient,
    sendAda
}) => {
    const navigate = useNavigate();
    const [stepNumber, setStepNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [retryModalVisible, setRetryModalVisible] = useState(false);
    const [forwardModalVisible, setForwardModalVisible] = useState(false);
    const [numberRetry, setNumberRetry] = useState(0);
    const [formParams, setFormParams] = useState({});
    const [searchOrCreateStep, setSearchOrCreateStep] = useState("search");
    const [formParamsCerved, setFormParamsCerved] = useState({});
    const intl = useIntl();

    useEffect(() => {
        if (createNewClientState.status.ended && !createNewClientState.status.error && stepNumber === 2) {
            setStepNumber(3);
            resetCreateNewClient();
        }
    }, [
        createNewClientState.status.ended,
        createNewClientState.status.error,
        navigate,
        resetCreateNewClient,
        stepNumber
    ]);

    useEffect(() => {
        if (createNewClientState.status.error && stepNumber === 2) {
            if (createNewClientState?.status?.errorInfo?.error?.type === "workspace") {
                setErrorMessage(createNewClientState?.status?.errorInfo?.error?.message);
            } else if (createNewClientState?.status?.errorInfo?.error?.type === "headquarter" && numberRetry === 0) {
                //OPEN MODAL FOR SAVING HEADQUARTER RETRY
                setRetryModalVisible(true);
                setNumberRetry(1);
                //OPEN MODAL TO GO TO THE LAST STEP ANYWAY IF THE SAVING OF THE HEADQUARTER STILL DOESN'T WORK AT THE SECOND RETRY
            } else if (createNewClientState?.status?.errorInfo?.error?.type === "headquarter" && numberRetry === 1) {
                setRetryModalVisible(false);
                setForwardModalVisible(true);
                setNumberRetry(0);
            }
        }
        //IF THE RETRY GOES TO A GOOD END, GO TO THE FINAL STEP
        else if (
            createNewClientState.status.ended &&
            !createNewClientState?.status?.error &&
            stepNumber === 2 &&
            numberRetry === 1
        ) {
            setRetryModalVisible(false);
            setForwardModalVisible(false);
            setNumberRetry(0);
            setStepNumber(stepNumber + 1);
        }
        // eslint-disable-next-line
    }, [createNewClientState.status, stepNumber]);

    const generateAdaCallback = useCallback(
        params => {
            generateAda(params);
        },
        [generateAda]
    );

    const sendAdaCallback = useCallback(
        params => {
            sendAda(params);
        },
        [sendAda]
    );

    return (
        <Container>
            <PageTitle
                backButtonTitle={intl.formatMessage({
                    id: "v-customer-dashboard.back-button-label"
                })}
                title={intl.formatMessage({
                    id: "c-new-registry.pageTitle"
                })}
                subtitle={intl.formatMessage({
                    id: "c-new-registry.pageSubTitle"
                })}
                onBack={() => navigate("/")}
            ></PageTitle>

            <StepContainer>
                <Stepper activeStep={stepNumber} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={`step-create-customer-${index}`}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </StepContainer>
            <NewClientForm
                onSubmit={params => {
                    setFormParams(params);
                    if (params["address"]) {
                        params["headquarterType"] = "REGISTERED_OFFICE";
                    }
                    if (
                        stepNumber === 1 &&
                        (params["classifier"] === "PERSON" || params["classifier"] === "BUILDING")
                    ) {
                        setStepNumber(stepNumber + 1);
                        setSearchOrCreateStep("create");
                    } else if (
                        stepNumber === 1 &&
                        params["classifier"] !== "PERSON" &&
                        params["classifier"] !== "BUILDING"
                    ) {
                        setStepNumber(stepNumber + 1);
                        setSearchOrCreateStep("search");
                    } else if (stepNumber !== 2) {
                        setStepNumber(stepNumber + 1);
                    } else {
                        createNewClient(authData.item.id, params);
                    }
                }}
                createNewClientStatus={createNewClientState.status}
                adaSendStatus={adaSendState.status}
                adaGenerateStatus={adaGenerateState.status}
                onGenerateAda={generateAdaCallback}
                onSendAda={sendAdaCallback}
                stepNumber={stepNumber}
                setStepNumber={setStepNumber}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                retryModalVisible={retryModalVisible}
                setRetryModalVisible={setRetryModalVisible}
                forwardModalVisible={forwardModalVisible}
                setForwardModalVisible={setForwardModalVisible}
                formParams={formParams}
                formParamsCerved={formParamsCerved}
                setFormParamsCerved={setFormParamsCerved}
                setSearchOrCreateStep={setSearchOrCreateStep}
                searchOrCreateStep={searchOrCreateStep}
                taxRegion={authData?.item?.taxRegion}
            />
        </Container>
    );
};

function mapStateToProps(state, props) {
    return {
        createNewClientState: state.newClient,
        adaSendState: state.adaSend,
        adaGenerateState: state.adaGenerate,
        authData: state.initDataFromParent.auth
    };
}

const actions = {
    generateAda,
    sendAda,
    createNewClient,
    resetCreateNewClient
};

const composedHoc = compose(connect(mapStateToProps, actions));

export default composedHoc(CreateNewClientView);
