import React from "react";
import styled from "styled-components";
import { LoadingElement } from "../../vapor-components/loading";
import CustomerHeaderData from "./customer-header-data";

const CustomerHeaderContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
`;

const CustomerHeader = ({ customer, pending }) => {
    return (
        <CustomerHeaderContainer>
            {pending ? <LoadingElement width="200px" height="96px" /> : <CustomerHeaderData customer={customer} />}
        </CustomerHeaderContainer>
    );
};

export default CustomerHeader;
