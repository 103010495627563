import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Title = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 0.875rem;
    margin-top: 1.5rem;
`;

export const Name = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 1.5rem;
`;

export const RdcUserContainer = styled.div`
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    gap: 0 1rem;
    margin-top: 0.75rem;
`;

export const ContactContainer = styled.div`
    display: flex;
    grid-gap: 1rem;
`;

export const Contact = styled.div`
    display: flex;
    grid-gap: 0.75rem;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1.25rem 1fr;
    gap: 1rem;
    margin-top: 1rem;
`;

export const Info = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.21px;
    line-height: 1.125rem;
`;

export const InfoIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 0.625rem;
    justify-self: center;
    margin-top: 0.25rem;
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
