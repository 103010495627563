import { faIndustryAlt } from "@fortawesome/pro-regular-svg-icons/faIndustryAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField
} from "@vapor/react-material";

import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router";
import { deleteBusiness, getCustomerBusiness, updateBusiness } from "../../../actions/business";
import CardsLayout from "../../cards-layout";
import { CardBusiness } from "./cards/card-business";
import { CardNewBusiness } from "./cards/card-create";
import CustomerEditBusiness from "./edit";
import { BusinessLeftSide } from "./edit/leftside";
import { Close } from "@mui/icons-material";
import { CustomerEditBusinessContainer } from "./styled";
import { business, dashboard } from "../customer-sections/constants";
import { AlertMessage } from "../../alert-message";
import { Field } from "@onefront/react-workspace-components";
import { MainTitleDesc, MainTitleText } from "../customer-edit-registry/styled";

const CustomerEditActivities = ({
    isEditing,
    render,
    setIsEditing,
    setLeftSide,
    setTitle,
    setSubtitle,
    setBackText,
    setMainSection
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [endDateConfirmModal, setEndDateConfirmModal] = useState(false);
    const [endDate, setEndDate] = useState(null);

    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const customerDetails = useSelector(state => state.customer.details);
    const [businessId, setBusinessId] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isEditingStep, setIsEditingStep] = useState(businessId ? false : true);

    const handleDeleteDialog = () => {
        setDeleteConfirmModal(!deleteConfirmModal);
    };

    const handleEndDateDialog = () => {
        setEndDateConfirmModal(!endDateConfirmModal);
    };

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    useEffect(() => {
        setLeftSide(isEditing ? <BusinessLeftSide stepOnLeftSide={businessId ? true : false} /> : null);
        setTitle(
            isEditing
                ? businessId
                    ? intl.formatMessage({
                          id: "v-customer-edit-business.editTitle"
                      })
                    : intl.formatMessage({
                          id: "v-customer-edit-business.title"
                      })
                : undefined
        );
        setSubtitle(
            isEditing
                ? businessId
                    ? intl.formatMessage({ id: "v-customer-edit-business.editSubtitle" })
                    : intl.formatMessage({
                          id: "v-customer-edit.subtitle"
                      })
                : undefined
        );
        setBackText(
            isEditing
                ? intl.formatMessage({
                      id: "v-customer-edit-business-create.back"
                  })
                : undefined
        );
        setMainSection(isEditing && businessId ? business : dashboard);
    }, [intl, setLeftSide, setSubtitle, setTitle, setBackText, setMainSection, isEditing, businessId]);

    useEffect(() => {
        if (!isEditing && render) {
            dispatch(getCustomerBusiness(ownerId, workspaceId));
        }
    }, [dispatch, ownerId, workspaceId, isEditing, render]);

    if (!render) {
        return null;
    }

    const onDeleteBusiness = async businessId => {
        if (!businessId) {
            return null;
        }
        await dispatch(deleteBusiness(ownerId, workspaceId, businessId)).then(() => {
            handleDeleteDialog();
            setTimeout(() => {
                dispatch(getCustomerBusiness(ownerId, workspaceId)).then(() => {
                    setAlertMessage({
                        status: "success",
                        title: <FormattedMessage id={"c-customer-edit-business.deleteConfirm"} />,
                        description: <FormattedMessage id={"c-customer-edit-business.deleteConfirmDescription"} />
                    });
                });
            }, 1000);
            setBusinessId(null);
        });
    };

    const onHandleEndDate = async (businessId, endDate) => {
        if (!businessId) {
            return null;
        }

        dispatch(updateBusiness(workspaceId, businessId, { endDate: endDate.toISOString() })).then(() => {
            handleEndDateDialog();
            setTimeout(() => {
                dispatch(getCustomerBusiness(ownerId, workspaceId)).then(() => {
                    setAlertMessage({
                        status: "success",
                        title: <FormattedMessage id={"c-customer-edit-business.endDateConfirm"} />,
                        description: <FormattedMessage id={"c-customer-edit-business.endDateConfirmDescription"} />
                    });
                });
            }, 1000);
            setBusinessId(null);
        });

        setEndDate(null);
    };

    if (!render) {
        return null;
    }

    if (isEditing) {
        return (
            <CustomerEditBusiness
                ownerId={ownerId}
                workspaceId={workspaceId}
                businessList={customerDetails?.additionalProps?.business}
                businessId={businessId}
                setIsEditing={setIsEditing}
                setLeftSide={setLeftSide}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isEditingStep={isEditingStep}
                setIsEditingStep={setIsEditingStep}
                onSave={(status, title, description) => {
                    setAlertMessage({ status, title, description });
                    dispatch(getCustomerBusiness(ownerId, workspaceId));
                }}
            />
        );
    }

    return (
        <CustomerEditBusinessContainer>
            {alertMessage?.status && (
                <AlertMessage
                    status={alertMessage?.status}
                    title={alertMessage?.title}
                    description={alertMessage?.description}
                    setAlertMessage={setAlertMessage}
                />
            )}
            {endDateConfirmModal && (
                <Dialog
                    open={endDateConfirmModal}
                    onClose={handleEndDateDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        <FormattedMessage id={"c-customer-edit-business.endDateTitle"} />
                        <IconButton color="primary" onClick={handleEndDateDialog}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Stack sx={{ padding: "8px 16px", minWidth: "320px", gap: "1rem" }}>
                            <DialogContentText id="alert-dialog-description">
                                <FormattedMessage id={"c-customer-edit-business.endDateMessage"} />
                            </DialogContentText>
                            <Field
                                component="datepicker"
                                label={intl.formatMessage({ id: "c-customer-edit-business.endDateLabel" })}
                                isEditing={true}
                                value={endDate}
                                onChange={val => {
                                    setEndDate(val);
                                }}
                                additionalProps={{
                                    renderInput: params => <TextField {...params} fullWidth />
                                }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined">
                            <FormattedMessage id={"general.cancel"} />
                        </Button>
                        <Button variant="contained" onClick={() => onHandleEndDate(businessId, endDate)}>
                            <FormattedMessage id={"general.confirm"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {deleteConfirmModal && (
                <Dialog
                    open={deleteConfirmModal}
                    onClose={handleDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        <FormattedMessage id={"c-customer-edit-business.delete"} />
                        <IconButton color="primary" onClick={handleDeleteDialog}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider variant="alert" />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"c-customer-edit-business.deleteDescription"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined">
                            <FormattedMessage id={"general.cancel"} />
                        </Button>
                        <Button variant="contained" color="error" onClick={() => onDeleteBusiness(businessId)}>
                            <FormattedMessage id={"general.delete"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <CardsLayout
                title={
                    <MainTitleText>
                        <FormattedMessage
                            id="c-customer-edit-business.header-title"
                            values={{ icon: <FontAwesomeIcon icon={faIndustryAlt} /> }}
                        />
                    </MainTitleText>
                }
                description={
                    <MainTitleDesc>
                        <FormattedMessage id="c-customer-edit-business.header-description" />
                    </MainTitleDesc>
                }
                isLoading={customerDetails?.additionalProps?.isGettingBusiness}
                skeletonFirstGrey
            >
                <CardNewBusiness
                    setIsEditing={setIsEditing}
                    setBusinessId={setBusinessId}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isEditingStep={isEditingStep}
                    setIsEditingStep={setIsEditingStep}
                />
                {customerDetails?.additionalProps?.business
                    ?.sort((a, b) => {
                        if (a.type === "MAIN" && b.type === "SECONDARY") {
                            return -1;
                        }
                        if (a.type === "SECONDARY" && b.type === "MAIN") {
                            return 1;
                        }
                        return 0;
                    })
                    ?.map(business => {
                        return (
                            <CardBusiness
                                key={business.id}
                                business={business}
                                setIsEditing={setIsEditing}
                                setBusinessId={setBusinessId}
                                setDeleteConfirmModal={setDeleteConfirmModal}
                                setEndDateConfirmModal={setEndDateConfirmModal}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                isEditingStep={isEditingStep}
                                setIsEditingStep={setIsEditingStep}
                            />
                        );
                    })}
            </CardsLayout>
        </CustomerEditBusinessContainer>
    );
};

export default CustomerEditActivities;
