import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";

export const statusTagMapping = {
    /* MAPPING STATI WORKSPACE */
    ACTIVE: {
        backgroundColor: props => props.theme.colors.status.laSalleGreen,
        icon: faCheckCircle,
        label: "general.status.ACTIVE"
    },
    DEACTIVATED: {
        backgroundColor: props => props.theme.colors.status.maximumRed,
        icon: faClock,
        label: "general.status.DEACTIVATED"
    },

    /* MAPPING STATI PORTALE */
    VALIDATED: {
        backgroundColor: props => props.theme.colors.status.laSalleGreen,
        icon: faCheckCircle,
        label: "general.status.VALIDATED"
    },
    UNVERIFIABLE: {
        backgroundColor: props => props.theme.colors.status.crayola,
        icon: faClock,
        label: "general.status.UNVERIFIABLE"
    },
    VERIFIED_PENDING_VALIDATE: {
        backgroundColor: props => props.theme.colors.status.crayola,
        icon: faClock,
        label: "general.status.VERIFIED_PENDING_VALIDATE"
    },
    UNVERIFIABLE_PENDING_VALIDATE: {
        backgroundColor: props => props.theme.colors.status.crayola,
        icon: faClock,
        label: "general.status.UNVERIFIABLE_PENDING_VALIDATE"
    },
    REJECTED: {
        backgroundColor: props => props.theme.colors.status.maximumRed,
        icon: faClock,
        label: "general.status.REJECTED"
    },
    REJECTED_PENDING_VALIDATE: {
        backgroundColor: props => props.theme.colors.status.crayola,
        icon: faClock,
        label: "general.status.REJECTED_PENDING_VALIDATE"
    }
};
