export const TAX_PURPOSE = "TAX_PURPOSE";
export const TAX_AND_STATISTICS_PURPOSE = "TAX_AND_STATISTICS_PURPOSE";
export const MONTHLY = "MONTHLY";
export const QUARTERLY = "QUARTERLY";

export const intrastatGoodsKeys = [
    "goodsSoldDatatypeReport",
    "goodsSoldReportFrequency",
    "goodsPurchasedReportFrequency"
];
export const intrastatServicesKeys = ["servicesPurchasedReportFrequency", "servicesSoldReportFrequency"];
