import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Modal } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/pro-regular-svg-icons/faFolderOpen";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { translateMessage } from "../../../i18n";

import {
    SearchAlert,
    SearchAlertIcon,
    SearchAlertLabel,
    SearchAlertLink,
    NoResultIcon,
    NoResult,
    NoResultLabel1,
    NoResultLabel2,
    ModalFooter,
    ModalContainer,
    ModalDescription,
    ModalIcon,
    StepTitle,
    StepSubtitle,
    TypeCards,
    LoadingRow,
    VisualizeAllLink,
    SubDescription,
    CardHeader,
    Pull,
    Footer,
    SpaceRow,
    ManualAlert
} from "./styled";
import SearchInputBar from "../../vapor-components/search-input-filter";
import { H1, H3, H5, SubTitleRegular } from "../../vapor-components/typography";
import NewClientCountry from "./step/new-client-country";
import NewClientType from "./step/new-client-type";
import NewRegistryResult from "./step/new-client-result";
import NewClientData from "./step/new-client-data";
import LoadingButton from "../../vapor-components/loading-button";
import TypeSelectorBox from "../../vapor-components/selector-box";
import { createNewClient, searchFromCerved } from "../../../actions/newclient";
import { useSelector, useDispatch } from "react-redux";
import LoadingCards from "./loadingCards";
import { LoadingElement } from "../../vapor-components/loading";
import { Divider, Stack } from "@vapor/material";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Button,
    Typography
} from "@vapor/react-material";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/system";

const NewClientFormWrapper = ({
    handleSubmit,
    loading,
    stepNumber,
    setStepNumber,
    errorMessage,
    setErrorMessage,
    retryModalVisible,
    setRetryModalVisible,
    forwardModalVisible,
    setForwardModalVisible,
    formParams,
    setSearchOrCreateStep,
    searchOrCreateStep,
    taxRegion
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [selectedCompanyRadio, setSelectedCompanyRadio] = useState(null);
    const [searchResult, setSearchResult] = useState([]);
    const [searchDone, setSearchDone] = useState(false);
    const [formParamsCerved, setFormParamsCerved] = useState(formParams);
    const [searchString, setSearchString] = useState("");
    const [endResults, setEndResults] = useState(3);
    const footer = useRef();
    const [heightPull, setHeightPull] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const error = useSelector(state => state?.newClient?.status?.error);
    const authData = useSelector(state => state.initDataFromParent.auth);
    const subjects = useSelector(state => state.cerved?.result?.subjects);
    const duplicatedCerved = useSelector(state => state.cerved?.result?.duplicates);
    const subjectsNumber = useSelector(state => state.cerved?.result?.subjects_total_number);
    const searchCervedState = useSelector(state => state.cerved.status);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        setHeightPull(footer.current.clientHeight);
    }, [setHeightPull, footer]);

    useEffect(() => {
        if (error) {
            setOpenErrorModal(error);
        }
    }, [error]);

    useEffect(() => {
        return searchResult?.map(subject => {
            let streetName,
                streetNumber = "";
            if (subject?.address?.street?.description.includes(",")) {
                streetName = subject?.address?.street?.description
                    ?.split(",")
                    ?.at(0)
                    ?.trim();
                streetNumber = subject?.address?.street?.description
                    ?.split(",")
                    ?.at(-1)
                    ?.trim();
            }
            return (
                subject.subject_id === selectedCompanyRadio &&
                setFormParamsCerved({
                    classifier: formParams.classifier,
                    countryCode: "IT",
                    ...(subject.person_info?.first_name && { name: subject.person_info?.first_name }),
                    ...(subject.person_info?.last_name && { surname: subject.person_info?.last_name }),
                    businessName: subject.company_info?.business_name
                        ? subject.company_info?.business_name
                        : subject.person_info?.name,
                    vatIdentifier: subject.company_info?.vat_number ? subject.company_info?.vat_number : null,
                    taxIdentifier: subject.tax_code ? subject.tax_code : null,
                    ...(subject?.address?.city?.description && { city: subject?.address?.city?.description }),
                    ...(subject?.address?.province?.code && { province: subject?.address?.province?.code }),
                    ...(subject?.address?.postal_code && { zipCode: subject?.address?.postal_code }),
                    ...(subject?.address?.street?.description && { streetNumber: streetNumber, streetName: streetName })
                })
            );
        });
    }, [searchResult, selectedCompanyRadio, formParams]);

    const handleDialog = () => {
        setOpenErrorModal(!openErrorModal);
    };

    const searchClient = value => {
        setSearchDone(true);
        setSearchString(value);
        dispatch(searchFromCerved({ ownerId: authData.item.id, search_text: value })).then(cerved => {
            setEndResults(3);
            let searchArr = null;
            if (selectedRadio === "COMPANY") {
                searchArr = cerved?.subjects?.filter(subject => subject?.company_info?.vat_number)?.slice(0, 3);
            } else {
                searchArr = cerved?.subjects?.slice(0, 3);
            }
            setSearchResult(searchArr);
        });
    };

    const saveDataFromCerved = () => {
        dispatch(createNewClient(authData.item.id, formParamsCerved));
    };

    const visualizeMoreResult = end => {
        setEndResults(endResults + end > 15 ? 15 : endResults + end);
        let results = null;
        if (selectedRadio === "COMPANY") {
            results = subjects?.filter(subject => subject?.company_info?.vat_number)?.slice(0, endResults + 3);
            setSearchResult(results);
        } else {
            results = setSearchResult(subjects?.slice(0, endResults + 3));
        }
    };

    const renderSubjects = () => {
        return (
            <div>
                {searchDone && searchResult?.length !== 0 && searchCervedState.ended && (
                    <div>
                        <FormattedMessage id="c-progress-bar.selection"></FormattedMessage>
                    </div>
                )}
                {searchDone &&
                    searchCervedState.ended &&
                    searchResult?.map(subject => {
                        const disabledCustomerSelection = duplicatedCerved?.some(item => item === subject.tax_code);
                        return (
                            <TypeSelectorBox
                                key={subject.subject_id}
                                icon={null}
                                disabled={disabledCustomerSelection}
                                cardHeader={
                                    <>
                                        <CardHeader>
                                            <H3
                                                lineHeight="1.5rem"
                                                color={disabledCustomerSelection ? "quickSilver" : "darkImperialBlue"}
                                            >
                                                {subject.company_info?.business_name
                                                    ? subject.company_info?.business_name
                                                    : subject.person_info?.name}
                                            </H3>
                                        </CardHeader>
                                        {subject?.address?.street?.description &&
                                            subject?.address?.city?.description &&
                                            subject?.address?.province?.code &&
                                            subject?.address?.postal_code && (
                                                <SubTitleRegular color={disabledCustomerSelection && "quickSilver"}>
                                                    {subject.address?.street?.description +
                                                        ", " +
                                                        subject.address?.postal_code +
                                                        " - " +
                                                        subject.address?.city?.description +
                                                        "(" +
                                                        subject.address?.province?.code +
                                                        ")"}
                                                </SubTitleRegular>
                                            )}
                                    </>
                                }
                                cardFooter={
                                    <SubTitleRegular color={disabledCustomerSelection && "quickSilver"}>
                                        <SubDescription>
                                            {subject.company_info?.vat_number ? (
                                                <div>P.IVA {subject.company_info?.vat_number}</div>
                                            ) : (
                                                ""
                                            )}
                                            {subject.tax_code ? <div>CF {subject.tax_code}</div> : ""}
                                        </SubDescription>
                                    </SubTitleRegular>
                                }
                                onChange={() => {
                                    if (!disabledCustomerSelection) {
                                        setSelectedCompanyRadio(subject.subject_id);
                                    } else {
                                        setSelectedCompanyRadio(null);
                                    }
                                }}
                                selected={subject.subject_id === selectedCompanyRadio}
                                value={subject.subject_id}
                                onLink={() => {
                                    setSearchOrCreateStep("modify");
                                }}
                            />
                        );
                    })}
            </div>
        );
    };

    return (
        <div>
            {retryModalVisible && (
                <Modal
                    onClose={() => setRetryModalVisible(false)}
                    closable={true}
                    type="danger"
                    footer={
                        <ModalFooter>
                            <LoadingButton onClick={handleSubmit} loading={loading}>
                                <FormattedMessage id="general.retry"></FormattedMessage>
                            </LoadingButton>
                        </ModalFooter>
                    }
                    visible={retryModalVisible}
                    title={intl.formatMessage({
                        id: "c-new-registry.headquarterAlert"
                    })}
                >
                    {" "}
                    <div>
                        <ModalContainer>
                            <ModalIcon>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </ModalIcon>
                            <div>
                                <FormattedMessage id="c-new-registry.headquarterError"></FormattedMessage>.
                            </div>
                        </ModalContainer>
                        <ModalDescription>
                            <FormattedMessage id="c-new-registry.retry"></FormattedMessage>.
                        </ModalDescription>
                    </div>
                </Modal>
            )}
            {forwardModalVisible && (
                <Modal
                    onClose={() => setForwardModalVisible(false)}
                    closable={true}
                    type="danger"
                    footer={
                        <ModalFooter>
                            <Button
                                onClick={() => {
                                    setForwardModalVisible(false);
                                    setStepNumber(stepNumber + 1);
                                }}
                            >
                                <FormattedMessage id="general.forward"></FormattedMessage>
                            </Button>
                        </ModalFooter>
                    }
                    visible={forwardModalVisible}
                    title={intl.formatMessage({
                        id: "c-new-registry.headquarterErrorDescription"
                    })}
                >
                    {" "}
                    <div>
                        <ModalContainer>
                            <ModalIcon>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </ModalIcon>
                            <div>
                                <FormattedMessage id="c-new-registry.headquarterError"></FormattedMessage>.
                            </div>
                        </ModalContainer>
                        <ModalDescription>
                            <FormattedMessage id="c-new-registry.headquarterErrorDescription2"></FormattedMessage>.
                        </ModalDescription>
                    </div>
                </Modal>
            )}
            {stepNumber === 0 && <NewClientCountry taxRegion={taxRegion} />}
            {stepNumber === 1 && (
                <NewClientType
                    stepNumber={stepNumber}
                    selectedRadio={selectedRadio}
                    setSelectedRadio={setSelectedRadio}
                    formParamsCerved={formParamsCerved}
                />
            )}
            {stepNumber === 2 && searchOrCreateStep === "search" && (
                <div>
                    <StepTitle>
                        <H1 color="yankeesBlue">
                            <FormattedMessage id="c-new-registry.title3" />
                        </H1>
                    </StepTitle>
                    <StepSubtitle>
                        <H5 weight="normal" color="cadet">
                            <FormattedMessage id="c-new-registry.subtitle3" />
                        </H5>
                    </StepSubtitle>
                    <StepSubtitle>
                        <SearchInputBar
                            label={translateMessage("c-new-client.new-client-form.searchAnag")}
                            width="580px"
                            placeholder={translateMessage("c-new-client.new-client-form.searchAnagPlaceholder")}
                            onSearch={value => {
                                searchClient(value);
                            }}
                            onLoading={searchCervedState.started}
                        ></SearchInputBar>
                    </StepSubtitle>
                    {!searchDone && (
                        <ManualAlert>
                            <FormattedMessage id="general.or" />
                            <SearchAlertLink onClick={() => setSearchOrCreateStep("create")}>
                                <FormattedMessage id="c-new-registry.insertData" />
                            </SearchAlertLink>
                        </ManualAlert>
                    )}
                    {searchDone && searchCervedState.started && (
                        <TypeCards>
                            <LoadingRow>
                                <LoadingElement width="5%" height="20px"></LoadingElement>
                                <LoadingElement width="40%" height="20px"></LoadingElement>
                                <LoadingElement width="40%" height="20px"></LoadingElement>
                            </LoadingRow>
                        </TypeCards>
                    )}
                    {searchDone && !searchCervedState.started && (searchCervedState.ended || searchCervedState.error) && (
                        <div>
                            <SearchAlert>
                                <SearchAlertIcon>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </SearchAlertIcon>
                                <SearchAlertLabel>
                                    <FormattedMessage id="c-new-registry.noResult" />
                                </SearchAlertLabel>
                                <SearchAlertLink onClick={() => setSearchOrCreateStep("create")}>
                                    <FormattedMessage id="c-new-registry.insertData" />
                                </SearchAlertLink>
                            </SearchAlert>
                        </div>
                    )}
                    {searchDone &&
                        !searchCervedState.started &&
                        (searchCervedState.ended || searchCervedState.error) &&
                        searchResult?.length === 0 && (
                            <div>
                                <NoResult>
                                    <NoResultIcon>
                                        <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                                    </NoResultIcon>
                                </NoResult>
                                <NoResultLabel1>
                                    <FormattedMessage id="c-new-registry.noResultLabel" />
                                </NoResultLabel1>
                                <NoResultLabel2>
                                    <FormattedMessage
                                        values={{ searchString: '"' + searchString + '"' }}
                                        id="c-new-registry.noResultLabel2"
                                    />
                                </NoResultLabel2>
                            </div>
                        )}
                    {searchDone && !searchCervedState.started && searchResult?.length > 0 && (
                        <TypeCards>
                            <div>{renderSubjects()}</div>

                            {endResults < 15 && subjectsNumber > 3 && (
                                <Stack spacing={2} mt={3}>
                                    <Divider variant="fullWidth" light />
                                    <VisualizeAllLink onClick={() => visualizeMoreResult(3)}>
                                        <FormattedMessage id="c-new-registry.visualizeAll" />
                                    </VisualizeAllLink>
                                </Stack>
                            )}
                        </TypeCards>
                    )}
                    <SpaceRow></SpaceRow>
                    {searchDone && searchCervedState.started && <LoadingCards></LoadingCards>}
                </div>
            )}
            {stepNumber === 2 && (searchOrCreateStep === "create" || searchOrCreateStep === "modify") && (
                <>
                    <Dialog
                        open={openErrorModal}
                        onClose={handleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "500px" } }}
                    >
                        <DialogTitle>
                            <FormattedMessage id="c-new-registry.headquarterAlert" />
                            <IconButton color="primary" onClick={handleDialog}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider variant="alert" />
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Stack spacing={2}>
                                    <Stack flexDirection="row" alignItems={"center"} sx={{ gap: 1.2 }}>
                                        <Box>
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" color={"#d82829"} />
                                        </Box>
                                        <Typography variant="bodyLarge500" component={"div"}>
                                            <FormattedMessage id="c-new-registry.headquarterAlertTitle" />
                                        </Typography>
                                    </Stack>
                                    <Typography variant="bodyLarge" component={"div"}>
                                        <FormattedMessage id="c-new-registry.headquarterAlertDescription" />
                                    </Typography>
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                <FormattedMessage id="c-new-registry.headquarterReturn" />
                            </Button>
                            <Button variant="contained" onClick={handleDialog}>
                                <FormattedMessage id="general.retry" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <NewClientData
                        setStepNumber={setStepNumber}
                        stepNumber={stepNumber}
                        searchOrCreateStep={searchOrCreateStep}
                        setSearchOrCreateStep={setSearchOrCreateStep}
                        selectedRadio={selectedRadio}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        formParams={formParams}
                        formParamsCerved={formParamsCerved}
                    />
                </>
            )}
            {stepNumber === 3 && <NewRegistryResult />}
            <Footer ref={footer}>
                {stepNumber > 0 && stepNumber !== 3 && (
                    <Button
                        kind="secondary"
                        onClick={
                            stepNumber === 2 && searchOrCreateStep === "search"
                                ? () => {
                                      setStepNumber(1);
                                      setSearchResult([]);
                                      setSearchDone(false);
                                  }
                                : stepNumber === 2 &&
                                  searchOrCreateStep === "create" &&
                                  formParams.classifier === "PERSON"
                                ? () => setStepNumber(1)
                                : stepNumber === 2 &&
                                  (searchOrCreateStep === "modify" || searchOrCreateStep === "create") &&
                                  formParams.classifier !== "PERSON"
                                ? () => {
                                      setStepNumber(2);
                                      setSearchOrCreateStep("search");
                                  }
                                : () => setStepNumber(stepNumber - 1)
                        }
                        style={{ marginRight: 16 }}
                        data-cy="go-back-button"
                    >
                        <FormattedMessage id="general.back" />
                    </Button>
                )}
                <LoadingButton
                    disabled={
                        (stepNumber === 1 && selectedRadio === null) ||
                        (stepNumber === 2 && !selectedCompanyRadio && searchOrCreateStep === "search")
                    }
                    onClick={
                        stepNumber === 3
                            ? () => navigate("/")
                            : stepNumber !== 2 || (stepNumber === 2 && searchOrCreateStep !== "search")
                            ? handleSubmit
                            : () => saveDataFromCerved()
                    }
                    loading={loading}
                >
                    {stepNumber !== 3 ? (
                        <FormattedMessage id="general.forward" />
                    ) : (
                        <FormattedMessage id="c-new-registry.finalButton" />
                    )}
                </LoadingButton>
            </Footer>
            <Pull pullHeight={heightPull} />
        </div>
    );
};

NewClientFormWrapper.propTypes = {
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    stepNumber: PropTypes.string,
    setStepNumber: PropTypes.func,
    errorMessage: PropTypes.string,
    setErrorMessage: PropTypes.func,
    retryModalVisible: PropTypes.bool,
    setRetryModalVisible: PropTypes.func,
    forwardModalVisible: PropTypes.bool,
    setForwardModalVisible: PropTypes.func,
    formParams: PropTypes.shape({
        countryCode: PropTypes.string,
        headquarterType: PropTypes.string,
        classifier: PropTypes.string
    })
};

export default NewClientFormWrapper;
