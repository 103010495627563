import styled from "styled-components";

export const CustomerEditBusinessTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CustomerEditBusinessTitle = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 20px;
`;

export const CustomerEditBusinessTitleText = styled.div`
    margin-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CustomerEditBusinessTitleStatus = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const CustomerEditBusinessStatusTag = styled.div`
    flex: 1 0 auto;
    margin-bottom: 1rem;
`;

export const CustomerEditBusinessContentLine = styled.div`
    max-width: 230px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 30px;
`;

export const CustomerEditBusinessFooterContainer = styled.div`
    width: 100%;
`;

export const CustomerEditBusinessContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 100%;
`;

export const CustomerEditDetails = styled.span`
    font-weight: 600;
    padding-left: 0.15rem;
`;

export const CustomerBusinessModalFooter = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const EditButton = styled.div`
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
    border-top-style: solid;
    border-top-width: 0.1px;
    border-top-color: ${props => props.theme.colors.background.azureishWhite};
`;
export const DeleteButton = styled.div`
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
`;
