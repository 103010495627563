import {
    INIT_DATA_FROM_PARENT_START,
    INIT_DATA_FROM_PARENT_SUCCESS,
    INIT_DATA_FROM_PARENT_ERROR
} from "../actions/auth.js";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function initDataFromParent(state = defaultState, { type, payload, error }) {
    switch (type) {
        case INIT_DATA_FROM_PARENT_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case INIT_DATA_FROM_PARENT_SUCCESS:
            return {
                auth: { ...payload },
                status: {
                    started: false,
                    error: false,
                    ended: true
                }
            };
        case INIT_DATA_FROM_PARENT_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        default:
            return state;
    }
}
