import styled from "styled-components";

export const StepContainer = styled.div`
    width: 60%;
    margin: 30px auto;
`;

export const StepButton = styled.div`
    width: 619px;
    color: ${props => props.theme.colors.primary.yankeesBlue};
    font-size: 42px;
    font-family: "Cairo";
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    padding: 0 40px;
    margin: 90px auto 10px auto;
    gap: 30px;
`;

export const FinalButton = styled.div`
    width: 619px;
    color: ${props => props.theme.colors.primary.yankeesBlue};
    font-size: 42px;
    font-family: "Cairo";
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 48px;
    text-align: center;
    padding: 0 40px;
    margin: 90px auto 10px auto;
`;

export const SelectCountry = styled.div`
    margin: 10px auto 10px auto;
    @media (min-width: 768px) {
        width: 620px;
    }

    @media (min-width: 500px) and (max-width: 1200px) {
        width: 450px;
    }
`;

export const TypeCards = styled.div`
    margin: 30px auto 10px auto;
    width: 620px;
`;

export const LoadingRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const CheckIcon = styled.div`
    width: 619px;
    color: ${props => props.theme.colors.status.laSalleGreen};
    font-size: 56px;
    font-family: "Cairo";
    font-weight: solid;
    letter-spacing: 0.31px;
    text-align: center;
    padding: 0 40px;
    margin: 10px auto -50px auto;
`;

export const ManualAlert = styled.div`
    height: 41px;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    margin: -10px auto 10px auto;
    align-items: center;
    @media (min-width: 768px) {
        width: 619px;
    }

    @media (min-width: 500px) and (max-width: 800px) {
        width: 450px;
    }
`;

export const SearchAlert = styled.div`
    height: 41px;
    border: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    border-radius: 0px;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    margin: -10px auto 10px auto;
    align-items: center;
    @media (min-width: 768px) {
        width: 619px;
    }

    @media (min-width: 500px) and (max-width: 800px) {
        width: 450px;
    }
`;

export const SearchAlertLabel = styled.div`
    font-weight: bold;
`;

export const SearchAlertIcon = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    margin-left: 10px;
`;

export const SearchAlertLink = styled.div`
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const VisualizeAllLink = styled.div`
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
`;

export const NoResult = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 10px auto;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.background.azureishWhite};
`;

export const NoResultIcon = styled.div`
    color: ${props => props.theme.colors.text.quickSilver};
    font-weight: bold;
    font-size: 32px;
`;

export const NoResultLabel1 = styled.div`
    width: 619px;
    height: 32px;
    font-size: 24px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin: 20px auto 10px auto;
    text-align: center;
`;

export const NoResultLabel2 = styled.div`
    width: 618px;
    height: 20px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 10px auto 10px auto;
    text-align: center;
`;

export const NewClient = styled.div`
    margin: 20px auto 20px auto;
    @media (min-width: 768px) {
        width: 619px;
    }

    @media (min-width: 500px) and (max-width: 800px) {
        width: 500px;
    }
`;

export const NewClientReminder = styled.div`
    border-top: 1px solid #c9d9e8;
    padding-top: 20px;
`;

export const NewClientRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
`;

export const NewClientRowHeadquarter = styled.div`
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    gap: 30px;
`;

export const NewClientRowReminder = styled.div`
    margin-top: 30px;
`;

export const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.status.maximumRed};
`;

export const NextStepRow = styled.div`
    width: 619px;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 5px auto;
    align-items: center;
`;

export const NextStepRowDetail = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const NextStepIcon = styled.div`
    font-size: 16px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.background.whiteSmoke};
`;

export const ModalFooter = styled.div`
    display: flex;
    padding: 10px;
    flex: auto;
    flex-flow: row-reverse;
`;

export const ModalContainer = styled.div`
    display: flex;
    padding: 10px;
`;

export const ModalDescription = styled.div`
    padding: 10px;
    width: 400px;
`;

export const ModalIcon = styled.div`
    margin-right: 10px;
    color: ${props => props.theme.colors.status.maximumRed};
`;

export const StepTitle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    padding: 0 20px;
    margin: 0px auto -30px auto;
    width: 619px;
`;

export const StepSubtitle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0px auto 10px auto;
    width: 619px;
`;

export const NextStepsLabel1 = styled.div`
    width: 619px;
    height: 20px;
    margin: 30px auto 5px auto;
    text-align: start;
`;

export const NextStepsLabel2 = styled.div`
    width: 619px;
    height: 48px;
    margin: 5px auto 20px auto;
`;

export const StepFinalTitle = styled.div`
    width: 619px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 0 40px;
    margin: 10px auto 10px auto;
`;

export const LoadingCardsContainer = styled.div`
    margin-top: 70px;
`;

export const SubDescription = styled.div`
    display: flex;
    gap: 30px;
    font-size: 12px;
    line-height: 2.5rem;
`;

export const CardHeader = styled.div`
    width: 460px;
    margin-bottom: 10px;
`;

export const Pull = styled.div`
    display: block;
    width: 100%;
    height: calc(${props => props.pullHeight}px + 2rem);
`;

export const Footer = styled.div`
    width: 100%;
    height: 72px;
    background: rgb(242, 245, 248);
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

export const SpaceRow = styled.div`
    min-height: 75px;
`;
