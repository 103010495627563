import React from "react";

import { Field } from "react-final-form";
import { Select } from "@ts-digital/vrc";

import { SelectSpacer } from "./styled";

const SelectInput = ({ input, meta, ...props }) => {
    return (
        <SelectSpacer>
            <Select
                {...input}
                {...props}
                error={meta.touched === true && meta.error !== undefined}
                errorMessage={meta.error}
            />
        </SelectSpacer>
    );
};

const FormSelect = ({ ...props }) => {
    return <Field {...props} component={SelectInput} />;
};

export default FormSelect;
