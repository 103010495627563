import {
    GET_ASSET_GROUP_START,
    GET_ASSET_GROUP_SUCCESS,
    GET_ASSET_GROUP_ERROR
  } from "../actions/business.js";
  
  const defaultState = {
    status: {
      started: false,
      error: false,
      ended: false,
      errorInfo: {
        code: "",
        message: "",
      },
    },
    result: null
  };
  
  export function asset(state = defaultState, { type, payload, error }) {
    switch (type) {
      case GET_ASSET_GROUP_START:
        return {
          status: {
            started: true,
            error: false,
            ended: false,
          },
        };
      case GET_ASSET_GROUP_SUCCESS:
        return {
          status: {
            started: false,
            error: false,
            ended: true,
          },
          result : payload
        };
      case GET_ASSET_GROUP_ERROR:
        return {
          status: {
            started: false,
            error: true,
            ended: false,
            errorInfo: { error },
          },
        };
      
      default:
        return state;
    }
  }