import React from "react";
import { FormattedMessage } from "react-intl";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faCarBuilding } from "@fortawesome/pro-regular-svg-icons/faCarBuilding";
import { faCity } from "@fortawesome/pro-regular-svg-icons/faCity";
import { faSuitcase } from "@fortawesome/pro-regular-svg-icons/faSuitcase";

export const steps = [
    <FormattedMessage id="c-progress-bar.step1" key="c-progress-bar.step1" />,
    <FormattedMessage id="c-progress-bar.step2" key="c-progress-bar.step2" />,
    <FormattedMessage id="c-progress-bar.step3" key="c-progress-bar.step3" />,
    <FormattedMessage id="c-progress-bar.step4" key="c-progress-bar.step4" />
];

export const radioCardsList = [
    {
        icon: faBuilding,
        label: "c-new-registry.type1",
        description: "c-new-registry.description1",
        radioValue: "COMPANY"
    },
    {
        icon: faUser,
        label: "c-new-registry.type2",
        description: "c-new-registry.description2",
        radioValue: "PERSON"
    },
    {
        icon: faCarBuilding,
        label: "c-new-registry.type3",
        description: "c-new-registry.description3",
        radioValue: "BUILDING"
    },
    ...(process.env.REACT_APP_ENV === "dev"
        ? [
              {
                  icon: faCity,
                  label: "c-new-registry.type4",
                  description: "c-new-registry.description4",
                  radioValue: "PUBLIC_COMPANY"
              }
          ]
        : [])
];

export const customerTypes = [
    {
        icon: faBuilding,
        radioValue: "COMPANY"
    },
    {
        icon: faUser,
        radioValue: "PERSON"
    },
    {
        icon: faCarBuilding,
        radioValue: "BUILDING"
    },
    {
        icon: faCity,
        radioValue: "PUBLIC_COMPANY"
    },
    {
        icon: faSuitcase,
        radioValue: "STUDIO"
    }
];
