import { Box, Stack, Typography } from "@vapor/react-material";
import React from "react";
import { InfoBoxContainer } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";

export const InfoBox = ({ title, description }) => {
    return (
        <InfoBoxContainer>
            {title && (
                <Stack direction={"row"} spacing={1}>
                    <Box>
                        <FontAwesomeIcon icon={faInfoCircle} color={"#005075"} />
                    </Box>
                    <Box>
                        <Typography variant="body700" gutterBottom>
                            {title}
                        </Typography>
                    </Box>
                </Stack>
            )}
            {description && (
                <Box sx={{ marginTop: ".5rem" }}>
                    <Typography variant="body" gutterBottom>
                        {description}
                    </Typography>
                </Box>
            )}
        </InfoBoxContainer>
    );
};
