import React from "react";

import NewClientFormWrapper from "./new-client-form-wrapper";

import { Form } from "react-final-form";

const NewClientForm = ({
    adaSendStatus,
    adaGenerateStatus,
    createNewClientStatus,
    onGenerateAda,
    onSendAda,
    onSubmit,
    stepNumber,
    setStepNumber,
    errorMessage,
    setErrorMessage,
    retryModalVisible,
    setRetryModalVisible,
    forwardModalVisible,
    setForwardModalVisible,
    formParams,
    setSearchOrCreateStep,
    searchOrCreateStep,
    taxRegion
}) => {
    return (
        <Form
            adaSendStatus={adaSendStatus}
            adaGenerateStatus={adaGenerateStatus}
            component={NewClientFormWrapper}
            formParams={formParams}
            loading={createNewClientStatus && createNewClientStatus.started}
            onGenerateAda={onGenerateAda}
            onSendAda={onSendAda}
            onSubmit={onSubmit}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            retryModalVisible={retryModalVisible}
            setRetryModalVisible={setRetryModalVisible}
            forwardModalVisible={forwardModalVisible}
            setForwardModalVisible={setForwardModalVisible}
            setSearchOrCreateStep={setSearchOrCreateStep}
            searchOrCreateStep={searchOrCreateStep}
            taxRegion={taxRegion}
        />
    );
};

export default NewClientForm;
