import { provinceValues } from "../../../../../../utils/constants";

export const sectionalRows = intl => {
    return [
        [
            {
                name: "description",
                label: intl?.formatMessage({ id: "field.business.sectionalDescription" }) || "Descrizione",
                component: "textfield",
                withMargin: true,
                fullWidth: true,
                style: { minWidth: "700px" },
                componentStyle: { minWidth: "700px" }
            }
        ],
        [
            {
                name: "numberSeries",
                label: intl?.formatMessage({ id: "field.business.sectionalName" }) || "Nome",
                component: "textfield",
                withMargin: true,
                fullWidth: true,
                componentStyle: { width: 320 },
                style: { width: "350px" }
            },
            {
                name: "province",
                label: intl?.formatMessage({ id: "field.business.sectionalProvince" }) || "Provincia",
                component: "select",
                withMargin: true,
                fullWidth: true,
                componentStyle: { width: 320 },
                selectHeight: 320,
                style: { width: "350px" },
                options: provinceValues.map(province => {
                    return { id: province.id, value: province.name };
                })
            }
        ],
        [
            {
                name: "main",
                label: intl?.formatMessage({ id: "field.business.mainTitle" }) || "Principale",
                component: "checkbox",
                withMargin: true,
                fullWidth: true,
                componentStyle: { width: 250 },
                style: { width: 300 },
                options: [{ id: "main", label: intl?.formatMessage({ id: "field.business.main" }) }]
            }
        ]
    ];
};
