import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "../../../vapor-components/card";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Descriptive } from "../../../vapor-components/typography";
import {
    CustomerEditIntermediaryTitleContainer,
    CustomerEditIntermediaryTitleStatus,
    CustomerEditIntermediaryTitle,
    CustomerEditIntermediaryStatusTag,
    CustomerEditIntermediariesIntermediaryContent,
    CustomerEditIntermediaryTitleText,
    CustomerEditIntermediaryFooterContainer,
    EditButton,
    DeleteButton,
    CustomerEditIntermediariesDetails
} from "../styled";
import { Button } from "@ts-digital/vrc";
import { PERSON } from "../../../customer-edit-intermediary/form/constants";
import Tag from "../../../vapor-components/tag";

export const CardIntermediary = ({ description, taxId, vatNumber, type }) => {
    const intl = useIntl();

    const handleDelete = e => {
        console.log(e);
    };
    const handleEdit = e => {
        console.log(e);
    };

    return (
        <Card
            verticalFooter={true}
            footer={
                <CustomerEditIntermediaryFooterContainer>
                    <DeleteButton>
                        <Button kind="tertiary" onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </DeleteButton>
                    <EditButton>
                        <Button kind="tertiary" onClick={handleEdit}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </EditButton>
                </CustomerEditIntermediaryFooterContainer>
            }
            title={
                <CustomerEditIntermediaryTitleContainer>
                    <CustomerEditIntermediaryTitleStatus>
                        <CustomerEditIntermediaryStatusTag>
                            <Tag
                                label={
                                    type === PERSON
                                        ? intl.formatMessage({ id: "classifier.PERSON" })
                                        : intl.formatMessage({ id: "classifier.LEGAL" })
                                }
                                color="#0d6c80"
                                bgColor="#0d6c80"
                                ghost
                                upperText
                            />
                        </CustomerEditIntermediaryStatusTag>
                    </CustomerEditIntermediaryTitleStatus>
                    <CustomerEditIntermediaryTitle>
                        <FontAwesomeIcon icon={type === PERSON ? faUser : faBuilding} />
                        <CustomerEditIntermediaryTitleText>{description}</CustomerEditIntermediaryTitleText>
                    </CustomerEditIntermediaryTitle>
                </CustomerEditIntermediaryTitleContainer>
            }
        >
            <CustomerEditIntermediariesIntermediaryContent>
                <Descriptive color="cadet" lineHeight="16px" style={{ marginTop: "10px", marginLeft: "27px" }}>
                    <FormattedMessage id="general.tax-id" />:
                    <CustomerEditIntermediariesDetails>{taxId}</CustomerEditIntermediariesDetails>
                </Descriptive>
                <Descriptive lineHeight="24px" style={{ marginLeft: "27px" }}>
                    <FormattedMessage id="general.piva" />:
                    <CustomerEditIntermediariesDetails>{vatNumber}</CustomerEditIntermediariesDetails>
                </Descriptive>
            </CustomerEditIntermediariesIntermediaryContent>
        </Card>
    );
};
