import customerManagementApiClient from "../apiClient/";

export const GET_CUSTOMER_BANKS_START = "GET_CUSTOMER_BANKS_START";
export const GET_CUSTOMER_BANKS_SUCCESS = "GET_CUSTOMER_BANKS_SUCCESS";
export const GET_CUSTOMER_BANKS_ERROR = "GET_CUSTOMER_BANKS_ERROR";
export const GET_CUSTOMER_BANKS_RESET = "GET_CUSTOMER_BANKS_RESET";

export const CREATE_CUSTOMER_BANK_START = "CREATE_CUSTOMER_BANK_START";
export const CREATE_CUSTOMER_BANK_SUCCESS = "CREATE_CUSTOMER_BANK_SUCCESS";
export const CREATE_CUSTOMER_BANK_ERROR = "CREATE_CUSTOMER_BANK_ERROR";

export const UPDATE_CUSTOMER_BANK_START = "UPDATE_CUSTOMER_BANK_START";
export const UPDATE_CUSTOMER_BANK_SUCCESS = "UPDATE_CUSTOMER_BANK_SUCCESS";
export const UPDATE_CUSTOMER_BANK_ERROR = "UPDATE_CUSTOMER_BANK_ERROR";

export const DELETE_CUSTOMER_BANK_START = "DELETE_CUSTOMER_BANK_START";
export const DELETE_CUSTOMER_BANK_SUCCESS = "DELETE_CUSTOMER_BANK_SUCCESS";
export const DELETE_CUSTOMER_BANK_ERROR = "DELETE_CUSTOMER_BANK_ERROR";

export const GET_TSPAY_BANKS_START = "GET_TSPAY_BANKS_START";
export const GET_TSPAY_BANKS_SUCCESS = "GET_TSPAY_BANKS_SUCCESS";
export const GET_TSPAY_BANKS_ERROR = "GET_TSPAY_BANKS_ERROR";
export const GET_TSPAY_BANKS_RESET = "GET_TSPAY_BANKS_RESET";

export function getCustomerBanks(ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CUSTOMER_BANKS_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().getCustomerBanks(ownerId, workspaceId);

            dispatch({
                type: GET_CUSTOMER_BANKS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CUSTOMER_BANKS_ERROR,
                payload: { e }
            });
        }
    };
}

export function deleteCustomerBanks(ownerId, workspaceId, bankId) {
    return async (dispatch, getState) => {
        dispatch({
            type: DELETE_CUSTOMER_BANK_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().deleteCustomerBank(ownerId, workspaceId, bankId);

            dispatch({
                type: DELETE_CUSTOMER_BANK_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: DELETE_CUSTOMER_BANK_ERROR,
                payload: { e }
            });
        }
    };
}

export function createCustomerBank(ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: CREATE_CUSTOMER_BANK_START,
                payload: { ownerId, requestParams }
            });

            try {
                new customerManagementApiClient().createCustomerBanks(ownerId, workspaceId, requestParams);

                setTimeout(() => {
                    dispatch({
                        type: CREATE_CUSTOMER_BANK_SUCCESS,
                        payload: requestParams
                    });
                    resolve(requestParams);
                }, 2000);
            } catch (e) {
                dispatch({
                    type: CREATE_CUSTOMER_BANK_ERROR,
                    payload: { e }
                });
                reject(e);
            }
        });
    };
}

export function updateCustomerBank(bankId, ownerId, workspaceId, requestParams) {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPDATE_CUSTOMER_BANK_START,
                payload: { bankId, ownerId, requestParams }
            });

            try {
                new customerManagementApiClient().updateCustomerBanks(bankId, ownerId, workspaceId, requestParams);

                setTimeout(() => {
                    dispatch({
                        type: UPDATE_CUSTOMER_BANK_SUCCESS,
                        payload: requestParams
                    });
                    resolve(requestParams);
                }, 2000);
            } catch (e) {
                dispatch({
                    type: UPDATE_CUSTOMER_BANK_ERROR,
                    payload: { e }
                });
                reject(e);
            }
        });
    };
}

export function getTsPayBanks(ownerId, workspaceId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_TSPAY_BANKS_START,
            payload: { ownerId, workspaceId }
        });

        try {
            const res = await new customerManagementApiClient().getTsPayBanks(ownerId, workspaceId);

            dispatch({
                type: GET_TSPAY_BANKS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_TSPAY_BANKS_ERROR,
                payload: { e }
            });
        }
    };
}
