import styled from "styled-components";

const handleSize = spinnerSize => {
    switch (spinnerSize) {
        case "small":
            return "14px";
        case "default":
            return "24px";
        case "large":
        default:
            return "40px";
    }
};

export const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    color: rgb(0, 144, 209);
    font-size: ${props => handleSize(props.spinnerSize)};
`;
