import { FormattedMessage } from "react-intl";

export const steps = [
    <FormattedMessage id="c-customer-edit-intermediaries.step1" />,
    <FormattedMessage id="c-customer-edit-intermediaries.step2" />,
    <FormattedMessage id="c-customer-edit-intermediaries.step3" />
];

//step 1
export const PERSON = "P";
export const LEGAL = "C";

export const step1DefaultBody = {
    description: "",
    vatNumber: "",
    taxId: ""
};

//step 2
export const step2DefaultBody = {
    id: "",
    description: "",
    siteCode: "",
    entratelCode: "",
    password: "",
    files: [],
    expirationDate: "",
    readOnly: false,
    isSaved: false
};

//step 3
export const step3DefaultBody = {
    id: "",
    taxId: "",
    firstName: "",
    lastName: "",
    description: ""
};
