import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@ts-digital/vrc";
import { Provider } from "react-redux";

import store from "./reducers";
import HandlerRoutes from "./views";
import { GlobalStyle } from "./globalStyles";
import { ParentAppProvider } from "./providers";
import VaporThemeProvider from "@vapor/react-material/styles";

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider>
            <VaporThemeProvider>
                <ParentAppProvider>
                    <HandlerRoutes />
                </ParentAppProvider>
                <GlobalStyle />
            </VaporThemeProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
