import React from "react";
import PropTypes from "prop-types";

import { RadioButton } from "@ts-digital/vrc";

import {
    CardFooterArea,
    CardHeaderArea,
    CardLinkArea,
    Container,
    EditLink,
    EditLinkContainer,
    RadioButtonArea
} from "./styled";
import { FormattedMessage } from "react-intl";
import { Tooltip, Box, Stack, Typography, Button } from "@vapor/react-material";
import { useNavigate } from "react-router";

const SelectorBox = ({ disabled, isComingSoon, cardHeader, cardFooter, onChange, selected, value, onLink }) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    return (
        <Container
            tabIndex={0}
            disabled={disabled || isComingSoon}
            selected={selected}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => onChange(value)}
        >
            <>
                <CardHeaderArea>{cardHeader}</CardHeaderArea>
                <RadioButtonArea>
                    <Box>
                        {disabled ? (
                            <Tooltip
                                placement={"bottom-start"}
                                arrow
                                open={open}
                                title={
                                    <Stack spacing={2} p={2}>
                                        <Stack>
                                            <Typography variant="body700" sx={{ color: "white" }}>
                                                <FormattedMessage id="tooltip.cerved.subject.disabled.tooltip.title" />
                                            </Typography>
                                            <Typography variant="bodySmall" sx={{ color: "white", marginTop: ".3rem" }}>
                                                <FormattedMessage id="tooltip.cerved.subject.disabled.tooltip.description" />
                                            </Typography>
                                        </Stack>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                navigate("/");
                                            }}
                                        >
                                            <FormattedMessage id="tooltip.cerved.subject.disabled.tooltip.button" />
                                        </Button>
                                    </Stack>
                                }
                            >
                                <div>
                                    <RadioButton
                                        checked={selected}
                                        disabled={disabled || isComingSoon}
                                        onChange={onChange}
                                        value={value}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <RadioButton
                                checked={selected}
                                disabled={disabled || isComingSoon}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    </Box>
                </RadioButtonArea>
                <CardFooterArea>{cardFooter}</CardFooterArea>
                <CardLinkArea>
                    {onLink && selected && (
                        <EditLinkContainer onClick={onLink}>
                            <EditLink>
                                <FormattedMessage id="c-new-registry.editData" />
                            </EditLink>
                        </EditLinkContainer>
                    )}
                </CardLinkArea>
            </>
        </Container>
    );
};

SelectorBox.defaultProps = {
    disabled: false,
    isComingSoon: false
};

SelectorBox.propTypes = {
    disabled: PropTypes.bool,
    isComingSoon: PropTypes.bool,
    cardHeader: PropTypes.any.isRequired,
    cardFooter: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onLink: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired
};

export default SelectorBox;
