import customerManagementApiClient from "../apiClient";

export const CREATE_NEW_CLIENT_START = "CREATE_NEW_CLIENT_START";
export const CREATE_NEW_CLIENT_SUCCESS = "CREATE_NEW_CLIENT_SUCCESS";
export const CREATE_NEW_CLIENT_ERROR = "CREATE_NEW_CLIENT_ERROR";
export const CREATE_NEW_CLIENT_RESET = "CREATE_NEW_CLIENT_RESET";

export const SEARCH_FROM_CERVED_START = "SEARCH_FROM_CERVED_START";
export const SEARCH_FROM_CERVED_SUCCESS = "SEARCH_FROM_CERVED_SUCCESS";
export const SEARCH_FROM_CERVED_ERROR = "SEARCH_FROM_CERVED_ERROR";

export function createNewClient(ownerid, params) {
    return async (dispatch, getState) => {
        dispatch({
            type: CREATE_NEW_CLIENT_START,
            payload: { ...params }
        });

        try {
            const res = await new customerManagementApiClient().createNewClient({ ownerId: ownerid, ...params });

            dispatch({
                type: CREATE_NEW_CLIENT_SUCCESS,
                payload: {
                    id: res.workspaceId
                }
            });
        } catch (e) {
            dispatch({
                type: CREATE_NEW_CLIENT_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function searchFromCerved(params) {
    return async (dispatch, getState) => {
        dispatch({
            type: SEARCH_FROM_CERVED_START,
            payload: { ...params }
        });

        try {
            const res = await new customerManagementApiClient().cervedSearch({ ...params });

            dispatch({
                type: SEARCH_FROM_CERVED_SUCCESS,
                payload: {
                    subjects: res
                }
            });

            return res;
        } catch (e) {
            dispatch({
                type: SEARCH_FROM_CERVED_ERROR,
                error: { code: e.response.status, message: e.response.data.message, type: e.response.data.type }
            });
        }
    };
}

export function resetCreateNewClient() {
    return async dispatch => {
        dispatch({
            type: CREATE_NEW_CLIENT_RESET
        });
    };
}
