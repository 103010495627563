import styled from "styled-components";


export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;


export const TooltipTip = styled.div`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: #ffffff;
  background: #005075;
  font-size: 12px;
  font-family: "Roboto";
  line-height: 14px;
  z-index: 100;
  width: ${props => props.width ? props.width : "260px"};
  

  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    transform: translateX(-50%);
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: #005075;
    margin-left: calc(16px * -1);
  }
`;


export const TooltipTipTop = styled(TooltipTip)`
  top: calc(30px * -1);
  transform: translateY(-75%) translateX(-43%);
  &::before {
    top: 100%;
    border-top-color: #005075;
  }
  `;


export const TooltipTipRight = styled(TooltipTip)`
  left: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: calc(-10px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: #005075;
  }
  `;

  export const TooltipTipBottom = styled(TooltipTip)`
  bottom: calc(20px * -1);
  transform: translateX(-43%) translateY(+80%);
  &::before {
    bottom: 100%;
    border-bottom-color: #005075;
  }

  }
  `;


  export const TooltipTipLeft = styled(TooltipTip)`
    left: auto;
    right: calc(100%);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &::before {
      left: auto;
      right: calc(3px * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: #005075;
    }
  }
  `;


