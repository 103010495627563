import { ADA_GET_START, ADA_GET_SUCCESS, ADA_GET_ERROR, ADA_GET_RESET } from "../actions/ada";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function adaInfo(state = defaultState, { type, payload, error }) {
    switch (type) {
        case ADA_GET_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case ADA_GET_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                ada: payload
            };
        case ADA_GET_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        case ADA_GET_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
