import React from "react";
import { FormattedMessage } from "react-intl";
import { GlobalRow } from "../../../../../globalStyles";
import { fieldsSettings } from "../../settings";
import CustomerEditRegistryFormField from "../fields/view";
import CustomerEditRegistryFormEditField from "../fields/edit";
import { FieldContainer } from "../../styled";

const CustomerEditRow = ({ bodyRequest, customerDetails, isEditing, row, setBodyRequest, subSection }) => {
    let rowItems = [];

    row.map((field, index) => {
        const fieldSettings = fieldsSettings?.[field];

        // Check if field is not in settings
        if (!fieldSettings) {
            return rowItems.push(
                <FieldContainer key={`customer-edit-section-row-field_${index}`}>
                    <FormattedMessage id={"general.fieldSettingsNotFound"} values={{ field: field }} />
                </FieldContainer>
            );
        }

        // Check if field has render condition and render condition function return false
        if (fieldSettings.renderCondition && !fieldSettings.renderCondition(bodyRequest)) {
            return null;
        }

        let componentValue = bodyRequest[subSection]?.[field];

        if (!isEditing) {
            return rowItems.push(
                <CustomerEditRegistryFormField
                    key={`customer-edit-section-row-field_${index}`}
                    bodyRequest={bodyRequest}
                    customerDetails={customerDetails}
                    field={field}
                    fieldSettings={fieldSettings}
                    subSection={subSection}
                    value={componentValue}
                />
            );
        }

        return rowItems.push(
            <CustomerEditRegistryFormEditField
                key={`customer-edit-section-row-field_${index}`}
                bodyRequest={bodyRequest}
                field={field}
                fieldSettings={fieldSettings}
                setBodyRequest={setBodyRequest}
                subSection={subSection}
            />
        );
    });
    return <GlobalRow>{rowItems}</GlobalRow>;
};

export default CustomerEditRow;
