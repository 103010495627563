import { Stack, Typography } from "@vapor/react-material";
import React from "react";
import { HorizontalDivider, LeftSideIcon, LeftSideIconDescription } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustryAlt } from "@fortawesome/pro-regular-svg-icons/faIndustryAlt";
import { FormattedMessage } from "react-intl";
import { CustomerChip } from "../../../customer-header/customer-header-data/styled";
import CustomerSections from "../../../customer-sections";
import { sections } from "./constants";

export const BusinessLeftSide = ({ businessId, businessData, activeStep, setActiveStep, disabled, warning }) => {
    return activeStep === 0 && !businessId ? (
        <></>
    ) : activeStep !== 0 && !businessId ? (
        <BusinessSummary businessData={businessData} />
    ) : businessId ? (
        <Stack spacing={6}>
            <BusinessSummary businessData={businessData} />
            <HorizontalDivider />
            <VerticalTabsStepper
                sections={sections}
                section={activeStep}
                setSection={setActiveStep}
                disabled={disabled}
                warning={warning}
            />
        </Stack>
    ) : null;
};

const VerticalTabsStepper = ({ setSection, section, sections, disabled, warning }) => {
    return (
        <CustomerSections
            sections={sections}
            section={section}
            setSection={setSection}
            disabled={disabled}
            warning={warning}
        />
    );
};

const BusinessSummary = ({ businessData }) => {
    return (
        <Stack direction="column" spacing={2}>
            <CustomerChip>
                <FormattedMessage id="c-customer-edit-sections.business" />
            </CustomerChip>
            {businessData?.description && (
                <LeftSideIconDescription>
                    <LeftSideIcon>
                        <FontAwesomeIcon icon={faIndustryAlt} />
                    </LeftSideIcon>

                    <Typography
                        variant="titleMedium"
                        color="primary.textTitleColor"
                        component="div"
                        sx={{
                            maxWidth: "310px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        {businessData?.description}
                    </Typography>
                </LeftSideIconDescription>
            )}
            <Stack spacing={1}>
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2" color="primary.textBodyColor">
                        <FormattedMessage id="c-customer-edit-business.ateco2007code" />
                    </Typography>
                    <Typography variant="body2" color="primary.textBodyColor">
                        {businessData?.atecoCode}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2" color="primary.textBodyColor">
                        <FormattedMessage id="field.registry.denomination" />
                        {": "}
                        {businessData?.atecoCodeDescription}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
