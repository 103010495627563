import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { statusTagMapping } from "./statusMapping";

export const NameAndIconContainer = styled.div`
    display: flex;
    grid-gap: 1rem;
    align-items: center;
`;

export const Name = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StatusContainer = styled.div`
    display: flex;
    grid-gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;
`;

export const Status = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.625rem;
    font-family: Roboto;
    font-weight: normal;
    text-align: right;
    letter-spacing: 0.33px;
    line-height: 1rem;
    margin-top: 0.2rem;
`;

export const DoubleIconContainer = styled.div`
    position: relative;
    display: inline-flex;
`;

export const SecondIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: -5px;
    bottom: 6px;
    font-size: 0.5rem;
`;

export const CircleStatus = styled.div`
    height: 0.5rem;
    width: 0.5rem;
    background-color: ${props => statusTagMapping[props.status].backgroundColor};
    border-radius: 100%;
`;

export const TaxIdContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem 0;
`;

export const Contact = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
