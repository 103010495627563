import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import { useIntl } from "react-intl";
import PageTitle from "../../../components/vapor-components/page-title";
import { Container } from "./styled";
import { useState } from "react";
import { getCustomerDetails } from "../../../actions/customers";
import { getCustomerBanks } from "../../../actions/banks";
import { getCustomerAccounting } from "../../../actions/accounting";
import CustomerEditLayout from "../../../components/customer-edit/customer-edit-layout";
import { getIntermediaries } from "../../../actions/intermediaries";
import { getCustomerIntrastat } from "../../../actions/intrastat";
import { getCurrencies } from "../../../actions/utilities";

const CustomerEditMain = () => {
    const intl = useIntl();
    const [section, setSection] = useState("registry");
    const [isEditing, setIsEditing] = useState(false);
    const [leftSide, setLeftSide] = useState(null);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [backText, setBackText] = useState(null);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    useEffect(() => {
        dispatch(getCustomerDetails(ownerId, workspaceId, `${new Date().getFullYear()}-01-01`));
    }, [dispatch, ownerId, workspaceId]);

    useEffect(() => {
        if (section === "intermediaries") {
            dispatch(getIntermediaries(ownerId));
            //TODO: Dispatch the Action that will get the intermediaries correlated for the workspace/customer
            //dispatch(getIntermediariesCorrelated(ownerId, workspaceId));
        }
        if (section === "creditlines") {
            dispatch(getCustomerBanks(ownerId, workspaceId));
            dispatch(getCurrencies(ownerId));
        }
        if (section === "taxation") {
            dispatch(getCustomerAccounting(ownerId, workspaceId));
        }
        if (section === "intrastat") {
            dispatch(getCustomerIntrastat(ownerId, workspaceId));
        }
    }, [section, dispatch, ownerId, workspaceId]);

    return (
        <Container>
            <PageTitle
                backButtonTitle={
                    backText ||
                    intl.formatMessage({
                        id: "v-customer-dashboard.back-button-label"
                    })
                }
                subtitle={
                    subtitle ||
                    intl.formatMessage({
                        id: "v-customer-edit.subtitle"
                    })
                }
                title={
                    title ||
                    intl.formatMessage({
                        id: "v-customer-edit.title"
                    })
                }
                onBack={() => {
                    if (isEditing) {
                        return setIsEditing(false);
                    }
                    navigate("/");
                }}
            />
            <CustomerEditLayout
                leftSide={leftSide || null}
                section={section}
                setSection={setSection}
                setLeftSide={setLeftSide}
                setTitle={setTitle}
                setSubtitle={setSubtitle}
                setBackText={setBackText}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
            />
        </Container>
    );
};

export default CustomerEditMain;
