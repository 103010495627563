import { faCalculator } from "@fortawesome/pro-regular-svg-icons/faCalculator";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, WsColors } from "@onefront/react-workspace-components";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/react-material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { GlobalRow } from "../../../globalStyles";
import { months } from "../../../utils/constants";
import { Spinner } from "../../vapor-components/spinner";
import { EditFormFooter, FormContainer } from "../customer-edit-registry/customer-edit-registry-form/styled";
import {
    MainTitleDesc,
    MainTitleText,
    Section,
    Sections,
    SectionTitle,
    SpinnerContainer,
    TitleIcon,
    TitleText
} from "../customer-edit-registry/styled";
import { LockedCardContainer, LockedCardContent, LockedCardTitle } from "./styled";
import { useSelector } from "react-redux";
import LoadingSkeleton from "../customer-edit-area/loading-skeleton";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createCustomerAccounting, getCustomerAccounting, updateCustomerAccounting } from "../../../actions/accounting";
import { useMatch } from "react-router";
import { CustomerEditFeedbackContainer } from "../customer-edit-banks/styled";
import VaporAlert from "../../vapor-components/alert";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faSun } from "@fortawesome/pro-regular-svg-icons/faSun";
import { faSun as faSuns } from "@fortawesome/pro-solid-svg-icons/faSun";

const initialState = (taxationData = {}) => {
    return Object.keys(taxationData).length > 0
        ? taxationData
        : {
              accountingPeriodEndMonth: 12,
              accountingPeriodEndDay: 31,
              firstAccountingPeriod: false
          };
};

const CustomerEditTaxation = ({ render }) => {
    const customer = useSelector(state => state.customer?.details);
    const taxationData = customer.additionalProps?.accounting;
    const isSavingData = customer.additionalProps?.isSavingAccounting;
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const dispatch = useDispatch();
    let blockedAccountingPeriod = taxationData?.blockedAccountingPeriod;

    const [isEditing, setIsEditing] = useState(false);
    const [errors, setErrors] = useState({});
    const [enableSave, setEnableSave] = useState(false);
    const [bodyRequest, setBodyRequest] = useState(initialState(taxationData));
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [firstAccountingPeriodDifference, setFirstAccountingPeriodDifference] = useState(null);
    const [financialAdjustmentDialog, setFinancialAdjustmentDialog] = useState(false);

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    const intl = useIntl();

    useEffect(() => {
        setBodyRequest(initialState(taxationData));
    }, [taxationData]);

    useEffect(() => {
        let enable = true;
        if (!bodyRequest.accountingPeriodEndMonth) {
            enable = false;
        }
        if (!bodyRequest.accountingPeriodEndDay) {
            enable = false;
        }
        if (bodyRequest.firstAccountingPeriod === null || bodyRequest.firstAccountingPeriod === undefined) {
            enable = false;
        }
        if (
            bodyRequest.firstAccountingPeriod === true &&
            bodyRequest.firstAccountingPeriodStartDate &&
            bodyRequest.firstAccountingPeriodEndDate
        ) {
            try {
                const periodDifference = Math.floor(
                    moment
                        .duration(
                            moment(bodyRequest.firstAccountingPeriodEndDate).diff(
                                moment(bodyRequest.firstAccountingPeriodStartDate)
                            )
                        )
                        .asMonths()
                );
                if (periodDifference > 15 || periodDifference < 12) {
                    enable = false;
                }
                setFirstAccountingPeriodDifference(periodDifference);
            } catch (e) {}
        }
        setEnableSave(enable);
        setErrors({});
    }, [bodyRequest, setEnableSave]);

    useEffect(() => {
        let editing = true;
        if (taxationData?.id) {
            editing = false;
            setBodyRequest({
                ...taxationData,
                firstAccountingPeriodStartDate: moment(taxationData.firstAccountingPeriodStartDate),
                firstAccountingPeriodEndDate: moment(taxationData.firstAccountingPeriodEndDate),
                accountingPeriodEndDay: taxationData.accountingPeriodEndDate.accountingPeriodEndDay,
                accountingPeriodEndMonth: taxationData.accountingPeriodEndDate.accountingPeriodEndMonth
            });
        }
        setIsEditing(editing);
    }, [taxationData]);

    if (!render) {
        return null;
    }

    const checkAndSave = () => {
        let haveErrors = false;
        let newErrors = {};
        if (bodyRequest.firstAccountingPeriod === true) {
            if (!bodyRequest.firstAccountingPeriodStartDate) {
                haveErrors = true;
                newErrors["firstAccountingPeriodStartDate"] = "required";
            }
            if (!bodyRequest.firstAccountingPeriodEndDate) {
                haveErrors = true;
                newErrors["firstAccountingPeriodEndDate"] = "required";
            }
        }
        setErrors(newErrors);
        if (!haveErrors) {
            setShowSaveConfirm(true);
        }
    };

    const confirmSave = () => {
        setShowSaveConfirm(false);
        if (bodyRequest.id) {
            dispatch(
                updateCustomerAccounting(bodyRequest.id, ownerId, workspaceId, {
                    firstAccountingPeriod: bodyRequest.firstAccountingPeriod,
                    firstAccountingPeriodStartDate: bodyRequest.firstAccountingPeriodStartDate
                        ? moment(bodyRequest.firstAccountingPeriodStartDate).format("YYYY-MM-DD") +
                          "T00:00:00.000+00:00"
                        : "",
                    firstAccountingPeriodEndDate: bodyRequest.firstAccountingPeriodEndDate
                        ? moment(bodyRequest.firstAccountingPeriodEndDate).format("YYYY-MM-DD") + "T00:00:00.000+00:00"
                        : "",
                    accountingPeriodEndDate: {
                        accountingPeriodEndMonth: bodyRequest.accountingPeriodEndMonth,
                        accountingPeriodEndDay: bodyRequest.accountingPeriodEndDay
                    }
                })
            )
                .then(res => {
                    dispatch(getCustomerAccounting(ownerId, workspaceId));
                    setFeedback("success");
                })
                .catch(err => {
                    setFeedback("error");
                });
        } else {
            dispatch(
                createCustomerAccounting(ownerId, workspaceId, {
                    firstAccountingPeriod: bodyRequest.firstAccountingPeriod,
                    firstAccountingPeriodStartDate: bodyRequest.firstAccountingPeriodStartDate
                        ? moment(bodyRequest.firstAccountingPeriodStartDate).format("YYYY-MM-DD") +
                          "T00:00:00.000+00:00"
                        : "",
                    firstAccountingPeriodEndDate: bodyRequest.firstAccountingPeriodEndDate
                        ? moment(bodyRequest.firstAccountingPeriodEndDate).format("YYYY-MM-DD") + "T00:00:00.000+00:00"
                        : "",
                    accountingPeriodEndDate: {
                        accountingPeriodEndMonth: bodyRequest.accountingPeriodEndMonth,
                        accountingPeriodEndDay: bodyRequest.accountingPeriodEndDay
                    }
                })
            )
                .then(res => {
                    dispatch(getCustomerAccounting(ownerId, workspaceId));
                    setFeedback("success");
                })
                .catch(err => {
                    setFeedback("error");
                });
        }
        setIsEditing(false);
    };

    const confirmFinancialAdjustmentDialog = () => {
        setFinancialAdjustmentDialog(false);
        dispatch(
            updateCustomerAccounting(bodyRequest.id, ownerId, workspaceId, {
                accountingPeriodEndDate: {
                    accountingPeriodEndMonth: 12,
                    accountingPeriodEndDay: 31
                }
            })
        )
            .then(res => {
                dispatch(getCustomerAccounting(ownerId, workspaceId));
                setFeedback("success");
            })
            .catch(err => {
                setFeedback("error");
            });
    };

    const getDays = () => {
        let days = [];
        for (let x = 0; x < 31; x++) {
            days.push({ id: x + 1, value: x + 1 });
        }
        return days;
    };

    const getMonths = () => {
        return months.map(month => {
            return { id: month.id, value: intl.formatMessage({ id: `months.${month.value}` }) };
        });
    };

    const checkAdjustmentRequirements = () => {
        if (
            bodyRequest?.firstAccountingPeriodEndDate?.date?.() === 31 &&
            bodyRequest?.firstAccountingPeriodEndDate?.months?.() === 11
        ) {
            return true;
        }
        return false;
    };

    let datePlus;
    datePlus = customer?.data?.personalData?.establishmentDate
        ? new Date(customer?.data?.personalData?.establishmentDate)?.toISOString()?.split("-")[0]
        : null;

    if (datePlus) {
        datePlus = parseInt(datePlus) + 1;
    }

    const FinancialAdjustmentDialog = () => {
        return (
            <Dialog
                open={financialAdjustmentDialog}
                onClose={() => setFinancialAdjustmentDialog(false)}
                aria-labelledby="taxation-dialog-adjustment-title"
                aria-describedby="taxation-dialog-adjustment-description"
            >
                <DialogTitle>
                    <FormattedMessage id="c-customer-edit-section.taxation.adjustment.title" />
                    <IconButton color="primary" onClick={() => setFinancialAdjustmentDialog(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ maxWidth: 400 }}>
                    <DialogContentText id="taxation-dialog-adjustment-description">
                        <FormattedMessage
                            id="c-customer-edit-section.taxation.adjustment.content"
                            values={{ months: "mesi" }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFinancialAdjustmentDialog(false)} variant="outlined">
                        <FormattedMessage id="general.cancel" />
                    </Button>
                    <Button onClick={() => confirmFinancialAdjustmentDialog()} variant="contained" color="primary">
                        <FormattedMessage id="general.adjust" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Sections>
            {financialAdjustmentDialog && <FinancialAdjustmentDialog />}
            {showSaveConfirm && (
                <Dialog
                    open={showSaveConfirm}
                    onClose={() => setShowSaveConfirm(false)}
                    aria-labelledby="taxation-alert-dialog-title"
                    aria-describedby="taxation-alert-dialog-description"
                >
                    <DialogTitle>
                        <FormattedMessage id="c-customer-edit-section.taxation.confirm.title" />
                        <IconButton color="primary" onClick={() => setShowSaveConfirm(false)}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider variant="alert" />
                    <DialogContent style={{ maxWidth: 320 }}>
                        <DialogContentText id="taxation-alert-dialog-description">
                            <FormattedMessage id="c-customer-edit-section.taxation.confirm.content" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowSaveConfirm(false)} variant="outlined">
                            <FormattedMessage id="general.cancel" />
                        </Button>
                        <Button onClick={() => confirmSave()} variant="contained" color="error">
                            <FormattedMessage id="general.confirm" />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Section>
                <SectionTitle vertical>
                    <MainTitleText>
                        <FontAwesomeIcon icon={faCalculator} />{" "}
                        <FormattedMessage id={"c-customer-edit-section.taxation.mainData"} />
                    </MainTitleText>
                    <MainTitleDesc>
                        <FormattedMessage id={"c-customer-edit-section.taxation.mainDataDesc"} />
                    </MainTitleDesc>
                    {feedback && (
                        <CustomerEditFeedbackContainer>
                            <VaporAlert
                                type={feedback === "error" ? "error" : "success"}
                                message={<FormattedMessage id={`feedbacks.accounting.${feedback}`} />}
                                title={<FormattedMessage id={`feedbacks.accounting.${feedback}.title`} />}
                                onClose={() => setFeedback(null)}
                                open={true}
                                anchor={{
                                    top: "124px"
                                }}
                            />
                        </CustomerEditFeedbackContainer>
                    )}
                </SectionTitle>
                {!isEditing && isSavingData && (
                    <SpinnerContainer>
                        <Spinner loading />
                    </SpinnerContainer>
                )}
            </Section>
            {((!isEditing && isSavingData) || customer?.additionalProps?.isGettingAccounting) && <LoadingSkeleton />}
            {!customer?.additionalProps?.isGettingAccounting && (isEditing || (!isEditing && !isSavingData)) && (
                <FormContainer isEditing={isEditing}>
                    <Section>
                        {!blockedAccountingPeriod && (
                            <>
                                <SectionTitle style={{ maxWidth: 730 }} margin={"4px 0 14px 0"}>
                                    <TitleText>
                                        <FormattedMessage
                                            id={"c-customer-edit-section.taxation.financialStatementTitle"}
                                        />{" "}
                                    </TitleText>
                                    {!isEditing && (
                                        <TitleIcon>
                                            <Button
                                                onClick={() => {
                                                    setIsEditing("financialStatement");
                                                }}
                                                color="secondary"
                                            >
                                                <FormattedMessage id="general.edit" />
                                                <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 12 }} />
                                            </Button>
                                        </TitleIcon>
                                    )}
                                </SectionTitle>
                                {isEditing && (
                                    <GlobalRow>
                                        <LockedCardContainer style={{ maxWidth: 700 }}>
                                            <LockedCardTitle>
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    style={{ marginRight: 4, color: WsColors.DarkImperialBlue }}
                                                />{" "}
                                                <FormattedMessage id="c-customer-edit-section.taxation.financialStatementTipTitle" />
                                            </LockedCardTitle>
                                            <LockedCardContent>
                                                <FormattedMessage id="c-customer-edit-section.taxation.financialStatementTipDesc" />
                                            </LockedCardContent>
                                        </LockedCardContainer>
                                    </GlobalRow>
                                )}

                                <GlobalRow>
                                    <Field
                                        isEditing={isEditing}
                                        withMargin
                                        fullWidth
                                        component={"radio"}
                                        radioGroupAlign={"vertical"}
                                        minWidth={"45vw"}
                                        options={[
                                            { id: "true", value: intl.formatMessage({ id: "general.yes" }) },
                                            { id: "false", value: intl.formatMessage({ id: "general.no" }) }
                                        ]}
                                        value={bodyRequest.firstAccountingPeriod === true ? "true" : "false"}
                                        onChange={val => {
                                            setBodyRequest({
                                                ...bodyRequest,
                                                firstAccountingPeriod: val === "true",
                                                firstAccountingPeriodStartDate:
                                                    bodyRequest.firstAccountingPeriodStartDate ||
                                                    moment(customer?.data?.personalData?.establishmentDate) ||
                                                    null,
                                                firstAccountingPeriodEndDate:
                                                    val === "true"
                                                        ? new Date(
                                                              `${datePlus || new Date().getFullYear()}-${
                                                                  bodyRequest.accountingPeriodEndMonth
                                                              }-${bodyRequest.accountingPeriodEndDay}`
                                                          )
                                                        : ""
                                            });
                                        }}
                                        label={intl.formatMessage({ id: "field.taxation.firstAccountingPeriod" })}
                                    />
                                </GlobalRow>
                            </>
                        )}
                        {bodyRequest.firstAccountingPeriod === true && (
                            <>
                                <SectionTitle style={{ maxWidth: 730 }}>
                                    <TitleText>
                                        <FormattedMessage id="c-customer-edit-section.taxation.firstTitle" />
                                    </TitleText>
                                    {blockedAccountingPeriod &&
                                        !isEditing &&
                                        bodyRequest.firstAccountingPeriod === true && (
                                            <TitleIcon>
                                                <Button
                                                    onClick={() => {
                                                        setIsEditing("financialStatement");
                                                    }}
                                                    color="secondary"
                                                >
                                                    <FormattedMessage id="general.edit" />
                                                    <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 12 }} />
                                                </Button>
                                            </TitleIcon>
                                        )}
                                </SectionTitle>
                                <GlobalRow>
                                    <Field
                                        isEditing={isEditing && !blockedAccountingPeriod}
                                        withMargin
                                        style={{ paddingRight: 16, width: 220 }}
                                        component={"datepicker"}
                                        value={bodyRequest.firstAccountingPeriodStartDate}
                                        onChange={val => {
                                            if (!val) {
                                                return setBodyRequest({
                                                    ...bodyRequest,
                                                    firstAccountingPeriodStartDate: null
                                                });
                                            }
                                            setBodyRequest({
                                                ...bodyRequest,
                                                firstAccountingPeriodStartDate:
                                                    isNaN(Date.parse(val)) === false ? new Date(val) : null
                                            });
                                        }}
                                        label={intl.formatMessage({
                                            id: "field.taxation.firstAccountingPeriodStartDate"
                                        })}
                                        additionalProps={
                                            errors.firstAccountingPeriodStartDate
                                                ? {
                                                      helperText: intl.formatMessage({
                                                          id: `error.taxation.firstAccountingPeriodStartDate.${errors.firstAccountingPeriodStartDate}`
                                                      }),
                                                      error: true
                                                  }
                                                : {}
                                        }
                                    />
                                    <Field
                                        isEditing={isEditing && !blockedAccountingPeriod}
                                        withMargin
                                        style={{ paddingRight: 16, width: 220 }}
                                        component={"datepicker"}
                                        value={bodyRequest.firstAccountingPeriodEndDate}
                                        onChange={val => {
                                            if (!val) {
                                                return setBodyRequest({
                                                    ...bodyRequest,
                                                    firstAccountingPeriodEndDate: null
                                                });
                                            }
                                            setBodyRequest({
                                                ...bodyRequest,
                                                firstAccountingPeriodEndDate:
                                                    isNaN(Date.parse(val)) === false ? new Date(val) : null
                                            });
                                        }}
                                        label={intl.formatMessage({
                                            id: "field.taxation.firstAccountingPeriodEndDate"
                                        })}
                                        additionalProps={
                                            errors.firstAccountingPeriodEndDate
                                                ? {
                                                      helperText: intl.formatMessage({
                                                          id: `error.taxation.firstAccountingPeriodEndDate.${errors.firstAccountingPeriodEndDate}`
                                                      }),
                                                      style: { width: 190 },
                                                      error: true
                                                  }
                                                : !blockedAccountingPeriod &&
                                                  (firstAccountingPeriodDifference > 15 ||
                                                      firstAccountingPeriodDifference < 12)
                                                ? {
                                                      helperText: intl.formatMessage({
                                                          id:
                                                              firstAccountingPeriodDifference > 15
                                                                  ? `error.taxation.firstAccountingPeriodDifferenceMore`
                                                                  : `error.taxation.firstAccountingPeriodDifferenceLess`
                                                      }),
                                                      style: { width: 190 },
                                                      error: true
                                                  }
                                                : {
                                                      style: { width: 190 }
                                                  }
                                        }
                                    />
                                    {isEditing &&
                                        !blockedAccountingPeriod &&
                                        firstAccountingPeriodDifference > 12 &&
                                        firstAccountingPeriodDifference < 16 && (
                                            <Box
                                                display={"flex"}
                                                alignItems="flex-end"
                                                style={{ paddingBottom: 24, fontWeight: "normal" }}
                                            >
                                                <Box>
                                                    <FormattedMessage
                                                        id="field.taxation.firstAccountingPeriodDifference"
                                                        values={{ months: firstAccountingPeriodDifference }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        style={{ color: WsColors.IslamicGreen, marginLeft: 5 }}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                </GlobalRow>
                            </>
                        )}
                        {bodyRequest.firstAccountingPeriod !== null &&
                            ((isEditing && !bodyRequest.firstAccountingPeriod) || !isEditing) && (
                                <>
                                    <SectionTitle style={{ maxWidth: 730 }}>
                                        <TitleText>
                                            <FormattedMessage id="c-customer-edit-section.taxation.financialStatementDuration" />
                                        </TitleText>
                                        {blockedAccountingPeriod &&
                                            !isEditing &&
                                            bodyRequest.firstAccountingPeriod === false && (
                                                <TitleIcon>
                                                    <Button
                                                        onClick={() => {
                                                            setIsEditing("financialStatement");
                                                        }}
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage id="general.edit" />
                                                        <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 12 }} />
                                                    </Button>
                                                </TitleIcon>
                                            )}
                                    </SectionTitle>
                                    <GlobalRow style={{ alignItems: "flex-start" }}>
                                        {!isEditing && (
                                            <>
                                                <Field
                                                    isEditing={isEditing}
                                                    withMargin
                                                    style={{ paddingRight: 16 }}
                                                    component="textfield"
                                                    selectHeight="320px"
                                                    value={`${bodyRequest.accountingPeriodEndDay} ${
                                                        getMonths().find(
                                                            x => x.id === bodyRequest.accountingPeriodEndMonth
                                                        )?.value
                                                    }`}
                                                    label={intl.formatMessage({
                                                        id: "field.taxation.financialStatementClosingDayMonth"
                                                    })}
                                                />
                                                {bodyRequest.blockedAccountingPeriod &&
                                                    bodyRequest.accountingPeriodEndDay === 31 &&
                                                    bodyRequest.accountingPeriodEndMonth === 12 && (
                                                        <Box style={{ color: WsColors.IslamicGreen, paddingTop: 46 }}>
                                                            <FormattedMessage id="field.taxation.financialStatementClosingDayMonthAdjusted" />
                                                            <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faSuns} />
                                                        </Box>
                                                    )}
                                            </>
                                        )}
                                        {isEditing && (
                                            <>
                                                <Field
                                                    isEditing={isEditing}
                                                    withMargin
                                                    style={{ paddingRight: 16 }}
                                                    component={"select"}
                                                    selectHeight="320px"
                                                    options={getDays()}
                                                    value={bodyRequest.accountingPeriodEndDay}
                                                    onChange={val =>
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            accountingPeriodEndDay: val
                                                            /*firstAccountingPeriodStartDate: null,
                                                firstAccountingPeriodEndDate:
                                                    bodyRequest.firstAccountingPeriod === true
                                                        ? new Date(
                                                              `${new Date().getFullYear()}-${
                                                                  bodyRequest.accountingPeriodEndMonth
                                                              }-${val}`
                                                          )
                                                        : null*/
                                                        })
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "field.taxation.financialStatementClosingDay"
                                                    })}
                                                />
                                                <Field
                                                    isEditing={isEditing}
                                                    withMargin
                                                    style={{ paddingLeft: 16 }}
                                                    component={"select"}
                                                    selectHeight="320px"
                                                    minWidth={160}
                                                    options={getMonths()}
                                                    value={bodyRequest.accountingPeriodEndMonth}
                                                    onChange={val =>
                                                        setBodyRequest({
                                                            ...bodyRequest,
                                                            accountingPeriodEndMonth: val
                                                            /*firstAccountingPeriodStartDate: null,
                                                firstAccountingPeriodEndDate:
                                                    bodyRequest.firstAccountingPeriod === true
                                                        ? new Date(
                                                              `${new Date().getFullYear()}-${val}-${
                                                                  bodyRequest.accountingPeriodEndDay
                                                              }`
                                                          )
                                                        : null*/
                                                        })
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "field.taxation.financialStatementClosingMonth"
                                                    })}
                                                />
                                            </>
                                        )}
                                    </GlobalRow>
                                    {blockedAccountingPeriod &&
                                        isEditing &&
                                        checkAdjustmentRequirements() &&
                                        !(
                                            bodyRequest.accountingPeriodEndDay === 31 &&
                                            bodyRequest.accountingPeriodEndMonth === 12
                                        ) && (
                                            <GlobalRow
                                                style={{
                                                    marginBottom: 8,
                                                    width: 714,
                                                    height: 65,
                                                    background: WsColors.WhiteSmoke,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    paddingLeft: 16
                                                }}
                                            >
                                                <Box>
                                                    <FontAwesomeIcon
                                                        icon={faExclamationTriangle}
                                                        style={{ color: WsColors.Crayola }}
                                                    />
                                                </Box>
                                                <Box ml={2}>
                                                    <FormattedMessage id="field.taxation.financialStatementAdjustment" />
                                                </Box>
                                                <Box ml={2}>
                                                    <Button
                                                        onClick={() => setFinancialAdjustmentDialog(true)}
                                                        variant="outlined"
                                                    >
                                                        <FontAwesomeIcon icon={faSun} style={{ marginRight: 5 }} />
                                                        <FormattedMessage id="field.taxation.financialStatementAdjustmentButton" />
                                                    </Button>
                                                </Box>
                                            </GlobalRow>
                                        )}
                                </>
                            )}
                    </Section>
                    {isEditing && (
                        <EditFormFooter style={{ justifyContent: "flex-start", paddingTop: 4, paddingBottom: 8 }}>
                            {bodyRequest?.id && (
                                <Button
                                    onClick={() => {
                                        setBodyRequest(
                                            taxationData?.id
                                                ? {
                                                      ...taxationData,
                                                      firstAccountingPeriodStartDate: moment(
                                                          taxationData.firstAccountingPeriodStartDate
                                                      ),
                                                      firstAccountingPeriodEndDate: moment(
                                                          taxationData.firstAccountingPeriodEndDate
                                                      ),
                                                      accountingPeriodEndDay:
                                                          taxationData.accountingPeriodEndDate.accountingPeriodEndDay,
                                                      accountingPeriodEndMonth:
                                                          taxationData.accountingPeriodEndDate.accountingPeriodEndMonth
                                                  }
                                                : initialState(taxationData)
                                        );
                                        setIsEditing(false);
                                    }}
                                    style={{ width: 92 }}
                                    size="small"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="general.cancel" />
                                </Button>
                            )}
                            <Button
                                onClick={() => checkAndSave()}
                                disabled={!enableSave}
                                style={{ width: 92 }}
                                size="small"
                                color="primary"
                                variant="contained"
                            >
                                <FormattedMessage id="general.save" />
                            </Button>
                        </EditFormFooter>
                    )}
                </FormContainer>
            )}
            <Divider light style={{ width: 730 }} />
            {!customer?.additionalProps?.isGettingAccounting && (isEditing || (!isEditing && !isSavingData)) && (
                <Section>
                    <TitleText style={{ margin: "14px 0" }}>
                        <FormattedMessage id={"c-customer-edit-section.taxation.chartAccounts"} />
                    </TitleText>
                    <GlobalRow>
                        <LockedCardContainer style={{ maxWidth: 700 }}>
                            <LockedCardTitle>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    style={{ marginRight: 4, color: WsColors.DarkImperialBlue }}
                                />{" "}
                                <FormattedMessage id="field.taxation.chartAccounts.standard.title" />
                            </LockedCardTitle>
                            <LockedCardContent>
                                <FormattedMessage id="field.taxation.chartAccounts.standard.desc" />
                            </LockedCardContent>
                        </LockedCardContainer>
                    </GlobalRow>
                </Section>
            )}
        </Sections>
    );
};

export default CustomerEditTaxation;
