import React from "react";
import { FormControl, InputLabel, FormHelperText } from "@vapor/react-material";
import { v4 } from "uuid";
import PropTypes from "prop-types";

const VaporFormField = ({ children, fullWidth, helperText, id, label, withMargin, style }) => {
    let childrenLength = -1;
    try {
        childrenLength = children.length;
    } catch (e) {
        // no actions
    }
    return (
        <FormControl fullWidth={fullWidth} style={{ ...style, margin: withMargin ? "16px 0" : "unset" }}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            {childrenLength === -1 || children > 1 ? children : React.cloneElement(children, { id: id })}
            {helperText && <FormHelperText id={id + "-helper"}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

VaporFormField.defaultProps = {
    fullWidth: false,
    label: null,
    style: {},
    children: <></>,
    id: "vapor-formField_" + v4()
};

VaporFormField.propTypes = {
    fullWidth: PropTypes.bool,
    label: PropTypes.any,
    style: PropTypes.object,
    children: PropTypes.node,
    id: PropTypes.string
};

export default VaporFormField;
