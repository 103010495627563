import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";

import { SpinnerContainer } from "./styled";

/**
 * Component that either renders a centered spinner
 * or renders its children based on the value of the
 * loading prop.
 */
export const Spinner = ({ children, loading, spinnerSize }) =>
    loading ? (
        <SpinnerContainer size={spinnerSize}>
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
        </SpinnerContainer>
    ) : (
        children
    );

Spinner.propTypes = {
    children: PropTypes.any.isRequired,
    /** if true, displays the spinner, otherwise displays the children */
    loading: PropTypes.bool,
    /** size of the spinner image */
    spinnerSize: PropTypes.oneOf(["default", "large", "small"])
};

Spinner.defaultProps = {
    spinnerSize: "large"
};
