import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TypeCards, StepTitle } from "../../styled";
import { radioCardsList } from "../../constants";
import { H1 } from "../../../../vapor-components/typography";
import TypeSelectorBoxesField from "../../../../general/form-radioCards";

const NewClientType = ({ setSelectedRadio, selectedRadio, formParamsCerved }) => {
    return (
        <div>
            <StepTitle>
                <H1 color="yankeesBlue">
                    <FormattedMessage id="c-new-registry.title2" />
                </H1>
            </StepTitle>
            <TypeCards>
                <TypeSelectorBoxesField
                    name={"classifier"}
                    selectedRadio={selectedRadio}
                    setSelectedRadio={setSelectedRadio}
                    radioCardsList={radioCardsList}
                    initialValue={formParamsCerved ? formParamsCerved.classifier : ""}
                />
            </TypeCards>
        </div>
    );
};

NewClientType.propTypes = {
    setStepNumber: PropTypes.func,
    setSelectedRadio: PropTypes.func,
    selectedRadio: PropTypes.string,
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool
};

export default NewClientType;
