import CustomerManagementApiClient from "../../apiClient";

export const GET_ARCHIVE_INFO_START = "GET_ARCHIVE_INFO_START";
export const GET_ARCHIVE_INFO_SUCCESS = "GET_ARCHIVE_INFO_SUCCESS";
export const GET_ARCHIVE_INFO_ERROR = "GET_ARCHIVE_INFO_ERROR";
export const GET_ARCHIVE_INFO_RESET = "GET_ARCHIVE_INFO_RESET";

export const customerManagementApiClient = new CustomerManagementApiClient();

export function getArchiveInfo(ownerId, itemId) {
    return async dispatch => {
        dispatch({
            type: GET_ARCHIVE_INFO_START
        });

        try {
            const res = await customerManagementApiClient.getArchiveInfo(ownerId, itemId);
            dispatch({
                type: GET_ARCHIVE_INFO_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_ARCHIVE_INFO_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetGetArchiveInfo() {
    return dispatch => {
        dispatch({
            type: GET_ARCHIVE_INFO_RESET
        });
    };
}
