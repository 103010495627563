import customerManagementApiClient from "../apiClient/";

export const GET_CURRENCIES_START = "GET_CURRENCIES_START";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_ERROR = "GET_CURRENCIES_ERROR";
export const GET_CURRENCIES_RESET = "GET_CURRENCIES_RESET";

export function getCurrencies(ownerId) {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_CURRENCIES_START,
            payload: { ownerId }
        });

        try {
            const res = await new customerManagementApiClient().getCurrencies(ownerId);

            dispatch({
                type: GET_CURRENCIES_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_CURRENCIES_ERROR,
                payload: { e }
            });
        }
    };
}
