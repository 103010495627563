import React, { useState, useCallback, useEffect } from "react";
import { CustomerEditBusinessBody, CustomerEditBusinessContainer } from "./styled";
import { Button, Stack, Step, StepLabel, Stepper } from "@vapor/react-material";
import { defaultBusinessBody, steps } from "./constants";
import { StickyFooter } from "../../../vapor-components/sticky-footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { CustomerEditBusinessStep1 } from "./steps/step1";
import { CustomerEditBusinessStep2 } from "./steps/step2";
import { CustomerEditBusinessStep3 } from "./steps/step3";
import { CustomerEditBusinessStep4 } from "./steps/step4";
import { useDispatch, useSelector } from "react-redux";
import { createNewBusiness, getAssetGroups, updateBusiness } from "../../../../actions/business";
import { BusinessLeftSide } from "./leftside";
import { AlertMessage } from "../../../alert-message";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons/faCircleNotch";
import { LoadingIcon } from "../../../vapor-components/table";
import { CustomerEditBusinessStep5 } from "./steps/step5";
import { CodeViewer } from "@onefront/react-workspace-components";
import { useBrowserStorage } from "../../../../hook/useBrowserStorage";

const CustomerEditBusiness = ({
    workspaceId,
    businessList,
    businessId,
    onSave,
    setIsEditing,
    setLeftSide,
    activeStep,
    setActiveStep,
    isEditingStep,
    setIsEditingStep
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [bodyRequest, setBodyRequest] = useState({ ...defaultBusinessBody });
    const [patchBody, setPatchBody] = useState({});
    const [errors, setErrors] = useState({});
    const [usedAtecos, setUsedAtecos] = useState([]);
    const [prevalentBusiness, setPrevalentBusiness] = useState(null);
    const [displayErrors, setDisplayErrors] = useState(false);
    const [businessData, setBusinessData] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);
    const [loadingSubmit, setLaodingSubmit] = useState(false);

    const assetList = useSelector(state => state.asset?.result?.items);
    const assetStatus = useSelector(state => state.asset?.status);
    const ivaProRataHandling = useBrowserStorage("noIvaProRataHandling");
    const ivaProRataHandlingCheck = ivaProRataHandling
        ?.get()
        ?.find(item => item.businessId === businessId && item.year < new Date(new Date().getFullYear(), 0, 15));

    useEffect(() => {
        if (businessList && businessList?.length > 0) {
            setUsedAtecos(businessList.map(item => item.atecoCode));
            setPrevalentBusiness(businessList.find(item => item.type === "MAIN"));
        }
    }, [businessList]);

    useEffect(() => {
        if (businessId) {
            const business = businessList.find(item => item.id === businessId);
            if (!businessData) {
                setBodyRequest({ ...business, sectionals: business?.sectionals.sort((a, b) => b.main - a.main) || [] });
                setBusinessData(business);
            }
            setUsedAtecos(businessList.filter(item => item.id !== businessId).map(item => item.atecoCode));
        } else {
            setBodyRequest({ ...defaultBusinessBody });
        }
    }, [businessId, businessList, businessData]);

    useEffect(() => {
        setLeftSide(
            <BusinessLeftSide
                businessId={businessData?.id}
                businessData={businessData || bodyRequest}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                warning={isEditingStep}
            />
        );
    }, [activeStep, setActiveStep, businessData, setLeftSide, isEditingStep, bodyRequest]);

    useEffect(() => {
        if (!assetList) {
            dispatch(getAssetGroups());
        }
    }, [dispatch, assetList]);

    useEffect(() => {
        document.body.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [activeStep]);

    const handleChange = useCallback(
        (value, key) => {
            if (!Object.keys(errors).some(key => errors[key] === true)) {
                setDisplayErrors(false);
            }

            setBodyRequest(prevState => ({
                ...prevState,
                [key]: value
            }));

            if (businessId) {
                setPatchBody(prevState => ({
                    ...prevState,
                    [key]: value
                }));
            }
        },
        [errors, businessId]
    );

    const handleEditCancel = useCallback(() => {
        setIsEditingStep(false);
        setBodyRequest(businessData);
        setPatchBody({});
    }, [setIsEditingStep, businessData]);

    const handleEditPatch = useCallback(() => {
        setLaodingSubmit(true);

        if (Object.keys(errors).every(item => errors[item] === false)) {
            dispatch(updateBusiness(workspaceId, businessId, patchBody)).then(res => {
                setLaodingSubmit(false);
                setIsEditingStep(false);
                setPatchBody({});
                setAlertMessage({
                    status: "success",
                    title: intl.formatMessage({ id: "business.edit.success" }),
                    description: (
                        <FormattedMessage
                            id="business.edit.success.description"
                            values={{ businessName: bodyRequest?.description }}
                        />
                    )
                });
            });

            window.scrollTo({ top: 0, behavior: "smooth" });

            if (activeStep === 4) {
                if (!ivaProRataHandlingCheck) {
                    ivaProRataHandling.set([
                        ...(ivaProRataHandling.get() || []),
                        {
                            businessId: businessId,
                            year: new Date().getFullYear()
                        }
                    ]);
                }
            }
        } else {
            setDisplayErrors(true);
        }
    }, [
        errors,
        setIsEditingStep,
        dispatch,
        bodyRequest,
        businessId,
        workspaceId,
        intl,
        patchBody,
        activeStep,
        ivaProRataHandlingCheck,
        ivaProRataHandling
    ]);

    const payloadRefactor = (mode = "output", payload) => {
        let newPayload = { ...payload };

        //If there are no sectional with main = true, set the first one as main
        if (!newPayload?.sectionals.some(item => item.main === true)) {
            newPayload.sectionals[0].main = true;
        }

        //remove id from sectional and ivaProRata
        newPayload.sectionals = payload.sectionals?.map(item => {
            return Object.keys(item).reduce((result, key) => {
                if (key !== "id") {
                    result[key] = item[key];
                }
                return result;
            }, {});
        });

        newPayload.ivaProRata = payload.ivaProRata?.map(item => {
            return Object.keys(item).reduce((result, key) => {
                if (key !== "id") {
                    result[key] = item[key];
                }
                return result;
            }, {});
        });

        return newPayload;
    };

    const onSubmit = useCallback(
        payload => {
            setLaodingSubmit(true);
            dispatch(createNewBusiness(workspaceId, payloadRefactor("output", payload)))
                .then(res => {
                    setBodyRequest({ ...defaultBusinessBody });
                    setLaodingSubmit(false);
                    setIsEditing(false);
                    onSave(
                        "success",
                        "Attività creata con successo",
                        `L'attività ${bodyRequest?.description} è stata creata con successo`
                    );
                })
                .catch(err => {
                    onSave("error", "Errore", err.toString);
                });
        },
        [workspaceId, dispatch, setIsEditing, bodyRequest?.description, onSave]
    );

    return (
        <CustomerEditBusinessContainer>
            {!businessId && (
                <Stepper activeStep={activeStep} alternativeLabel sx={{ maxWidth: "55rem" }}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={index}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            )}
            {businessId && alertMessage?.status && (
                <AlertMessage
                    status={alertMessage?.status}
                    title={alertMessage?.title}
                    description={alertMessage?.description}
                    setAlertMessage={setAlertMessage}
                />
            )}
            <CustomerEditBusinessBody margin={businessId && "0 0 4rem 0"}>
                {activeStep === 0 && (
                    <CustomerEditBusinessStep1
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        usedAtecos={usedAtecos}
                        errors={errors}
                        setErrors={setErrors}
                        displayErrors={displayErrors}
                        businessId={businessId}
                        isEditingStep={isEditingStep}
                        setIsEditingStep={setIsEditingStep}
                        prevalentBusiness={prevalentBusiness}
                        workspaceId={workspaceId}
                    />
                )}
                {activeStep === 1 && (
                    <CustomerEditBusinessStep2
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        assetList={assetList}
                        assetStatus={assetStatus}
                        errors={errors}
                        setErrors={setErrors}
                        displayErrors={displayErrors}
                        isEditingStep={isEditingStep}
                        setIsEditingStep={setIsEditingStep}
                        businessId={businessId}
                    />
                )}
                {activeStep === 2 && (
                    <CustomerEditBusinessStep3
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        errors={errors}
                        setErrors={setErrors}
                        displayErrors={displayErrors}
                        setDisplayErrors={setDisplayErrors}
                        isEditingStep={isEditingStep}
                        setIsEditingStep={setIsEditingStep}
                        businessId={businessId}
                        workspaceId={workspaceId}
                    />
                )}
                {activeStep === 3 && (
                    <CustomerEditBusinessStep4
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        errors={errors}
                        setErrors={setErrors}
                        displayErrors={displayErrors}
                        isEditingStep={isEditingStep}
                        setIsEditingStep={setIsEditingStep}
                        businessId={businessId}
                    />
                )}
                {activeStep === 4 && (
                    <CustomerEditBusinessStep5
                        bodyRequest={bodyRequest}
                        setBodyRequest={setBodyRequest}
                        handleChange={handleChange}
                        errors={errors}
                        setErrors={setErrors}
                        displayErrors={displayErrors}
                        setDisplayErrors={setDisplayErrors}
                        isEditingStep={isEditingStep}
                        setIsEditingStep={setIsEditingStep}
                        businessId={businessId}
                    />
                )}
            </CustomerEditBusinessBody>

            <CodeViewer json={bodyRequest} display={process.env.REACT_APP_CODEVIEWER} label={"BUSINESS"} />
            <CodeViewer json={patchBody} display={process.env.REACT_APP_CODEVIEWER} label={"PATCH BODY"} />

            {isEditingStep && businessId && (
                <StickyFooter pullHeight={"4rem"}>
                    <Stack spacing={3} direction="row">
                        <Button variant="outlined" onClick={handleEditCancel}>
                            <FormattedMessage id="general.cancel" />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleEditPatch}
                            disabled={
                                loadingSubmit ||
                                (displayErrors && !Object.keys(errors).every(item => errors[item] === false))
                            }
                        >
                            {loadingSubmit && (
                                <LoadingIcon
                                    color="white"
                                    size="1rem"
                                    height="fit-content"
                                    width="fit-content"
                                    rightGutter
                                >
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
                                </LoadingIcon>
                            )}
                            <FormattedMessage id="general.save" />
                        </Button>
                    </Stack>
                </StickyFooter>
            )}

            {!businessId && (
                <StickyFooter pullHeight={"4rem"}>
                    <Stack spacing={3} direction="row">
                        <Button
                            variant="text"
                            size="large"
                            sx={{ width: "fit-content" }}
                            onClick={() => {
                                setActiveStep(previousStep =>
                                    previousStep > 0 ? previousStep - 1 : setIsEditing(false)
                                );
                                setDisplayErrors(false);
                            }}
                        >
                            <Stack direction="row">
                                <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "1rem" }} />
                                <FormattedMessage id="general.back" />
                            </Stack>
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ width: "fit-content", padding: "0 2rem" }}
                            disabled={
                                loadingSubmit ||
                                (displayErrors && !Object.keys(errors).every(item => errors[item] === false))
                            }
                            onClick={() => {
                                if (Object.keys(errors).every(item => errors[item] === false)) {
                                    if (activeStep === 4) {
                                        onSubmit(bodyRequest);
                                    } else {
                                        setActiveStep(previousStep => previousStep + 1);
                                        setDisplayErrors(false);
                                    }
                                } else {
                                    setDisplayErrors(true);
                                }
                            }}
                        >
                            {loadingSubmit && (
                                <LoadingIcon
                                    color="white"
                                    size="1rem"
                                    height="fit-content"
                                    width="fit-content"
                                    rightGutter
                                >
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
                                </LoadingIcon>
                            )}
                            {activeStep === 4 ? (
                                <FormattedMessage id="general.conclude" />
                            ) : (
                                <FormattedMessage id="general.forward" />
                            )}
                        </Button>
                    </Stack>
                </StickyFooter>
            )}
        </CustomerEditBusinessContainer>
    );
};

export default CustomerEditBusiness;
