import React, { useState } from "react";
import { TooltipTipBottom, TooltipTipLeft, TooltipTipRight, TooltipTipTop, TooltipWrapper } from "./styled";


const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  
  return (
    <TooltipWrapper
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && 
        props.direction === 'top' && 
        <TooltipTipTop width={props.width}>
          {props.content}
        </TooltipTipTop>
      }
      {/*   Default */}
      {active && 
        (!props.direction || props.direction === 'bottom') && 
        <TooltipTipBottom width={props.width}>
          {props.content}
        </TooltipTipBottom>
      }
      {active && 
        props.direction === 'left' && 
        <TooltipTipLeft width={props.width}>
          {props.content}
        </TooltipTipLeft>
      }
      {active && 
        props.direction === 'right' && 
        <TooltipTipRight width={props.width}>
          {props.content}
        </TooltipTipRight>
      }

      
    </TooltipWrapper>
  );
};

export default Tooltip;
