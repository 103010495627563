import CustomerManagementApiClient from "../../apiClient";

export const GET_ITEM_TOOLS_START = "GET_ITEM_TOOLS_START";
export const GET_ITEM_TOOLS_SUCCESS = "GET_ITEM_TOOLS_SUCCESS";
export const GET_ITEM_TOOLS_ERROR = "GET_ITEM_TOOLS_ERROR";
export const GET_ITEM_TOOLS_RESET = "GET_ITEM_TOOLS_RESET";

export const customerManagementApiClient = new CustomerManagementApiClient();

export function getItemTools(itemId) {
    return async dispatch => {
        dispatch({
            type: GET_ITEM_TOOLS_START,
            payload: { itemId }
        });

        try {
            const res = await customerManagementApiClient.getItemTools(itemId);
            dispatch({
                type: GET_ITEM_TOOLS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_ITEM_TOOLS_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetGetItemTools() {
    return dispatch => {
        dispatch({
            type: GET_ITEM_TOOLS_RESET
        });
    };
}

export const GET_TOOLS_START = "GET_TOOLS_START";
export const GET_TOOLS_SUCCESS = "GET_TOOLS_SUCCESS";
export const GET_TOOLS_ERROR = "GET_TOOLS_ERROR";
export const GET_TOOLS_RESET = "GET_TOOLS_RESET";

export function getTools() {
    return async dispatch => {
        dispatch({
            type: GET_TOOLS_START
        });

        try {
            const res = await customerManagementApiClient.getTools();

            dispatch({
                type: GET_TOOLS_SUCCESS,
                payload: res
            });
        } catch (e) {
            dispatch({
                type: GET_TOOLS_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetGetTools() {
    return dispatch => {
        dispatch({
            type: GET_TOOLS_RESET
        });
    };
}
