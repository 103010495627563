import React from "react";
import { FormattedMessage } from "react-intl";
import CustomerEditRegistryField from "../../../customer-edit-registry-field";

const CustomerEditRegistryFormField = ({ bodyRequest, customerDetails, field, fieldSettings, subSection, value }) => {
    if (value && fieldSettings.componentType === "number") {
        value = Intl.NumberFormat(
            "it-IT",
            fieldSettings.isCurrency && {
                style: "currency",
                currency: "EUR"
            }
        ).format(customerDetails[subSection]?.[field]);
    }
    if (value !== undefined && ["select", "radio"].indexOf(fieldSettings.component) > -1) {
        value = fieldSettings.options.find(x => x.id === customerDetails[subSection]?.[field])?.value;
        if (fieldSettings.translateValues) {
            value = fieldSettings.options.find(x => x.id === customerDetails[subSection]?.[field]) && (
                <FormattedMessage
                    id={`${fieldSettings.translateValues}${
                        fieldSettings.options.find(x => x.id === customerDetails[subSection]?.[field])?.id
                    }`}
                />
            );
        }
    }
    if (value && fieldSettings.component === "datepicker") {
        value = `${
            bodyRequest[subSection]?.[field]
                ? new Intl.DateTimeFormat("it-IT", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric"
                  }).format(bodyRequest[subSection]?.[field])
                : ""
        }`;
    }
    return (
        <CustomerEditRegistryField
            vertical
            dark
            lightText={!value}
            labelWidth={!fieldSettings.fullWidth ? "240px" : fieldSettings.width}
            label={
                <>
                    <FormattedMessage id={fieldSettings?.label} />
                    {!fieldSettings.required && (
                        <>
                            {" "}
                            (<FormattedMessage id={"general.optional"} />)
                        </>
                    )}
                </>
            }
        >
            {value !== undefined && value !== null ? (
                value
            ) : (
                <FormattedMessage id={fieldSettings?.emptyLabel || "general.notSpecified"} />
            )}
        </CustomerEditRegistryField>
    );
};

export default CustomerEditRegistryFormField;
