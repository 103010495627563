import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 1rem;
    padding: 0.625rem 1rem;
    align-items: center;
`;

export const KpiContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    justify-self: end;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    padding: 0.25rem 0.625rem 0;
    align-items: center;
`;

export const AppName = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 0.75rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.09px;
    line-height: 1.5rem;
`;

export const Value = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 32px;
    font-family: Roboto;
    font-weight: bold;
`;

export const ValueDescription = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
`;

export const Date = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 0.75rem;
`;

export const Percentage = styled.div`
    color: ${props =>
        props.percentage > 0 ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.maximumRed};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: bold;
    text-align: right;
`;

export const ArrowIconContainer = styled.div`
    color: ${props =>
        props.percentage > 0 ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.maximumRed};
    font-size: 1.5rem;
`;
