import styled, { css } from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    border: 1px solid transparent;
    padding-bottom: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    display: grid;
    grid-template-areas:
        "header header header header header radio"
        "footer footer footer footer footer link";
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 3px;
    position: relative;
    margin-top: 20px;
    padding: 10px 0px 10px 20px;
    transition: all 0.2s ease-in-out;

    ${props =>
        props.selected &&
        css`
            border-top-width: 1px;
            border-right-width: 1px;
            border-left-width: 1px;
            border-bottom-width: 3px;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(
                -26.56505117707799deg,
                ${props => props.theme.colors.primary.cyanProgress} 0%,
                ${props => props.theme.colors.primary.richElectricBlue} 100%
            );
            box-shadow: 0px 8px 24px 0px rgba(21, 41, 53, 0.32);
        `}
`;

export const LabelContainer = styled.div`
    justify-self: flex-start;
`;

export const RadioButtonArea = styled.div`
    padding-top: 1rem;
    grid-area: radio;
    margin: -18px 0px -25px auto;
    width: 35px;
`;

export const CardHeaderArea = styled.div`
    grid-area: header;
`;

export const CardLinkArea = styled.div`
    grid-area: link;
`;

export const CardFooterArea = styled.div`
    grid-area: footer;
`;

export const Icon = styled.div`
    color: ${props =>
        props.disabled ? props.theme.colors.primary.columbiaBlue : props.theme.colors.primary.darkImperialBlue};
    font-size: 1.5rem;
    font-size: 1.5rem;
    align-self: center;
    justify-self: center;
    width: 8%;
`;

export const ComingSoonContainer = styled.div`
    position: absolute;
    right: 5rem;
    top: 1.5rem;
    border-radius: 0.25rem;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-family: Cairo;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
`;

export const CardDescription = styled.div`
    margin-left: 62px;
    width: 532px;
    height: 20px;
`;

export const EditLinkContainer = styled.div`
    text-align: right;
    margin-right: 10px;
`;

export const EditLink = styled.div`
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    white-space: nowrap;
`;

export const SubTitleAndLink = styled.div`
    display: flex;
    justify-content: space-between;
`;
