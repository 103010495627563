import customerManagementApiClient from "../apiClient";
import { downloadBase64 } from "../utils/download";

export const ADA_GENERATE_START = "ADA_GENERATE_START";
export const ADA_GENERATE_SUCCESS = "ADA_GENERATE_SUCCESS";
export const ADA_GENERATE_ERROR = "ADA_GENERATE_ERROR";
export const ADA_GENERATE_RESET = "ADA_GENERATE_RESET";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function generateAda(params) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADA_GENERATE_START,
            payload: { ...params }
        });

        try {
            /* FIXME chiamata finta, da sostituire con chiamata a be for fe */
            await sleep(2000);

            dispatch({
                type: ADA_GENERATE_SUCCESS,
                payload: {}
            });
        } catch (e) {
            dispatch({
                type: ADA_GENERATE_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetAdaGenerate() {
    return dispatch => {
        dispatch({
            type: ADA_GENERATE_RESET
        });
    };
}

export const ADA_SEND_START = "ADA_SEND_START";
export const ADA_SEND_SUCCESS = "ADA_SEND_SUCCESS";
export const ADA_SEND_ERROR = "ADA_SEND_ERROR";
export const ADA_SEND_RESET = "ADA_SEND_RESET";

export function sendAda(params) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADA_SEND_START,
            payload: { ...params }
        });

        try {
            /* FIXME chiamata finta, da sostituire con chiamata a be for fe */
            await sleep(2000);

            dispatch({
                type: ADA_SEND_SUCCESS,
                payload: {}
            });
        } catch (e) {
            dispatch({
                type: ADA_SEND_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetSendAda() {
    return dispatch => {
        dispatch({
            type: ADA_SEND_RESET
        });
    };
}

export const ADA_GET_START = "ADA_GET_START";
export const ADA_GET_SUCCESS = "ADA_GET_SUCCESS";
export const ADA_GET_ERROR = "ADA_GET_ERROR";
export const ADA_GET_RESET = "ADA_GET_RESET";

export function getAdaInfo(connectionId) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADA_GET_START,
            payload: { connectionId }
        });

        try {
            const res = await new customerManagementApiClient().getAdaInfo(connectionId);

            dispatch({
                type: ADA_GET_SUCCESS,
                payload: { ...res }
            });
        } catch (e) {
            dispatch({
                type: ADA_GET_ERROR,
                payload: { e }
            });
        }
    };
}

export const ADA_DOWNLOAD_START = "ADA_DOWNLOAD_START";
export const ADA_DOWNLOAD_SUCCESS = "ADA_DOWNLOAD_SUCCESS";
export const ADA_DOWNLOAD_ERROR = "ADA_DOWNLOAD_ERROR";

export function downloadAda(pdf) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADA_DOWNLOAD_START
        });

        try {
            downloadBase64(pdf, `TSDigitalActOfEntrustment.pdf`);

            dispatch({
                type: ADA_DOWNLOAD_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: ADA_DOWNLOAD_ERROR,
                payload: { e }
            });
        }
    };
}

export function resetAdaInfo() {
    return dispatch => {
        dispatch({
            type: ADA_GET_RESET
        });
    };
}
