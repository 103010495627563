import React from "react";
import { FooterButtonContainer } from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons/faAnalytics";
import { useNavigate } from "react-router";
import { IconButton, Popover, List, ListItemText, ListItemButton } from "@vapor/react-material";
import { FormattedMessage } from "react-intl";
import { CUSTOMERS_FULL_OPTIONS } from "../constants";

export const CustomerCardFooter = ({ customerId }) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [id, setId] = React.useState(null);

    const handlePopoverOpen = (event, id) => {
        setId(id);
        setAnchorEl(event.currentTarget);
    };

    const togglePopOver = () => {
        setAnchorEl(null);
        setId(null);
    };

    return (
        <FooterButtonContainer>
            {CUSTOMERS_FULL_OPTIONS && (
                <>
                    <IconButton
                        color="primary"
                        size="small"
                        //onClick={() => navigate(`/${customerId}/customer-dashboard`)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </IconButton>

                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/${customerId}/customer-dashboard`)}
                    >
                        <FontAwesomeIcon icon={faAnalytics} />
                    </IconButton>
                </>
            )}
            <IconButton
                color="primary"
                size="small"
                aria-describedby={id}
                onClick={e => handlePopoverOpen(e, customerId)}
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Popover
                className={`popover-${customerId}`}
                open={customerId === id}
                anchorEl={anchorEl}
                onClose={togglePopOver}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <List disablePadding sx={{ width: 240 }}>
                    {CUSTOMERS_FULL_OPTIONS && (
                        <ListItemButton>
                            <ListItemText primary={<FormattedMessage id="c-customer-card.footerlabel-services" />} />
                        </ListItemButton>
                    )}
                    <ListItemButton onClick={() => navigate(`/${customerId}/customer-edit/main`)}>
                        <ListItemText
                            primary={<FormattedMessage id="c-customer-card.footerlabel-edit-customerdata" />}
                        />
                    </ListItemButton>
                    {CUSTOMERS_FULL_OPTIONS && (
                        <ListItemButton>
                            <ListItemText
                                primary={<FormattedMessage id="c-customer-card.footerlabel-removemanagement" />}
                            />
                        </ListItemButton>
                    )}
                </List>
            </Popover>
        </FooterButtonContainer>
    );
};
