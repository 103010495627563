import styled from "styled-components";

export const Container = styled.div`
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: ${props =>
        props.backgroundColor
            ? props.theme.colors.background[props.backgroundColor]
            : props.dark
            ? props.theme.colors.text.yankeesBlue
            : props.theme.colors.background.white};
`;

export const Content = styled.div`
    padding: 1.5rem 1.2rem 0.5rem 1.2rem;
    width: 100%;
    box-sizing: border-box;
`;

export const Footer = styled.div`
    background-color: ${props =>
        props.dark ? props.theme.colors.text.yankeesBlue : props.theme.colors.background.whiteSmoke};
    padding: 0.25rem;
    display: flex;
    align-items: center;
    min-height: 3rem;
    box-sizing: border-box;
`;

export const VerticalFooter = styled.div`
    background-color: ${props =>
        props.dark ? props.theme.colors.text.yankeesBlue : props.theme.colors.background.whiteSmoke};
    padding: 0.25rem;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    width: 48px;
    border-left-style: solid;
    border-left-width: 0.1px;
    border-color: ${props => props.theme.colors.background.azureishWhite};
`;

export const VerticalContent = styled.div`
    display: flex;
    height: 100%;
`;
