import React from "react";
import { FormattedMessage } from "react-intl";
import { GlobalCol, GlobalHorizontalDivider } from "../../../../../globalStyles";
import { Section, SectionTitle, TitleText } from "../../styled";
import CustomerEditSectionTitle from "./title";
import CustomerEditRow from "../row";

const CustomerEditSection = props => {
    const {
        alert,
        bodyRequest,
        customerDetails,
        isEditing,
        section,
        setAlert,
        setBodyRequest,
        setIsEditing,
        settingsFind,
        subSection
    } = props;

    return (
        <>
            <Section>
                {section?.mainTitle && (
                    <CustomerEditSectionTitle
                        alert={alert}
                        isEditing={isEditing}
                        section={section}
                        setAlert={setAlert}
                        setIsEditing={setIsEditing}
                        secondary={section?.mainTitleSecondary}
                    />
                )}
                {section?.title && (
                    <SectionTitle margin={"16px 0"}>
                        <TitleText>
                            <FormattedMessage id={section?.title} />
                        </TitleText>
                    </SectionTitle>
                )}
                <GlobalCol>
                    {section?.rows?.map((row, index) => {
                        return (
                            <CustomerEditRow
                                key={`customer-edit-section-row_${index}`}
                                bodyRequest={bodyRequest}
                                customerDetails={customerDetails}
                                isEditing={isEditing}
                                row={row}
                                setBodyRequest={setBodyRequest}
                                subSection={subSection}
                            />
                        );
                    })}
                </GlobalCol>
            </Section>
            {!section?.noDividerAfter &&
                settingsFind?.sections.indexOf(section) < settingsFind?.sections.length - 1 && (
                    <GlobalHorizontalDivider />
                )}
        </>
    );
};

export default CustomerEditSection;
