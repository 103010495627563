import styled from "styled-components";

export const FieldContainer = styled.div`
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    align-items: baseline;
    ${props =>
        props.vertical &&
        `
flex-direction: column;
margin-top: 16px;
margin-bottom: 16px;
`}
`;

export const FieldLabel = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: normal;
    line-height: 20px;
    margin-right: 7px;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    width: ${props => props.width || "inherit"};
    min-width: ${props => (props.width ? "" : props.vertical ? "240px" : "")};
    ${props =>
        props.vertical &&
        `
margin-right: 0;
margin-bottom: 12px;
`}
    ${props =>
        props.dark &&
        `
    color: ${props.theme.colors.text.yankeesBlue};
    font-weight: 500;
`}
`;

export const FieldText = styled.div`
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: normal;
    line-height: 20px;
    color: ${props => props.theme.colors.text.yankeesBlue};
    ${props => props.lightText && `color: ${props.theme.colors.text.cadet}`}
`;
