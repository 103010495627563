import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Tooltip } from "@vapor/react-material";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";

export const InfoTooltip = ({ title, message }) => (
    <Tooltip
        arrow
        placement="bottom"
        title={
            <Stack spacing={1} p={1}>
                <Box sx={{ fontWeight: "bold" }}>{title}</Box>
                {message && <Box>{message}</Box>}
            </Stack>
        }
    >
        <div style={{ display: "inline-block" }}>
            <Button
                sx={{
                    minWidth: "16px !important",
                    minHeight: "16px !important",
                    maxWidth: "16px !important",
                    maxHeight: "16px !important",
                    fontSize: "inherit"
                }}
            >
                <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
        </div>
    </Tooltip>
);
