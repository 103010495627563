import styled from "styled-components";

export const FooterBar = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: rgb(255, 255, 255);
    border-top: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    display: flex;
    justify-content: ${props =>
        props.position === "left" ? "flex-start" : props.position === "center" ? "center" : "flex-end"};
    align-items: center;
    padding: 1rem 3rem;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    z-index: 1;
`;

export const Pull = styled.div`
    display: block;
    width: 100%;
    height: ${props => props.pullHeight || "1rem"};
`;
