import React from "react";
import { useIntl } from "react-intl";
//import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import PageTitle from "../../../components/vapor-components/page-title";
import { Container } from "./styled";
import CustomerEditLayout from "../../../components/customer-edit/customer-edit-layout";
import { CustomerCreateIntermediaries } from "../../../components/customer-edit-intermediary/form";
import { IntermediarySidebar } from "../../../components/customer-edit-intermediary/sidebar";

const CustomerEditIntermediary = () => {
    const intl = useIntl();

    const navigate = useNavigate();
    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/intermediary");

    return (
        <Container>
            <PageTitle
                backButtonTitle={intl.formatMessage({
                    id: "v-customer-edit-intermediary.back-button-label"
                })}
                subtitle={intl.formatMessage({
                    id: "v-customer-edit-intermediary.subtitle"
                })}
                title={intl.formatMessage({
                    id: "v-customer-edit-intermediary.title"
                })}
                onBack={() => {
                    navigate(`/${workspaceId}/customer-edit/main`);
                }}
            />
            <CustomerEditLayout leftSide={<IntermediarySidebar />}>
                <CustomerCreateIntermediaries />
            </CustomerEditLayout>
        </Container>
    );
};

export default CustomerEditIntermediary;
