import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Container = styled.div`
    @media (max-width: 968px) {
        overflow-x: auto;
    }
`;

export const NameAndClassifierContainer = styled.div`
    display: flex;
    grid-gap: 1rem;
    align-items: center;
`;

export const StatusContainer = styled.div`
    display: flex;
    grid-gap: 1rem;
    align-items: center;
`;

export const StatusIcon = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    font-size: 1rem;
`;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
