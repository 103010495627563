import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/react-material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LoadingElement } from "../../vapor-components/loading";
import { sections as dashboardSections } from "./constants";
import { CustomerSectionItem, CustomerSectionsContainer, CustomerSectionsItems, CustomerSectionsTitle } from "./styled";
import { Close } from "@mui/icons-material";

const WarningDialog = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                <FormattedMessage id="alerts.section-change-title" />
                <IconButton color="primary" onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider variant="alert" />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="alerts.section-change-message" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    <FormattedMessage id="general.cancel" />
                </Button>
                <Button onClick={onConfirm} variant="contained" color="error">
                    <FormattedMessage id="general.proceed" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CustomerSections = ({ pending, sections, section, setSection, disabled, warning }) => {
    const [openWarning, setOpenWarning] = useState(-1);
    const classifier = useSelector(state => state?.initDataFromParent?.auth?.item?.classifier);
    const sectionsList = sections || dashboardSections;
    return (
        <CustomerSectionsContainer>
            {openWarning !== -1 && (
                <WarningDialog
                    open={openWarning !== -1}
                    onClose={() => setOpenWarning(-1)}
                    onConfirm={() => {
                        setSection(openWarning);
                        setOpenWarning(-1);
                    }}
                />
            )}
            <CustomerSectionsTitle>
                {pending ? (
                    <LoadingElement width="200px" height="20px" />
                ) : (
                    <FormattedMessage id="c-customer-edit-area-title.main" />
                )}
            </CustomerSectionsTitle>
            <CustomerSectionsItems>
                {sectionsList.map(item => {
                    if (item.enabled && (item?.classifier ? classifier.includes(item.classifier) : true)) {
                        if (pending)
                            return (
                                <CustomerSectionItem key={`customer-section_${item?.value}`}>
                                    <LoadingElement width="200px" height="20px" />
                                </CustomerSectionItem>
                            );
                        return (
                            <CustomerSectionItem
                                key={`customer-section_${item?.value}`}
                                onClick={() =>
                                    disabled ? null : warning ? setOpenWarning(item?.value) : setSection(item?.value)
                                }
                                selected={item?.value === section}
                            >
                                <FormattedMessage
                                    id={`c-customer-edit-sections.${item.value}${item.label ? "-" + item.label : ""}`}
                                />
                            </CustomerSectionItem>
                        );
                    }
                    return null;
                })}
            </CustomerSectionsItems>
        </CustomerSectionsContainer>
    );
};

export default CustomerSections;
