import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.colors.background.azureishWhite};
    padding: 1.5rem 2rem;
`;

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem 1.5rem;
    margin-top: 1rem;
    grid-auto-columns: 1fr;
    grid-template-areas:
        "customer-card customer-card customer-card widget widget widget widget widget widget widget widget widget"
        "customer-card customer-card customer-card graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools tools tools graphic graphic graphic graphic graphic graphic graphic graphic graphic"
        "tools-advising tools-advising tools-advising entrustment-deed entrustment-deed entrustment-deed rdc-delegation rdc-delegation rdc-delegation . . .";
`;

export const GridElement = styled.div`
    grid-area: ${props => props.gridArea};
`;

export const WidgetCardContainer = styled(GridElement)`
    grid-area: widget;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
`;

export const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
