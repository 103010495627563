export const defaultBodyAntiMoneyLaundering = {
    verifiedBy: "",
    verifiedWith: "",
    other: null,
    politicallyExposedPerson: false,
    politicalOfficeHeld: null,
    occupationalActivity: null
};

export const idOptions = intl => [
    {
        id: "IDENTITY_CARD",
        value: intl.formatMessage({ id: "IDENTITY_CARD" })
    },
    {
        id: "DRIVERS_LICENSE",
        value: intl.formatMessage({ id: "DRIVERS_LICENSE" })
    },
    {
        id: "PASSPORT",
        value: intl.formatMessage({ id: "PASSPORT" })
    },

    {
        id: "FIREARM_LICENSE",
        value: intl.formatMessage({ id: "FIREARM_LICENSE" })
    },
    {
        id: "RESIDENCY_PERMIT",
        value: intl.formatMessage({ id: "RESIDENCY_PERMIT" })
    },
    {
        id: "OTHER",
        value: intl.formatMessage({ id: "OTHER" })
    }
];

export const politicalOfficeHeldOptions = intl => [
    {
        id: "PRESIDENT_OF_THE_REPUBLIC",
        value: intl.formatMessage({ id: "PRESIDENT_OF_THE_REPUBLIC" })
    },
    {
        id: "PRIME_MINISTER",
        value: intl.formatMessage({ id: "PRIME_MINISTER" })
    },
    {
        id: "MINISTER",
        value: intl.formatMessage({ id: "MINISTER" })
    },
    {
        id: "DEPUTY_MINISTER",
        value: intl.formatMessage({ id: "DEPUTY_MINISTER" })
    },
    {
        id: "UNDERSECRETARY",
        value: intl.formatMessage({ id: "UNDERSECRETARY" })
    },
    {
        id: "PRESIDENT_OF_THE_REGION",
        value: intl.formatMessage({ id: "PRESIDENT_OF_THE_REGION" })
    },
    {
        id: "REGIONAL_COUNTY_COUNCILLOR",
        value: intl.formatMessage({ id: "REGIONAL_COUNTY_COUNCILLOR" })
    },
    {
        id: "MAYOR_OF_A_PROVINCE",
        value: intl.formatMessage({ id: "MAYOR_OF_A_PROVINCE" })
    },
    {
        id: "MAYOR_OF_A_MUNICIPALITY",
        value: intl.formatMessage({ id: "MAYOR_OF_A_MUNICIPALITY" })
    },
    {
        id: "DEPUTY",
        value: intl.formatMessage({ id: "DEPUTY" })
    },
    {
        id: "SENATOR",
        value: intl.formatMessage({ id: "SENATOR" })
    },
    {
        id: "PARLIAMENTARIAN",
        value: intl.formatMessage({ id: "PARLIAMENTARIAN" })
    },
    {
        id: "EUROPEAN_PARLIAMENTARIAN",
        value: intl.formatMessage({ id: "EUROPEAN_PARLIAMENTARIAN" })
    },
    {
        id: "REGIONAL_COUNCILLOR",
        value: intl.formatMessage({ id: "REGIONAL_COUNCILLOR" })
    },
    {
        id: "SIMILAR_OFFICES_FOREIGN_COUNTRIES",
        value: intl.formatMessage({
            id: "SIMILAR_OFFICES_FOREIGN_COUNTRIES"
        })
    }
];
