import { Field, RadioSub } from "@onefront/react-workspace-components";
import { Box, Button, FormControl, FormHelperText, Stack, Typography } from "@vapor/react-material";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { isEmptyOrSpaces } from "../../../../../../utils/string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { ceasedBusiness } from "../../constants";

export const CustomerEditBusinessStep2 = ({
    bodyRequest,
    handleChange,
    assetList,
    errors,
    setErrors,
    displayErrors,
    isEditingStep,
    setIsEditingStep,
    businessId
}) => {
    const intl = useIntl();
    const [asset, setAsset] = useStateWithCallbackLazy(bodyRequest?.asset);
    const [accountingConfiguration, setAccountingConfiguration] = useStateWithCallbackLazy(
        bodyRequest?.accountingConfiguration
    );

    //validation
    useEffect(() => {
        const fieldsDefaultValidation = Object.keys({
            accountingType: true,
            ...(accountingConfiguration?.accountingType === "SIMPLIFIED" && {
                simplifiedAccountingType: true
            }),
            assetGroup: true,
            assetSpecies: true
        }).reduce((acc, key) => {
            if (key === "assetGroup" || key === "assetSpecies") {
                acc[key] = isEmptyOrSpaces(bodyRequest?.["asset"]?.[key] || "");
            } else {
                acc[key] = isEmptyOrSpaces(bodyRequest?.["accountingConfiguration"]?.[key] || "");
            }
            return acc;
        }, {});

        setErrors({
            ...fieldsDefaultValidation
        });
    }, [bodyRequest, setErrors, accountingConfiguration?.accountingType]);

    useEffect(() => {
        if (bodyRequest?.asset) {
            setAsset(bodyRequest.asset);
        }
    }, [bodyRequest, setAsset]);

    return (
        <Stack spacing={5}>
            {/* Taxation Type */}
            <Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", maxWidth: "60%" }}>
                    <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-business.step2-title" />
                    </Typography>
                    {businessId && !isEditingStep && !ceasedBusiness(bodyRequest?.endDate) && (
                        <Button onClick={() => setIsEditingStep(true)}>
                            <Stack direction="row" spacing={1}>
                                <Box>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Box>
                                <Box>
                                    <FormattedMessage id="general.edit" />
                                </Box>
                            </Stack>
                        </Button>
                    )}
                </Stack>
                {!businessId && (
                    <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-business.step2-description" />
                    </Typography>
                )}
                <Stack>
                    <FormControl
                        sx={{
                            minWidth: 350,
                            my: 3,
                            gap: isEditingStep ? "3rem" : "1rem",
                            maxWidth: "60%"
                        }}
                    >
                        <Stack>
                            <RadioSub
                                id="accountingType"
                                label={intl.formatMessage({ id: "field.business.taxationType" })}
                                onChange={val => {
                                    setAccountingConfiguration(
                                        {
                                            ...accountingConfiguration,
                                            accountingType: val,
                                            simplifiedAccountingType: val === "SIMPLIFIED" ? "CASH_ACCOUNTING" : null
                                        },
                                        accounting => {
                                            handleChange(accounting, "accountingConfiguration");
                                        }
                                    );
                                }}
                                value={accountingConfiguration?.accountingType}
                                isEditing={isEditingStep}
                                sx={{ width: "100%", display: "flex", gap: 1 }}
                                options={[
                                    {
                                        id: "ORDINARY",
                                        label: intl.formatMessage({
                                            id: "c-customer-edit-business.taxationType.ORDINARY"
                                        })
                                    },
                                    {
                                        id: "SIMPLIFIED",
                                        label: intl.formatMessage({
                                            id: "c-customer-edit-business.taxationType.SIMPLIFIED"
                                        }),
                                        sublist: [
                                            {
                                                id: "simplifiedAccountingType",
                                                component: "radio",
                                                value: accountingConfiguration?.simplifiedAccountingType,
                                                label: intl.formatMessage({
                                                    id: "c-customer-edit-business.taxationType.SIMPLIFIED_LABEL"
                                                }),
                                                radioGroupAlign: "vertical",
                                                fullWidth: true,
                                                isEditing: isEditingStep,
                                                onChange: val => {
                                                    setAccountingConfiguration(
                                                        {
                                                            ...accountingConfiguration,
                                                            simplifiedAccountingType: val
                                                        },
                                                        accounting => {
                                                            handleChange(accounting, "accountingConfiguration");
                                                        }
                                                    );
                                                },
                                                options: [
                                                    {
                                                        id: "CASH_ACCOUNTING",
                                                        value: intl.formatMessage({
                                                            id:
                                                                "c-customer-edit-business.taxationType.SIMPLIFIED_CASH_ACCOUNTING"
                                                        })
                                                    },
                                                    {
                                                        id: "PAYMENTS_PRESUMPTION",
                                                        value: intl.formatMessage({
                                                            id:
                                                                "c-customer-edit-business.taxationType.SIMPLIFIED_PAYMENTS_PRESUMPTION"
                                                        })
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]}
                            />
                            {displayErrors && errors.accountingType && (
                                <FormHelperText error={displayErrors && errors.accountingType}>
                                    {intl.formatMessage({ id: "error.business.accountingType" })}
                                </FormHelperText>
                            )}
                        </Stack>
                    </FormControl>
                </Stack>
            </Stack>

            {/* Cespiti - Asset Taxation Data */}

            <Stack>
                <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.step2.cespitiTitle" />
                </Typography>
                {!businessId && (
                    <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-business.step2.cespitiDescription" />
                    </Typography>
                )}
                <Stack mt={2}>
                    <FormControl
                        sx={{
                            minWidth: 120,
                            my: 1,
                            maxWidth: "60%",
                            gap: "1rem"
                        }}
                    >
                        <Stack mb={isEditingStep && 2}>
                            <Field
                                component="select"
                                name="select-assets-group"
                                label={intl.formatMessage({ id: "field.business.cespitiGroup" })}
                                isEditing={isEditingStep}
                                value={asset.assetGroup}
                                options={assetList?.map(asset => ({
                                    id: asset.details.group,
                                    value: asset.details.description
                                }))}
                                onChange={val => {
                                    setAsset(
                                        {
                                            ...asset,
                                            assetGroup: val
                                        },
                                        asset => {
                                            handleChange(asset, "asset");
                                        }
                                    );
                                }}
                                selectHeight="320px"
                            />
                            {displayErrors && errors.assetGroup && (
                                <FormHelperText error={displayErrors && errors.assetGroup}>
                                    {intl.formatMessage({ id: "error.business.assetGroup" })}
                                </FormHelperText>
                            )}
                        </Stack>
                        <Stack mt={isEditingStep && 2}>
                            <Field
                                component="select"
                                name="select-assets-species"
                                label={intl.formatMessage({ id: "field.business.cespitiSpecies" })}
                                isEditing={isEditingStep}
                                disabled={asset.assetGroup === ""}
                                value={asset.assetSpecies}
                                options={assetList
                                    ?.find(item => item.details.group === asset.assetGroup)
                                    ?.details?.kinds?.map(item => ({
                                        id: item.kind,
                                        value: item.description
                                    }))}
                                onChange={val => {
                                    setAsset(
                                        {
                                            ...asset,
                                            assetSpecies: val
                                        },
                                        asset => {
                                            handleChange(asset, "asset");
                                        }
                                    );
                                }}
                                selectHeight="320px"
                            />
                            {displayErrors && errors.assetSpecies && (
                                <FormHelperText error={displayErrors && errors.assetSpecies}>
                                    {intl.formatMessage({ id: "error.business.assetSpecies" })}
                                </FormHelperText>
                            )}
                        </Stack>
                    </FormControl>
                </Stack>
            </Stack>
        </Stack>
    );
};
