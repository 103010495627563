const it = {
    // General
    "general.vatnumber": "P.Iva",
    "general.tax-id": "Codice fiscale",
    "general.tax-id-short": "Cf",
    "general.business-name": "Ragione sociale",
    "general.state-customer": "Stato gestione cliente",
    "general.state-registry": "Stato anagrafica",
    "general.typology": "Tipo di anagrafica",
    "general.user-registered": "UTENTE REGISTRATO",
    "general.user-unregistered": "UTENTE NON REGISTRATO",
    "general.name": "Nome",
    "general.surname": "Cognome",
    "general.fullname": "Nome e Cognome",
    "general.piva": "Partita IVA",
    "general.taxId": "Codice Fiscale",
    "general.private": "Privato",
    "general.professionist": "Libero prefessionista",
    "general.company": "Azienda",
    "general.cancel": "Annulla",
    "general.proceed": "Procedi",
    "general.send": "Invia",
    "general.edit": "Modifica",
    "general.reset": "Reset",
    "general.back": "Indietro",
    "general.forward": "Avanti",
    "general.conclude": "Salva e concludi",
    "general.select": "Seleziona",
    "general.or": "Oppure",
    "general.error": "Si è verificato un errore",
    "general.retry": "Riprova",
    "general.obtain": "Ricava",
    "general.optionSelect": "Seleziona un'opzione",
    "general.mutipleOptionSelect": "Seleziona una o più opzioni",
    "general.yes": "Sì",
    "general.no": "No",
    "general.save": "Salva",
    "general.apply": "Applica",
    "general.replaceClaim": "Si, sostituici",
    "general.delete": "Elimina",
    "general.notSpecified": "Non specificato",
    "general.notFound": "Non trovato",
    "general.optional": "opzionale",
    "general.subSettingsNotFound": "Impostazioni per sezione {section} non trovate",
    "general.settingsNotFound": "Impostazioni tipologia cliente {classifier} non trovate",
    "general.fieldSettingsNotFound": "Impostazioni campo {field} non trovate",
    "general.settingsNoComponent": "Nessun componente selezionato per il campo {field}",
    "general.expire-date": "Data di scadenza",
    "general.password": "Password",
    "general.insert-password": "Inserisci Password",
    "general.confirm": "Conferma",
    "general.year": "Anno",
    "general.form.field-required": "Campo obbligatorio",
    "general.form.field-length-range": "Il campo deve avere una lunghezza compresa tra {min} e {max} caratteri",
    "general.form.email-required": "L'indirizzo email digitato non è valido",
    "general.form.taxid-required": "Il codice fiscale digitato non è valido",
    "general.form.piva-required": "La partita iva digitata non è valida",
    "general.handle": "Gestisci",
    "general.notHandle": "Non Gestire",
    "general.notHandled": "Non Gestito",
    "general.adjust": "Adegua",

    // Classifiers
    "general.classifiers.COMPANY": "Azienda",
    "general.classifiers.PERSON": "Privato",
    "general.classifiers.PERSON-variant": "Persona Fisica",
    "general.classifiers.STUDIO": "Studio",
    "general.classifiers.BUILDING": "Condominio",
    "general.classifiers.PUBLIC_COMPANY": "Pubblica amministrazione",

    // Company connection status
    "general.status": "Stato",
    "general.status.VALIDATED": "Validata",
    "general.status.UNVERIFIABLE": "Non validata",
    "general.status.UNVERIFIABLE_PENDING_VALIDATE": "In attesa di validazione",
    "general.status.REJECTED": "Rigettata",
    "general.status.REJECTED_PENDING_VALIDATE": "In attesa di validazione",
    "general.status.APPROVED": "Approvato",
    "general.status.AWATING_ACCEPTANCE": "In attesa di accettazione",
    "general.status.ACTIVE": "Gestione attiva",
    "general.status.DEACTIVATED": "Gestione incompleta",
    "general.status.UNCONNECTED": "Non collegata",

    // Views
    "v-customers.title": "Clienti",
    "v-customers.subtitle":
        "Gestisci le aziende clienti modificando i servizi attivi o attivandone di nuovi o crea nuove aziende clienti.",
    "v-customers.headerbar": "GESTIONE CLIENTI",
    "v-customers.input.search-a-client.label": "Cerca un cliente",
    "v-customers.input.search-a-client.placeholder": "Cerca per C.F., P. IVA o ragione sociale",
    "v-customers.select.active-services.label": "Filtra per servizi e sotto servizi attivi",
    "v-customers.select.active-services.placeholder": "Scegli un opzione",
    "v-customers.select.sort.label": "Ordina",
    "v-customers.create-customer-company": "Crea cliente",
    "v-customers.show-more-customers": "Visualizza altri clienti",
    "v-customers.empty-customers-message": "Nessuna azienda cliente da visualizzare",
    "v-customer-company-created.title": "Azienda cliente creata con successo!",
    "v-customer-company-created.subtitle":
        "L’azienda cliente è stata aggiunta alla tua lista dei clienti. Puoi gestire tutti i tuoi clienti dalla sezione Gestione Clienti.",
    "v-customer-company-created.start-configuration-button": "Avvia configurazione",
    "v-customer-company-created.or-go-to": "Oppure vai alla",
    "v-customer-company-created.list-customers-company-list-button": "Lista aziende clienti",
    "v-customer-dashboard.back-button-headerbar": "Torna alla Dashboard",
    "v-customer-dashboard.back-button-label": "Torna alla Lista Clienti",
    "v-customer-dashboard.title": "Scheda cliente",
    "v-customer-dashboard.metering-widget.title": "Totale consumi",
    "v-customer-dashboard.change-personal-data-button-label": "Modifica dati anagrafici",

    "v-customer-edit.title": "DATI DEL CLIENTE",
    "v-customer-edit.subtitle": "Visualizza e modifica i dati relativi all’anagrafica del cliente",

    "v-customer-edit-business.title": "CREA ATTIVITA'",
    "v-customer-edit-business.editTitle": "DATI DELL’ATTIVITÀ",
    "v-customer-edit-business.subtitle": "Registra l'attività del tuo cliente",
    "v-customer-edit-business.editSubtitle": "Visualizza e modifica i dati relativi all’attività",
    "v-customer-edit-business.back-button-label": "Torna indietro",
    "v-customer-edit-business-create.back": "Torna alle Attività",

    "v-customer-edit-intermediary.title": "CREA INTERMEDIARIO",
    "v-customer-edit-intermediary.subtitle":
        "Inserisci i dati dell'intermediario, associa uno o più ambienti di sicurezza e uno o più incaricati se necessario",
    "v-customer-edit-intermediary.back-button-label": "Torna indietro",

    // Components
    "c-general-spinnerMessage": "Caricamento dati, attendere prego...",
    "c-progress-bar.step1": "Paese di residenza",
    "c-progress-bar.step2": "Tipo di anagrafica",
    "c-progress-bar.step3": "Dati anagrafici",
    "c-progress-bar.step4": "Completa la configurazione",
    "c-progress-bar.selection": "Seleziona l'anagrafica",

    "c-new-registry.type1": "Azienda",
    "c-new-registry.type2": "Privato",
    "c-new-registry.type3": "Condominio",
    "c-new-registry.type4": "Pubblica amministrazione",

    "c-new-registry.description1": "Liberi professionisti, ditte individuali, società di persone, di capitali o altro",
    "c-new-registry.description2": "Persona o singolo cittadino privo di incarico pubblico",
    "c-new-registry.description3": "Ente gestito dall’amministratore di condominio per conto degli inquilini",
    "c-new-registry.description4": "Tutti gli enti pubblici, sia nazionali che  locali",

    "c-new-registry.pageTitle": "CREA CLIENTE",
    "c-new-registry.pageSubTitle": "Registra l’anagrafica del cliente che vuoi gestire",
    "c-new-registry.title1": "Seleziona il paese di residenza del tuo cliente",
    "c-new-registry.title2": "Seleziona il tipo di anagrafica del tuo cliente",
    "c-new-registry.title3": "Cerca l’anagrafica del cliente",
    "c-new-registry.title4": "Cliente creato con successo!",
    "c-new-registry.finalButton": "Salta e configura in seguito",
    "c-new-registry.title3_1": "Inserisci i dati anagrafici",

    "c-new-registry.headquarterError": "Errore nel salvataggio della sede legale",
    "c-new-registry.headquarterAlert": "Qualcosa è andato storto!",
    "c-new-registry.headquarterAlertTitle": "Errore alla creazione dell’azienda cliente",
    "c-new-registry.headquarterAlertDescription":
        "Attendi qualche secondo e riprova oppure torna alla gestione clienti: i dati inseriti rimarrano salvati fino al logout e potrai riprendere così il processo di creazione in qualsiasi momento.",
    "c-new-registry.headquarterReturn": "Torna alla gestione clienti",
    "c-new-registry.retry": "Ti preghiamo di riprovare",
    "c-new-registry.headquarterErrorDescription": "Non è possibile salvare la sede legale",
    "c-new-registry.headquarterErrorDescription2":
        "Attualmente la sede legale non può essere salvata per problemi tecnici, ti verrà richiesto di inserire questo dato in un secondo momento. Clicca su Avanti per completare la configurazione",

    "c-new-registry.subtitle1":
        "Registra l’anagrafica del tuo cliente in pochi semplici step, ci vorranno circa 5 minuti!",
    "c-new-registry.subtitle3": "Recupereremo i suoi dati automaticamente.",

    "c-new-registry.noResult": "Non trovi l'anagrafica tra i risultati?",
    "c-new-registry.insertData": "Inserisci i dati manualmente",
    "c-new-registry.noResultLabel": "Nessun risultato trovato",
    "c-new-registry.noResultLabel2": "Non sono stati trovati risultati per {searchString}",
    "c-new-registry.visualizeAll": "Mostra altri risultati",
    "c-new-registry.editData": "Modifica i dati",

    "c-new-registry.reminderLabel":
        "<b>Ricorda</b>: potrai aggiornare o modificare i dati inseriti in qualsiasi momento dalla Scheda Cliente o dalla Lista Clienti.",

    "c-progress-bar.phases": "Fasi completate {completedSteps} di {totalSteps}",
    "c-customers-table.thead.kind-activity": "Tipo di anagrafica",

    "c-new-client.new-client-form.confirm-button": "Crea azienda cliente",
    "c-new-client.new-client-form.title": "Dati azienda cliente",
    "c-new-client.new-client-form.subtitle": "Inserisci i dati dell'azienda cliente che vuoi creare e gestire.",
    "c-new-client.new-client-form.nextSteps": "Prossimi passi",
    "c-new-client.new-client-form.nextSteps2":
        "Ecco i prossimi step per completare la configurazione del tuo cliente e iniziare a gestirlo! Puoi gestire tutti i tuoi clienti dalla sezione Gestione Clienti.",

    "c-new-client.new-client-form.serviceButton": "<b>Attiva e configura i servizi</b> del cliente",
    "c-new-client.new-client-form.serviceButton3": "Attiva servizi",

    "c-new-client.new-client-form.completeAnag": "<b>Completa l'anagrafica</b> del cliente",
    "c-new-client.new-client-form.completeAnagButton": "Completa anagrafica",

    "c-new-client.new-client-form.searchAnagPlaceholder": "Cerca per Ragione Sociale, P.IVA, Codice Fiscale",
    "c-new-client.new-client-form.searchAnag": "Cerca anagrafica *",

    "c-new-client.new-client-form.additional-data.collapse.title": "Informazioni aggiuntive",
    "c-new-client.new-client-form.additional-data.collapse.subtitle": "Completa l'inserimento dei dati dell'azienda",

    "c-new-client.new-client-form.ada.collapse.title": "Atto di affidamento",
    "c-new-client.new-client-form.ada.collapse.subtitle": "Scarica l'atto di affidamento",

    "c-new-client.form.company-type": "Tipo di attività",
    "c-new-client.form.select-company-type-placeholder": "Seleziona la tua attività",
    "c-new-client.form.ragsoc": "Ragione sociale",
    "c-new-client.form.denominazione": "Denominazione",
    "c-new-client.form.ragsocInsert": "Inserisci la ragione sociale",
    "c-new-client.form.denominazioneInsert": "Inserisci la denominazione",
    "c-new-client.form.name": "Nome",
    "c-new-client.form.nameInsert": "Inserisci il nome",
    "c-new-client.form.surname": "Cognome",
    "c-new-client.form.surnameInsert": "Inserisci il cognome",
    "c-new-client.form.country": "Paese di residenza del cliente",
    "c-new-client.form.taxregion": "Nazione",
    "c-new-client.form.taxregion.it": "Italia (IT)",
    "c-new-client.form.taxregion.swiss": "Svizzera (CH)",
    "c-new-client.form.taxregion.sanmarino": "San Marino (SM)",
    "c-new-client.form.taxregion.spagna": "Spagna (ES)",
    "c-new-client.form.taxregion-placeholder": "Seleziona la tua nazione",
    "c-new-client.form.piva": "Partita IVA",
    "c-new-client.form.piva-duplicated": "Partita Iva presente in lista clienti",
    "c-new-client.form.pivaInsert": "Inserisci la p.iva",
    "c-new-client.form.taxid": "Codice fiscale",
    "c-new-client.form.taxid-duplicated": "Codice fiscale presente in lista clienti",
    "c-new-client.form.taxidInsert": "Inserisci il codice fiscale",
    "c-new-client.form.sede-legale": "Indirizzo sede legale",
    "c-new-client.form.residenza": "Indirizzo residenza anagrafica/domicilio fiscale",
    "c-new-client.form.sede-legaleInsert": "Inserisci l'indirizzo",
    "c-new-client.form.numero_civico": "N.civico",
    "c-new-client.form.numero_civicoInsert": "Inserisci n.civico",
    "c-new-client.form.cap": "CAP",
    "c-new-client.form.capInsert": "Inserisci il CAP",
    "c-new-client.form.city": "Comune",
    "c-new-client.form.cityInsert": "Inserisci il comune",
    "c-new-client.form.province": "Provincia",
    "c-c-new-client.form.province-placeholder": "Seleziona la tua provincia",
    "c-new-client.form.email": "Email",
    "c-new-client.form.pec": "PEC",
    "c-new-client.form.telephone": "Telefono",

    "c-new-client.form.additional-data.name": "Nome attività",
    "c-new-client.form.additional-data.type": "Tipo attività",
    "c-c-new-client.form.additional-data.type-placeholder": "Seleziona il tipo attività",
    "c-c-new-client.form.additional-data.type.primary": "Primaria",
    "c-c-new-client.form.additional-data.type.secondary": "Secondaria",
    "c-new-client.form.additional-data.ateco": "Codice ateco",

    "c-new-client.ada.description":
        "Per gestire determinati servizi (eRicevi, Corrispettivi,NSO,SDI, Check up Impresa, Digital Signature) e per conservare le fatture è necessaria l’autorizzazione del cliente. Genera l’Atto di Affidamento e invialo al tuo cliente. Una volta ottenuta la copia firmata potrai caricarlo dalla relativa scheda cliente e monitorarne lo stato (dovrai attendere che venga verificato).",
    "c-new-client.ada.generate.button": "Genera atto di affidamento",
    "c-new-client.ada.send-ada-by-email.description": "Oppure inoltralo direttamente al tuo cliente",
    "c-new-client.ada.send-ada-by-email.email-input": "Email del cliente",
    "c-new-client.ada.send-ada-by-email.email-input.placeholder": "Inserisci l'email del cliente",

    "c-active-tools-card.title": "Strumenti",
    "c-active-tools-card.active-services": "Servizi attivi",
    "c-active-tools-card.consumption-charge": "Addebito consumi",
    "c-active-tools-card.management-status": "Stato gestione",
    "c-active-tools-card.consumptionTo.studio": "Studio",
    "c-active-tools-card.consumptionTo.client": "Azienda Cliente",
    "c-active-tools-card.manage-button-label": "Gestisci i servizi",

    "c-advertising-tools-card.other-services": "Altri servizi",
    "c-advertising-tools-card.actions": "Azioni",

    "c-entrustment-deed-card.title": "Atto di Affidamento",
    "c-entrustment-deed-card.download-copy-button-label": "Scarica copia",
    "c-entrustment-deed-card.info":
        "Grazie a questo documento puoi gestire i sotto servizi che hai attivato e conservare le fatture per il cliente di riferimento.",

    "c-entrustment-deed-card.status.UNVERIFIED": "Non Convalidata",
    "c-entrustment-deed-card.status.PENDING_REQUEST": "In attesa di accettazione",
    "c-entrustment-deed-card.status.VALIDATED": "Convalidata",
    "c-entrustment-deed-card.status.REQUEST_REJECTED": "Rifiutata",
    "c-entrustment-deed-card.status.VALIDATION_REJECTED": "Validazione rifiutata",
    "c-entrustment-deed-card.status.PENDING_VALIDATION": "In attesa di convalida",
    "c-entrustment-deed-card.status.CERTIFIED": "Certificata",
    "c-entrustment-deed-card.status.AWAITING_UPLOAD": "In attesa di certificazione",
    "c-entrustment-deed-card.status.AWAITING_APPROVAL": "Certificazione in verifica",

    "c-rdc-delegation-card.title": "Delega RDC",
    "c-rdc-delegation-card.see-details-button-label": "Visualizza dettagli",
    "c-rdc-delegation-card.responsible-for-conservation": "Resposabile della Conservazione",
    "c-rdc-delegation-card.info": "Il Responsabile della Conservazione ricopre la carica di Legale Rapprensentante",

    "c-metering-chart.title": "Consumi {appDescription} ({year})",
    "c-metering-chart.button": "Vai alla sezione Consumi",

    "c-customer-edit-area.requiredFields": "DATI OBBLIGATORI",
    "c-customer-edit-area.notRequiredFields": "DATI NON OBBLIGATORI",

    "c-customer-edit-area-title.main": "Dati",
    "c-customer-edit-area-title.overwiew": "Overview",
    "c-customer-edit-area-title.registry": "Dati aziendali",
    "c-customer-edit-area-title.registers": "Registri",
    "c-customer-edit-area-title.taxation": "Contabilità",
    "c-customer-edit-area-title.site": "Sede",
    "c-customer-edit-area-title.destination": "Destinazione",
    "c-customer-edit-area-title.intrastat": "Configurazioni intrastat",
    "c-customer-edit-area-title.creditlines": "Linee di credito e Banche",
    "c-customer-edit-area-title.roles": "Ruoli",
    "c-customer-edit-area-title.business": "Attività",
    "c-customer-edit-area-title.intermediaries": "Intermediario",

    "c-customer-edit-sections.overwiew": "Overview",
    "c-customer-edit-sections.registry": "Dati generali",
    "c-customer-edit-sections.registers": "Registri",
    "c-customer-edit-sections.taxation": "Contabilità",
    "c-customer-edit-sections.site": "Sede",
    "c-customer-edit-sections.destination": "Destinazione",
    "c-customer-edit-sections.intrastat": "Configurazione intrastat",
    "c-customer-edit-sections.creditlines": "Linee di credito e Banche",
    "c-customer-edit-sections.roles": "Ruoli",
    "c-customer-edit-sections.business": "Attività",
    "c-customer-edit-sections.intermediaries": "Intermediari",

    //Antimoney laundering
    "c-customer-edit-sections.antimoneylaundering": "Antiriciclaggio",
    "c-customer-edit-antimoneylaundering-title": "Antiriciclaggio",
    "c-customer-edit-antimoneylaundering-description":
        "Informazioni necessarie per effettuare gli adempimenti relativi all’antiriciclaggio",
    "c-customer-edit-antimoneylaundering-info-title":
        "Adempimenti antiriciclaggio: l’adeguata verifica della clientela",
    "c-customer-edit-antimoneylaundering-info-description":
        "Il D.Lgs. 21 novembre 2007, n. 231, prevede che i professionisti destinatari della normativa antiriciclaggio devono effettuare l’adeguata verifica del cliente e del titolare effettivo con riferimento ai rapporti e alle operazioni inerenti allo svolgimento dell’attività professionale.",
    "c-customer-edit-antimoneylaundering-who-have-to-verify": "Professionista che effettua la verifica",
    "c-customer-edit-antimoneylaundering-who-have-to-verify-label": "Indica da chi viene effettuata la verifica",
    "c-customer-edit-antimoneylaundering-who-have-to-verify-placeholder":
        "Per es. dal professionista che gestisce il cliente o da altra figura incaricata",
    "c-customer-edit-antimoneylaundering-idcard-label":
        "La verifica è effettuata tramite il seguente documento del titolare effettivo",
    "c-customer-edit-antimoneylaundering-company-verification":
        "Informazioni sul titolare effettivo dell’organizzazione cliente di cui vuoi effettuare la verifica",
    "c-customer-edit-antimoneylaundering-occupationalActivity-label": "Attività lavorativa del titolare effettivo",
    "c-customer-edit-antimoneylaundering-politicallyExposedPerson-label":
        "Il titolare effettivo è una persona politicamente esposta?",
    "c-customer-edit-antimoneylaundering-politicalOfficeHeld-label":
        "Seleziona carica politica ricoperta dal titolare effettivo",
    "c-customer-edit-antimoneylaundering-other-label": "Descrivi il metodo di verifica da effettuare",
    "c-customer-edit-antimoneylaundering-alert-save": "Salvataggio effettuato con successo",
    "c-customer-edit-antimoneylaundering-alert-edit": "Modifica effettuata con successo",

    //Political Office Helds
    //ids: [ PRESIDENT_OF_THE_REPUBLIC, PRIME_MINISTER, MINISTER, DEPUTY_MINISTER, UNDERSECRETARY, PRESIDENT_OF_THE_REGION, MAYOR_OF_A_PROVINC, MAYOR_OF_A_MUNICIPALITY, DEPUTY, SENATOR, PARLIAMENTARY_EUROPEAN, REGIONAL_COUNCILOR ]

    PRESIDENT_OF_THE_REPUBLIC: "Presidente della Repubblica",
    PRIME_MINISTER: "Presidente del Consiglio",
    MINISTER: "Ministro",
    DEPUTY_MINISTER: "Vice Ministro",
    UNDERSECRETARY: "Sottosegretario",
    PRESIDENT_OF_THE_REGION: "Presidente della Regione",
    REGIONAL_COUNTY_COUNCILLOR: "Assessore regionale",
    MAYOR_OF_A_PROVINCE: "Sindaco di una Provincia",
    MAYOR_OF_A_MUNICIPALITY: "Sindaco di una Comunità",
    DEPUTY: "Deputato",
    SENATOR: "Senatore",
    PARLIAMENTARIAN: "Parlamentare",
    EUROPEAN_PARLIAMENTARIAN: "Parlamentare Europeo",
    REGIONAL_COUNCILLOR: "Consigliere Regionale",
    SIMILAR_OFFICES_FOREIGN_COUNTRIES: "Cariche analoghe in Stati esteri",

    //Documents
    IDENTITY_CARD: "Carta d'identità",
    DRIVERS_LICENSE: "Patente di guida",
    PASSPORT: "Passaporto",
    FIREARM_LICENSE: "Patente di porto d'armi",
    RESIDENCY_PERMIT: "Permesso di soggiorno",
    OTHER: "Altro",

    //Intrastat
    "c-customer-edit-intrastat-title": "Modello Intrastat",
    "c-customer-edit-intrastat-description": "Informazioni sulla presentazione e le finalità del modello intrastat",
    "c-customer-edit-intrastat.InfoBox.title": "Adempimenti modello Intrastat",
    "c-customer-edit-intrastat.InfoBox.intro": `Secondo la legislazione vigente, il presente adempimento è obbligatorio per <b>tutti gli operatori economici</b> che effettuano operazioni intracomunitarie, ossia:`,
    "c-customer-edit-intrastat.InfoBox.nonEU.goods": "• Cessioni ed acquisti di <b>beni intracomunitari</b>",
    "c-customer-edit-intrastat.InfoBox.intracommunityoperators":
        "• Prestazioni generiche di servizi da/verso <b>operatori intracomunitari</b>",
    "c-customer-edit-intrastat.InfoBox.outro":
        "Se il cliente che gestisci acquista o vende beni e/o servizi <b>all’interno della comunità europea</b> interfacciandosi con <b>Clienti/Fornitori UE</b> abilita il modello intrastat.",
    "field.intrastat.toggle-label": "Il cliente è tenuto alla compilazione del modello intrastat?",
    "field.intrastat.buyAndSell.goods.title": "Acquisto e vendita di beni",
    "field.intrastat.buyAndSell.goods.toggleLabel": "Il cliente gestisce l'acquisto e la vendita di beni",
    "field.intrastat.buyAndSell.goods.goodsPurchasedReportFrequency": "Periodicità dell'acquisto di beni",
    "field.intrastat.buyAndSell.goods.goodsSoldReportFrequency": "Periodicità della vendita di beni",
    "field.intrastat.buyAndSell.goods.goodsSoldDatatypeReport":
        "Qual è la finalità della compilazione del modello intrastat?",
    "field.intrastat.buyAndSell.goods.TAX_PURPOSE": "Fiscale",
    "field.intrastat.buyAndSell.goods.TAX_AND_STATISTICS_PURPOSE": "Fiscale e statistica",
    "field.intrastat.buyAndSell.services.title": "Acquisto e vendita di servizi",
    "field.intrastat.buyAndSell.services.toggleLabel": "Il cliente gestisce l'acquisto e la vendita di servizi",
    "field.intrastat.buyAndSell.services.servicesPurchasedReportFrequency": "Periodicità dell'acquisto di servizi",
    "field.intrastat.buyAndSell.services.servicesSoldReportFrequency": "Periodicità della vendita di servizi",
    "c-customer-edit-intrastat.alertMessage.success.title": "Modello intrastat:",
    "c-customer-edit-intrastat.alertMessage.success.description": "dati salvati con successo",
    "error.intrastat.buyAndSell.goods.goodsPurchasedReportFrequency": "Seleziona la periodicità dell'acquisto di beni",
    "error.intrastat.buyAndSell.goods.goodsSoldReportFrequency": "Seleziona la periodicità della vendita di beni",
    "error.intrastat.buyAndSell.goods.goodsSoldDatatypeReport":
        "Seleziona la finalità della compilazione del modello intrastat",
    "error.intrastat.buyAndSell.services.servicesPurchasedReportFrequency":
        "Seleziona la periodicità dell'acquisto di servizi",
    "error.intrastat.buyAndSell.services.servicesSoldReportFrequency":
        "Seleziona la periodicità della vendita di servizi",

    //Business sections
    "c-customer-edit-sections.0-business-step": "Dettagli dell'attività",
    "c-customer-edit-sections.1-business-step": "Contabilità e Cespiti",
    "c-customer-edit-sections.2-business-step": "Sezionali",
    "c-customer-edit-sections.3-business-step": "Configurazioni Iva",
    "c-customer-edit-sections.4-business-step": "Iva pro-rata",

    "c-customer-edit-activity-tag.MAIN": "ATTIVITÀ PREVALENTE",
    "c-customer-edit-activity-tag.SECONDARY": "ATTIVITÀ SECONDARIA",
    "c-customer-edit-activity-tag.CANCELED": "ATTIVITÀ CESSATA",

    "c-customer-edit-activity-data-missing": "Dati mancanti",
    "c-customer-edit-activity-data-completed": "Dati completi",

    "c-customer-card.footerlabel-services": "Gestisci servizi",
    "c-customer-card.footerlabel-edit-customerdata": "Gestisci i dati del cliente",
    "c-customer-card.footerlabel-removemanagement": "Annulla gestione",

    "c-customer-edit-section.registry.mainData": "Dati generali",
    "c-customer-edit-section.registry.mainDataDesc": "Informazioni sui dati generali del cliente",
    "c-customer-edit-section.registry.otherData": "Altri dati",
    "c-customer-edit-section.registry.legalFormData": "Dati relativi alla natura giuridica",
    "c-customer-edit-section.registry.fiscalPositionData": "Posizione fiscale",
    "c-customer-edit-section.registry.balanceData": "Bilancio",
    "c-customer-edit-section.registry.codesData": "Codici",
    "c-customer-edit-section.registry.headquartersData": "Sedi principali",
    "c-customer-edit-section.registry.mainHeadquarterData": "Sede legale",
    "c-customer-edit-section.registry.subjectData": "Dati del soggetto",

    "c-customer-edit-section.taxation.mainData": "Contabilità",
    "c-customer-edit-section.taxation.mainDataDesc": "Informazioni sulla contabilità del cliente",
    "c-customer-edit-section.taxation.financialStatement": "Esercizio contabile",
    "c-customer-edit-section.taxation.firstTitle": "Primo esercizio",
    "c-customer-edit-section.taxation.financialStatementTitle": "Bilancio d'esercizio",
    "c-customer-edit-section.taxation.financialStatementDuration": "Data chiusura dell'esercizio contabile",
    "c-customer-edit-section.taxation.financialStatementDurationTip":
        "<b>Data chiusura dell’esercizio.</b>{br}{br}La data (giorno e mese) di chiusura dell’esercizio consente di determinare la durata dello stesso esercizio contabile. Il primo esercizio può durare al massimo 15 mesi mentre gli esercizi successivi al primo durano solitamente 12 mesi salvo eccezioni.",
    "c-customer-edit-section.taxation.financialStatementTipTitle": "Determinare la chiusura del bilancio di esercizio ",
    "c-customer-edit-section.taxation.financialStatementTipDesc":
        "Se la chiusura del bilancio da effettuare fa riferimento all'esercizio di costituzione e se l'esercizio in questione ha una durata maggiore di 12 mesi (può durare al massimo 15 mesi), sarà necessario indicare le date di inizio e fine dell’esercizio. Se non si verificano entrambe le condizioni e per esercizi successivi al primo basterà indicare solo giorno e mese di chiusura del bilancio d’esercizio. ",
    "c-customer-edit-section.taxation.otherData": "Altri dati",
    "c-customer-edit-section.taxation.chartAccounts": "Piano dei conti",
    "c-customer-edit-section.taxation.locked.title": "Dati fine esercizio contabile non modificabili",
    "c-customer-edit-section.taxation.locked.content":
        "Giorno e mese di chiusura del bilancio d’esercizio state applicate alle tue registrazioni contabili e non sono più modificabili.",
    "c-customer-edit-section.taxation.confirm.title": "Conferma dati esercizio contabile",
    "c-customer-edit-section.taxation.confirm.content":
        "Attenzione: quando i dati dell’esercizio contabile che stai per salvare verranno applicati per la prima volta in una qualsiasi registrazione contabile (prima nota, bilancio ecc) non sarà più possibile modificarli. Confermi di voler salvare i dati? ",
    "c-customer-edit-section.taxation.adjustment.title": "Adegua esercizio all’anno solare",
    "c-customer-edit-section.taxation.adjustment.content":
        "Adeguando l’esercizio contabile all’anno solare verrà creato un esercizio contabile di {months} mesi. A partire dall’anno prossimo l’esercizio coiciderà con l’anno solare e durerà 12 mesi.",

    "c-customer-edit-business.header-title": "{icon} Attività",
    "c-customer-edit-business.header-description": "Lista delle attività del cliente ",

    "c-customer-edit-business.step1": "Dettagli dell'attività",
    "c-customer-edit-business.step1-title": "Dettagli dell'attività",
    "c-customer-edit-business.step1-description": "Informazioni sull’attività del tuo cliente",
    "c-customer-edit-business.step2": "Contabilità e Cespiti",
    "c-customer-edit-business.step2-title": "Configurazioni contabili",
    "c-customer-edit-business.step2-description": "Informazioni sulla contabilità in merito all’attività del cliente",
    "c-customer-edit-business.step2.cespitiTitle": "Dati cespiti",
    "c-customer-edit-business.step2.cespitiDescription":
        "Informazioni sui beni strumentali utilizzati per l’attività del cliente",
    "c-customer-edit-business.step2.sectionalTitle": "Dati sezionali",
    "c-customer-edit-business.step2.sectionalDescription": "Informazioni sui beni materiali o i",
    "c-customer-edit-business.step3": "Sezionali",
    "c-customer-edit-business.step3-title": "Sezionali",
    "c-customer-edit-business.step3-description": "Informazioni sulla numerazione dei documenti del cliente",
    "c-customer-edit-business.step4": "Configurazioni Iva",
    "c-customer-edit-business.ivaProRataDescription": "Informazioni sulla quota di IVA detraibile",
    "c-alert-business-iva-pro-rata": "Iva pro-rata non compilata per l’anno in corso",
    "c-alert-business-iva-pro-rata-notHandle": "Non gestire per il {year}",
    "c-customer-edit-business.step4-title": "Configurazioni Iva",
    "c-customer-edit-business.step4-description": "Informazioni sull’iva e il pro-rata del cliente",
    "c-customer-edit-business.step5": "Iva pro-rata",
    "c-customer-edit-business.step5.title.pastProRatas": "Riepilogo Iva pro-rata",
    "c-customer-edit-business.step5.desc.pastProRatas": "Riepilogo dell'iva pro rata gestita in precedenza",
    "c-customer-edit-business.dialog-main-sectional-title": "Conferma sostituzione sezionale prevalente",
    "c-customer-edit-business.dialog-main-sectional-content":
        "Hai già un sezionale prevalente ({sectionalDescription}), vuoi sostituirlo?",
    "c-customer-edit-business.dialog-main-sectional-confirm": "Sì, sostituisci",
    "c-customer-edit-business.sectional.delete.success": "Sezionale eliminato con successo",
    "c-customer-edit-business.sectional.create.success": "Sezionale creato con successo",
    "c-customer-edit-business.sectional.update.success": "Sezionale aggiornato con successo",

    "c-customer-edit-business.type.PREVALENT": "Prevalente",
    "c-customer-edit-business.type.SECONDARY": "Secondaria",
    "c-customer-edit-business.alert.prevalentExist-title": "Attenzione: esiste già un’attività prevalente",
    "c-customer-edit-business.alert.prevalentExist-description":
        "L’attività {businessName} è attualmente quella prevalente, desideri sostituirla rendendo prevalente l’attività che stai creando? Se la sostituisci {businessName} verrà contrassegnata come secondaria.",
    "c-customer-edit-business.taxationType.ORDINARY": "Ordinaria",
    "c-customer-edit-business.taxationType.SIMPLIFIED": "Semplificata",
    "c-customer-edit-business.taxationType.SIMPLIFIED_LABEL": "Tipo di regime reddituale imprese semplificate",
    "c-customer-edit-business.taxationType.SIMPLIFIED_CASH_ACCOUNTING": "Di cassa",
    "c-customer-edit-business.taxationType.SIMPLIFIED_PAYMENTS_PRESUMPTION": "Presunzione di incassi e pagamenti",
    "c-customer-edit-business.taxationType.FLAT_RATE": "Forfettaria",
    "c-customer-edit-business.type.ACTIVE": "Attiva",
    "c-customer-edit-business.type.DEACTIVATED": "Non attiva",
    "c-customer-edit-business.taxationType.CASH": "Di cassa",
    "c-customer-edit-business.taxationType.COLLECTION_OR_PAYMENT_PRESUMPTION": "Incassi e pagamenti",
    "c-customer-edit-business.taxationType.VAT_WITH_ANNOTATION": "Iva con annotazione",
    "c-customer-edit-business.taxationType.RECEIPTS_AND_PAYMENT": "incassi e pagamenti",
    "c-customer-edit-business.vatPeriodicity.NORMAL_MONTHLY": "Mensile normale",
    "c-customer-edit-business.vatPeriodicity.MONTHLY_ADVANCE": "Mensile posticipata",
    "c-customer-edit-business.vatPeriodicity.NORMAL_QUARTERLY": "Trimestrale normale",
    "c-customer-edit-business.vatPeriodicity.QUARTERLY_ART_74_C_4": "Trimestrale art. 74 c.4",
    "c-customer-edit-business.reportRegister.ON_SALES": "Su vendite",
    "c-customer-edit-business.reportRegister.ON_RECEIPT": "Su corrispettivi",
    "c-customer-edit-business.depositType.HISTORICAL": "Storico",
    "c-customer-edit-business.depositType.ANALYTICAL": "Calcolato",
    "c-customer-edit-business.depositType.FORECAST": "Previsionale",
    "c-customer-edit-business.plafondManagement.NO": "No",
    "c-customer-edit-business.plafondManagement.FIXED_PLAFOND": "Sì, del metodo plafond fisso",
    "c-customer-edit-business.plafondManagement.MOBILE_PLAFOND": "Sì, del metodo plafond mobile",
    "c-customer-edit-business.proRataManagementType.NON_DEDUCTIBLE_VAT_ON_COSTS": "Iva non detraibile imputata a costo",
    "c-customer-edit-business.proRataManagementType.NON_DEDUCTIBLE_VAT_DINSTINCT_FROM_COSTS":
        "Iva non detraibile distinta dal costo",
    "c-customer-edit-business.NON_DEDUCTIBLE_VAT_ON_COSTS.Tooltip":
        "Si tratta della percentuale di indetraibilità da applicare sull'Iva acquisti ed è bloccata al 100%, quindi non modificabile, nel caso di iva non detraibile imputata a costo.",
    "c-customer-edit-business.NON_DEDUCTIBLE_VAT_DINSTINCT_FROM_COSTS.Tooltip":
        "Indica la percentuale di indetraibilità da applicare sull'Iva acquisti.",
    "c-customer-edit-business.proRataQuestion": "Vuoi gestire l’iva pro-rata per l’anno in corso?",
    "c-customer-edit-business.proRata.yearQuestion":
        "Quale tipo di trattamento del conto iva indetraibile viene utilizzato?",
    "c-customer-edit-business.atecoTooltipTitle": "Cos’è il Codice Ateco 2007 e dove lo trovo?",
    "c-customer-edit-business.atecoTooltipDescription":
        "Si tratta di una combinazione alfanumerica che identifica l’attività economica del cliente. Puoi trovarlo nella Visura Camerale oppure puoi ricavarlo cliccando su “Ricava”.",
    "c-customer-edit-business.plafondTooltipTitle": "Da cosa si costituisce il Plafond?",
    "c-customer-edit-business.plafondTooltipDescription":
        "Il plafond è costituito dalla somma delle cessioni relative ad operazioni internazionali, non imponibili, registrate ai fini IVA.",
    "c-customer-edit-business.proRataNonDeducibilityRate": "Percentuale di indetraibilità dell’IVA",
    "c-customer-edit-business.proRataYear": "Anno di riferimento",
    "c-customer-edit-business.separateNumberMarginTooltipTitle": "Numerazione registri del margine",
    "c-customer-edit-business.separateNumberMarginTooltipDescription":
        "Indica se nella stampa dei registri che riguardano i beni usati (regime del margine), deve essere utilizzata una numerazione delle pagine separata rispetto alla stampa dei registri per le vendite ordinarie.",
    "c-customer-edit-business.cashVatTooltipTitle": "A cosa serve l’IVA di cassa?",
    "c-customer-edit-business.cashVatTooltipDescription":
        "Permette di differire la detrazione dell’IVA sulle fatture di acquisto nel momento in cui viene effettuato il pagamento del fornitore o al massimo entro un anno.",

    "c-customer-edit-business.ateco2007": "RICERCA AVANZATA CODICE ATECO 2007",
    "c-customer-edit-business.ateco2007subtitle": "Cerca il codice Ateco della tua attività",
    "c-customer-edit-business.ateco2007description":
        "Il codice Ateco è una combinazione alfanumerica che identifica l’attività economica del cliente. Se non conosci il codice Ateco segui la ricerca guidata per ricavarlo.",
    "c-customer-edit-business.ateco2007searchPlaceholder": "Cerca per denominazione, parola chiave",
    "c-customer-edit-business.ateco2007searchTitle": "Cerca Codice Ateco 2007",
    "c-customer-edit-business.ateco2007selection": "Seleziona la denominazione e l’annesso Codice Ateco",
    "c-customer-edit-business.atecoName": "Descrizione:",
    "c-customer-edit-business.ateco2007code": "Codice ATECO:",
    "c-customer-edit-business.ateco2007codeDisabledTooltipTitle": "Codice ATECO non selezionabile",
    "c-customer-edit-business.ateco2007codeDisabledTooltipDescription":
        "Esiste già un'attività associata al codice ATECO selezionato. Seleziona un altro codice ATECO per procedere.",
    "c-customer-edit-business.ateco2007codeNotificationTitle": "Errore codice ATECO",
    "c-customer-edit-business.ateco2007codeNotificationDescription":
        "esiste già un'attività associata al codice ATECO selezionato. Seleziona un altro codice ATECO per procedere",

    "c-customer-edit-business.deleteButton": "Elimina attività",
    "c-customer-edit-business.setNoActiveButton": "Cambia lo stato in Cessata",
    "c-customer-edit-business.setActiveButton": "Cambia lo stato in Attiva",
    "c-customer-edit-business.delete": "Conferma eliminazione attività",
    "c-customer-edit-business.deleteDescription":
        "Cliccando su Elimina rimuoverai in modo permanente l’attività e tutte i dati associati.",
    "c-customer-edit-business.creationConfirm": "Attività creata con successo",
    "c-customer-edit-business.creationConfirmDescription": "L'attività {businessName} è stata creata con successo",
    "c-customer-edit-business.deleteConfirm": "Attività eliminata con successo",
    "c-customer-edit-business.deleteConfirmDescription": "L'attività selezionata è stata eliminata con successo",
    "c-customer-edit-createIn": "Crea in {icon} {minutes}",

    "c-customer-edit-business.create": "Crea nuova attività",

    "c-customer-edit-business.createDescription":
        "Inserisci i dettagli dell’attività, informazioni su Contabilità, Cespiti, Sezionali e Iva pro-rata",
    "business.edit.success": "Attività modificata con successo",
    "business.edit.success.description": "L'attività {businessName} è stata modificata con successo",

    "c-customer-edit-business.endDateTitle": "Data cessazione",
    "c-customer-edit-business.endDateMessage": "Indica la data in cui l’attività è cessata",
    "c-customer-edit-business.endDateLabel": "Data cessazione attività",
    "c-customer-edit-business.endDateConfirm": "Attività cessata con successo",
    "c-customer-edit-business.endDateConfirmDescription": "L'attività è adesso in stato di cessazione",
    "c-customer-edit-activity-tag.ceased": "Attività Cessata",
    // Bank

    "c-customer-edit-banks.title": "Conti",
    "c-customer-edit-banks.titleDescription": "Informazioni sui conti del cliente",
    "c-customer-edit-bank.tag.registryAccount": "CONTO ANAGRAFICA",
    "c-customer-edit-bank.tag.tspayAccount": "CONTO TS PAY",
    "c-customer-edit-bank.create": "Crea nuovo conto",
    "c-customer-edit-bank.createTsPay": "Aggiungi conti",
    "c-customer-edit-bank.createDescription":
        "Crea conti correnti, conti deposito e Paypal per l’anagrafica di riferimento.",
    "c-customer-edit-bank.createTsPayDescription":
        "Aggiungendo conti TS Pay potrai visualizarli sia sull’anagrafica che sull’applicazione di TS Pay.",
    "c-customer-edit-bank.createIn": "Crea in ",
    "c-customer-edit-bank.createTsPayIn": "Aggiungi in ",
    "c-customer-edit-bank.createMin": "min",
    "c-customer-edit-bank.delete": "Conferma eliminazione",
    "c-customer-edit-bank.deleteDescription": "Sei sicuro di voler rimuovere il conto selezionato?",
    "c-customer-edit-bank.createModal.title": "CREA CONTO",
    "c-customer-edit-bank.editModal.title": "MODIFICA CONTO",
    "c-customer-edit-bank.createModal.subtitle":
        "Completa il form con i dati del conto e poi salva per visualizzarlo nella lista dei conti dell’anagrafica",
    "c-customer-edit-bank.createModal.saveAndCreate": "Salva e crea nuovo",
    "c-customer-edit-bank.type": "Tipologia di conto",
    "c-customer-edit-bank.iban": "IBAN",
    "c-customer-edit-bank.account": "Email",
    "c-customer-edit-bank.bankAccount": "Numero del conto corrente",
    "c-customer-edit-bank.bank": "Nome della banca",
    "c-customer-edit-bank.bic": "BIC",
    "c-customer-edit-bank.abi": "ABI",
    "c-customer-edit-bank.cab": "CAB",
    "c-customer-edit-bank.cin": "CIN",
    "c-customer-edit-bank.currency": "Valuta di riferimento",
    "c-customer-edit-bank.overdraftAmount": "Importo castelletto",
    "c-customer-edit-bank.creditLineAmount": "Importo fido",

    "bankTypes.BANK_ACCOUNT": "Conto corrente",
    "bankTypes.PAYPAL_ACCOUNT": "Conto Paypal",
    "bankTypes.DEPOSIT_ACCOUNT": "Conto deposito",

    "currencies.EUR": "Euro",

    "c-customer-header-status.ACTIVE": "Gestione attiva",
    "c-customer-header-status.DEACTIVATED": "Gestione disattivata",
    "c-customer-header-status.UNCONNECTED": "Non collegata",

    "c-customer-edit-intermediaries.header-title": "{icon} Intermediari",
    "c-customer-edit-intermediaries.header-description":
        "Gli intermediari sono coloro che operano su incarico degli interessati e provvedono, per loro conto, alla trasmissione delle dichiarazioni, alla registrazione degli atti, all’esecuzione dei pagamenti, alla consultazione e all’aggiornamento del catasto e dei registri immobiliari, all’invio di comunicazioni e istanze.",
    "c-customer-edit-intermediaries.createDescription":
        "Inserisci i dati dell'intermediario, associa uno o più ambienti di sicurezza e uno o più incaricati se necessario",
    "c-customer-edit-intermediaries.create": "Crea nuovo intermediario",
    "c-customer-edit-intermediary.choose-toselect-or-totype": "oppure crea un nuovo intermediario",
    "c-customer-edit-intermediaries.step1": "Intermediario",
    "c-customer-edit-intermediaries.step2": "Ambienti di sicurezza",
    "c-customer-edit-intermediaries.step3": "Incaricati",
    "c-customer-edit-first-intermediaries.step1-title": "Crea il tuo primo intermediario",
    "c-customer-edit-intermediaries.step1-title": "Crea o collega un intermediario",
    "c-customer-edit-intermediaries.step2-title": "Ambienti di sicurezza",
    "c-customer-edit-intermediaries.step3-title": "Incaricati",
    "c-customer-edit-intermediaries.step1-description": "Inserisci informazioni sull'intermediario",
    "c-customer-edit-intermediaries.step2-description": "Crea uno o più ambienti di sicurezza per l’intermediario",
    "c-customer-edit-intermediaries.step3-description": "Crea uno o più incaricati associati all’intermediario",
    "c-customer-edit-intermediaries.securityenv-exists": "{icon} Ambiente di sicurezza associato",
    "c-customer-edit-intermediaries.securityenv-exists-description":
        "L’intermediario che hai selezionato ha già un ambiente di sicurezza associato: aggiungi nuovi ambienti di sicurezza o procedi",
    "c-customer-edit-intermediaries.delegateds-companyinfo": "{icon} Associa Incaricato",
    "c-customer-edit-intermediaries.delegateds-companyinfo-description":
        "Gli intermediari identificati come persone giuridiche necessitano l’inserimento di un incaricato in quanto persona fisica (che possiede nome e cognome)",
    "c-customer-edit-intermediaries.fiedbox-incremental": "Ambiente di sicurezza {n}",
    "c-customer-edit-intermediaries.add-security-env": "Aggiungi ambiente di sicurezza",
    "c-customer-edit-intermediaries.expirydate-info":
        "Per visualizzare la data di scadenza devi inserire la password dell'ambiente di sicurezza che hai creato sul sito dell'agenzia delle entrate.",
    "c-customer-edit-intermediaries.create-intermediary": "Crea Intermediario",
    "c-customer-edit-intermediaries.add-delegated": "Aggiungi incaricato",
    "c-customer-edit-intermediaries.delegated": "Incaricato",

    "c-customer-edit-intermediary.error.name": "Il nome dell'intermediario è obbligatorio",
    "c-customer-edit-intermediary.error.surname": "Il cognome dell'intermediario è obbligatorio",
    "c-customer-edit-intermediary.error.company": "La ragione sociale dell'intermediario è obbligatoria",
    "c-customer-edit-intermediary.error.vat": "La partita iva digitata è incompleta o errata",
    "c-customer-edit-intermediary.error.alreadyExists":
        "L'intermediario inserito è già esistente, selezionalo dalla lista",
    "c-customer-edit-intermediary.error.taxcode": "Il codice fiscale non è valido",
    "c-customer-edit-intermediary.error.taxcode.emptyOrNull": "Il codice fiscale non può essere nullo",
    "c-customer-edit-intermediary.error.entratelCode": "L'utenza Entratel è incompleta o errata",
    "c-customer-edit-intermediary.error.description": "La descrizione è obbligatoria",
    "c-customer-edit-intermediary.error.sitecode": "Codice non inserito o non valido",
    "c-customer-edit-intermediary.error.sitecode-duplicated": "Codice già esistente",
    "c-customer-edit-intermediary.error.password": "Password non valida, ritenta",
    "c-customer-edit-intermediary.error.password-not-typed": "Password mancante, digita e conferma la password",
    "c-customer-edit-intermediary.error.files": "Carica tutti i file necessari",
    "c-customer-edit-intermediary.error.filemissing": "Carica tutti i file necessari e ritenta",
    "c-customer-edit-intermediary.error.filesExists": "File esistenti in un precedente ambiente di sicurezza",
    "c-customer-edit-intermediary.error.filesIncongruent":
        "File non validi, sono consentiti due file .p12 ed un file .ks",

    "c-customer-edit-intermediary.error.invalid-extension": "File .p12 non presenti in lista, ritenta",
    "c-customer-edit-intermediary.delegateds.choose-totype": "oppure crea nuovi incaricati",

    "c-customer-edit-intermediary.security-env-dialog.title": "Salva ambienti di sicurezza",
    "c-customer-edit-intermediary.security-env-dialog.content":
        "Procedendo al terzo step i dati relativi agli ambienti di sicurezza verranno salvati in modo definitivo: non sarà più possibile modificare i file associati a ogni singolo ambiente di sicurezza. Se successivamente vorrai sostituire i file sarà necessario eliminare e creare un nuovo ambiente di sicurezza.",
    "c-customer-edit-intermediary.security-env-dialog.confirm": "Ok, ho capito",
    "c-customer-edit-intermediary.question-in-charge": "Vuoi associare incaricati all’intermediario? (opzionale)",

    // Errors
    "error.business.description": "Descrizione incompleta",
    "error.business.type": "Tipo di attività non scelto",
    "error.business.atecoCodeDescription": "Codice Ateco non selezionato",
    "error.business.startDate": "Data di inizio attività non valida",
    "error.business.accountingType": "Tipo di contabilità non selezionato",
    "error.business.assetGroup": "Gruppo di appartenenza dei cespiti non selezionato",
    "error.business.assetSpecies": "Specie di appartenenza dei cespiti non selezionato",
    "error.business.vatPeriodicity": "Periodicità IVA non selezionata",
    "error.business.vatStatementRegistry": "Registro IVA non selezionato",
    "error.business.vatInAdvanceType": "Tipo di acconto IVA non selezionato",
    "error.business.specialVatRegime": "Regime IVA speciale non selezionato",
    "error.business.vatExemptionManagementForExporter": "Plafond non selezionato",
    "error.business.vatExempitonForExporterStartingDate": "Data di inizio plafond non valida",
    "error.business.proRataManagementType": "Tipo di gestione del pro rata non selezionato",
    "error.business.sectional": "Completa la compilazione dei sezionali in stato di modifica",
    "error.taxation.firstAccountingPeriodStartDate.required": "Data di inizio obbligatoria",
    "error.taxation.firstAccountingPeriodEndDate.required": "Data di fine obbligatoria",
    "error.taxation.firstAccountingPeriodDifferenceMore": "non può durare più di 15 mesi",
    "error.taxation.firstAccountingPeriodDifferenceLess": "non può durare meno di 12 mesi",
    "error.business.cashAccountingExpiry": "Data di scadenza IVA di cassa non valida",

    //List
    "list.business.deleteBusiness": "Elimina attività",
    "list.business.changeIntoCeased": "Cambio lo stato in cessata",
    "list.business.favoritesBusiness": "Aggiungi alle attività preferite",

    // Fields
    "field.registry.type": "Tipologia anagrafica",
    "field.registry.country": "Nazione",
    "field.registry.businessName": "Ragione sociale",
    "field.registry.denomination": "Denominazione",
    "field.registry.taxIdentifier": "Codice fiscale",
    "field.registry.vatIdentifier": "Partita IVA",
    "field.registry.businessLegalForm": "Natura giuridica",
    "field.registry.shareCapital": "Valore del capitale sociale",
    "field.registry.paidUpShareCapital": "Importo del capitale sociale versato",
    "field.registry.sharesCount": "Numero di azioni",
    "field.registry.worthOfStock": "Valore delle azioni",
    "field.registry.companySituation": "Situazione dell'impresa",
    "field.registry.companyStatus": "Stato della società",
    "field.registry.establishmentDate": "Data di costituzione",
    "field.registry.expiryDate": "Data termine",
    "field.registry.financialStatementApprovalDate": "Data di approvazione",
    "field.registry.financialStatementEndDate": "Data chiusura bilancio",
    "field.registry.inpsHeadQuarter": "Sede Inps",
    "field.registry.foreignStateOfResidence": "Stato estero",

    "field.registry.name": "Nome",
    "field.registry.surname": "Cognome",
    "field.registry.birthDate": "Data di nascita",
    "field.registry.gender": "Sesso",

    "field.taxation.interimFinancialStatement": "Periodicità del bilancio infrannuale",
    "field.taxation.excludeVATPaymentsAccumulationOrCompensation":
        "Desideri escludere il cumulo/compensazione delle liquidazioni?",
    "field.taxation.taxationType": "Tipo tassazione",
    "field.taxation.chartAccounts": "Struttura del piano dei conti",
    "field.taxation.chartAccounts.notSpecified": "Piano dei conti standard",
    "field.taxation.chartAccounts.standard.title": "Struttura del Piano dei Conti Standard ",
    "field.taxation.chartAccounts.standard.desc":
        "La struttura applicabile è quella del Piano dei Conti Standard che è organizzata su tre livelli: MASTRO, CONTO e SOTTOCONTO, basata sulle norme che regolano la redazione del Bilancio.",
    "field.taxation.financialStatementClosingDay": "Giorno",
    "field.taxation.financialStatementClosingMonth": "Mese",
    "field.taxation.financialStatementClosingDayMonth": "Giorno e mese di chiusura",
    "field.taxation.financialStatementClosingDayMonthAdjusted": "Adeguato all’anno solare",
    "field.taxation.firstAccountingPeriod":
        "L'esercizio da chiudere è l'esercizio di costituzione e dura piu' di 12 mesi?",
    "field.taxation.firstAccountingPeriodStartDate": "Data di inizio esercizio",
    "field.taxation.firstAccountingPeriodEndDate": "Data di fine esercizio",
    "field.taxation.firstAccountingPeriodDifference": "Durata dell'esercizio: {months} mesi",
    "field.taxation.financialStatementAdjustment": "Se desideri far coincidere la data di chiusura con il 31/12",
    "field.taxation.financialStatementAdjustmentButton": "Adegua esercizio all’anno solare",

    "field.business.description": "Descrizione attività",
    "field.business.businessType": "Tipo di attività",
    "field.business.selfEmployed": "L'attività fa riferimento ad un professionista?",
    "field.business.atecoCode": "Codice ATECO",
    "field.business.taxationType": "Qual è il tipo di contabilità utilizzato?",
    "field.business.activity": "Descrizione codice ATECO",
    "field.business.activityStart": "Data inizio attività",
    "field.business.activityState": "Qual è lo stato attuale dell’attività?",
    "field.business.destination": "Abilitazione destinazioni",
    "field.business.cespitiGroup": "Gruppo di appartenenza dei cespiti",
    "field.business.cespitiGroupOption": "GRUPPO",
    "field.business.cespitiKindOption": "Specie",
    "field.business.cespitiSpecies": "Specie di appartenenza dei cespiti",
    "field.business.sectionalCode": "Sezionale",
    "field.business.sectionalDescriptionCode": "Descrizione del sezionale",
    "field.business.sectionalDescription": "Descrizione",
    "field.business.sectionalName": "Nome",
    "field.business.sectionalProvince": "Provincia",
    "field.business.simplifiedType": "Di che tipo è il regime reddituale imprese semplificate?",
    "field.business.profitabilityPercentage": "Percentuale di redditività",
    "field.business.flatRatePercentage": "Percentuale di forfettizzazione IVA",
    "field.business.simplifiedCompaniesSprintLog": "Tipo di registro di stampa imprese semplificate adottato",
    "field.business.vatPeriodicity": "Indica la periodicità dell’IVA relativa all’attività",
    "field.business.reportRegister":
        "Registro denuncia: qual è il registro su cui verrà effettuata la liquidazione IVA?",
    "field.business.depositType": "Tipo acconto: qual è la modalità utilizzata per l’acconto IVA?",
    "field.business.taxRegime": "Regime speciale",
    "field.business.flatTaxAccountingRate": "Percentuale di forfettizzazione",
    "field.business.vatDeductionRate": "Percentuale di detraibilità dell’IVA",
    "field.business.plafondManagement": "L’attività si avvale del plafond?",
    "field.business.mobileplafondPeriodStart": "Data di inizio del plafond mobile",
    "field.business.fixedplafondPeriodStart": "Data di inizio del plafond fisso",
    "field.business.useSeparateNumberingForMarginRegisters":
        "Viene utilizzata una numerazione separata per i registri del margine?",
    "field.business.flatRatePercentageForfait": "Percentuale di forfait",
    "field.business.vatDeductibilityPercentage": "Percentuale di detraibilità dell’IVA",
    "field.business.cashAccounting": "Iva di cassa: la detrazione dell’IVA differisce sulle fatture di acquisto?",
    "field.business.cashAccountingExpiry": "Mese e anno di termine dell’IVA di cassa",
    "field.business.proRataManagementType": "Il conto IVA indetraibile è sottoposto ad un trattamento? (opzionale)",
    "field.business.mainTitle": "Sezionale prevalente",
    "field.business.main": "Imposta come sezionale prevalente",

    "field.headquarter.sameAddress":
        "La sede dell’attività coincide con la sede legale dell’azienda o con il domicilio fiscale?",
    "field.headquarter.noSameAddress": "No, è un'unità locale",
    "field.headquarter.headquarter": "Sede 0",
    "field.headquarter.province": "Provincia",
    "field.headquarter.municipality": "Comune",
    "field.headquarter.hamlet": "Frazione (opzionale)",
    "field.headquarter.postalCode": "CAP",
    "field.headquarter.address": "Indirizzo",
    "field.headquarter.streetNumber": "Numero civico",
    "field.headquarter.add": "Aggiungi sede",
    "field.headquarter.cciaaProvince": "Provincia della Camera di Commercio",
    "field.headquarter.samePaymentProvince":
        "La provincia di versamento differisce dalla provincia della Camera di Commercio?",
    "field.headquarter.paymentProvince": "Provincia di versamento",
    "field.headquarter.reaNumber": "Numero R.E.A.",
    "field.headquarter.reaProgressive": "Progressivo R.E.A.",

    "field.destination.activeDestination": "Hai destinazioni attive al momento?",
    "field.destination.noActiveDestination": "No, non ci sono destinazioni attive",
    "field.destination.yesActiveDestination": "Sì, c’è una o più destinazioni attive",
    "field.destination.add": "Aggiungi destinazione",
    "field.destination.destination": "Destinazione 0",
    "field.destination.type": "Tipologia di destinazione",
    "field.destination.description": "Descrizione destinazione",
    "field.destination.activeDestinationQuestion": "Qual è lo stato attuale dell’attività?",
    "field.destination.active": "Attiva",
    "field.destination.noActive": "Non attiva",
    "field.destination.activityType": "Tipo di attività",
    "field.destination.activityTypeDesctiption": "Indica la prevalenza dell’attività ai fini ISA",
    "field.destination.revenueOnly": "Solo ricavi",
    "field.destination.costsAndRevenues": "Costi e ricavi",
    "field.destination.annotation": "Modalità di annotazione distinta",
    "tips.destination.annotationDescription": "Indica l’annotazione distinta ai fini ISA",
    "field.destination.validityStartDate": "Data inizio validità",
    "field.destination.validityEndDate": "Data fine validità",

    "field.address": "Indirizzo",
    "field.streetNumber": "Numero civico",
    "field.municipality": "Comune",
    "field.province": "Provincia",
    "field.postalCode": "CAP",
    "field.hamlet": "Frazione",
    "field.code": "Codice",
    "field.entratelCode": "Utenza Entratel",
    "field.expiringdate": "Data scadenza",

    "field.intermediary.radiolabel": "Seleziona il tipo di anagrafica dell’intermediario",
    "field.intermediary.description": "Descrizione",
    "field.intermediary.select-label": "Seleziona intermediario esistente",
    "field.intermediary.select-placeholder": "Seleziona intermediario",
    "field.delegated.select-label": "Seleziona incaricati esistenti",
    "field.delegated.select-placeholder": "Seleziona incaricati",
    "field.multiple-upload": "Trascina qui i {maxfiles} file, oppure ",
    "field.multiple-upload-button": "Carica i file",
    "field.multiple-security-env": "File dell’ambiente di sicurezza  ",
    "field.multiple-upload.info-title": "Dove trovo i 3 file dell’ambiente di sicurezza?",
    "field.multiple-upload.info-description":
        "Sono i 3 fle allegati nel percorso del supporto di sicurezza quando hai impostato l’ambiente di sicurezza per l’Agenzia delle Entrate",
    "field.multiple-upload.error-file-length": "Il numero di file consentito è stato superato",
    "field.multiple-upload.error-file-size": "Uno o più file superano le dimensioni massime consentite",
    "field.multiple-upload.error-file-extension": "Uno o più file hanno una estensione non consentita",

    // Tips

    "tips.business.description":
        "Descrizione libera dell’attività, non è la denominazione associata al Codice Ateco 2007",
    "tips.business.ateco2007Code": "Per esempio 58.29.00",
    "tips.business.destinationDescription":
        "Indica i tipi di destinazione da utilizzare per l’attivà (puoi scegliere una o più opzioni)",
    "tips.business.taxRegimeDescription":
        "Indica l’eventuale regime speciale in riferimento all’attività, se quest’ultima segue un regime speciale",
    "tips.business.cashAccountingTitle": "A cosa serve l'iva di cassa?",
    "tips.business.cashAccountingDescription":
        "Permette di differire la detrazione dell’IVA sulle fatture di acquisto nel momento in cui viene effettuato il pagamento del fornitore o al massimo entro un anno.",
    "tips.business.plafondManagementTitle": "Da cosa si costituisce il Plafond?",
    "tips.business.plafondManagementDescription":
        "Il plafond è costituito dalla somma delle cessioni relative ad operazioni internazionali, non imponibili, registrate ai fini IVA.",
    "tips.headquarter.cciaaProvinceDescription":
        "Indica la provincia della Camera di Commercio a cui fa riferimento l’attività",
    "tips.headquarter.reaNumberDescription": "Per esempio RM-123456.",
    "tips.headquarter.reaProgressiveDescription": "Per esempio …",
    "tips.intermediary.description": "Descrizione libera dell’ambiente di sicurezza",
    "tips.intermediary.env-files-title": "Carica i 3 file dell’ambiente di sicurezza",
    "tips.intermediary.env-files-description":
        "Puoi caricare massimo 3 file (2 file .P12 e 1 file .KS) con dimensioni massime di 100 KB",

    //Tooltips
    "tooltip.cerved.subject.disabled.tooltip.title": "Anagrafica non selezionabile",
    "tooltip.cerved.subject.disabled.tooltip.description":
        "Esiste già un cliente associato a questa anagrafica. Seleziona un’altra anagrafica per procedere o vai alla Lista Clienti e cerca il cliente di riferimento per gestirlo.",
    "tooltip.cerved.subject.disabled.tooltip.button": "Vai alla Lista Clienti",

    // Countries
    "country.IT": "Italia",

    // Classifiers
    "classifier.COMPANY": "Azienda",
    "classifier.STUDIO": "Studio",
    "classifier.PERSON": "Persona fisica",
    "classifier.LEGAL": "Persona giuridica",
    "classifier.BUILDING": "Condominio",
    "classifier.GOVERNMENT": "Pubblica amministrazione",

    // Legal Natures
    "businessLegalForm.SP": "Società per azioni",
    "businessLegalForm.SR": "Società A Responsabilità Limitata",
    "businessLegalForm.AA": "Societa' In Accomandita Per Azioni",
    "businessLegalForm.AC": "Associazione",
    "businessLegalForm.AE": "Societa' Consortile In Accomandita Semplice",
    "businessLegalForm.AF": "Altre Forme",
    "businessLegalForm.AI": "Associazione Impresa",
    "businessLegalForm.AL": "Azienda Speciale Di Ente Locale",
    "businessLegalForm.AM": "Azienda Municipale",
    "businessLegalForm.AN": "Societa' Consortile In Nome Collettivo",
    "businessLegalForm.AP": "Azienda Provinciale",
    "businessLegalForm.AR": "Azienda Regionale",
    "businessLegalForm.AS": "Societa' In Accomandita Semplice",
    "businessLegalForm.AT": "Azienda Autonoma Statale",
    "businessLegalForm.AU": "Societa' Per Azioni Con Socio Unico",
    "businessLegalForm.AZ": "Azienda Speciale",
    "businessLegalForm.CC": "Consorzio Con Attivita' Esterna",
    "businessLegalForm.CE": "Comunione Ereditaria",
    "businessLegalForm.CF": "Consorzio Fidi",
    "businessLegalForm.CI": "Societa' Cooperativa A Responsabilita Illimitata",
    "businessLegalForm.CL": "Societa' Cooperativa A Responsabilita Limitata",
    "businessLegalForm.CM": "Consorzio Municipale",
    "businessLegalForm.CN": "Societa' Consortile",
    "businessLegalForm.CO": "Consorzio",
    "businessLegalForm.CR": "Consorzio Intercomunale",
    "businessLegalForm.CS": "Consorzio Senza Attivita' Esterna",
    "businessLegalForm.CZ": "Consorzio Di Cui Al Dlgs 267/2000",
    "businessLegalForm.DI": "Impresa Individuale",
    "businessLegalForm.EC": "Ente Pubblico Commerciale",
    "businessLegalForm.ED": "Ente Diritto Pubblico",
    "businessLegalForm.EE": "Ente Ecclesiastico",
    "businessLegalForm.EI": "Ente Impresa",
    "businessLegalForm.EL": "ENTE Sociale",
    "businessLegalForm.EM": "Ente Morale",
    "businessLegalForm.EN": "Ente",
    "businessLegalForm.EP": "Ente Pubblico Economico",
    "businessLegalForm.ER": "Ente Ecclesiastico Civilmente Riconosciuto",
    "businessLegalForm.ES": "Ente Di Cui Alla L.R. 21-12-93",
    "businessLegalForm.FI": "Fondazione Impresa",
    "businessLegalForm.FO": "Fondazione",
    "businessLegalForm.GE": "Gruppo Europeo Di Interesse Economico",
    "businessLegalForm.IC": "Istituto Di Credito",
    "businessLegalForm.ID": "Istituto Di Credito Di Diritto Pubblico",
    "businessLegalForm.IF": "Impresa Familiare",
    "businessLegalForm.IR": "Istituto Religioso",
    "businessLegalForm.LL": "Azienda Speciale Di Cui Al Dlgs 267/2000",
    "businessLegalForm.MA": "Mutua Assicurazione",
    "businessLegalForm.OC": "Societa' Cooperativa Consortile",
    "businessLegalForm.OO": "Cooperativa Sociale",
    "businessLegalForm.OS": "Societa' Consortile Cooperativa A Responsabilita' Limitata",
    "businessLegalForm.PA": "Associazione In Partecipazione",
    "businessLegalForm.PC": "Piccola Societa' Cooperativa",
    "businessLegalForm.PF": "Persona Fisica",
    "businessLegalForm.PS": "Piccola Societa' Cooperativa A Responsabilita' Limitata",
    "businessLegalForm.RC": "Contratto Di Rete Dotato Di Soggettivita' Giuridica",
    "businessLegalForm.RR": "Societa' A Responsabilita' Limitata A Capitale Ridotto",
    "businessLegalForm.RS": "Societa' A Responsabilita' Limitata Semplificata",
    "businessLegalForm.SA": "Societa' Anonima",
    "businessLegalForm.SC": "Societa' Cooperativa",
    "businessLegalForm.SD": "Societa' Europea",
    "businessLegalForm.SE": "Societa' Semplice",
    "businessLegalForm.SF": "Societa' Di Fatto",
    "businessLegalForm.SG": "Societa' Cooperativa Europea",
    "businessLegalForm.SI": "Societa' Irregolare",
    "businessLegalForm.SL": "Societa' Consortile A Responsabilita' Limitata",
    "businessLegalForm.SM": "Societa' Di Mutuo Soccorso",
    "businessLegalForm.SN": "Societa' In Nome Collettivo",
    "businessLegalForm.SO": "Societa' Consortile Per Azioni",
    "businessLegalForm.SS": "Societa' Costituita In Base A Leggi Di Altro Stato",
    "businessLegalForm.ST": "Soggetto Estero",
    "businessLegalForm.SU": "Societa' A Responsabilita' Limitata Con Unico Socio",
    "businessLegalForm.SV": "Societa' Tra Professionisti",
    "businessLegalForm.SZ": "Societa' Non Prevista Dalla Legislazione Italiana",
    "businessLegalForm.XX": "Non Precisata",

    // Company Situations
    "companySituation.TAX_PERIOD_START_OF_LIQUIDATION": "Periodo d'imposta inizio liquidazione",
    "companySituation.TAX_PERIODS_SUBSEQUENT_BANKRUPTCY": "Periodo d'imposta successivi al fallimento",
    "companySituation.TAX_PERIOD_END_OF_LIQUIDATION": "Periodo d'imposta termine liquidazione",
    "companySituation.TAX_PERIOD_EXTINCTION_BY_MERGER": "Periodo d'imposta estinzione per fusione",
    "companySituation.TAX_PERIOD_TRANSFORMATION_IRES_NON": "Periodo d'imposta trasformazione IRES",
    "companySituation.NORMAL_TAX_PERIOD": "Periodo normale d'imposta",
    "companySituation.TAX_PERIOD_EXTINCTION_BY_DIVISION": "Periodo d'imposta estinzione per scissione",

    // Company Statuses
    "companyStatus.SUBJECT_IN_NORMAL_ACTIVITY": "Soggetto in attività",
    "companyStatus.SUBJECT_IN_LIQUIDATION_FOR_DISCONTINUED_ACTIVITY": "Soggetto in liquidazione cessata attività",
    "companyStatus.SUBJECT_IN_BANKRUPTCY_OR_COMPULSORY_WINDING_UP": "Soggetto in fallimento o liquidazione coatta",
    "companyStatus.EXTINCT_SUBJECT": "Soggetto estinto",

    // Gender
    "gender.MALE": "Maschio",
    "gender.FEMALE": "Femmina",

    // Months
    "months.JAN": "Gennaio",
    "months.FEB": "Febbraio",
    "months.MAR": "Marzo",
    "months.APR": "Aprile",
    "months.MAY": "Maggio",
    "months.JUN": "Giugno",
    "months.JUL": "Luglio",
    "months.AUG": "Agosto",
    "months.SEP": "Settembre",
    "months.OCT": "Ottobre",
    "months.NOV": "Novembre",
    "months.DEC": "Dicembre",

    // interimFinancialStatement
    "interimFinancialStatement.MONTHLY": "Mensile",
    "interimFinancialStatement.QUARTERLY": "Trimestrale",
    "interimFinancialStatement.HALF_YEARLY": "Semestrale",

    // excludeVATPaymentsAccumulationOrCompensation
    "excludeVATPaymentsAccumulationOrCompensation.true": "Si, escludi",
    "excludeVATPaymentsAccumulationOrCompensation.false": "No, non escludere",

    // taxationType
    "taxationType.IRES": "Ires",
    "taxationType.IRPEF": "Irpef",
    "taxationType.IRPEF_OPTION_IRAP": "Irpef con opzione di calcolo Irap come per l’Ires",

    // Validation
    "validation.taxId.emptyOrNull": "Il codice fiscale non può essere vuoto",
    "validation.taxId.person.wrongLength": "Il codice fiscale deve avere lunghezza 16 caratteri",
    "validation.taxId.person.wrongFormat": "Il formato del codice fiscale è errato",
    "validation.taxId.person.wrongChecksum": "Carattere di controllo non valido",
    "validation.failed": "Il valore inserito non è valido",

    "validation.vatNumber.emptyOrNull": "La partita IVA non può essere nulla",
    "validation.vatNumber.wrongLength": "La partita IVA deve essere di 11 cifre",
    "validation.vatNumber.wrongFormat": "La partita IVA può contenere solo cifre",
    "validation.vatNumber.wrongChecksum": "Carattere di controllo non valido",

    // Valori non specificati
    "notSpecified.birthDate": "Non hai specificato una data di nascita",

    // Alerts
    "alerts.personalData-error": "Errore durante il salvataggio dei dati.",
    "alerts.personalData-success": "Dati salvati con successo.",
    "alerts.taxation-error": "Errore durante il salvataggio dei dati.",
    "alerts.taxation-success": "Dati salvati con successo.",
    "alerts.section-change-title": "Attenzione: dati non salvati",
    "alerts.section-change-message": "Sei sicuro di voler procedere? Perderai i dati non salvati sulla pagina.",
    "alerts.section-ivaprorata-handle": "<b>Attenzione:</b> non hai indicato l’iva pro-rata per l’anno in corso",
    "alerts.section-ivaprorata-button": "Gestisci per il {year}",

    // Feedbacks
    "feedbacks.banks.create.title": "Crea conto",
    "feedbacks.banks.create": "conto creato con successo",
    "feedbacks.banks.update.title": "Modifica conto",
    "feedbacks.banks.update": "conto modificato con successo",
    "feedbacks.banks.delete.title": "Elimina conto",
    "feedbacks.banks.delete": "conto eliminato con successo",
    "feedbacks.banks.error.title": "Errore",
    "feedbacks.banks.error": "si è verificato un errore",
    "feedbacks.accounting.success.title": "Contabilità",
    "feedbacks.accounting.success": "dati salvati con successo",
    "feedbacks.accounting.error.title": "Errore",
    "feedbacks.accounting.error": "si è verificato un errore",

    //SPOTLIGHT
    "spotlight.cards.VAT_NUMBER": "P.IVA: <b>value</b>",
    "spotlight.cards.TAX_ID": "Codice fiscale: <b>value</b>",

    //DIALOG
    "dialog.intrastat.text.title": "Conferma scelte modello intrastat",
    "dialog.intrastat.text.content":
        "Hai indicato che il cliente non gestisce nè l’acquisto e la vendita di beni, nè l’acquisto e la vendita di servizi. Confermi di non voler gestire il modello intrastat e la sua compilazione per il cliente di riferimento?",
    "dialog.intrastat.button.confirm": "Si, confermo",
    "dialog.business.ivaprorata.title": "Non gestire iva pro-rata per il {year}",
    "dialog.business.ivaprorata.description":
        "Sei sicuro di non voler gestire l’Iva pro-rata per il {year}?{br}{br}Se cambi idea basta potrai sempre inserirla cliccando sull’icona di modifica a destra della card e aggiungere l’informazioni dell’iva-pro-rata dell’anno in corso."
};

export default it;
