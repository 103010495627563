import React from "react";

import CustomerEditActivities from "../customer-edit-business";
import CustomerEditRegistry from "../customer-edit-registry";
import CustomerEditBanks from "../customer-edit-banks";
import { CustomerEditAntimoneylaundering } from "../customer-edit-antimoneylaundering";
import { CustomerEditIntrastat } from "../customer-edit-intrastat";

import CustomerEditIntermediaries from "../customer-edit-intermediaries";
import CustomerEditTaxation from "../customer-edit-taxation";
import { CustomerEditAreaContainer, CustomerEditAreaContent } from "./styled";
import LoadingSkeleton from "./loading-skeleton";

const CustomerEditArea = ({
    customer,
    isEditing,
    isSavingData,
    pending,
    section,
    subSection,
    setIsEditing,
    setLeftSide,
    setMainSection,
    setTitle,
    setSubtitle,
    setBackText
}) => {
    return (
        <CustomerEditAreaContainer>
            <CustomerEditAreaContent>
                {pending && <LoadingSkeleton />}
                {!pending && (
                    <>
                        <CustomerEditAntimoneylaundering
                            render={section === "antimoneylaundering"}
                            isSavingData={isSavingData}
                        />
                        <CustomerEditIntrastat render={section === "intrastat"} />
                        <CustomerEditRegistry
                            render={["registry"].indexOf(section) > -1}
                            isSavingData={isSavingData}
                            customerDetails={customer}
                            section={section}
                            subSection={subSection}
                            withHeadquarters={section === "registry"}
                            differentByClassifier={section === "registry"}
                        />
                        <CustomerEditTaxation render={section === "taxation"} />
                        <CustomerEditBanks render={section === "creditlines"} />
                        <CustomerEditActivities
                            render={section === "business"}
                            setLeftSide={setLeftSide}
                            setTitle={setTitle}
                            setSubtitle={setSubtitle}
                            setBackText={setBackText}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            setMainSection={setMainSection}
                        />
                        <CustomerEditIntermediaries render={section === "intermediaries"} />
                    </>
                )}
            </CustomerEditAreaContent>
        </CustomerEditAreaContainer>
    );
};

export default CustomerEditArea;
