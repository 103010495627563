import React from "react";
import PropTypes from "prop-types";

import { faBuilding } from "@fortawesome/pro-solid-svg-icons/faBuilding";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { Button } from "@ts-digital/vrc";
import { useNavigate } from "react-router-dom";

import { BodyCopy, H2, H3 } from "../../components/vapor-components/typography";
import { ButtonContainer, ClassifierIcon, Container, ShadowBox, SubTitleContainer, SuccessCheckIcon } from "./styled";
import { FormattedMessage } from "react-intl";

const CustomerCompanyCreated = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <SuccessCheckIcon icon={faCheckCircle} />
            <H2 color="darkImperialBlue">
                <FormattedMessage id="v-customer-company-created.title" />
            </H2>
            <SubTitleContainer>
                <BodyCopy>
                    <FormattedMessage id="v-customer-company-created.subtitle" />
                </BodyCopy>
            </SubTitleContainer>
            <ShadowBox>
                <div>
                    <ClassifierIcon icon={faBuilding} />
                </div>
                <H3>Azienda cliente 1</H3>
                <BodyCopy color="cadet">Via Visconti di Modrone, 4 - Milano 20100 - MI, Italy </BodyCopy>
                <BodyCopy color="cadet">
                    <FormattedMessage id="general.tax-id-short" />: MKHRLP60R22I109K
                </BodyCopy>
                <BodyCopy color="cadet">
                    <FormattedMessage id="general.vatnumber" />: 012832233006
                </BodyCopy>
                <ButtonContainer>
                    <Button>
                        <FormattedMessage id="v-customer-company-created.start-configuration-button" />
                    </Button>
                </ButtonContainer>
            </ShadowBox>
            <BodyCopy>
                <FormattedMessage id="v-customer-company-created.or-go-to" />
            </BodyCopy>
            <ButtonContainer>
                <Button kind="secondary" colorScheme="ghost" onClick={() => navigate("/")}>
                    <FormattedMessage id="v-customer-company-created.list-customers-company-list-button" />
                </Button>
            </ButtonContainer>
        </Container>
    );
};

CustomerCompanyCreated.propTypes = {
    classifier: PropTypes.oneOf(["STUDIO", "COMPANY", "PERSON", "BUILDING"]),
    name: PropTypes.string,
    address: PropTypes.string,
    taxId: PropTypes.string,
    vatNumber: PropTypes.string
};

export default CustomerCompanyCreated;
