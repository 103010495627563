import React from "react";
import { useSelector } from "react-redux";
import CustomerEditArea from "../customer-edit-area";
import CustomerHeader from "../customer-header";
import CustomerSections from "../customer-sections";
import { ContentWrapper, GridContainer, GridElement, RightGridWrapper } from "./styled";
import PropTypes from "prop-types";

const CustomerEditLayout = props => {
    const customerDetails = useSelector(state => state.customer.details.data);
    const isSavingData = useSelector(state => state.customer.details.isSavingData);
    const status = useSelector(state => state.customer.details.status);

    const {
        isEditing,
        leftSide,
        section,
        setSection,
        children,
        setIsEditing,
        setLeftSide,
        setTitle,
        setSubtitle,
        setBackText
    } = props;

    const subSection = section === "taxation" ? "taxation" : "personalData";
    const [mainSection, setMainSection] = React.useState("dashboard");

    return (
        <ContentWrapper>
            <GridContainer>
                <GridElement withPadding width="21%">
                    {mainSection === "dashboard" && (
                        <CustomerHeader pending={status.started} customer={customerDetails} />
                    )}
                    {leftSide ?? (
                        <CustomerSections pending={status.started} section={section} setSection={setSection} />
                    )}
                </GridElement>
                <GridElement width="76%" fullHeight>
                    <RightGridWrapper>
                        {children ?? (
                            <CustomerEditArea
                                pending={status.started}
                                section={section}
                                subSection={subSection}
                                customer={customerDetails}
                                isSavingData={isSavingData}
                                setLeftSide={setLeftSide}
                                setMainSection={setMainSection}
                                setTitle={setTitle}
                                setSubtitle={setSubtitle}
                                setBackText={setBackText}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                            />
                        )}
                    </RightGridWrapper>
                </GridElement>
            </GridContainer>
        </ContentWrapper>
    );
};

CustomerEditLayout.propTypes = {
    section: PropTypes.string,
    setSection: PropTypes.func,
    leftSide: PropTypes.node
};

export default CustomerEditLayout;
