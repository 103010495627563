import styled from "styled-components";

export const CustomerEditBusinessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CustomerEditBusinessList = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const CustomerEditBusinessActivity = styled.div`
    display: flex;
    margin: 8px;
`;

export const CustomerEditBusinessActivityContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100px;
`;

export const CustomerEditActivityTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerEditActivityTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
`;

export const CustomerEditActivityTitleText = styled.div`
    margin-left: 10px;
`;

export const CustomerEditActivityTitleStatus = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const CustomerEditActivityStatusTag = styled.div`
    flex: 1 0 auto;
`;

export const CustomerEditActivityStatus = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-left: 32px;
    color: ${props => props.theme.colors.text.cadet};
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const DotStatus = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-right: 8px;
    background-color: ${props =>
        props.missing ? props.theme.colors.status.crayola : props.theme.colors.status.laSalleGreen};
`;

export const CustomerEditBusinessActivityContentLine = styled.div`
    max-width: 230px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`;

export const CustomerEditActivityFooterContainer = styled.div`
    width: 100%;
`;

export const ModalContainer = styled.div`
    width: 370px;
    margin: 10px auto 10px auto;
`;

export const CustomerActivityModalFooter = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const MenuButton = styled.div`
    text-align: center;
    margin-bottom: 5px;
`;

export const EditButton = styled.div`
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
    border-top-style: solid;
    border-top-width: 0.1px;
    border-top-color: ${props => props.theme.colors.background.azureishWhite};
`;
