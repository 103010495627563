import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { steps } from "./constants";
import { FormContainer, StepContainer, StepTitle, StepSubtitle } from "./styled";
import { CustomerEditIntermediariesStep1 } from "./steps/step1";
import { CustomerEditIntermediariesStep2 } from "./steps/step2";
import { CustomerEditIntermediariesStep3 } from "./steps/step3";
import { Step, StepLabel, Stepper } from "@vapor/react-material";

export const CustomerCreateIntermediaries = () => {
    const [stepNumber, setStepNumber] = useState(0);

    const intermediaries = useSelector(state => state.intermediaries?.details?.items);
    const securityEnvironments = useSelector(state => state.intermediaries?.securityEnviroments?.items).sort(
        (a, b) => +a.siteCode - +b.siteCode
    );
    const delegateds = useSelector(state => state.intermediaries?.delegateds?.items);
    const flowIntermediaryTaxId = useSelector(state => state.intermediaries?.details?.flowItem?.intermediary?.taxId);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);

    return (
        <FormContainer>
            <StepContainer>
                <Stepper activeStep={stepNumber} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={`step_${index}`}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </StepContainer>
            <StepTitle>
                <FormattedMessage
                    id={
                        stepNumber === 0 && intermediaries.length < 1
                            ? `c-customer-edit-first-intermediaries.step${stepNumber + 1}-title`
                            : `c-customer-edit-intermediaries.step${stepNumber + 1}-title`
                    }
                />
            </StepTitle>
            <StepSubtitle>
                <FormattedMessage id={`c-customer-edit-intermediaries.step${stepNumber + 1}-description`} />
            </StepSubtitle>
            {stepNumber === 0 && (
                <CustomerEditIntermediariesStep1
                    setStepNumber={setStepNumber}
                    intermediaries={intermediaries}
                    ownerId={ownerId}
                />
            )}
            {stepNumber === 1 && (
                <CustomerEditIntermediariesStep2
                    setStepNumber={setStepNumber}
                    taxIdentifier={flowIntermediaryTaxId}
                    ownerId={ownerId}
                    securityEnviroments={securityEnvironments}
                />
            )}
            {stepNumber === 2 && (
                <CustomerEditIntermediariesStep3
                    setStepNumber={setStepNumber}
                    taxIdentifier={flowIntermediaryTaxId}
                    ownerId={ownerId}
                    delegateds={delegateds}
                />
            )}
        </FormContainer>
    );
};
