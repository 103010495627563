import {
  CREATE_NEW_CLIENT_START,
  CREATE_NEW_CLIENT_SUCCESS,
  CREATE_NEW_CLIENT_ERROR,
  CREATE_NEW_CLIENT_RESET,
} from "../actions/newclient.js";

const defaultState = {
  status: {
    started: false,
    error: false,
    ended: false,
    errorInfo: {
      code: "",
      message: "",
    },
  },
};

export function newClient(state = defaultState, { type, payload, error }) {
  switch (type) {
    case CREATE_NEW_CLIENT_START:
      return {
        status: {
          started: true,
          error: false,
          ended: false,
        },
      };
    case CREATE_NEW_CLIENT_SUCCESS:
      return {
        status: {
          started: false,
          error: false,
          ended: true,
        },
      };
    case CREATE_NEW_CLIENT_ERROR:
      return {
        status: {
          started: false,
          error: true,
          ended: false,
          errorInfo: { error },
        },
      };
    case CREATE_NEW_CLIENT_RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}
