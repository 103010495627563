import styled from "styled-components";

const statusColor = toolsStatus => {
    return props => (toolsStatus ? props.theme.colors.status.laSalleGreen : props.theme.colors.status.crayola);
};

export const ToolsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.25rem;
    margin-top: 1.5rem;
`;

export const ColTitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 0.875rem;
`;

export const ColValue = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 1.5rem;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const AppStatusCircle = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.status.laSalleGreen};
    margin-right: 0.5rem;
`;

export const StatusIcon = styled.span`
    font-size: 0.625rem;
    color: ${props => statusColor(props.status)};
    margin-right: 0.5rem;
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
