import { faBook } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faChairOffice } from "@fortawesome/pro-regular-svg-icons/faChairOffice";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faHotel } from "@fortawesome/pro-regular-svg-icons/faHotel";
import { faMapMarkedAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkedAlt";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons/faQuestion";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons/faShoppingBasket";

//Types of sections
export const dashboard = "dashboard";
export const business = "business";

const ENABLED_INTERMEDIARIES = process.env.REACT_APP_INTERMEDIARIES_ENABLED === "true";
const ENABLED_BUSINESS = process.env.REACT_APP_BUSINESS_ENABLED === "true";
const ENABLED_BANKS = process.env.REACT_APP_BANKS_ENABLED === "true";
const ENABLED_ACCOUNTING = process.env.REACT_APP_ACCOUNTING_ENABLED === "true";
const ENABLED_ANTIMONEYLAUNDERING = process.env.REACT_APP_ANTIMONEYLAUNDERING_ENABLED === "true";
const ENABLED_INTASTAT = process.env.REACT_APP_INTRASTAT_ENABLED === "true";

export const sections = [
    {
        value: "overwiew",
        icon: faEye,
        required: false,
        enabled: false
    },
    {
        value: "registry",
        icon: faQuestion,
        required: true,
        enabled: true
    },
    {
        value: "antimoneylaundering",
        icon: faQuestion,
        required: false,
        enabled: ENABLED_ANTIMONEYLAUNDERING
    },
    {
        value: "registers",
        icon: faBook,
        required: false,
        enabled: false
    },
    {
        value: "taxation",
        icon: faReceipt,
        required: true,
        enabled: ENABLED_ACCOUNTING
    },
    {
        value: "site",
        icon: faChairOffice,
        required: true,
        enabled: false
    },
    {
        value: "destination",
        icon: faMapMarkedAlt,
        required: false,
        enabled: false
    },
    {
        value: "intrastat",
        icon: faShoppingBasket,
        required: false,
        enabled: ENABLED_INTASTAT
    },
    {
        value: "business",
        icon: faHotel,
        required: true,
        enabled: ENABLED_BUSINESS
    },
    {
        value: "creditlines",
        required: true,
        enabled: ENABLED_BANKS
    },
    {
        value: "roles",
        required: true,
        enabled: false
    },
    {
        value: "intermediaries",
        required: true,
        enabled: ENABLED_INTERMEDIARIES
    }
];
