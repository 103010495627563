import React from "react";
import PropTypes from "prop-types";

import { SubTitleRegular } from "../typography";
import { Container, Content, Footer, VerticalContent, VerticalFooter } from "./styled";
import ProgressBar from "../progress-bar";

const Card = ({
    children,
    completedSteps,
    completionPercentage,
    footer,
    verticalFooter,
    showProgressBar,
    totalSteps,
    title,
    backgroundColor,
    dark
}) => {
    return verticalFooter ? (
        <Container backgroundColor={backgroundColor} dark={dark}>
            <VerticalContent>
                <Content>
                    <SubTitleRegular color={dark ? "white" : "darkImperialBlue"}>{title}</SubTitleRegular>
                    {children}
                </Content>
                <VerticalFooter dark={dark}>{footer}</VerticalFooter>
            </VerticalContent>
            {showProgressBar && (
                <ProgressBar
                    completedSteps={completedSteps}
                    totalSteps={totalSteps}
                    completionPercentage={completionPercentage}
                />
            )}
        </Container>
    ) : (
        <Container backgroundColor={backgroundColor} dark={dark}>
            <Content>
                <SubTitleRegular color={dark ? "white" : "darkImperialBlue"}>{title}</SubTitleRegular>
                {children}
            </Content>
            {showProgressBar && (
                <ProgressBar
                    completedSteps={completedSteps}
                    totalSteps={totalSteps}
                    completionPercentage={completionPercentage}
                />
            )}
            <Footer dark={dark}>{footer}</Footer>
        </Container>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    completedSteps: PropTypes.number,
    completionPercentage: PropTypes.number,
    footer: PropTypes.any,
    showProgressBar: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    totalSteps: PropTypes.number
};

Card.defaultProps = {
    completedSteps: 2,
    completionPercentage: 50,
    totalSteps: 4
};

export default Card;
