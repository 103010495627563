import { GET_CURRENCIES_START, GET_CURRENCIES_ERROR, GET_CURRENCIES_SUCCESS } from "../actions/utilities";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    },
    result: null
};

export function currencies(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_CURRENCIES_START:
            return {
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_CURRENCIES_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                result: payload
            };
        case GET_CURRENCIES_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { error }
                }
            };

        default:
            return state;
    }
}
