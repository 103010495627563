import { cerved } from "./cerved";
import { ateco } from "./ateco";
import { asset } from "./asset";
import { newClient } from "./new-client";
import { adaGenerate } from "./ada-generate";
import { adaSend } from "./ada-send";
import { initDataFromParent } from "./initAuthData";
import { customers } from "./customers";
import customer from "./customer";
import { connections } from "./connections";
import { adaInfo } from "./ada-info";
import { itemTools, tools } from "./tools";
import { archiveInfo } from "./archive";
import { consumption } from "./metering";
import tspay from "./tspay";
import { intermediaries } from "./intermediaries";
import { currencies } from "./currencies";

const reducersSchema = {
    adaInfo,
    adaGenerate,
    adaSend,
    archiveInfo,
    asset,
    ateco,
    cerved,
    connections,
    consumption,
    currencies,
    initDataFromParent,
    intermediaries,
    customers,
    customer,
    itemTools,
    tools,
    newClient,
    tspay
};

export default reducersSchema;
