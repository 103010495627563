import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { customerTypes } from "../../../new-client-form/new-client-form-wrapper/constants";
import { FormattedMessage } from "react-intl";
import {
    CustomerHeaderNameContainer,
    CustomerName,
    CustomerNameSubtitle,
    CustomerChip,
    CustomerNameTitle,
    CustomerNameAdditionals,
    CustomerNameAdditionalsItem,
    CustomerNameStatus,
    CustomerNameStatusIcon
} from "./styled.js";

const CustomerHeaderData = props => {
    const { customer } = props;
    return (
        <CustomerHeaderNameContainer>
            <CustomerName>
                <CustomerNameSubtitle>
                    <CustomerChip>
                        {customer?.classifier && (
                            <FormattedMessage
                                id={`general.classifiers.${
                                    customerTypes.find(x => x.radioValue === customer?.classifier)?.radioValue
                                }`}
                            />
                        )}
                    </CustomerChip>
                    {/*<CustomerChip>
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: 4 }} /> Utente non registrato
                            </CustomerChip>*/}
                </CustomerNameSubtitle>
                <CustomerNameTitle>
                    {customer?.classifier && (
                        <FontAwesomeIcon
                            style={{ fontSize: 24, marginRight: 4, paddingBottom: 2 }}
                            icon={customerTypes.find(x => x.radioValue === customer?.classifier)?.icon}
                        />
                    )}{" "}
                    {["COMPANY", "STUDIO", "BUILDING"].indexOf(customer?.classifier) > -1 &&
                        customer?.personalData?.businessName}
                    {customer?.classifier === "PERSON" &&
                        (customer?.personalData?.name || customer?.personalData?.surname
                            ? `${customer?.personalData?.name || ""} ${customer?.personalData?.surname || ""}`
                            : customer?.personalData?.businessName || "")}
                </CustomerNameTitle>
                <CustomerNameAdditionals>
                    {["PERSON", "BUILDING"].indexOf(customer?.classifier) === -1 && (
                        <CustomerNameAdditionalsItem>
                            Partita IVA: {customer?.personalData?.vatIdentifier}
                        </CustomerNameAdditionalsItem>
                    )}
                    <CustomerNameAdditionalsItem>
                        Codice fiscale: {customer?.personalData?.taxIdentifier}
                    </CustomerNameAdditionalsItem>
                </CustomerNameAdditionals>
                <CustomerNameStatus>
                    <CustomerNameStatusIcon status={customer?.status}>
                        <FontAwesomeIcon icon={faCircle} />
                    </CustomerNameStatusIcon>{" "}
                    {customer?.status && <FormattedMessage id={`c-customer-header-status.${customer?.status}`} />}
                </CustomerNameStatus>
            </CustomerName>
        </CustomerHeaderNameContainer>
    );
};

export default CustomerHeaderData;
