export const convertFileToBinary = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
    });
};

export const getBase64Size = base64 => {
    return Math.round(window.atob(base64).length);
};

export function queryStringify(params) {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&");
}

export const getISODateAndMonth = date => {
    if (date) {
        return { month: date.getMonth() + 1, year: date.getFullYear() };
    }
    return null;
};

export const getISOFromDateAndMonth = date => {
    if (date?.month && date?.year) {
        return new Date(date.year, date.month - 1, 1);
    }
    return null;
};

export const getISOFromYear = year => {
    if (year) {
        return new Date(year, 0, 1).toISOString();
    }
    return null;
};
