import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { useIntl } from "react-intl";

import Card from "../vapor-components/card";
import {
    Contact,
    RdcUserContainer,
    FooterButtonContainer,
    GridContainer,
    Info,
    InfoIcon,
    Name,
    Title,
    ContactContainer
} from "./styled";
import ClassifierIcon from "../classifier-icon";
import { Descriptive } from "../vapor-components/typography";

const RdcDelegationCard = ({ info }) => {
    const intl = useIntl();
    const { name, lastName, email, phone } = info.rdc || {};
    return (
        <Card
            title={intl.formatMessage({
                id: "c-rdc-delegation-card.title"
            })}
            footer={
                <FooterButtonContainer>
                    <Button kind="tertiary">
                        {intl.formatMessage({
                            id: "c-rdc-delegation-card.see-details-button-label"
                        })}
                    </Button>
                </FooterButtonContainer>
            }
        >
            <Title>
                {intl.formatMessage({
                    id: "c-rdc-delegation-card.responsible-for-conservation"
                })}
                :
            </Title>
            <RdcUserContainer>
                <ClassifierIcon classifier="PERSON" />
                <Name>{name + " " + lastName}</Name>
                <div></div>
                <ContactContainer>
                    <Descriptive weight="500">
                        <Contact>
                            <FontAwesomeIcon icon={faEnvelope} />
                            {email}
                        </Contact>
                    </Descriptive>
                    <Descriptive weight="500">
                        <Contact>
                            <FontAwesomeIcon icon={faPhone} />
                            {phone}
                        </Contact>
                    </Descriptive>
                </ContactContainer>
            </RdcUserContainer>
            <GridContainer>
                <InfoIcon icon={faInfoCircle} />
                <Info>
                    {intl.formatMessage({
                        id: "c-rdc-delegation-card.info"
                    })}
                </Info>
            </GridContainer>
        </Card>
    );
};

RdcDelegationCard.propTypes = {
    rdc: PropTypes.object
};

export default RdcDelegationCard;
