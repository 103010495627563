import React from "react";
import PropTypes from "prop-types";
import { CustomerEditContainer, CustomerEditGridCards } from "./styled";
import { Box, Stack, Typography } from "@vapor/react-material";
import { LoadingElement } from "../vapor-components/loading";
import Card from "../vapor-components/card";

const CardsLayout = ({
    title,
    description,
    minHeight,
    isLoading,
    children,
    gap,
    skeletonElem,
    skeletonVerticalFooter,
    skeletonFirstGrey
}) => {
    const loadingElements = skeletonElem || 2;

    return (
        <CustomerEditContainer>
            {title && (
                <Typography variant="titleMedium" color="primary.textTitleColor" component="div">
                    {title}
                </Typography>
            )}
            {description && (
                <Typography variant="subtitle" gutterBottom component="div">
                    {description}
                </Typography>
            )}
            {children && !isLoading ? (
                <CustomerEditGridCards minHeight={minHeight} gap={gap}>
                    {children}
                </CustomerEditGridCards>
            ) : (
                <CustomerEditGridCards minHeight={minHeight} gap={gap}>
                    {/* For loop to generate loading elements */
                    [...Array(loadingElements)].map((e, i) => (
                        <Card
                            backgroundColor={skeletonFirstGrey && i === 0 ? "whiteSmoke" : "white"}
                            key={`card-skeleton-${i}`}
                            verticalFooter={skeletonVerticalFooter === undefined ? true : skeletonVerticalFooter}
                            title={<LoadingElement width="100%" height="20px" />}
                            footer={<LoadingElement width="100%" height="36px" />}
                        >
                            <Stack spacing={1} mt={1}>
                                <LoadingElement width="100%" height="24px" />
                                <LoadingElement width="100%" height="32px" />
                                <Box sx={{ padding: "6px 0" }}>
                                    <LoadingElement width="100%" height="10px" />
                                </Box>
                            </Stack>
                        </Card>
                    ))}
                </CustomerEditGridCards>
            )}
        </CustomerEditContainer>
    );
};

CardsLayout.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    minHeight: PropTypes.string,
    isLoading: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default CardsLayout;
