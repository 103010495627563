import React from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from "@vapor/react-material";
import { FormattedMessage, useIntl } from "react-intl";
import { LEGAL, PERSON, step1DefaultBody } from "../../constants";
import { handleMaxLength } from "../../../../../utils/validate";

const IntermediaryInputs = ({
    error,
    type,
    handleChange,
    bodyRequest,
    setBodyRequest,
    setError,
    setIntermediaryType
}) => {
    const intl = useIntl();
    return (
        <>
            <FormControl>
                <FormLabel id="registry-intermediary-type" sx={{ fontWeight: "600", marginBottom: "1rem" }}>
                    <FormattedMessage id="field.intermediary.radiolabel" />
                </FormLabel>

                <RadioGroup
                    aria-labelledby="registry-intermediary-type"
                    defaultValue={PERSON}
                    name="controlled-radio-buttons-group"
                    sx={{ width: "fit-content" }}
                    onChange={e => {
                        setIntermediaryType(e.target.value);
                        setError({});
                        setBodyRequest({ ...step1DefaultBody });
                    }}
                >
                    <FormControlLabel
                        control={<Radio />}
                        label={intl.formatMessage({ id: "classifier.PERSON" })}
                        value={PERSON}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label={intl.formatMessage({ id: "classifier.LEGAL" })}
                        value={LEGAL}
                    />
                </RadioGroup>
            </FormControl>
            <Stack mt={4} spacing={3}>
                <TextField
                    error={error?.name === "description"}
                    autoComplete="off"
                    helperText={error?.name === "description" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    label={
                        type === PERSON
                            ? intl.formatMessage({ id: "general.fullname" })
                            : intl.formatMessage({ id: "general.business-name" })
                    }
                    name="description"
                    onChange={handleChange}
                    inputProps={{ onChange: e => handleMaxLength(e, 75) }}
                    variant="outlined"
                    value={bodyRequest.description}
                />
            </Stack>
            <Stack mt={4} spacing={3} direction="row">
                <TextField
                    error={error?.name === "vatNumber"}
                    helperText={error?.name === "vatNumber" ? intl.formatMessage({ id: error.message }) : ""}
                    id="outlined-required"
                    type="text"
                    autoComplete="off"
                    name="vatNumber"
                    value={bodyRequest.vatNumber}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "general.piva" })}
                    inputProps={{ onChange: e => handleMaxLength(e, 11) }}
                    sx={{
                        "& input": {
                            textAlign: `left`
                        }
                    }}
                    variant="outlined"
                />
                <TextField
                    error={error?.name === "taxId"}
                    helperText={error?.name === "taxId" ? intl.formatMessage({ id: error?.message }) : ""}
                    id="outlined-required"
                    autoComplete="off"
                    name="taxId"
                    value={bodyRequest.taxId}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "general.taxId" })}
                    variant="outlined"
                    inputProps={{ onChange: e => handleMaxLength(e, type === PERSON ? 16 : 11) }}
                />
            </Stack>
        </>
    );
};

export default IntermediaryInputs;
