import React from "react";
import { useSelector } from "react-redux";
import { SideContent, SideHeading } from "./styled";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { Box, Stack } from "@vapor/react-material";

export const IntermediarySidebar = () => {
    const intermediaryActive = useSelector(state => state.intermediaries?.details?.flowItem?.intermediary);

    return (
        <Stack spacing={2}>
            {/* Intermediary on Flow */}
            <Box>
                {intermediaryActive?.description && (
                    <SideHeading>
                        <FontAwesomeIcon icon={faUser} />
                        {intermediaryActive?.description}
                    </SideHeading>
                )}
                <SideContent>
                    <Stack direction={"column"} spacing={1}>
                        {intermediaryActive?.vatNumber && (
                            <Box>
                                <FormattedMessage id="general.piva" />: {intermediaryActive.vatNumber}
                            </Box>
                        )}
                        {intermediaryActive?.taxId && (
                            <Box>
                                <FormattedMessage id="general.tax-id" />: {intermediaryActive.taxId}
                            </Box>
                        )}
                    </Stack>
                </SideContent>
            </Box>
            {/* Security Env on Flow */}
            <Box></Box>
            {/* Delegateds on Flow */}
            <Box></Box>
        </Stack>
    );
};
