import React from "react";
import { FormattedMessage } from "react-intl";
import { InfoContainer, Percentage, Progress } from "./styled";

const ProgressBar = ({ completionPercentage, completedSteps, totalSteps }) => {
  const percentage = Math.round(completionPercentage);
  return (
    <div>
      <InfoContainer>
        <Percentage>{percentage}%</Percentage>
        <div>
          <FormattedMessage
            id="c-progress-bar.phases"
            values={{
              completedSteps: completedSteps,
              totalSteps: totalSteps,
            }}
          />
        </div>
      </InfoContainer>
      <Progress toWidth={percentage} />
    </div>
  );
};

export default ProgressBar;
