import React, { useEffect } from "react";

import { useMatch, useNavigate } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { useIntl } from "react-intl";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

import CustomerCard from "../../components/customer-card";
import PageTitle from "../../components/vapor-components/page-title";
//import { customers } from "../customers/mock-customers";
import {
    Container,
    ContentWrapper,
    FooterButtonContainer,
    ChartContainer,
    GridElement,
    WidgetCardContainer
} from "./styled";
import MeteringWidget from "../../components/metering-widget";
import MeteringChart from "../../components/metering-chart";
import ActiveToolsCard from "../../components/active-tools-card";
import AdvisingToolsCard from "../../components/advising-tools-card";
import EntrustmentDeedCard from "../../components/entrustment-deed-card";
import RdcDelegationCard from "../../components/rdc-delegation-card";

import { getAdaInfo, resetAdaInfo, downloadAda } from "../../actions/ada";
import { getItemTools, resetGetItemTools } from "../../actions/tools";
import { getArchiveInfo, resetGetArchiveInfo } from "../../actions/archive";
import { getConnections } from "../../actions/connections";
import { getConsumption } from "../../actions/metering";

const CustomerDashboard = ({
    adaInfo,
    archiveInfo,
    connections,
    consumption,
    connectionsStatus,
    itemTools,
    customers,
    downloadAda,
    getAdaInfo,
    getArchiveInfo,
    getConnections,
    getConsumption,
    getItemTools,
    resetGetArchiveInfo,
    resetGetItemTools,
    resetAdaInfo
}) => {
    const navigate = useNavigate();
    const match = useMatch("/:item/customer-dashboard");
    const intl = useIntl();

    const customer = customers.filter(customer => customer.id === match.params.item)[0];

    useEffect(() => {
        getConnections(customer.uuid);
    }, [getConnections, customer.uuid]);

    useEffect(() => {
        getItemTools(customer.uuid);
    }, [getItemTools, customer]);

    useEffect(() => {
        getConsumption(customer.uuid, customer.uuid);
    }, [getConsumption, customer]);

    useEffect(() => {
        if (itemTools && itemTools.length > 0) {
            const archiveConfig = itemTools.filter(it => it.agyoService.id === "CCT-FLOW" && it.availableToUser)[0];
            if (archiveConfig) {
                getArchiveInfo(archiveConfig.ownerId, archiveConfig.itemId);
            } else {
                resetGetArchiveInfo();
            }
        } else {
            resetGetArchiveInfo();
        }
    }, [getArchiveInfo, resetGetArchiveInfo, itemTools]);

    useEffect(() => {
        if (!adaInfo && connectionsStatus.ended && !connectionsStatus.error) {
            if (connections && connections.length > 0) {
                for (let conn of connections) {
                    if (["CERTIFIED", "AWAITING_APPROVAL", "VALIDATION_REJECTED"].includes(conn.status)) {
                        getAdaInfo(conn.id);
                        break;
                    }
                }
            }
        }
    }, [adaInfo, connections, connectionsStatus, getAdaInfo, getArchiveInfo]);
    return (
        <Container>
            <PageTitle
                backButtonTitle={intl.formatMessage({
                    id: "v-customer-dashboard.back-button-label"
                })}
                title={intl.formatMessage({
                    id: "v-customer-dashboard.title"
                })}
                onBack={() => {
                    resetAdaInfo();
                    resetGetItemTools();
                    navigate("/");
                }}
            />
            <ContentWrapper>
                <GridElement gridArea="customer-card">
                    <CustomerCard
                        key={customer.id}
                        id={customer.id}
                        address={customer?.details?.mainAddress ? customer?.details?.mainAddress : null}
                        classifier={customer.details.classifier}
                        email={customer.details.email}
                        footer={
                            <FooterButtonContainer>
                                <Button kind="tertiary" size="small">
                                    {intl.formatMessage({
                                        id: "v-customer-dashboard.change-personal-data-button-label"
                                    })}
                                </Button>
                                <Button kind="tertiary" size="small">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </FooterButtonContainer>
                        }
                        name={customer.details.description}
                        pec={customer.details.pec}
                        phone={customer.details.phone}
                        status={customer.status}
                        taxId={customer.identifier.taxIdentifier}
                        vatNumber={customer.identifier.vatIdentifier}
                    />
                </GridElement>
                <WidgetCardContainer>
                    <MeteringWidget
                        title={intl.formatMessage({
                            id: "v-customer-dashboard.metering-widget.title"
                        })}
                        kpiValue="35"
                        kpiDescription="documenti"
                        appName="TS Digital Invoice"
                        date="Ottobre 2021"
                        percentage="-12,5"
                    />
                    <MeteringWidget
                        title={intl.formatMessage({
                            id: "v-customer-dashboard.metering-widget.title"
                        })}
                        kpiValue="25"
                        kpiDescription="documenti"
                        appName="TS Digital Invoice"
                        date="Novembre 2021"
                        percentage="-28"
                    />
                    <MeteringWidget
                        title={intl.formatMessage({
                            id: "v-customer-dashboard.metering-widget.title"
                        })}
                        kpiValue="30"
                        kpiDescription="documenti"
                        appName="TS Digital Invoice"
                        date="Dicembre 2021"
                        percentage="+20"
                    />
                </WidgetCardContainer>

                <GridElement gridArea="graphic">
                    <ChartContainer>
                        <MeteringChart data={consumption || {}} />
                    </ChartContainer>
                </GridElement>

                <GridElement gridArea="tools">
                    <ActiveToolsCard tools={itemTools || []} />
                </GridElement>

                <GridElement gridArea="tools-advising">
                    <AdvisingToolsCard />
                </GridElement>
                {adaInfo && adaInfo.state && (
                    <GridElement gridArea="entrustment-deed">
                        <EntrustmentDeedCard adaInfo={adaInfo} onDownload={() => downloadAda(adaInfo.content)} />
                    </GridElement>
                )}
                {archiveInfo && archiveInfo.status.ended && !archiveInfo.status.error && !archiveInfo.status.started && (
                    <GridElement gridArea="rdc-delegation">
                        <RdcDelegationCard info={archiveInfo.info} />
                    </GridElement>
                )}
            </ContentWrapper>
        </Container>
    );
};

function mapStateToProps(state) {
    return {
        adaInfo: state.adaInfo.ada,
        archiveInfo: state.archiveInfo,
        connections: state.connections.connections,
        consumption: state.consumption.consumption,
        itemTools: state.itemTools.tools,
        connectionsStatus: state.connections.status,
        customers: state.customers.items
    };
}

const actions = {
    downloadAda,
    getAdaInfo,
    getArchiveInfo,
    getConnections,
    getConsumption,
    getItemTools,
    resetGetItemTools,
    resetGetArchiveInfo,
    resetAdaInfo
};

const composedHoc = compose(connect(mapStateToProps, actions));

export default composedHoc(CustomerDashboard);
