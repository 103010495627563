import { Field, RadioSub } from "@onefront/react-workspace-components";
import { Box, Button, FormControl, FormHelperText, Stack, TextField, Typography } from "@vapor/react-material";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmptyOrSpaces } from "../../../../../../utils/string";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { getISODateAndMonth, getISOFromDateAndMonth } from "../../../../../../utils/convert";
import {
    specialVatDeductionRate,
    specialVatRegimeOptions,
    vatExemptionManagementForExporterOptions,
    vatPeriodicityOptions
} from "./costants";
import { useState } from "react";
import { SubField } from "./styled";
import { InfoTooltip } from "../../../../../tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { ceasedBusiness } from "../../constants";

export const CustomerEditBusinessStep4 = ({
    bodyRequest,
    setBodyRequest,
    handleChange,
    errors,
    setErrors,
    displayErrors,
    isEditingStep,
    setIsEditingStep,
    businessId
}) => {
    const intl = useIntl();
    const [vatConfiguration, setVatConfiguration] = useStateWithCallbackLazy(bodyRequest?.vatConfiguration);
    const [specialVatRegimeAdditionalFields, setSpecialVatRegimeAdditionalFields] = useState(
        specialVatRegimeOptions(intl)?.find(vatRegime => vatRegime.id === vatConfiguration?.specialVatRegime)
            ?.subcomponent
    );

    //validation
    useEffect(() => {
        const fieldsDefaultValidation = Object.keys({
            vatPeriodicity: true,
            vatStatementRegistry: true,
            vatInAdvanceType: true,
            specialVatRegime: true,
            vatExemptionManagementForExporter: true
        }).reduce((acc, key) => {
            acc[key] = isEmptyOrSpaces(bodyRequest["vatConfiguration"]?.[key] || "");
            return acc;
        }, {});

        //check if FLAT_RATE_MARGIN is selected and flatTaxAccountingRate is null or empty
        if (bodyRequest?.vatConfiguration?.specialVatRegime === "FLAT_RATE_MARGIN") {
            fieldsDefaultValidation["flatTaxAccountingRate"] = isEmptyOrSpaces(
                bodyRequest?.vatConfiguration?.flatTaxAccountingRate || ""
            );
        } else {
            delete fieldsDefaultValidation["flatTaxAccountingRate"];
        }
        //check if cashAccounting is true and cashAccountingExpiry is valid
        if (
            (!bodyRequest?.vatConfiguration?.cashAccountingExpiry?.month ||
                !bodyRequest?.vatConfiguration?.cashAccountingExpiry?.year) &&
            bodyRequest?.vatConfiguration?.cashAccounting === true
        ) {
            fieldsDefaultValidation["cashAccountingExpiry"] = true;
        } else {
            delete fieldsDefaultValidation["cashAccountingExpiry"];
        }
        //check if vatExemptionManagementForExporter is MOBILE_PLAFOND and vatExempitonForExporterStartingDate is valid
        if (
            (!bodyRequest?.vatConfiguration?.vatExempitonForExporterStartingDate?.month ||
                !bodyRequest?.vatConfiguration?.vatExempitonForExporterStartingDate?.year) &&
            bodyRequest?.vatConfiguration?.vatExemptionManagementForExporter === "MOBILE_PLAFOND"
        ) {
            fieldsDefaultValidation["vatExempitonForExporterStartingDate"] = true;
        } else {
            delete fieldsDefaultValidation["vatExempitonForExporterStartingDate"];
        }

        setErrors({
            ...fieldsDefaultValidation
        });
    }, [bodyRequest, setErrors]);

    useEffect(() => {
        if (bodyRequest?.vatConfiguration) {
            const newVatConfiguration = Object.keys(bodyRequest?.vatConfiguration).reduce((acc, key) => {
                if (bodyRequest?.vatConfiguration[key] !== null) {
                    acc[key] = bodyRequest?.vatConfiguration[key];
                }
                return acc;
            }, {});
            setVatConfiguration(newVatConfiguration);
            setSpecialVatRegimeAdditionalFields(
                specialVatRegimeOptions(intl)?.find(
                    vatRegime => vatRegime.id === bodyRequest?.vatConfiguration?.specialVatRegime
                )?.subcomponent
            );
        }
    }, [bodyRequest?.vatConfiguration, intl, setVatConfiguration]);

    return (
        <Stack spacing={5}>
            <Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", maxWidth: "60%" }}>
                    <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-business.step4-title" />
                    </Typography>
                    {businessId && !isEditingStep && !ceasedBusiness(bodyRequest?.endDate) && (
                        <Button onClick={() => setIsEditingStep(true)}>
                            <Stack direction="row" spacing={1}>
                                <Box>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Box>
                                <Box>
                                    <FormattedMessage id="general.edit" />
                                </Box>
                            </Stack>
                        </Button>
                    )}
                </Stack>
                {!businessId && (
                    <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-business.step4-description" />
                    </Typography>
                )}

                <FormControl
                    sx={{
                        minWidth: "320px",
                        my: 3,
                        gap: isEditingStep ? "3rem" : "1rem",
                        maxWidth: "60%"
                    }}
                >
                    <Stack>
                        <Field
                            fullWidth
                            component="radio"
                            label={intl.formatMessage({ id: "field.business.vatPeriodicity" })}
                            isEditing={isEditingStep}
                            radioGroupAlign="vertical"
                            onChange={val => {
                                setVatConfiguration(
                                    {
                                        ...vatConfiguration,
                                        vatPeriodicity: val
                                    },
                                    newVatConfiguration => {
                                        handleChange(newVatConfiguration, "vatConfiguration");
                                    }
                                );
                            }}
                            value={vatConfiguration?.vatPeriodicity}
                            options={vatPeriodicityOptions(intl)}
                        />
                        {displayErrors && errors.vatPeriodicity && (
                            <FormHelperText error={displayErrors && errors.vatPeriodicity}>
                                {intl.formatMessage({ id: "error.business.vatPeriodicity" })}
                            </FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <Field
                            fullWidth
                            component="radio"
                            label={intl.formatMessage({ id: "field.business.reportRegister" })}
                            isEditing={isEditingStep}
                            radioGroupAlign="vertical"
                            onChange={val => {
                                setVatConfiguration(
                                    {
                                        ...vatConfiguration,
                                        vatStatementRegistry: val
                                    },
                                    newVatConfiguration => {
                                        handleChange(newVatConfiguration, "vatConfiguration");
                                    }
                                );
                            }}
                            value={vatConfiguration?.vatStatementRegistry}
                            options={[
                                {
                                    id: "ON_SALES",
                                    value: intl.formatMessage({
                                        id: "c-customer-edit-business.reportRegister.ON_SALES"
                                    })
                                },
                                {
                                    id: "ON_RECEIPT",
                                    value: intl.formatMessage({
                                        id: "c-customer-edit-business.reportRegister.ON_RECEIPT"
                                    })
                                }
                            ]}
                        />
                        {displayErrors && errors.vatStatementRegistry && (
                            <FormHelperText error={displayErrors && errors.vatStatementRegistry}>
                                {intl.formatMessage({ id: "error.business.vatStatementRegistry" })}
                            </FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <Field
                            fullWidth
                            component="radio"
                            label={intl.formatMessage({ id: "field.business.depositType" })}
                            isEditing={isEditingStep}
                            radioGroupAlign="vertical"
                            onChange={val => {
                                setVatConfiguration(
                                    {
                                        ...vatConfiguration,
                                        vatInAdvanceType: val
                                    },
                                    newVatConfiguration => {
                                        handleChange(newVatConfiguration, "vatConfiguration");
                                    }
                                );
                            }}
                            value={vatConfiguration?.vatInAdvanceType}
                            options={[
                                {
                                    id: "HISTORICAL",
                                    value: intl.formatMessage({
                                        id: "c-customer-edit-business.depositType.HISTORICAL"
                                    })
                                },
                                {
                                    id: "ANALYTICAL",
                                    value: intl.formatMessage({
                                        id: "c-customer-edit-business.depositType.ANALYTICAL"
                                    })
                                },
                                {
                                    id: "FORECAST",
                                    value: intl.formatMessage({
                                        id: "c-customer-edit-business.depositType.FORECAST"
                                    })
                                }
                            ]}
                        />
                        {displayErrors && errors.vatInAdvanceType && (
                            <FormHelperText error={displayErrors && errors.vatInAdvanceType}>
                                {intl.formatMessage({ id: "error.business.vatInAdvanceType" })}
                            </FormHelperText>
                        )}
                    </Stack>
                    <Stack sx={{ gap: "2rem" }}>
                        <Field
                            fullWidth
                            component="select"
                            label={intl.formatMessage({ id: "field.business.taxRegime" })}
                            labelTip={isEditingStep && intl.formatMessage({ id: "tips.business.taxRegimeDescription" })}
                            isEditing={isEditingStep}
                            onChange={val => {
                                const cleanVatConfiguration = {
                                    ...vatConfiguration,
                                    flatTaxAccountingRate: null,
                                    cashAccounting: false,
                                    cashAccountingExpiry: null
                                };
                                setVatConfiguration(
                                    {
                                        ...cleanVatConfiguration,
                                        specialVatRegime: val,
                                        vatDeductionRate: specialVatDeductionRate[val]
                                    },
                                    newVatConfiguration => {
                                        handleChange(newVatConfiguration, "vatConfiguration");
                                    }
                                );
                                setSpecialVatRegimeAdditionalFields(
                                    specialVatRegimeOptions(intl)?.find(vatRegime => vatRegime.id === val)?.subcomponent
                                );
                            }}
                            value={vatConfiguration?.specialVatRegime}
                            options={specialVatRegimeOptions(intl).map(item => {
                                return { id: item.id, value: item.value };
                            })}
                            selectHeight={320}
                        />

                        {vatConfiguration?.vatDeductionRate > 0 && (
                            <SubField>
                                <Field
                                    fullWidth
                                    isEditing={false}
                                    component="textfield"
                                    label={intl.formatMessage({ id: "field.business.vatDeductionRate" })}
                                    value={`${vatConfiguration?.vatDeductionRate}%`}
                                />
                            </SubField>
                        )}

                        {specialVatRegimeAdditionalFields && (
                            <SubField>
                                <Field
                                    {...specialVatRegimeAdditionalFields}
                                    isEditing={specialVatRegimeAdditionalFields?.isEditing ?? isEditingStep}
                                    value={bodyRequest?.vatConfiguration?.[specialVatRegimeAdditionalFields?.k]}
                                    onChange={val => {
                                        const isBool = val === "true" || val === "false";
                                        const finalValue = isBool ? val === "true" : val;

                                        setVatConfiguration(
                                            {
                                                ...vatConfiguration,
                                                cashAccountingExpiry: null,
                                                [specialVatRegimeAdditionalFields?.k]: finalValue
                                            },
                                            newVatConfiguration => {
                                                handleChange(newVatConfiguration, "vatConfiguration");
                                            }
                                        );
                                    }}
                                    sx={{ minWidth: "250px" }}
                                    fullWidth
                                    additionalProps={{
                                        ...specialVatRegimeAdditionalFields?.additionalProps,
                                        error: displayErrors && errors?.[specialVatRegimeAdditionalFields?.k],
                                        helperText:
                                            displayErrors &&
                                            errors?.[specialVatRegimeAdditionalFields?.k] &&
                                            intl.formatMessage({
                                                id: `error.business.${specialVatRegimeAdditionalFields?.k}`
                                            })
                                    }}
                                />
                                {vatConfiguration?.cashAccounting && (
                                    <Field
                                        component="datepicker"
                                        label={intl.formatMessage({
                                            id: "field.business.cashAccountingExpiry"
                                        })}
                                        value={
                                            new Date(
                                                getISOFromDateAndMonth(vatConfiguration?.cashAccountingExpiry) ||
                                                    new Date()
                                            )
                                        }
                                        isEditing={specialVatRegimeAdditionalFields?.isEditing ?? isEditingStep}
                                        style={{ width: "420px" }}
                                        onChange={val => {
                                            setVatConfiguration(
                                                {
                                                    ...vatConfiguration,
                                                    cashAccountingExpiry: getISODateAndMonth(val)
                                                },
                                                newVatConfiguration => {
                                                    handleChange(newVatConfiguration, "vatConfiguration");
                                                }
                                            );
                                        }}
                                        additionalProps={{
                                            views: ["month", "year"],
                                            renderInput: params => (
                                                <TextField
                                                    {...params}
                                                    sx={{ width: "40%" }}
                                                    error={displayErrors && errors.cashAccountingExpiry}
                                                    helperText={
                                                        displayErrors &&
                                                        errors.cashAccountingExpiry &&
                                                        intl.formatMessage({
                                                            id: "error.business.cashAccountingExpiry"
                                                        })
                                                    }
                                                />
                                            )
                                        }}
                                    />
                                )}
                            </SubField>
                        )}

                        {displayErrors && errors.specialVatRegime && (
                            <FormHelperText error={displayErrors && errors.specialVatRegime}>
                                {intl.formatMessage({ id: "error.business.specialVatRegime" })}
                            </FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <div>
                            {intl.formatMessage({ id: "field.business.plafondManagement" })}{" "}
                            <InfoTooltip
                                title={intl.formatMessage({ id: "tips.business.plafondManagementTitle" })}
                                message={intl.formatMessage({
                                    id: "tips.business.plafondManagementDescription"
                                })}
                            />
                        </div>
                        <RadioSub
                            id="vatExemptionManagementForExporter"
                            isEditing={isEditingStep}
                            onChange={val => {
                                setVatConfiguration(
                                    {
                                        ...vatConfiguration,
                                        vatExemptionManagementForExporter: val,
                                        vatExempitonForExporterStartingDate: null
                                    },
                                    newVatConfiguration => {
                                        handleChange(newVatConfiguration, "vatConfiguration");
                                    }
                                );
                            }}
                            value={vatConfiguration?.vatExemptionManagementForExporter}
                            options={vatExemptionManagementForExporterOptions(
                                intl,
                                vatConfiguration,
                                setVatConfiguration,
                                handleChange
                            )}
                        />
                        {displayErrors && errors.vatExemptionManagementForExporter && (
                            <FormHelperText error={displayErrors && errors.vatExemptionManagementForExporter}>
                                {intl.formatMessage({ id: "error.business.vatExemptionManagementForExporter" })}
                            </FormHelperText>
                        )}

                        {displayErrors && errors.vatExempitonForExporterStartingDate && (
                            <FormHelperText error={displayErrors && errors.vatExempitonForExporterStartingDate}>
                                {intl.formatMessage({ id: "error.business.vatExempitonForExporterStartingDate" })}
                            </FormHelperText>
                        )}
                    </Stack>
                </FormControl>
            </Stack>
        </Stack>
    );
};
