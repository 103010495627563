import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UpdateSearchContainer = styled.div`
    display: flex;
    font-size: 14px;
`;

export const UpdateContainer = styled.div`
    margin-right: 8px;
    padding-bottom: 10px;
    padding-top: 18px;
`;


export const MarginIcon = styled(FontAwesomeIcon)`
    margin-right: 4px;
`;


export const SearchIconContainer = styled.div`
    margin-top: 28px;
    background-color: ${props => props.theme.colors.primary.richElectricBlue};
    min-width: 40px;
    text-align: center;
    height: 40px;
    background-image: linear-gradient(
        to right,
        ${(props) => props.theme.colors.primary.richElectricBlue},
        ${(props) => props.theme.colors.primary.cyanProgress}
      );
    background-color: ${(props) => props.theme.colors.primary.richElectricBlue};
    color: white;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 16px;
    height: 16px;
    width: 16px;
    transition: 0.1s color ease;
    margin-top: 12px;
`;
