import React from "react";
import { FieldContainer, FieldLabel, FieldText } from "./styled.js";

const CustomerEditRegistryField = ({ children, dark, label, labelWidth, lightText, vertical }) => {
    return (
        <FieldContainer vertical={vertical}>
            <FieldLabel dark={dark} vertical={vertical} width={labelWidth}>
                {label}
            </FieldLabel>
            <FieldText dark={dark} lightText={lightText} vertical={vertical}>
                {children}
            </FieldText>
        </FieldContainer>
    );
};

CustomerEditRegistryField.defaultProps = {
    width: 220,
    vertical: false,
    dark: false
};

export default CustomerEditRegistryField;
