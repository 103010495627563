import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle, IconButton } from "@vapor/react-material";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faMinusCircle } from "@fortawesome/pro-regular-svg-icons/faMinusCircle";
import { Close } from "@mui/icons-material";
import React, { useEffect } from "react";

export const AlertMessage = ({ status, title, description, setAlertMessage }) => {
    useEffect(() => {
        if (status) {
            setTimeout(() => {
                setAlertMessage(null);
            }, 5000);
        }
    }, [status, setAlertMessage]);

    return (
        <Alert
            variant="outlined"
            severity={status}
            icon={<FontAwesomeIcon icon={status === "success" ? faCheckCircle : faMinusCircle} />}
            action={
                <IconButton size="small" onClick={() => setAlertMessage(null)}>
                    <Close />
                </IconButton>
            }
            sx={{
                position: "absolute",
                right: "3rem",
                zIndex: 400
            }}
        >
            {title && (
                <AlertTitle>
                    <strong>{title}</strong>
                </AlertTitle>
            )}
            {description && description}
        </Alert>
    );
};
