import React, { useCallback } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@vapor/react-material";
import { FormattedMessage, useIntl } from "react-intl";
import { ResetSelect } from "../../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";

const SelectDelegateds = ({ delegateds, bodyRequest, setBodyRequest, selected, setSelected }) => {
    const intl = useIntl();

    const handleSelect = useCallback(
        e => {
            const { value } = e.target;

            if (value) {
                setSelected([...value]);

                value.forEach(delegatedTaxId => {
                    const delegatedFound = delegateds.find(item => item.taxId === delegatedTaxId);
                    if (delegatedFound) {
                        const { firstName, lastName, description, itemId } = delegatedFound;
                        const itemFound = bodyRequest.find(item => item.taxId === delegatedTaxId);
                        if (!itemFound) {
                            setBodyRequest([
                                ...bodyRequest,
                                {
                                    taxId: delegatedTaxId,
                                    firstName,
                                    lastName,
                                    description,
                                    id: itemId,
                                    selected: true
                                }
                            ]);
                        }
                    }
                });

                const itemsToRemove = bodyRequest.filter(item => !value.includes(item.taxId));
                if (itemsToRemove.length > 0) {
                    const newBodyRequest = bodyRequest.filter(item => !itemsToRemove.includes(item));
                    setBodyRequest(newBodyRequest);
                }
            }
        },
        [bodyRequest, delegateds, setBodyRequest, setSelected]
    );

    const resetSelect = useCallback(
        e => {
            e.preventDefault();
            setSelected([]);
            setBodyRequest(bodyRequest.filter(item => !item.selected));
        },
        [bodyRequest, setBodyRequest, setSelected]
    );

    return (
        <Box>
            <Stack spacing={2} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="select-delegateds">
                        <FormattedMessage id="field.delegated.select-label" />
                    </InputLabel>
                    <Select
                        name="select-delegateds"
                        id="select-delegateds"
                        label="field.delegated.select-placeholder"
                        labelId="labels-select-delegateds"
                        multiple
                        onChange={handleSelect}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 312
                                }
                            }
                        }}
                        sx={{
                            "& div#select-delegateds": {
                                display: "flex",
                                alignContent: "center",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "flex-start"
                            },
                            "& div#select-delegateds > div": {
                                padding: "0 .1rem 0 .4rem"
                            },
                            "& div#select-delegateds > div > div:last-child": {
                                display: "none"
                            }
                        }}
                        value={selected}
                    >
                        {delegateds?.length > 0 &&
                            delegateds.map(delegated => (
                                <MenuItem key={delegated.taxId} value={delegated.taxId}>
                                    <Stack direction="column" spacing={1}>
                                        <Box fontSize={"15px"} fontWeight={500} width="fit-content">
                                            {`${delegated.firstName} ${delegated.lastName}`}
                                        </Box>
                                        <Box fontSize={"14px"} sx={{ textTransform: "uppercase" }}>
                                            {intl.formatMessage({ id: "general.tax-id-short" })}:{delegated.taxId}
                                        </Box>
                                    </Stack>
                                </MenuItem>
                            ))}
                    </Select>
                    <ResetSelect disabled={selected.length < 1} onClick={resetSelect}>
                        <FontAwesomeIcon icon={faTimes} />
                    </ResetSelect>
                </FormControl>
            </Stack>
        </Box>
    );
};

export default SelectDelegateds;
