import styled from "styled-components";

export const CustomerEditIntermediaryTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CustomerEditIntermediaryTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
`;

export const CustomerEditIntermediaryTitleText = styled.div`
    margin-left: 10px;
`;

export const CustomerEditIntermediaryTitleStatus = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const CustomerEditIntermediaryStatusTag = styled.div`
    flex: 1 0 auto;
    margin-bottom: 1rem;
`;

export const CustomerEditIntermediariesIntermediaryContentLine = styled.div`
    max-width: 230px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 30px;
`;

export const CustomerEditIntermediaryFooterContainer = styled.div`
    width: 100%;
`;

export const CustomerEditIntermediariesIntermediaryContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 100%;
`;

export const CustomerEditIntermediariesDetails = styled.span`
    font-weight: 600;
    padding-left: 0.15rem;
`;

export const CustomerIntermediaryModalFooter = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const EditButton = styled.div`
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
    border-top-style: solid;
    border-top-width: 0.1px;
    border-top-color: ${props => props.theme.colors.background.azureishWhite};
`;
export const DeleteButton = styled.div`
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: -3px;
`;
