import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

export const CustomersLayout = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    min-height: calc(102vh);
    border: ${props => (props.withBorder ? "1rem solid #e4eaf0" : "none")};
`;

export const Container = styled.div`
    display: grid;
    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0 2rem;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
        ". header header header header header header header header header header ."
        ". filters filters filters filters filters filters filters filters filters filters ."
        ". . . . . . . . . toggle toggle ."
        ". customers customers customers customers customers customers customers customers customers customers .";
`;

export const Header = styled.div`
    grid-area: header;
`;

export const FilterContainer = styled.div`
    grid-area: filters;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1240px) {
        gap: 1rem;
    }
`;

export const FilterContainerLeft = styled.div`
    display: flex;
    gap: 0px 1.5rem;
`;

export const FilterContainerRight = styled.div`
    align-self: flex-start;
`;

export const SearchbarInput = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const ToggleViewTypeContainer = styled.div`
    grid-area: toggle;
    justify-self: flex-end;
    display: flex;
`;

export const ToggleButton = styled.div`
    color: ${props => props.theme.colors.text.silverSand};
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.colors.primary.richElectricBlue};
    }

    ${props =>
        props.isActive &&
        css`
            color: ${props => props.theme.colors.primary.darkImperialBlue};
        `}

    ${props =>
        props.disabled &&
        css`
            color: ${props => props.theme.colors.primary.columbiaBlue};
            cursor: not-allowed;
        `};
`;

export const CustomersContainer = styled.div`
    grid-area: customers;
    margin-bottom: 2rem;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 4rem;
`;

export const CustomersCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 385px));
    grid-gap: 1rem;
    justify-content: center;
`;

export const EmptyCustomersMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9.5rem;
`;

export const EmptyCustomersIconsContainer = styled.div`
    position: relative;
    margin-bottom: 0.5rem;
`;

export const TableIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.background.azureishWhite};
    font-size: 5rem;
`;

export const TimesIconContainer = styled.div`
    border: 0.25rem solid ${props => props.theme.colors.background.white};
    background-color: ${props => props.theme.colors.primary.darkImperialBlue};
    color: ${props => props.theme.colors.background.white};
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    position: absolute;
    bottom: -2rem;
    right: -1.5rem;
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > * {
        min-width: 48px !important;
    }
`;
