import styled from "styled-components";

export const SideHeading = styled.h3`
    font-family: Cairo;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const SideContent = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
`;
