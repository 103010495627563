import { Box, Button, Stack, Typography } from "@vapor/react-material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserLock, faCircleNotch } from "@fortawesome/pro-regular-svg-icons/";
import { useDispatch, useSelector } from "react-redux";
import { InfoBox } from "../../infobox";
import { Field } from "@onefront/react-workspace-components";
import { defaultBodyAntiMoneyLaundering, idOptions, politicalOfficeHeldOptions } from "./constant";
import { useMatch } from "react-router";
import { AlertMessage } from "../../alert-message";
import { LoadingIcon } from "../../vapor-components/table";
import {
    updateCustomerAntiMoneyLaundering,
    createCustomerAntiMoneyLaundering
} from "../../../actions/antimoneylaundering";
import { getCustomerDetails } from "../../../actions/customers";

export const CustomerEditAntimoneylaundering = ({ render }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const antiMoneyLaundering = useSelector(state => state?.customer?.details?.data?.antiMoneyLaundering);
    const ownerId = useSelector(state => state.initDataFromParent.auth.item.id);
    const [isFilled, setIsFilled] = useState(antiMoneyLaundering ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const [isEditing, setIsEditing] = useState(!isFilled);
    const [bodyRequest, setBodyRequest] = useState(antiMoneyLaundering || { ...defaultBodyAntiMoneyLaundering });
    const [previousBodyRequest, setPreviousBodyRequest] = useState(
        antiMoneyLaundering || { ...defaultBodyAntiMoneyLaundering }
    );

    const {
        params: { item: workspaceId }
    } = useMatch("/:item/customer-edit/main");

    const handleChange = (name, value) => {
        setBodyRequest({
            ...bodyRequest,
            [name]: value
        });
    };

    const handleCancel = () => {
        setBodyRequest(previousBodyRequest);
        setIsEditing(false);
    };

    const handleSave = () => {
        setIsLoading(true);

        dispatch(createCustomerAntiMoneyLaundering({ ...bodyRequest, workspaceId: workspaceId })).then(() => {
            setAlertMessage({
                status: "success",
                title: isFilled ? (
                    <div>
                        <b>{intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-title" })}:</b>{" "}
                        {intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-alert-edit" })}
                    </div>
                ) : (
                    <div>
                        <b>{intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-title" })}:</b>{" "}
                        {intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-alert-save" })}
                    </div>
                )
            });

            dispatch(getCustomerDetails(ownerId, workspaceId, new Date().toISOString())).then(() => {
                setIsLoading(false);
                setIsFilled(true);
                setIsEditing(false);
            });
        });
    };

    const handlePatch = () => {
        setIsLoading(true);

        dispatch(updateCustomerAntiMoneyLaundering({ ...bodyRequest, workspaceId: workspaceId })).then(() => {
            setIsLoading(false);
            setIsFilled(true);
            setIsEditing(false);

            setAlertMessage({
                status: "success",
                title: isFilled ? (
                    <div>
                        <b>{intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-title" })}:</b>{" "}
                        {intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-alert-edit" })}
                    </div>
                ) : (
                    <div>
                        <b>{intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-title" })}:</b>{" "}
                        {intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-alert-save" })}
                    </div>
                )
            });
        });
    };

    if (!render) {
        return null;
    }

    return (
        <>
            {alertMessage?.status && (
                <AlertMessage
                    status={alertMessage?.status}
                    title={alertMessage?.title}
                    description={alertMessage?.description}
                    setAlertMessage={setAlertMessage}
                />
            )}

            <Box
                sx={{
                    width: "70%",
                    "@media (min-width: 1680px)": {
                        width: "60%"
                    }
                }}
            >
                <Stack mb={6}>
                    <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                        <Box sx={{ display: "inline-block", marginRight: ".5rem" }}>
                            <FontAwesomeIcon icon={faUserLock} />
                        </Box>
                        <FormattedMessage id="c-customer-edit-antimoneylaundering-title" />
                    </Typography>
                    <Typography variant="bodyLarge" gutterBottom component="div">
                        <FormattedMessage id="c-customer-edit-antimoneylaundering-description" />
                    </Typography>
                </Stack>
                {/* Info */}
                {isEditing && (
                    <InfoBox
                        title={intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-info-title" })}
                        description={intl.formatMessage({ id: "c-customer-edit-antimoneylaundering-info-description" })}
                    />
                )}
                {/* Who have to verify */}
                <Stack mt={isEditing ? 6 : 2} spacing={isEditing ? 3 : 0}>
                    <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                        <Box component={"div"}>
                            <Typography variant="bodyLarge700" component="div" color="primary.textTitleColor">
                                <FormattedMessage id="c-customer-edit-antimoneylaundering-who-have-to-verify" />
                            </Typography>
                        </Box>
                        {isFilled && !isEditing && (
                            <Button
                                onClick={() => {
                                    setIsEditing(true);
                                    setPreviousBodyRequest(
                                        antiMoneyLaundering || { ...defaultBodyAntiMoneyLaundering }
                                    );
                                }}
                            >
                                <Stack direction="row" spacing={1}>
                                    <Box>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Box>
                                    <Box>
                                        <FormattedMessage id="general.edit" />
                                    </Box>
                                </Stack>
                            </Button>
                        )}
                    </Stack>
                    <Box component={"div"}>
                        <Field
                            component="textfield"
                            fullWidth
                            isEditing={isEditing}
                            name="verifiedBy"
                            label={intl.formatMessage({
                                id: "c-customer-edit-antimoneylaundering-who-have-to-verify-label"
                            })}
                            defaultValue={bodyRequest?.verifiedBy}
                            value={bodyRequest?.verifiedBy}
                            additionalProps={{
                                placeholder: intl.formatMessage({
                                    id: "c-customer-edit-antimoneylaundering-who-have-to-verify-placeholder"
                                })
                            }}
                            onChange={value => handleChange("verifiedBy", value)}
                        />
                    </Box>
                </Stack>

                {/* Informations about the customer company */}
                <Box component={"div"} sx={{ marginTop: isEditing ? "3rem" : "2rem" }}>
                    <Typography variant="bodyLarge700" gutterBottom component="div" color="primary.textTitleColor">
                        <FormattedMessage id="c-customer-edit-antimoneylaundering-company-verification" />
                    </Typography>
                </Box>
                <Stack mt={isEditing ? 4 : 2} spacing={isEditing ? 5 : 2}>
                    <Box component={"div"}>
                        <Field
                            component="select"
                            fullWidth
                            isEditing={isEditing}
                            name="verifiedWith"
                            label={intl.formatMessage({
                                id: "c-customer-edit-antimoneylaundering-idcard-label"
                            })}
                            value={bodyRequest?.verifiedWith}
                            options={idOptions(intl)}
                            onChange={value => handleChange("verifiedWith", value)}
                        />
                    </Box>
                    {bodyRequest?.verifiedWith === "OTHER" && (
                        <Box component={"div"}>
                            <Field
                                component="textfield"
                                fullWidth
                                isEditing={isEditing}
                                name="other"
                                label={intl.formatMessage({
                                    id: "c-customer-edit-antimoneylaundering-other-label"
                                })}
                                value={bodyRequest?.other}
                                defaultValue={bodyRequest?.other}
                                onChange={value => handleChange("other", value)}
                            />
                        </Box>
                    )}
                    <Box component={"div"} sx={{ marginTop: "1rem" }}>
                        <Field
                            component="textfield"
                            fullWidth
                            isEditing={isEditing}
                            name="occupationalActivity"
                            label={intl.formatMessage({
                                id: "c-customer-edit-antimoneylaundering-occupationalActivity-label"
                            })}
                            defaultValue={bodyRequest?.occupationalActivity}
                            value={bodyRequest?.occupationalActivity}
                            onChange={value => handleChange("occupationalActivity", value)}
                        />
                    </Box>
                    <Box component={"div"} sx={{ marginTop: "1rem" }}>
                        <Field
                            component="radio"
                            radioGroupAlign="vertical"
                            fullWidth
                            isEditing={isEditing}
                            name="politicallyExposedPerson"
                            label={intl.formatMessage({
                                id: "c-customer-edit-antimoneylaundering-politicallyExposedPerson-label"
                            })}
                            value={bodyRequest?.politicallyExposedPerson?.toString()}
                            options={[
                                { id: "false", value: intl.formatMessage({ id: "general.no" }) },
                                { id: "true", value: intl.formatMessage({ id: "general.yes" }) }
                            ]}
                            onChange={value => handleChange("politicallyExposedPerson", value === "true")}
                        />
                    </Box>
                </Stack>

                {bodyRequest?.politicallyExposedPerson && (
                    <Stack mt={isEditing ? 5 : 2}>
                        <Box component={"div"}>
                            <Field
                                component="select"
                                fullWidth
                                isEditing={isEditing}
                                name="politicalOfficeHeld"
                                label={intl.formatMessage({
                                    id: "c-customer-edit-antimoneylaundering-politicalOfficeHeld-label"
                                })}
                                value={bodyRequest?.politicalOfficeHeld}
                                selectHeight="320px"
                                options={politicalOfficeHeldOptions(intl)}
                                onChange={value => handleChange("politicalOfficeHeld", value)}
                            />
                        </Box>
                    </Stack>
                )}

                <Box component={"div"} sx={{ height: "3rem" }} />

                {isFilled && isEditing && (
                    <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            <FormattedMessage id="general.cancel" />
                        </Button>
                        <Button variant="contained" onClick={handlePatch} disabled={isLoading}>
                            {isLoading && (
                                <LoadingIcon
                                    color="white"
                                    size="1rem"
                                    height="fit-content"
                                    width="fit-content"
                                    rightGutter
                                >
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
                                </LoadingIcon>
                            )}
                            <FormattedMessage id="general.save" />
                        </Button>
                    </Stack>
                )}

                {!isFilled && isEditing && (
                    <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
                        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
                            {isLoading && (
                                <LoadingIcon
                                    color="white"
                                    size="1rem"
                                    height="fit-content"
                                    width="fit-content"
                                    rightGutter
                                >
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-pulse" />
                                </LoadingIcon>
                            )}
                            <FormattedMessage id="general.apply" />
                        </Button>
                    </Stack>
                )}

                <Box component={"div"} sx={{ height: "7rem" }} />
            </Box>
        </>
    );
};
