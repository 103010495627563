import React from "react";
import { useIntl } from "react-intl";

import Card from "../vapor-components/card";
import { Action, AppStatusCircle, ColTitle, ColValue, ToolsGrid } from "./styled";

const AdvisingToolsCard = () => {
    const intl = useIntl();

    const tools = [
        {
            appName: "Check Up Impresa",
            action: () => {},
            actionLabel: "Acquista"
        },
        {
            appName: "Adempimenti Agroalimentare",
            action: () => {},
            actionLabel: "Attiva"
        },
        {
            appName: "Incassa subito",
            action: () => {},
            actionLabel: "Attiva"
        },
        {
            appName: "HR Paghe",
            action: () => {},
            actionLabel: "Acquista"
        }
    ];

    return (
        <Card title="Strumenti che potrebbero interessarti">
            <ToolsGrid>
                <ColTitle>
                    {intl.formatMessage({
                        id: "c-advertising-tools-card.other-services"
                    })}
                    :
                </ColTitle>
                <ColTitle>
                    {intl.formatMessage({
                        id: "c-advertising-tools-card.actions"
                    })}
                    :
                </ColTitle>
                {tools.map((tool, key) => (
                    <React.Fragment key={key}>
                        <ColValue>
                            <AppStatusCircle />
                            {tool.appName}
                        </ColValue>
                        <ColValue>
                            <Action onClick={() => tool.action}>{tool.actionLabel}</Action>
                        </ColValue>
                    </React.Fragment>
                ))}
            </ToolsGrid>
        </Card>
    );
};

export default AdvisingToolsCard;
