//parse data from p12 file
import forge from "node-forge";

export function parsePKCS12(p12Base64, password) {
    const p12Der = forge.util.decode64(p12Base64);
    const p12Asn1 = forge.asn1.fromDer(p12Der);
    const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);
    const data = p12.getBags({ bagType: forge.pki.oids.certBag });

    if (!data || !data?.[forge.pki.oids.certBag]?.[0])
        throw new Error("Unable to parse certificate. Incorrect Password?");

    return data[forge.pki.oids.certBag][0];
}
