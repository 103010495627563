import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";

import Card from "../vapor-components/card";
import { AppStatusCircle, ColTitle, ColValue, FooterButtonContainer, StatusIcon, ToolsGrid } from "./styled";

const ActiveToolsCard = tools => {
    const intl = useIntl();

    const statusIconAndLabel = active => {
        return active
            ? {
                  icon: faCheckCircle,
                  label: intl.formatMessage({
                      id: "general.status.ACTIVE"
                  })
              }
            : {
                  icon: faClock,
                  label: intl.formatMessage({
                      id: "general.status.AWATING_ACCEPTANCE"
                  })
              };
    };

    //TODO: capire gestione terza colonna, possibili altenative (app description)
    return (
        <Card
            title={intl.formatMessage({
                id: "c-active-tools-card.title"
            })}
            footer={
                <FooterButtonContainer>
                    <Button kind="tertiary">
                        {intl.formatMessage({
                            id: "c-active-tools-card.manage-button-label"
                        })}
                    </Button>
                </FooterButtonContainer>
            }
        >
            <ToolsGrid>
                <ColTitle>
                    {intl.formatMessage({
                        id: "c-active-tools-card.active-services"
                    })}
                    :
                </ColTitle>
                <ColTitle>
                    {intl.formatMessage({
                        id: "c-active-tools-card.consumption-charge"
                    })}
                    :
                </ColTitle>
                <ColTitle>
                    {intl.formatMessage({
                        id: "c-active-tools-card.management-status"
                    })}
                    :
                </ColTitle>
                {tools.tools.map((tool, key) => (
                    <React.Fragment key={key}>
                        <ColValue>
                            <AppStatusCircle />
                            {tool.agyoService.description}
                        </ColValue>
                        <ColValue>
                            {intl.formatMessage({
                                id: tool.owner
                                    ? "c-active-tools-card.consumptionTo.client"
                                    : "c-active-tools-card.consumptionTo.studio"
                            })}
                        </ColValue>
                        <ColValue>
                            <StatusIcon status={tool.agyoService.active}>
                                <FontAwesomeIcon icon={statusIconAndLabel(tool.agyoService.active).icon} />
                            </StatusIcon>
                            {statusIconAndLabel(tool.agyoService.active).label}
                        </ColValue>
                    </React.Fragment>
                ))}
            </ToolsGrid>
        </Card>
    );
};

ActiveToolsCard.propTypes = {
    tools: PropTypes.array
};

ActiveToolsCard.defaultProps = {
    tools: []
};

export default ActiveToolsCard;
