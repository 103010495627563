import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@vapor/react-material";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormField from "../../../../vapor-components/field";
import { PERSON, step1DefaultBody } from "../../constants";
import { ResetSelect } from "../../styled";

const SelectIntermediaries = ({
    intermediaries,
    value,
    bodyRequest,
    setBodyRequest,
    selected,
    setSelected,
    setDisplayBox,
    setError
}) => {
    const intl = useIntl();

    const handleSelect = useCallback(
        e => {
            const { value } = e.target;
            if (value) {
                const intermediary = intermediaries.find(intermediary => intermediary.taxId === value);
                if (intermediary) {
                    const { vatNumber, description } = intermediary;
                    setSelected(true);
                    setError({});
                    setDisplayBox(false);
                    setBodyRequest({ ...bodyRequest, taxId: value, vatNumber, description });
                }
            } else {
                setSelected(false);
                setBodyRequest({ ...step1DefaultBody });
            }
        },
        [bodyRequest, intermediaries, setBodyRequest, setSelected, setError, setDisplayBox]
    );

    const resetSelect = useCallback(
        e => {
            e.preventDefault();
            setSelected(false);
            setBodyRequest({ ...step1DefaultBody });
        },
        [setBodyRequest, setSelected]
    );

    return (
        <Box>
            <Stack spacing={2} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="select-intermediaries">
                        <FormattedMessage id="field.intermediary.select-label" />
                    </InputLabel>
                    <Select
                        name="select-intermediary"
                        id="select-intermediary"
                        label="field.intermediary.select-placeholder"
                        labelId="select-intermediaries"
                        onChange={handleSelect}
                        value={value}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 312
                                }
                            }
                        }}
                    >
                        {intermediaries.map((intermediary, index) => (
                            <MenuItem key={index} value={intermediary.taxId}>
                                <Stack direction="column" spacing={1}>
                                    <Box fontSize={"15px"} fontWeight={500}>
                                        {intermediary.description} (
                                        {intermediary.type === PERSON
                                            ? intl.formatMessage({ id: "general.classifiers.PERSON-variant" })
                                            : intl.formatMessage({ id: "general.classifiers.COMPANY" })}
                                        )
                                    </Box>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetSelect onClick={resetSelect} disabled={!selected}>
                        <FontAwesomeIcon icon={faTimes} />
                    </ResetSelect>
                </FormControl>
            </Stack>
            {selected && (
                <Stack spacing={7} mt={3} direction={"row"} alignItems={"center"}>
                    <FormField
                        size="1.1"
                        label={intl.formatMessage({ id: "general.taxId" })}
                        description={bodyRequest.taxId}
                    />
                    <FormField
                        size="1.1"
                        label={intl.formatMessage({ id: "general.piva" })}
                        description={bodyRequest.vatNumber}
                    />
                </Stack>
            )}
        </Box>
    );
};

export default SelectIntermediaries;
