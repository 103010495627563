import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, IconButton, Snackbar } from "@vapor/react-material";
import PropTypes from "prop-types";
import React from "react";

const VaporAlert = ({ autoHideDuration, title, message, onClose, type, open, anchor, sx }) => {
    const { top, left, right, bottom } = anchor;
    const position =
        top || left || right || bottom
            ? {
                  ...(top && { top: `${top} !important` }),
                  ...(left && { left: `${left} !important` }),
                  ...(right && { right: `${right} !important` }),
                  ...(bottom && { bottom: `${bottom} !important` })
              }
            : {};

    const types = {
        success: {
            color: "f058",
            iconName: "check-circle",
            icon:
                "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
        },
        info: {
            color: "f05a",
            iconName: "info-circle",
            icon:
                "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
        },
        error: {
            color: "f056",
            iconName: "minus-circle",
            icon:
                "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
        },
        warning: {
            color: "f06a",
            iconName: "exclamation-circle",
            icon:
                "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm32 224c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        onClose?.();
    };

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: "right",
                vertical: "top"
            }}
            sx={{ ...position, ...sx }}
            autoHideDuration={autoHideDuration || 6000}
            onClose={handleClose}
            open={open}
        >
            <Alert
                action={
                    <IconButton onClick={handleClose} size="small">
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                }
                icon={
                    <FontAwesomeIcon
                        icon={{
                            icon: [
                                512,
                                512,
                                [61533, types[type]?.iconName || "info-circle"],
                                types[type]?.color || "f05a",
                                types[type]?.icon ||
                                    "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
                            ],
                            iconName: types[type]?.iconName || "info-circle",
                            prefix: "fas"
                        }}
                    />
                }
                severity={types[type] ? type : "info"}
                variant="outlined"
            >
                {title && <strong>{title}</strong>}
                {title && <strong>{": "}</strong>}
                {message}
            </Alert>
        </Snackbar>
    );
};

VaporAlert.propTypes = {
    type: PropTypes.oneOf(["success", "info", "error", "warning"])
};

export default VaporAlert;
