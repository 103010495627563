import styled from "styled-components";

export const ToolsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 0.25rem;
    margin-top: 1.5rem;
`;

export const ColTitle = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 0.875rem;
`;

export const ColValue = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 1.5rem;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const AppStatusCircle = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.text.quickSilver};
    margin-right: 0.5rem;
`;

export const Action = styled.button`
    background-color: transparent;
    border: none;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 0.75rem;
    padding: 0;

    :hover,
    :active,
    :focus {
        color: ${props => props.theme.colors.primary.cyanProgress};
    }

    :focus,
    :focus-visible {
        outline: none;
    }
`;
