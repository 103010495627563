import styled from "styled-components";

export const CustomerEditBusinessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CustomerEditBusinessBody = styled.div`
    margin: ${props => props.margin || "4rem 0"};
    display: flex;
    flex-direction: column;
    width: 100%;
`;
