export const sections = [
    {
        value: 0,
        label: "business-step",
        enabled: true
    },
    {
        value: 1,
        label: "business-step",
        enabled: true
    },
    {
        value: 2,
        label: "business-step",
        enabled: true
    },
    {
        value: 3,
        label: "business-step",
        enabled: true
    },
    {
        value: 4,
        label: "business-step",
        enabled: true
    }
];
