import styled from "styled-components";

const color = {
    darkImperialBlue: props => props.theme.colors.primary.darkImperialBlue,
    yankeesBlue: props => props.theme.colors.text.yankeesBlue,
    cadet: props => props.theme.colors.text.cadet,
    white: props => props.theme.colors.background.white,
    whiteSmoke: props => props.theme.colors.background.whiteSmoke,
    quickSilver: props => props.theme.colors.text.quickSilver
};

export const H1 = styled.h1`
    color: ${props => color[props.color]};
    font-size: 2.625rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 800)};
    line-height: 3rem;
`;

export const H2 = styled.h2`
    color: ${props => color[props.color]};
    font-size: 2.125rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    line-height: 2.5rem;
`;

export const H3 = styled.h3`
    color: ${props => color[props.color]};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.18px;
    line-height: ${props => (props.lineHeight ? props.lineHeight : "2rem")};
`;

export const H4 = styled.h4`
    color: ${props => color[props.color]};
    font-size: 1.25rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.15px;
    line-height: ${props => (props.lineHeight ? props.lineHeight : "2rem")};
    margin: 0;
`;

export const H5 = styled.h5`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.12px;
    line-height: 1.5rem;
`;

export const Descriptive = styled.div`
    color: ${props => color[props.color]};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.4px;
    line-height: ${props => (props.lineHeight ? props.lineHeight : "1rem")};
    ${props => props.display && `display: ${props.display} `};
`;

export const BodyCopy = styled.div`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.44px;
    line-height: 1.5rem;
`;

export const SubTitleRegular = styled.div`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.11px;
    line-height: 1.5rem;
    max-width: 90%;
`;

export const SubHeading = styled.div`
    color: ${props => color[props.color]};
    font-size: 0.875rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.1px;
    line-height: 1.375rem;
`;
