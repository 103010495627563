import { Field, FieldsBox } from "@onefront/react-workspace-components";
import { Stack, Typography, FormControl, Box, Button, Link, Alert, Divider } from "@vapor/react-material";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { proRataRows } from "./rows";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { isEmptyOrSpaces } from "../../../../../../utils/string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { useSelector } from "react-redux";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { ceasedBusiness } from "../../constants";
import { useBrowserStorage } from "../../../../../../hook/useBrowserStorage";

export const CustomerEditBusinessStep5 = ({
    bodyRequest,
    setBodyRequest,
    handleChange,
    errors,
    setErrors,
    displayErrors,
    setDisplayErrors,
    isEditingStep,
    setIsEditingStep,
    businessId
}) => {
    const intl = useIntl();
    const ivaProRataOnState = useSelector(
        state => state?.customer?.details?.additionalProps?.business?.find(item => item.id === businessId)?.ivaProRata
    );

    const dateYear = new Date().getFullYear();
    const [items, setItems] = useStateWithCallbackLazy([]);
    const [pastIvaProRata] = useState(ivaProRataOnState?.filter(item => item.proRataYear !== dateYear));
    const [pastIvaProRataSettings, setPastIvaProRataSettings] = useState({});
    const ivaProRataHandling = useBrowserStorage("noIvaProRataHandling");
    const ivaProRataHandlingCheck = ivaProRataHandling
        ?.get()
        ?.find(item => item.businessId === businessId && item.year === new Date().getFullYear());

    const [isEditing, setIsEditing] = useState(false);
    const [itemsSettings, setItemsSettings] = useState({});
    const [itemToSave, setItemToSave] = useState(null);
    const [ivaProRataHandled, setIvaProRataHandled] = useState(bodyRequest?.ivaProRataHandled);

    const ivaProRataOfTheYear = ivaProRataOnState?.find(item => item.proRataYear === dateYear);

    const enableNotifyIvaProRata = () => {
        return new Date().getTime() <= new Date(`January 15, ${new Date().getFullYear()} 00:00:00`).getTime();
    };

    useEffect(() => {
        setErrors({
            ivaProRata: true
        });

        const error = ivaProRataHandled
            ? items?.some(item => {
                  return (
                      isEmptyOrSpaces(item?.proRataYear || "") &&
                      isEmptyOrSpaces(item?.proRataNonDeducibilityRate || "") &&
                      isEmptyOrSpaces(item?.proRataManagementType || "")
                  );
              })
            : false;

        setDisplayErrors(error);

        setErrors({
            ivaProRata: error
        });
    }, [bodyRequest, setErrors, businessId, items, isEditing, ivaProRataHandled, setDisplayErrors]);

    useEffect(() => {
        if (businessId && ivaProRataHandled && items?.length === 0 && bodyRequest?.ivaProRata?.length > 0) {
            setItems(bodyRequest?.ivaProRata?.filter(x => x.proRataYear === dateYear));
        }
    }, [businessId, items, bodyRequest?.ivaProRata, dateYear, setItems, ivaProRataHandled]);

    useEffect(() => {
        let settings = {};

        items?.map(item => {
            settings[item.id] = {
                title: (
                    <Box sx={{ marginBottom: 2 }}>
                        {intl.formatMessage({ id: "general.year" })}{" "}
                        {typeof item?.id === "string" ? item?.proRataYear.toString() : item?.id?.toString()}
                    </Box>
                ),
                notCancellable: businessId && !isEditingStep,
                notEditable: businessId && !isEditingStep
            };
            return null;
        });

        setItemsSettings(settings);
    }, [items, setItemsSettings, isEditing, dateYear, businessId, isEditingStep, intl]);

    useEffect(() => {
        let settingsPastIvaProRata = {};
        pastIvaProRata?.map(item => {
            settingsPastIvaProRata[item.id] = {
                title: (
                    <Box sx={{ marginBottom: 2 }}>
                        {intl.formatMessage({ id: "general.year" })} {item?.proRataYear.toString()}
                    </Box>
                ),
                notCancellable: true,
                notEditable: true
            };
            return null;
        });

        setPastIvaProRataSettings(s => ({ ...s, ...settingsPastIvaProRata }));
    }, [pastIvaProRata, setPastIvaProRataSettings, intl]);

    const handleValueChange = (name, value) => {
        let item = {};

        if (name === "proRataManagementType" && value === "NON_DEDUCTIBLE_VAT_ON_COSTS") {
            item = {
                ...itemToSave,
                proRataNonDeducibilityRate: 100,
                ...(!itemToSave?.id && { id: isEditing }),
                ...(itemToSave?.proRataYear ? { proRataYear: itemToSave?.proRataYear } : { proRataYear: isEditing }),
                [name]: value
            };
        } else if (name === "proRataManagementType") {
            item = {
                ...itemToSave,
                proRataNonDeducibilityRate: "",
                ...(!itemToSave?.id && { id: isEditing }),
                ...(itemToSave?.proRataYear ? { proRataYear: itemToSave?.proRataYear } : { proRataYear: isEditing }),
                [name]: value
            };
        } else {
            item = { ...itemToSave, [name]: value };
        }

        setItemToSave({ ...item });
    };

    return (
        <Stack>
            <Stack direction="row" sx={{ justifyContent: "space-between", maxWidth: "60%" }}>
                <Typography variant="titleMedium" color="primary.textTitleColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.step5" />
                </Typography>

                {businessId && !isEditingStep && !ceasedBusiness(bodyRequest?.endDate) && (
                    <Button onClick={() => setIsEditingStep(true)}>
                        <Stack direction="row" spacing={1}>
                            <Box>
                                <FontAwesomeIcon icon={faEdit} />
                            </Box>
                            <Box>
                                <FormattedMessage id="general.edit" />
                            </Box>
                        </Stack>
                    </Button>
                )}
            </Stack>
            {!businessId && (
                <Typography variant="subtitle" color="primary.textSubduedColor" gutterBottom component="div">
                    <FormattedMessage id="c-customer-edit-business.ivaProRataDescription" />
                </Typography>
            )}

            <FormControl
                sx={{
                    minWidth: 120,
                    my: 3,
                    gap: "2rem",
                    width: "766px"
                }}
            >
                <Stack>
                    {enableNotifyIvaProRata() &&
                        !ivaProRataHandlingCheck &&
                        isEditingStep &&
                        businessId &&
                        !ivaProRataHandled && (
                            <Alert
                                icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                                severity="warning"
                                variant="outlined"
                                sx={{
                                    mb: 4
                                }}
                            >
                                <Typography variant="bodyLarge">
                                    <FormattedMessage
                                        id="alerts.section-ivaprorata-handle"
                                        values={{
                                            b: (...chunks) => <b>{chunks}</b>
                                        }}
                                    />
                                </Typography>
                                <Link
                                    sx={{
                                        ml: 1
                                    }}
                                    onClick={() => {
                                        handleChange(true, "ivaProRataHandled");
                                        handleChange([...bodyRequest?.ivaProRata, { id: dateYear }], "ivaProRata");
                                        setIvaProRataHandled(true);
                                        setItems([{ id: dateYear }]);
                                        setIsEditing(dateYear);
                                    }}
                                >
                                    <Typography variant="bodyLarge" color="#0090d1">
                                        <FormattedMessage
                                            id="alerts.section-ivaprorata-button"
                                            values={{
                                                year: dateYear
                                            }}
                                        />
                                    </Typography>
                                </Link>
                            </Alert>
                        )}
                    <Field
                        component="radio"
                        label={intl.formatMessage({ id: "c-customer-edit-business.proRataQuestion" })}
                        isEditing={isEditingStep}
                        onChange={val => {
                            handleChange(val === "true" ? true : false, "ivaProRataHandled");
                            val === "true"
                                ? handleChange(ivaProRataOnState || [], "ivaProRata")
                                : handleChange(
                                      businessId && ivaProRataOfTheYear?.id ? [...ivaProRataOnState] : [],
                                      "ivaProRata"
                                  );
                            setIvaProRataHandled(val === "true" ? true : false);

                            if (!businessId) {
                                setIsEditing(val === "true" ? dateYear : false);
                                val === "true" ? setItems([{ id: dateYear }]) : setItems([]);
                            } else if (items?.length === 0) {
                                setItems([{ id: dateYear }]);
                                setIsEditing(dateYear);
                            } else {
                                setItems([{ id: dateYear }]);
                                setIsEditing(dateYear);
                            }
                            setItemToSave(null);
                        }}
                        value={ivaProRataHandled}
                        radioGroupAlign="vertical"
                        options={[
                            {
                                id: true,
                                value: intl.formatMessage({ id: "general.yes" })
                            },
                            {
                                id: false,
                                value: intl.formatMessage({ id: "general.no" })
                            }
                        ]}
                    />
                </Stack>
                {ivaProRataHandled && (
                    <Stack>
                        <FieldsBox
                            disableAdd
                            items={items}
                            itemsSettings={itemsSettings}
                            isEditing={isEditing}
                            editedItem={isEditing ? itemToSave : null}
                            enableSave={!isEmptyOrSpaces(itemToSave?.proRataNonDeducibilityRate || "")}
                            name={intl.formatMessage({ id: "general.year" })}
                            onCancel={() => {
                                if (businessId) {
                                    setIsEditing(false);
                                }
                                setItems([]);
                            }}
                            onDelete={() => {
                                handleChange(false, "ivaProRataHandled");
                                handleChange(
                                    businessId && ivaProRataOfTheYear?.id ? [...ivaProRataOnState] : [],
                                    "ivaProRata"
                                );
                                setIvaProRataHandled(false);
                                setItemToSave(null);
                                setItems(null);
                            }}
                            onEdit={(itemId, item) => {
                                setIsEditing(itemId);
                                setItemToSave(item);
                            }}
                            onSave={() => {
                                let newItems = items?.filter(v => v?.id !== isEditing);
                                setItems([...newItems, { ...itemToSave }], items => {
                                    handleChange(
                                        items.map(it => ({
                                            ...it,
                                            proRataNonDeducibilityRate: parseInt(it?.proRataNonDeducibilityRate)
                                        })),
                                        "ivaProRata"
                                    );
                                    setItemToSave(null);
                                    setIsEditing(false);
                                });
                                handleChange(ivaProRataHandled, "ivaProRataHandled");
                            }}
                            onValueChange={handleValueChange}
                            rows={proRataRows(intl, itemToSave, isEditing)}
                        />
                    </Stack>
                )}
                {businessId && pastIvaProRata?.length > 0 && (
                    <Stack>
                        <Divider variant={"fullWidth"} light sx={{ my: 3 }} />
                        <Stack mt={2}>
                            <FieldsBox
                                disableAdd
                                items={pastIvaProRata}
                                itemsSettings={pastIvaProRataSettings}
                                isEditing={false}
                                name={intl.formatMessage({ id: "general.year" })}
                                rows={proRataRows(intl)}
                            />
                        </Stack>
                    </Stack>
                )}
            </FormControl>
        </Stack>
    );
};
