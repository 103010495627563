import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack
} from "@vapor/react-material";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { step3DefaultBody } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { StickyFooter } from "../../../../vapor-components/sticky-footer";
import {
    FieldBoxInfo,
    FieldsBox,
    FieldsBoxHeading,
    FieldsBoxTitle,
    FieldsChooseInfo,
    FieldsContainer,
    Spacer
} from "../../styled";
import { useDispatch, useSelector } from "react-redux";
import { getDelegateds } from "../../../../../actions/intermediaries";
import SelectDelegateds from "../../fields/select-delegateds";
import FormField from "../../../../vapor-components/field";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import ToggleDelegateds from "../../fields/toggle-delegateds";
import DelegatedForm from "../../fields/delegated-form";

export const CustomerEditIntermediariesStep3 = ({ setStepNumber, ownerId, taxIdentifier, delegateds }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.intermediaries?.delegateds?.isLoading);

    const [inCharge, setInCharge] = useState(true);
    const [bodyRequest, setBodyRequest] = useState([]);
    const [delegatedsLength, setDelegatedsLength] = useState(0);
    const [selected, setSelected] = useState([]);
    const [displayBox, setDisplayBox] = useState(false);
    const [error, setError] = useState({});

    const handleChange = e => {
        let { value, name } = e.target;
        setError({});

        console.log(value, name);
    };

    const handleSubmit = e => {
        e.preventDefault();
        console.log(e);
    };

    useEffect(() => {
        dispatch(getDelegateds(ownerId, taxIdentifier));
    }, [dispatch, ownerId, taxIdentifier]);

    useEffect(() => {
        setDelegatedsLength(delegateds?.length || 0);
        setBodyRequest(!isLoading && delegatedsLength < 1 ? [{ ...step3DefaultBody, id: uuidv4() }] : []);
    }, [delegateds, isLoading, delegatedsLength]);

    return (
        <>
            <FieldsContainer>
                {isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "20vh"
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Stack direciton="column" spacing={2} mt={3}>
                        {taxIdentifier?.length > 11 ? (
                            /* Display radio buttons if intermediary is PERSON */
                            <Stack direction="column" mb={4}>
                                <FormControl>
                                    <FormLabel
                                        id="registry-delegated-type"
                                        sx={{ fontWeight: "600", marginBottom: "1rem", fontSize: ".9rem" }}
                                    >
                                        <FormattedMessage id="c-customer-edit-intermediary.question-in-charge" />
                                    </FormLabel>

                                    <RadioGroup
                                        aria-labelledby="registry-delegated-type"
                                        defaultValue={"true"}
                                        sx={{ width: "fit-content" }}
                                        onChange={e => setInCharge(JSON.parse(e.target.value))}
                                        name="controlled-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            control={<Radio />}
                                            label={intl.formatMessage({ id: "general.yes" })}
                                            value={"true"}
                                        />
                                        <FormControlLabel
                                            control={<Radio />}
                                            label={intl.formatMessage({ id: "general.no" })}
                                            value={"false"}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        ) : (
                            /* Display infobox if intermediary is LEGAL */
                            <FieldBoxInfo>
                                <FormField
                                    label={intl.formatMessage(
                                        { id: "c-customer-edit-intermediaries.delegateds-companyinfo" },
                                        { icon: <FontAwesomeIcon icon={faInfoCircle} /> }
                                    )}
                                    description={intl.formatMessage({
                                        id: "c-customer-edit-intermediaries.delegateds-companyinfo-description"
                                    })}
                                />
                            </FieldBoxInfo>
                        )}

                        {inCharge && delegatedsLength < 1 && (
                            /* Display infobox if intermediary is PERSON and has no delegateds */
                            <FieldsBox>
                                <FieldsBoxHeading>
                                    <FieldsBoxTitle>
                                        <FormattedMessage id="c-customer-edit-intermediaries.delegated" />
                                    </FieldsBoxTitle>
                                </FieldsBoxHeading>
                                <DelegatedForm
                                    bodyRequest={bodyRequest}
                                    setBodyRequest={setBodyRequest}
                                    handleChange={handleChange}
                                    error={error}
                                />
                            </FieldsBox>
                        )}

                        {inCharge && delegatedsLength > 0 && (
                            /* Display the select if there is delegateds from API */
                            <SelectDelegateds
                                delegateds={delegateds}
                                bodyRequest={bodyRequest}
                                setBodyRequest={setBodyRequest}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        {inCharge &&
                            selected.length > 0 &&
                            bodyRequest
                                .filter(item => item.selected)
                                .map(item => (
                                    /* Display with Rows the selected delegateds */
                                    <Stack spacing={7} mt={3} direction={"row"} alignItems={"center"}>
                                        <FormField
                                            size="1.1"
                                            label={intl.formatMessage({ id: "general.fullname" })}
                                            description={`${item.firstName} ${item.lastName}`}
                                        />
                                        <FormField
                                            size="1.1"
                                            label={intl.formatMessage({ id: "general.taxId" })}
                                            description={item.taxId}
                                        />
                                    </Stack>
                                ))}

                        {inCharge && delegatedsLength > 1 && (
                            /* Toggle delegateds if there are delegateds from API */
                            <Stack spacing={4} mt={12}>
                                <FieldsChooseInfo>
                                    <FormattedMessage id="c-customer-edit-intermediary.delegateds.choose-totype" />
                                </FieldsChooseInfo>
                                <ToggleDelegateds
                                    bodyRequest={bodyRequest}
                                    setBodyRequest={setBodyRequest}
                                    displayBox={displayBox}
                                    setDisplayBox={setDisplayBox}
                                    handleChange={handleChange}
                                    error={error}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
            </FieldsContainer>
            <Spacer spacing={"15em"} />
            <StickyFooter pullHeight={"4rem"}>
                <Stack spacing={3} direction="row">
                    <Button
                        variant="text"
                        size="large"
                        sx={{
                            width: "fit-content",
                            "&:hover": { background: "none", color: "inherit" },
                            "&:focus": { background: "none", color: "inherit" }
                        }}
                        onClick={() => setStepNumber(1)}
                    >
                        <Stack direction="row">
                            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "1rem" }} />
                            <FormattedMessage id="general.back" />
                        </Stack>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ width: "fit-content", padding: "0 2rem" }}
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="general.conclude" />
                    </Button>
                </Stack>
            </StickyFooter>
        </>
    );
};
